import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IInfoChapter } from 'common_library';
import { MultimediaService } from 'src/app/services/entities/multimedia.service';

@Component({
  selector: 'app-info-preview',
  templateUrl: './info-preview.component.html',
  styleUrls: ['./info-preview.component.scss']
})
export class InfoPreviewComponent implements OnInit {

  @Input() chapter: IInfoChapter;
  @Input() chapters: IInfoChapter[];

  constructor(public multimediaService: MultimediaService, private translate:TranslateService) { }

  ngOnInit(): void {
  }

}

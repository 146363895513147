export type ValueOf<T> = T[keyof T];

export const PARKING_RESTRICTIONS = Object.freeze({
  EV_ONLY: "EV_ONLY",
  PLUGGED: "PLUGGED",
  DISABLED: "DISABLED",
  CUSTOMERS: "CUSTOMERS",
  MOTORCYCLES: "MOTORCYCLES"
})

export type PARKING_RESTRICTIONSValues = ValueOf<typeof PARKING_RESTRICTIONS>;
export type PARKING_RESTRICTIONSKeys = keyof typeof PARKING_RESTRICTIONS;
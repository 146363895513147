export type ValueOf<T> = T[keyof T];

export const BankTransactionState = Object.freeze({
  New: 0,
  Processing: 1,
  Reprocessing: 2,
  Completed: 3,
  Failed: 4,
  Unnecessary: 5
});

export type BankTransactionStateValues = ValueOf<typeof BankTransactionState>;
export type BankTransactionStateKeys = keyof typeof BankTransactionState;
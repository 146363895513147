<app-block-header title="{{'CRS.DETAIL.INCOME_DETAIL'|translate}}" icon="euro-circle" [isRefreshActive]="false"></app-block-header>
<app-skeleton [loading]="loading">
  <div *ngIf="crs; else noDati" class="block read-only-details">
    <app-block-values [data]="crs"
    [mainFields]="['entity', 'userId', 'isInCredit', 'amountWithVat', 'session', 'tariff', 'cdr', 'userId', 'currency', 'source', 'type']"></app-block-values>
  </div>
</app-skeleton>
<ng-template #noDati>
  <app-empty-data>
  </app-empty-data>
</ng-template>

import { Component, Input } from '@angular/core';
import { CU } from 'common_library';

@Component({
  selector: 'app-timespan',
  template: `<div class="yf-flex"><div class="item mr-5" *ngFor="let item of items">{{ item.value}}{{item.type}}</div></div>`,
  // styles: [`
  // `]
})
export class TimeSpanComponent {
  @Input() fromDate: Date;
  @Input() toDate: Date;
  _utilityFuncs = CU.timeSpan

  get items(): any[]{
    const rv:Array<{ type: string, value: number }> = <Array<{ type: string, value: number }>>this._utilityFuncs(this.fromDate, this.toDate || new Date());
    return rv;
  }
}

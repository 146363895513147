import { Injectable } from "@angular/core";
import { IChargeDetailRecord, IError, RecordCounted, ISearchCriteriaCDR } from "common_library";
import { CommonService } from "../common.service";
import { HttpIoService } from "../communication/http-io.service";
import { Observable } from "rxjs";

const CDR_CONTROLLER_ROOT = 'cdr';

@Injectable({ providedIn: 'root' })
export class ChargeDetailRecordService {

  constructor(private commonService: CommonService, private HIO: HttpIoService) { }

  public getCdrs(page?: number, search?: string): Observable<RecordCounted<IChargeDetailRecord>> {
    return this.commonService.getAndCount$({
      controllerRoot: CDR_CONTROLLER_ROOT,
      controllerDir: '',
      page: page,
      search: search
    });
  }

  public async getOne(id: string): Promise<IChargeDetailRecord> {
    try {
      return this.HIO.get(`${CDR_CONTROLLER_ROOT}/${id}`);
    } catch (error) {
      console.log("🐸, error: ", error)
    }
  }

  public async getOneAdmin(id: string): Promise<IChargeDetailRecord> {
    try {
      return this.HIO.get(`${CDR_CONTROLLER_ROOT}/admin/${id}`);
    } catch (error) {
      console.log("🐸, error: ", error)
    }
  }

  public async getOneAdminBlock(id: string): Promise<IChargeDetailRecord> {
    try {
      return this.HIO.get(`${CDR_CONTROLLER_ROOT}/admin/block/${id}`);
    } catch (error) {
      console.log("🐸, error: ", error)
    }
  }

  public async getOneBySession(sessionId: string): Promise<IChargeDetailRecord> {
    try {
      return this.HIO.get(`${CDR_CONTROLLER_ROOT}/bySessionId/${sessionId}`)
    } catch (error) {
      console.log("🐸, error: ", error)
    }
  }

  public async getOneBySessionAdminBlock(sessionId: string): Promise<IChargeDetailRecord> {
    try {
      return this.HIO.get(`${CDR_CONTROLLER_ROOT}/admin/block/bySessionId/${sessionId}`)
    } catch (error) {
      console.log("🐸, error: ", error)
    }
  }

  public getCdrsWithState(dto: ISearchCriteriaCDR): Observable<RecordCounted<IChargeDetailRecord>> {
    dto.pageIndex = dto.pageIndex - 1;
    try {
      const route = `${CDR_CONTROLLER_ROOT}/admin/find-filtered-paginated-by-state`
      const rv = this.HIO.post$<RecordCounted<IChargeDetailRecord>, any>(route, dto)
      return rv;
    } catch (error) {
      console.log("🐸, error: ", error);
    }
  }

  public async setCdrDoneState(cdrId: string): Promise<boolean> {
    try {
      return this.HIO.post(`${CDR_CONTROLLER_ROOT}/admin/set-cdr-done-state/${cdrId}`, {});
    } catch (error) {
      console.log("🐸, error: ", error);
    }
  }

  public async forceInvioCorrispettivo(cdrId: string): Promise<boolean | IError> {
    try {
      return this.HIO.post(`${CDR_CONTROLLER_ROOT}/admin/force-invio-corrispettivo`, { cdrId })
    } catch (error) {
      console.error("🐸, error: ", error);
    }
  }

  public async forceAnnullamentoCorrispettivo(cdrId: string): Promise<boolean | IError> {
    try {
      return this.HIO.post(`${CDR_CONTROLLER_ROOT}/admin/force-annullamento-corrispettivo`, { cdrId })
    } catch (error) {
      console.error("🐸, error: ", error);
    }
  }
}

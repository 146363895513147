import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ITariffRevenues, RevenueDimension } from 'common_library';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-block-tariff-revenue',
  templateUrl: './block-tariff-revenue.component.html',
  styleUrls: ['./block-tariff-revenue.component.scss']
})
export class BlockTariffRevenueComponent implements OnInit, OnDestroy {
  form = new UntypedFormGroup({});
  @Input() revenues: ITariffRevenues;
  @Output() revenuesChange: EventEmitter<{ valid: boolean, value: ITariffRevenues }> = new EventEmitter<{ valid: boolean, value: ITariffRevenues }>();

  model: ITariffRevenues;
  fields: FormlyFieldConfig[] = [];
  alert: boolean = false;
  alertDescription: string;
  alertType: "info" | "error" = "info";
  private subscriptions: Subscription[] = [];

  constructor(private translate: TranslateService) {
    this.alertDescription = this.translate.instant("PAYMENTS.DETAIL.DEFAULT_VALUE");
    this.subscriptions.push(this.form.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (this.form.dirty) {
        this.validateRevenue(value);
      }
    }));

    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.alertDescription = this.getUpdatedString();
      this.createForms();
      this.model = { ...this.model }; // Trigger model update
    }));
  }

  ngOnInit(): void {
    this.model = this.revenues;
    this.createForms();
    this.validateRevenue(this.model, false);
  }

  getUpdatedString() {
    return this.translate.instant("PAYMENTS.DETAIL.DEFAULT_VALUE");
  }

  createForms() {
    this.fields = []; // Clear the fields array before repopulating
    let self = this;
    Object.keys(this.revenues).forEach(function (key) {
      if (key !== "type") {
        let form: FormlyFieldConfig = {
          key: `${key}`,
          type: 'number',
          className: 'i-simulator',
          props: {
            label: self.translate.instant('CRS.ACTORS.' + key.toUpperCase())
          },
          validators: {
            'number': {
              expression: (c: AbstractControl) => !isNaN(Number(c.value)) && c.value !== "",
              message: (error: any, field: FormlyFieldConfig) => 'Inserire un valore corretto'
            }
          }
        }
        self.fields.push(form);
      }
    });
  }

  validateRevenue(value: any, emitChanges: boolean = true) {
    let allZeros = true;
    this.alert = false;
    Object.values(value).forEach(v => {
      if (typeof v === 'number' && v !== 0) {
        allZeros = false;
      }
    });

    if (allZeros) {
      if (this.revenues?.type === RevenueDimension.DEFAULT) {
        this.alertDescription = `La revenue ${RevenueDimension.DEFAULT} non può avere tutti 0 come valori.`
        this.alertType = "error";
        value.type = this.revenues.type;
        this.revenuesChange.emit({ valid: false, value });
      }
      else {
        if (emitChanges) {
          value.type = this.revenues.type;
          this.revenuesChange.emit({ valid: true, value });
        }
      }
      this.alert = true;
    }
    else {
      if (emitChanges) {
        value.type = this.revenues.type;
        this.revenuesChange.emit({ valid: true, value });
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

export type ValueOf<T> = T[keyof T];

export const RevenueEntity = Object.freeze({
  cpo: 0,
  emsp: 1,
  brandOwner: 2,
  podOwner: 3,
  deviceOwner: 4,
  installationOwner: 5
});

export type RevenueEntityValues = ValueOf<typeof RevenueEntity>;
export type RevenueEntityKeys = keyof typeof RevenueEntity;
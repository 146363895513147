import { Component, inject, Input } from '@angular/core';
import { BehaviorSubject, map, Subject, Subscription, switchMap } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { IQuery } from 'common_library';
import { ConfiguratorHttpService } from 'src/app/services/reports/configHttp.service';
import { ConfigurationService } from 'src/app/services/reports/configuration.service';
import * as clone from 'clone';
import { Location } from '@angular/common';
import { QueryService } from 'src/app/services/reports/query.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'query-form',
  templateUrl: './query-form.component.html',
  styleUrls: ['./query-form.component.scss']
})
//Formly approach
export class QueryFormComponent {

  form = new UntypedFormGroup({});
  options: FormlyFormOptions = {};
  model: Partial<any> = {} // IQuery = {}

  @Input() elementListener$: BehaviorSubject<IQuery>;

  _query$ = new Subject<IQuery>(); query$ = this._query$.asObservable();
  _items$ = new Subject<any[]>(); items$ = this._items$.asObservable();
  _ms$ = new Subject<number>(); ms$ = this._ms$.asObservable();
  _err$ = new Subject<any>(); err$ = this._err$.asObservable();
  _loading$ = new BehaviorSubject<boolean>(false); loading$ = this._loading$.asObservable();
  _testerOpen$ = new Subject<boolean>(); testerOpen$ = this._testerOpen$.asObservable();
  _selectorTesterOpen$ = new Subject<boolean>();
  selectorTesterOpen$ = this._selectorTesterOpen$.asObservable();

  //dataSources$ = this.configHttpService.dataSources$().pipe(map(ds => ds.map(i => { return { value: i, label: i.toUpperCase() } })));
  usedBy$ = this.query$.pipe(switchMap((q) => this.configHttpService.isQueryUsed$(q.name)));



  // usedBy$ = this.query$.pipe(switchMap((q)=> this.configHttpService.isQueryUsed$(q.id)));


  params: { [name: string]: number | string } = {};
  // paramNames$ = this.query$.pipe(map(q=>q.params));

  _sql: string;
  location = inject(Location);
  queryId: string;
  query: IQuery;
  sub: Subscription;
  fields: FormlyFieldConfig[] = this.getUpdatedFields();
  private langChangeSubscription: Subscription;


  constructor(
    protected configService: ConfigurationService,
    protected configHttpService: ConfiguratorHttpService<any>,
    private queryService: QueryService,
    private router: Router,
    private AC: ActivatedRoute,
    private app: AppService,
    private translate: TranslateService

  ) {

    this.query$.subscribe(q => {
      this.clear();
    });

    this.testerOpen$.subscribe(open => {

      if (!!open) {
        this._sql = clone<string>(this.model.sql);
        this.params = {};
        if (!!this.model.params) for (const p of this.model.params) this.params[p] = "";
      } else {
        if (this._sql != this.model.sql) {
          this.form.patchValue({ sql: this.model.sql });
          this.form.markAsDirty();
        }
      }
    })

    this.sub = this.AC.paramMap.subscribe(async (params) => {
      this.queryId = params.get('queryId');
      this.query = await this.queryService.getQuery(this.queryId);

      if (this.query == null) {
        this.model.name = '';
        this.model.source = 'default';
        this.model.sql = '';
        this.model.params = '';
      } else {
        this.model = this.query; // Imposta il modello per il form
        this.form.patchValue(this.model); // Popola il form
      }
    });
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.fields = this.getUpdatedFields();
    });
  }

  async ngOnInit() {
    this.sub = this.AC.paramMap.subscribe(async (params) => {
      this.queryId = params.get('queryId');
      this.query = await this.queryService.getQuery(this.queryId);
      if (this.query == null) {
        this.model.name = '';
        this.model.source = 'default';
        this.model.sql = '';
        this.model.params = '';
        this.model.id = '';
      } else {
        this.model = this.query; // Imposta il modello per il form
        this.form.patchValue(this.model); // Popola il form
      }
    });
  }

  // fields: FormlyFieldConfig[] = [
  //   {
  //     fieldGroup: [
  //       { 
  //         key: "name",
  //         type: "input", 
  //         className: "fi-normal", 
  //         props: {
  //           type: "text",
  //           label: this.translate.instant("REPORT.NAME"),
  //           required: true 
  //         } 
  //       },
  //       { 
  //         key: 'source', 
  //         type: 'select', 
  //         className: "fi-xsmall", 
  //         defaultValue: 'default', 
  //         props: { 
  //           label: this.translate.instant("REPORT.CONNECTION"),
  //           required: false, 
  //           disabled: true 
  //         } 
  //       },
  //     ], fieldGroupClassName: "fg"
  //   },
  //   { fieldGroup: [
  //     { 
  //       key: 'sql', 
  //       type: 'textarea', 
  //       className: "fi-normal", 
  //       props: {
  //         label: 'SQL', 
  //         required: true 
  //       } 
  //     }], fieldGroupClassName: "fg" },
  //   { fieldGroup: [
  //     { 
  //       key: 'params', 
  //       type: 'repeat-section', 
  //       className: "fi-normal",
  //       props: { 
  //         label: this.translate.instant("REPORT.PARAMETERS")
  //       }, 
  //       fieldArray: { 
  //         type: 'input', 
  //         props: { 
  //           placeholder: this.translate.instant("REPORT.PARAMETER_NAME"),
  //           required: true 
  //         } 
  //       } 
  //     }], fieldGroupClassName: "fg" }
  // ];

  getUpdatedFields(): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          { 
            key: "name",
            type: "input", 
            className: "fi-normal", 
            props: {
              type: "text",
              label: this.translate.instant("REPORT.NAME"),
              required: true 
            } 
          },
          { 
            key: 'source', 
            type: 'select', 
            className: "fi-xsmall", 
            defaultValue: 'default', 
            props: { 
              label: this.translate.instant("REPORT.CONNECTION"),
              required: false, 
              disabled: true 
            } 
          },
        ], fieldGroupClassName: "fg"
      },
      { fieldGroup: [
        { 
          key: 'sql', 
          type: 'textarea', 
          className: "fi-normal", 
          props: {
            label: 'SQL', 
            required: true 
          } 
        }], fieldGroupClassName: "fg" },
      { fieldGroup: [
        { 
          key: 'params', 
          type: 'repeat-section', 
          className: "fi-normal",
          props: { 
            label: this.translate.instant("REPORT.PARAMETERS"),
            required: true 
          }, 
          fieldArray: { 
            type: 'input', 
            props: { 
              placeholder: this.translate.instant("REPORT.PARAMETER_NAME"),
              required: true 
            } 
          } 
        }], fieldGroupClassName: "fg" }
    ];
  }

  protected makeForm(query: IQuery): void {
    this._query$.next(query);
    this.resetForm();
  }

  protected resetForm() {
    this.options.resetModel();
  }

  async execSql() {
    this.clear();
    this._loading$.next(true);
    const st = new Date().getTime();
    const rv = await this.configHttpService.rawQuery(this.model, this.params);
    this._ms$.next(new Date().getTime() - st);
    this._loading$.next(false);
    this._items$.next(rv.items);
    this._err$.next(rv.err);
  }

  clear() {
    this._sql = this.model.sql;
    this._items$.next([]);
    this._err$.next(null);
  }

  heightPx(perc: number = 40): string {
    return Math.round(window.innerHeight * (perc / 100)) + 'px';
  }

  drawerWidth(): string {
    return window.innerWidth < 500 ? '100%' : '75%';
  }

  goBack() {
    this.location.back();
  }

  async submitForm() {
    if (this.form.valid) {
      const queryDto = {
        name: this.model.name,
        source: this.model.source,
        sql: this.model.sql,
        params: this.model.params || [],
        id: this.queryId,
      };
      try {
        if (this.queryId) {
          const rv: IQuery = await this.queryService.createOrUpdateQueryByForm(queryDto);
          this.router.navigate([`/authenticated/reports-query/${rv.id}`]);
          this.app.createNotification('success', this.translate.instant("PHRASE.UPDATE_DONE"), this.translate.instant("Query aggiornata con successo"))
        } else {
          const rv = await this.queryService.createOrUpdateQueryByForm(queryDto);
          this.router.navigate([`/authenticated/reports-query/${rv.id}`]);
          this.app.createNotification('success', this.translate.instant("Creazione effettuata"), this.translate.instant("Query creata con successo"))
        }
      } catch (error) {
        this.app.createNotification('error', this.translate.instant("PHRASE.SOMETHING_WENT_WRONG"), this.translate.instant("Errore, riprova."))
      }
    }
  }

  reset() {
    this.options.resetModel();
  }


  ngOnDestroy() {
    this.langChangeSubscription.unsubscribe();
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

}

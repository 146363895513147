import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GestoreEntityTypeValues, IGestore } from 'common_library';
import { BehaviorSubject } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GestoreService } from 'src/app/services/entities/gestore.service';

@Component({
  selector: 'app-gestore-entity-link',
  templateUrl: './gestore-entity-link.component.html',
  styleUrls: ['./gestore-entity-link.component.scss']
})
export class GestoreEntityLinkComponent implements OnInit {
  @Input() entityId: string | number;
  @Input() entityType: GestoreEntityTypeValues;

  listGestori$ = new BehaviorSubject<IGestore[]>(null);
  gestoriAssociati$ = new BehaviorSubject<IGestore[]>(null);
  isAdmin$ = new BehaviorSubject<boolean>(false);

  constructor(private app: AppService, private gestoreService: GestoreService, private translate: TranslateService, private auth: AuthService) { }

  async ngOnInit(): Promise<void> {
    await this.getGestoriAssociati();
    await this.getGestoriOrphans();
    this.isAdmin$.next(this.auth.user$.value.isAdmin);
  }

  async getGestoriAssociati() {
    const gestoriAssociati = await this.gestoreService.getGestoriConnectedToEntity(this.entityId, this.entityType);
    this.gestoriAssociati$.next(gestoriAssociati);
  }

  async getGestoriOrphans() {
    const gestoriOrphans = await this.gestoreService.getGestoriOrphansFromEntity(this.entityId);
    this.listGestori$.next(gestoriOrphans);
  }
  
  async addGestore(manager: any) {
    try {
      const rv = await this.gestoreService.addGestoreToEntity(this.entityId, manager.id, this.entityType);
      if (!rv) {
        this.app.createNotification('error', this.translate.instant("PHRASE.ERROR"), this.translate.instant("PHRASE.SOMETHING_WENT_WRONG_TRY"));
        return;
      }
      this.app.createNotification(
        'success',
        this.translate.instant("PHRASE.UPDATE_DONE"),
        this.translate.instant("PHRASE.MANAGER_ADDED", {manager: manager.businessName}),
      );
      this.getGestoriAssociati();
      this.getGestoriOrphans();
    } catch (err) {
      console.error(err);
      this.app.createNotification('error', this.translate.instant("PHRASE.ERROR"), this.translate.instant("PHRASE.SOMETHING_WENT_WRONG_TRY"));
    }
  }
  
  async removeGestore(manager: any) {
    try {
      const rv = await this.gestoreService.removeGestoreToEntity(this.entityId, manager.id);
      if (!rv) {
        this.app.createNotification('error', this.translate.instant("PHRASE.ERROR"), this.translate.instant("PHRASE.SOMETHING_WENT_WRONG_TRY"));
        return;
      }
      this.app.createNotification(
        'success',
        this.translate.instant("PHRASE.UPDATE_DONE"),
        this.translate.instant("PHRASE.MANAGER_REMOVED", {manager: manager.businessName}),
      );
      this.getGestoriAssociati();
      this.getGestoriOrphans();
    } catch (err) {
      console.error(err);
      this.app.createNotification('error', this.translate.instant("PHRASE.ERROR"), this.translate.instant("PHRASE.SOMETHING_WENT_WRONG_TRY"));
    }
  }

}

import { IBaseEntity, IStringIdEntity } from "../base.interface";
import { IError } from "../errorCode.interface";

export const FILE_TYPES =  ['xlsx','pdf','csv'];
export const BUILDERS =  ['excel-js','xlsx-template'];


export interface IExcelReport extends IStringIdEntity {
  name: string;
  description: string;
  template: string;
  icon: string;
  category: string;
  file: 'csv' | 'xlsx' | 'pdf';
  builder: 'excel-js' | 'xlsx-template';
  formFields: any[];
  home: boolean;
  gestore: string[];
  queries: string[];
  namegestoriconnecttoexport?: string[];

}

export interface IRequestExcelReportDTO {
  excelReportId: string;
  model: { [key: string]: any };
  skipArchiving?:boolean,
  file?:string;
  builder?:string;
  template?: string;
}

export interface IExportsArchiveYear {
  year:string,
  items:number,
  months:IExportsArchiveMonth[]
}

export interface IExportsArchiveMonth {
  month:string, // 01,02,03...
  items:number,
}

export interface IExportsArchiveItem {
  folder:string,
  ctx:IRequestCtx,
  definition:IExcelReport,
  request:IRequestExcelReportDTO,
  error?:IError,
}

export interface IExportsLink {
  uid:string,
  fileName:string,
}

export interface IAgentState {
  name:string,
  version:string,
  counter:number,
  errors:number,
  queue:IAgentQueueItem[],
  startedAt:Date,
}

export interface IAgentQueueItem {
  uid:string,
}

export interface IRequestCtx {
  userId: string;
  gestoreId?: string;
  ts: Date;
}

export type ID = string;
export type Nullable<T> = T | null;
export interface IGenericObj {[id:string]:any }



<app-block-header title="{{'USER.DETAIL.NOTIFICATIONS'|translate}}" icon="notification" [isRefreshActive]="isRefreshActive"
  (refreshRequest)="refreshItem()">
</app-block-header>
<ng-container *ngIf="!app.isMobile" [ngTemplateOutlet]="nzPaginationTemplate"></ng-container>
<app-skeleton [loading]="loading">
  <ng-container *ngIf="notificationRecord?.data && notificationRecord?.data.length > 0; else noDati">
    <nz-table #smallTable nzSize="small" [nzData]="notificationRecord?.data" [nzPageSize]="50" [nzFrontPagination]="false"
      [nzShowPagination]="false" [nzScroll]="{ y: '240px' }" style="height: 300px; overflow: auto">
      <thead>
        <tr>
          <th nzWidth="60px"></th>
          <th>{{'USER.DETAIL.MESSAGE'|translate}}</th>
          <th nzWidth="140px">{{'USER.DETAIL.INFO'|translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of smallTable.data">
          <td>
            <span nz-icon nzType="read" [nzTheme]="'outline'" [ngStyle]="{'color': data.readedAt? 'blue' : '#ccc'}"
              style="font-size: 14pt" nz-tooltip
              [nzTooltipTitle]="getTooltipTitleReadedAt(data)"></span> 
            <span class="m-1" *ngIf="data.notificationType == 'WEBPUSH'" nz-icon nzType="mobile" [nzTheme]="'twotone'"
              [nzTwotoneColor]="data.notificationType ? 'blue' : '#ccc'" style="font-size: 14pt" nz-tooltip
              nzTooltipTitle="{{'NZTOOLTIPTITLE.WEBPUSH'|translate}}"></span>
            <span class="m-1" *ngIf="data.notificationType == 'EMAIL'" nz-icon nzType="mail" [nzTheme]="'twotone'"
              [nzTwotoneColor]="data.notificationType ? 'blue' : '#ccc'" style="font-size: 14pt" nz-tooltip
              nzTooltipTitle="{{'NZTOOLTIPTITLE.EMAIL'|translate}}"></span>
          </td>
          <td>
            <span class="title">{{ data.notification.title }}</span>
            <div>
              <small>{{ data.notification.body }}</small>
            </div>
          </td>
          <td class="info">
            <span nz-icon nzType="arrow-right" nzTheme="outline"></span> {{ data.notification.data.dateOfArrival |
            date:"dd/MM/yy HH:mm" }} <br>
            <div *ngIf="data.readedAt">
              <span nz-icon nzType="read" nzTheme="outline"></span> {{ data.readedAt | localizedDate:"medium" }}
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>
  <ng-template #nzPaginationTemplate>
    <nz-pagination
      [nzTotal]="notificationRecord?.count"
      [nzSize]="'small'"
      [nzPageSize]="50"
      (nzPageIndexChange)="pageIndex$.next($event)"
      [nzPageIndex]="pageIndex$ | async"></nz-pagination>
  </ng-template>
  <ng-template #noDati>
    <app-empty-data></app-empty-data>
  </ng-template>
</app-skeleton>
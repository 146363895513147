
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
// import { QueryService } from '@ep-om/project/service/query.service';
import { DEFAULT_TEMPLATES, EntitySelectorOptions, EntitySelectorOptionsTemplate } from '../entitySelectorOptions';
import { IExecutionQueryResult, IQuery } from 'common_library';
// import Color from 'color';
import { QueryService } from 'src/app/services/reports/query.service';

@Component({
  selector: 'entity-select',
  templateUrl: './entity-select.component.html',
  styleUrls: ['./entity-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class EntitySelectComponent implements OnInit {

  @Input() selector: EntitySelectorOptions;
  @Input() template: EntitySelectorOptionsTemplate[][];

  value: string;
  valueWithButton: string;

  @Input() mode: 'multiple' | 'tags' | 'default' = 'default';
  @Input() placeholder: string = '';
  @Input() disabled: boolean;
  @Input() clearable: boolean = true;

  @Output() onEntityChanged = new EventEmitter<any | any[]>();

  items: any[];
  items$: Observable<IExecutionQueryResult>;

  search$ = new BehaviorSubject<string>('');
  searchWithButton$ = new BehaviorSubject<string>('');//serve per gestire la casistica della ricerca da inventory, che non deve scatenare subito la ricerca
  selectedItems$ = new BehaviorSubject<any[]>([]);
  isLoading$ = new BehaviorSubject<boolean>(false);
  showLimitAlert$: Observable<boolean>;
  showNoResultsAlert$: Observable<boolean>;
  requestedSearch$: Observable<boolean>;
  searchButtonEnabled$: Observable<boolean>;

  template$ = new BehaviorSubject<EntitySelectorOptionsTemplate[][]>(null);
  query: IQuery = null;
  listSelectedItem: string[] = [];

  constructor(
    private queryService: QueryService,
    private translate: TranslateService
  ) { }

  async ngOnChanges(changes: SimpleChanges) {
    const selectorChange = changes.selector;
    if (selectorChange && selectorChange.currentValue !== selectorChange.previousValue) {
      const opt: EntitySelectorOptions = selectorChange.currentValue;
      if (opt) {
        if (opt.queryId) {
          this.query = await this.queryService.getQuery(this.selector.queryId);
        }
        if (opt.templateName) {
          const templateByName = DEFAULT_TEMPLATES.find(c => c.name === opt.templateName);
          if (templateByName) {
            this.translateTemplateLabels(templateByName.template);
            this.template$.next(templateByName.template);
          }
        }
        else if (opt.template) {
          this.translateTemplateLabels(opt.template);
          this.template$.next(opt.template);
        }
      }
      else {
        this.template$.next(null);
      }
    }

    const templateChange = changes.template;
    // Il template è prioritario quello dei settings: se quindi arrivo qui (template passato per input) e trovo che il template$ è già valorizzato, lo ignoro
    if (templateChange && templateChange.currentValue !== templateChange.previousValue && !this.template$.value) {
      this.translateTemplateLabels(templateChange.currentValue);
      this.template$.next(templateChange.currentValue);
    }
  }

  translateTemplateLabels(template: EntitySelectorOptionsTemplate[][]) {
    for (let row of template) {
      let itemsToTranslate = row.filter(c => c.label?.includes("##"));
      for (let index = 0; index < itemsToTranslate.length; index++) {
        const element = itemsToTranslate[index];
        while (element.label.includes("##")) {
          let substringIndexStart = element.label.indexOf("##");
          let substringToTranslateUntilEnd = element.label.substring(substringIndexStart + 2);
          let substringIndexEnd = substringToTranslateUntilEnd.indexOf("##");
          let substringToTranslate = substringToTranslateUntilEnd.substring(0, substringIndexEnd);
          element.label = element.label.replace(`##${substringToTranslate}##`, this.translate.instant(substringToTranslate));
        }
      }
    }
  }

  updateSearchWithButtonString(value: string) {
    console.log("UPDATE VALUE WITH BUTTON", value);
    this.searchWithButton$.next(value);
  }

  filterEntities(value: string) {
    this.search$.next(value);
  }

  selectionChanged(itemIds: string | string[]) {
    const item = this.getSelctedEntities(itemIds);
    this.onEntityChanged.emit(item);
  }

  toggleSelectedItem(itemId: string) {
    if (this.mode === 'multiple') {
      if (this.listSelectedItem.includes(itemId)) {
        this.listSelectedItem.splice(this.listSelectedItem.indexOf(itemId), 1);
      }
      else {
        this.listSelectedItem.push(itemId);
      }
      this.selectionChanged(this.listSelectedItem);
    }
    else {
      this.listSelectedItem = [itemId];
      this.selectionChanged(itemId);
    }
  }

  async ngOnInit() {
    this.items$ = this.search$.pipe(
      untilDestroyed(this),
      debounceTime(500),
      filter((search) => !!this.query && !!search),
      switchMap((search) => {
        // All'inizio devo far in modo che tra i valori compaia almeno quello selezionato, se esiste
        this.isLoading$.next(true);
        let res: IExecutionQueryResult = { count: 0, items: [] };
        let itemsFound = of(res);

        if (search) {
          const params = { search };
          itemsFound = this.queryService.execQuery(this.query, params, this.getSelectorLimit());
        }

        this.isLoading$.next(false);
        return itemsFound;
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.items$.pipe(untilDestroyed(this)).subscribe(c => {
      this.items = c.items;
    });

    this.searchButtonEnabled$ = combineLatest([this.searchWithButton$, this.isLoading$]).pipe(untilDestroyed(this),
      map(([search, loading]) => {
        return search && search.length > 3 && !loading;
      }),
      shareReplay({ refCount: true, bufferSize: 1 }));

    this.requestedSearch$ = this.search$.pipe(untilDestroyed(this), map((search) => { return !!search }), shareReplay({ refCount: true, bufferSize: 1 }));

    this.showLimitAlert$ = this.items$.pipe(
      map(i => {
        return i.count === this.getSelectorLimit();
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.showNoResultsAlert$ = combineLatest([
      this.requestedSearch$,
      this.isLoading$
    ]).pipe(
      untilDestroyed(this),
      debounceTime(500),
      map(([searchDone, loading]) => {
        return searchDone && !loading
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  getSelctedEntities(selectedIds: string | string[]): (any | any[]) {
    let itemsToReturn;
    if (Array.isArray(selectedIds)) {
      itemsToReturn = this.items.filter(c => selectedIds.includes(c.id));
    }
    else {
      const singleSelectedItem = this.items.find(c => selectedIds === c.id);
      itemsToReturn = this.mode === 'default' ? singleSelectedItem : [].concat(singleSelectedItem);
    }
    return itemsToReturn;
  }

  getSelectorLimit(): number {
    return this.selector?.limit ?? 50;
  }

  computeTextColor(hexadecimal: string): string {
    // return hexadecimal ? new Color(hexadecimal).isDark() ? 'white' : 'black' : 'black';
    return 'white'
  }

  isItemSelected(itemId: string) {
    return this.listSelectedItem.includes(itemId);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CPOs, ResetType, ResetTypeValues, TriggerMessage, TriggerMessageValues, TroubleshootingRequests } from 'common_library';
import { AppService } from 'src/app/services/app.service';
import { CoreService } from 'src/app/services/core.service';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as clone from 'clone';
import { ConnectorService } from 'src/app/services/entities/connector.service';
import { TranslateService } from '@ngx-translate/core';

interface SetChargingProfileRequest {
  name: string;
  connectorId: number;
  csChargingProfiles: {
    chargingProfileId: number;
    transactionId?: number;
    stackLevel: number;
    chargingProfilePurpose: "ChargePointMaxProfile" | "TxDefaultProfile" | "TxProfile";
    chargingProfileKind: "Absolute" | "Recurring" | "Relative";
    recurrencyKind?: "Daily" | "Weekly";
    validFrom?: string;
    validTo?: string;
    chargingSchedule: {
      duration?: number;
      startSchedule?: string;
      chargingRateUnit: "A" | "W";
      chargingSchedulePeriod: {
        startPeriod: number;
        limit: number;
        numberPhases?: number;
        [k: string]: unknown;
      }[];
      minChargingRate?: number;
      [k: string]: unknown;
    };
    [k: string]: unknown;
  };
}
const CHARGING_PROFILE_MINUTES = 1680;
@Component({
  selector: 'app-block-troubleshooting',
  templateUrl: './block-troubleshooting.component.html',
  styleUrls: ['./block-troubleshooting.component.scss']
})
export class BlockTroubleshootinComponent implements OnInit {
  triggerMessageVal: TriggerMessageValues = 'BootNotification';
  triggers = TriggerMessage;
  gotConfiguration: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  gettingConfiguration: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  resetVal: ResetTypeValues = 'Soft';
  resets = ResetType;
  stationConfiguration: any[] = [];
  textResult$ = new BehaviorSubject<any>('Qui verrà visualizzata la risposta del backend');
  configurationResult$ = new BehaviorSubject<any>("");
  mapConfigurationButtonCommands: Map<string, { sendingCommand: boolean, result: string }> = new Map();
  mapConfigurationButtonCommands$ = new BehaviorSubject<Map<string, { sendingCommand: boolean, result: string }>>(new Map());


  form = new UntypedFormGroup({});
  model = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = this.getUpdatedFields();

  configurationForm = new UntypedFormGroup({});
  cpos = CPOs;
  @Input() stationId: string;
  @Input() buttonDisabled: boolean;
  @Input() sessionId: string;
  @Input() connectorId: number = null;
  @Input() cpoId: string;
  @Output() sessionStopped = new EventEmitter();
  @Output() softResetEvent = new EventEmitter();
  @Output() hardResetEvent = new EventEmitter();
  @Output() unlockConnectorEvent = new EventEmitter();

  chargingProfileVals: SetChargingProfileRequest[] = [
    {
      name: 'Parte da 3kW',
      connectorId: this.connectorId,
      csChargingProfiles: {

        chargingProfileId: 158798,
        chargingProfileKind: 'Absolute',
        chargingProfilePurpose: 'TxProfile',
        chargingSchedule: {
          chargingRateUnit: 'W',
          chargingSchedulePeriod: [
            {
              limit: 3000.0,
              startPeriod: 0
            },
            {
              limit: 4000.0,
              startPeriod: 780
            },
            {
              limit: 5500.0,
              startPeriod: 1680
            },
          ],
          transactionId: 339373,
          validFrom: new Date(),
          validTo: new Date()
        },
        stackLevel: 0,
      }
    },
    {
      name: 'Parte da 6kW',
      connectorId: this.connectorId,
      csChargingProfiles: {
        chargingProfileId: 158798,
        chargingProfileKind: 'Absolute',
        chargingProfilePurpose: 'TxProfile',
        chargingSchedule: {
          chargingRateUnit: 'W',
          chargingSchedulePeriod: [
            {
              limit: 6000.0,
              startPeriod: 0
            },
            {
              limit: 7000.0,
              startPeriod: 780
            },
            {
              limit: 8500.0,
              startPeriod: 1680
            },
          ],
          transactionId: 339373,
          validFrom: new Date(),
          validTo: new Date()
        },
        stackLevel: 0,
      }
    },
  ]
  chargingProfileValue = this.chargingProfileVals[0];

  connectorOptions$ = new BehaviorSubject<{ label: string, value: number }[]>([]);
  connectorIdDataTransfer: number;

  formRfid = new UntypedFormGroup({});
  modelRfid = {};
  optionsRfid: FormlyFormOptions = {};
  fieldsRfid: FormlyFieldConfig[] = this.getUpdatedFieldsRfid();

  formDataTransfer = new UntypedFormGroup({});
  modelDataTransfer: { vendorId?: string, messageId?: string, connettore?: number, data?: string } = {};
  optionsDataTransfer: FormlyFormOptions = {};
  fieldsDataTransfer: FormlyFieldConfig[] = this.getUpdatedFieldsData();


  formLog = new UntypedFormGroup({});
  modelLog = {};
  optionsLog: FormlyFormOptions = {};
  fieldsLog: FormlyFieldConfig[] = this.getUpdatedFieldsLog();

  formUpdateConfiguration = new FormGroup({});
  modelUpdateConfiguration: { keyInput?: string; valueInput?: string } = {
    keyInput: '',
    valueInput: ''
  };
  optionsUpdateConfiguration: FormlyFormOptions = {};
  fieldsUpdateConfiguration: FormlyFieldConfig[] = this.getUpdatedFieldsUpdateConfiguration();

  private langChangeSubscription: Subscription;

  constructor(
    private core: CoreService,
    private formBuilder: UntypedFormBuilder,
    private connectorService: ConnectorService,
    private app: AppService,
    private translate: TranslateService
  ) {
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.fields = this.getUpdatedFields();
      this.fieldsRfid = this.getUpdatedFieldsRfid();
      this.fieldsDataTransfer = this.getUpdatedFieldsData();
      this.fieldsLog = this.getUpdatedFieldsLog();
    });
  }

  async ngOnInit(): Promise<void> {
    const connectors = await this.connectorService.getConnectorsByStationId(this.stationId);
    let connectorOptions = connectors.map((c) => ({ label: c.name || c.id.toString(), value: c.id }));
    connectorOptions.unshift({ label: this.translate.instant("LABEL.NOT_SELECTED"), value: null })
    this.connectorOptions$.next(connectorOptions);
  }

  getUpdatedFields(): FormlyFieldConfig[] {
    return [
      {
        key: 'url',
        type: 'input',
        templateOptions: {
          label: this.translate.instant("LABEL.URL"),
          placeholder: this.translate.instant("PLACEHOLDER.WRITE_URL"),
          required: true,
        },
        validators: {
          validUrl: {
            expression: (control) => {
              if (control.value && !/^(http|https):\/\/[^ "]+$/.test(control.value)) {
                return false;
              }
              return true;
            },
            message: 'Please enter a valid URL (e.g., http://example.com).',
          },
        },
      },
    ];
  }

  getUpdatedFieldsLog(): FormlyFieldConfig[] {
    return [
      {
        key: 'url',
        type: 'input',
        templateOptions: {
          label: this.translate.instant("LABEL.URL"),
          placeholder: this.translate.instant("PLACEHOLDER.WRITE_URL"),
          required: true,
        },
        validators: {
          validUrl: {
            expression: (control) => {
              if (control.value && !/^(http|https):\/\/[^ "]+$/.test(control.value)) {
                return false;
              }
              return true;
            },
            message: 'Please enter a valid URL (e.g., http://example.com).',
          },
        },
      },
    ];
  }

  getUpdatedFieldsRfid(): FormlyFieldConfig[] {
    return [
      {
        key: 'code',
        type: 'input',
        templateOptions: {
          label: this.translate.instant("LABEL.CODE"),
          placeholder: this.translate.instant("PLACEHOLDER.ENTER_CODE"),
          required: true,
        },
      },
      {
        key: 'userId',
        type: 'input',
        props: {
          label: this.translate.instant("LABEL.USER_ID"),
          placeholder: this.translate.instant("PLACEHOLDER.ENTER_USER_ID"),
          required: true,
        },
      }
    ];
  }

  getUpdatedFieldsData(): FormlyFieldConfig[] {
    return [
      {
        key: 'vendorId',
        type: 'input',
        props: {
          label: this.translate.instant("LABEL.VENDOR_ID"),
          required: true,
          maxLength: 255,
        },
      },
      {
        key: 'messageId',
        type: 'input',
        props: {
          label: this.translate.instant("LABEL.MESSAGE_ID"),
          required: false,
          maxLength: 50,
        },
      },
      {
        key: 'connettore',
        type: 'select',
        props: {
          label: this.translate.instant("LABEL.CONNECTOR"),
          required: false,
          options: this.connectorOptions$
        },
      },
      {
        key: 'data',
        type: 'textarea',
        props: {
          label: this.translate.instant("LABEL.DATA"),
          required: true
        }
      }
    ];
  }

  getUpdatedFieldsUpdateConfiguration(): FormlyFieldConfig[] {
    return [
      {
        key: 'keyInput',
        type: 'input',
        props: {
          label: "Key",
          required: true,
          maxLength: 50,
        },
      },
      {
        key: 'valueInput',
        type: 'input',
        props: {
          label: "Value",
          required: true,
          maxLength: 50,
        },
      }
    ];
  }

  ngOnDestroy(): void {
    this.langChangeSubscription.unsubscribe();
  }

  stopSession() {
    this.sessionStopped.emit()
  }

  async reset() {
    const result = await this.core.stationReset(
      this.stationId,
      this.resetVal
    );
    this.textResult$.next(JSON.stringify(result))
  }

  async unlockConnector() {
    const result = await this.core.troubleshooting(
      TroubleshootingRequests.UnlockConnector,
      this.stationId,
      null,
      this.connectorId
    );
    this.textResult$.next(JSON.stringify(result))
  }

  async triggerMessage() {
    const result = await this.core.troubleshooting(
      TroubleshootingRequests.TriggerMessage,
      this.stationId,
      this.triggerMessageVal
    );
    this.textResult$.next(JSON.stringify(result))
  }

  setTriggerValue(event: any) {
    this.triggerMessageVal = event;
  }

  setResetValue(event: any) {
    this.resetVal = event;
  }

  async updateFirmware() {
    let body = {
      location: this.form.value.url,
      retries: 3,
      retryInterval: 60
    };
    const result = await this.core.troubleshooting(
      TroubleshootingRequests.UpdateFirmare,
      this.stationId,
      null,
      this.connectorId,
      body
    )
    this.textResult$.next(JSON.stringify(result))
  }

  setChargingProfile(event) {
    this.chargingProfileValue = event;
    this.chargingProfileValue = this.chargingProfileVals.find((val) => val.name === event);
  }

  async sendChargingProfile() {
    const currentDate = new Date();
    const validTo = new Date(currentDate.getTime() + CHARGING_PROFILE_MINUTES * 60000);
    let csChargingProfiles = clone(this.chargingProfileValue);
    csChargingProfiles.csChargingProfiles.chargingSchedule.validFrom = currentDate;
    csChargingProfiles.csChargingProfiles.chargingSchedule.validTo = validTo;
    delete csChargingProfiles.name;
    csChargingProfiles.connectorId = this.connectorId;
    const result = await this.core.troubleshooting(
      TroubleshootingRequests.SetChargingProfile,
      this.stationId,
      null,
      this.connectorId,
      csChargingProfiles
    )
    this.textResult$.next(JSON.stringify(result))
  }

  async sendGetDiagnostics() {
    let body = {
      location: this.formLog.value.url,
      retries: 3,
      retryInterval: 60
    };
    const result = await this.core.troubleshooting(
      TroubleshootingRequests.GetDiagnostics,
      this.stationId,
      null,
      this.connectorId,
      body
    )
    this.textResult$.next(JSON.stringify(result))
  }

  async registerRfid() {
    const result = await this.core.registerRfid(
      this.formRfid.value.code,
      this.formRfid.value.userId,
      this.stationId
    )
    this.textResult$.next(JSON.stringify(result))
  };

  async sendGetConfigRequest() {
    this.gettingConfiguration.next(true);
    const result = await this.core.troubleshooting(TroubleshootingRequests.GetConfiguration, this.stationId);
    this.gotConfiguration.next(true);
    this.gettingConfiguration.next(false);
    this.mapConfigurationButtonCommands = new Map();
    this.mapConfigurationButtonCommands$.next(new Map());

    this.stationConfiguration = result?.configurationKey?.map((key, index) => {
      return {
        ...key,
        index,
        readonly: key.readonly || false,
      };
    }).sort((a, b) => (a.readonly === b.readonly) ? 0 : a.readonly ? 1 : -1);

    for (let key of this.stationConfiguration) {
      this.configurationForm.addControl(key.key, this.formBuilder.control(key.value));
      this.mapConfigurationButtonCommands.set(key, { sendingCommand: false, result: null });
    }
    this.mapConfigurationButtonCommands$.next(this.mapConfigurationButtonCommands);
    this.configurationResult$.next(JSON.stringify(result));
  }

  async sendChangeConfiguration(templateKey: any) {
    try {
      this.mapConfigurationButtonCommands.set(templateKey, { sendingCommand: true, result: null });
      const result = await this.core.troubleshooting(TroubleshootingRequests.ChangeConfiguration, this.stationId, null, null, {
        key: templateKey,
        value: this.configurationForm.value[templateKey],
      });
      this.mapConfigurationButtonCommands.set(templateKey, { sendingCommand: false, result: JSON.stringify(result) });
      this.mapConfigurationButtonCommands$.next(this.mapConfigurationButtonCommands);
    } catch (e) {
      this.mapConfigurationButtonCommands.set(templateKey, { sendingCommand: false, result: JSON.stringify(e) });
      this.mapConfigurationButtonCommands$.next(this.mapConfigurationButtonCommands);
    }
  }

  async dataTransferJson() {
    const isJson = this.isValidJSON(this.modelDataTransfer.data);
    if (!isJson) {
      this.app.createNotification('error', this.translate.instant("PHRASE.ERROR"), this.translate.instant("PHRASE.INVALID_JSON"));
    }
    else {
      let jsonData = this.modelDataTransfer.data;

      if (this.modelDataTransfer.connettore) {
        let jsonParsed = JSON.parse(jsonData);
        jsonParsed.connectorId = this.modelDataTransfer.connettore;
        jsonData = JSON.stringify(jsonParsed);
      }
      let body = {
        vendorId: this.modelDataTransfer.vendorId,
        messageId: this.modelDataTransfer.messageId,
        data: jsonData
      };
      const result = await this.core.troubleshooting(
        TroubleshootingRequests.DataTransfer,
        this.stationId,
        null,
        this.modelDataTransfer.connettore,
        body
      )
      this.textResult$.next(JSON.stringify(result))
    }
  }

  async updateConfiguration() {
    const result = await this.core.troubleshooting(TroubleshootingRequests.ChangeConfiguration, this.stationId, null, null, {
      key: this.modelUpdateConfiguration.keyInput,
      value: this.modelUpdateConfiguration.valueInput,
    });
    this.textResult$.next(JSON.stringify(result))
  };

  isValidJSON(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}

import { Component, Input, OnInit } from '@angular/core';

export interface EntityCardinality {
  entity: string,
  cardinality: {
      min: number;
      max?: number;
  },
  count?: number;
}

@Component({
  selector: 'app-list-entity',
  templateUrl: './list-entity.component.html',
  styleUrls: ['./list-entity.component.scss']
})
export class ListEntityComponent implements OnInit {

  @Input() entity: EntityCardinality[];
  dictType = {
    'user': 'user',
    'station': 'usb',
    'pod': 'one-to-one',
    'group': 'group',
    tooltip: {
      'user' : 'Utente',
      'station': 'Stazione',
      'pod': 'Pod',
      'group': 'Gruppo'
    }
  };

  constructor() { }

  ngOnInit() {   
  }

}

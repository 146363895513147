<div class="w-full flex flex-col bg-gray-100 pt-1 px-1 mb-2">

    <div class="w-full flex justify-between mb-1">
        <div class="text-gray-600"> <button icon="this.collapsed ? 'next' : 'expand' " className="btn-empty mr-2" (click)="toggleCollapsed()"></button><b>{{ to.title }} ({{ field.fieldGroup.length }})</b></div>
        <button [hidden]="!to.modifiable" [disabled]="field.props.disabled" icon="add" className="btn-empty" (click)="add()">{{ to.addText }}</button>
    </div>

    <div [style.display]="(this.collapsed || field.fieldGroup.length>0) ? 'none' : null" class="w-full flex justify-between p-1 bg-white mb-1 text-gray-400">
        Nessun elemento.
    </div>

    <div [style.display]="this.collapsed ? 'none' : null" *ngFor="let field of field.fieldGroup; let i = index" class="w-full flex justify-between p-1 bg-white mb-1">
        <formly-field class="flex-grow mr-2" [field]="field"></formly-field>
        <button [hidden]="!to.modifiable" [disabled]="field.props.disabled" class="flex-none w-8" icon="trash" className="btn-empty" (click)="remove(i)"></button>
    </div>
</div>

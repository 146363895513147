<div>
  <nz-page-header [nzGhost]="false">
    <nz-page-header-title>{{ label }} <b class="ml-4">{{ value }} <span *ngIf="date">{{date | localizedDate}}</span></b>
    </nz-page-header-title>
    <nz-page-header-subtitle class="subtitle">{{ subtitle }}
      <nz-page-header-tags *ngIf="state">
        <app-entity-tag [entity]="state" entityType="state"></app-entity-tag>
      </nz-page-header-tags>
      <nz-page-header-tags *ngIf="stateGroup">
        <app-entity-tag entityType="group_validation" [stateGroupValue]="stateGroupValue"></app-entity-tag>
      </nz-page-header-tags>
    </nz-page-header-subtitle>

    <nz-page-header-extra>
      <nz-space>
        <ng-content></ng-content>
        <button *ngIf="isRefreshActive" (click)="refresh()" nzSpaceItem nz-button><i nz-icon nzType="reload"
            nzTheme="outline"></i></button>
      </nz-space>
    </nz-page-header-extra>
  </nz-page-header>
</div>

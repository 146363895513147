export type ValueOf<T> = T[keyof T];

export const StationType = Object.freeze({
  Unknown: 'unknown',
  Type1: 'tipo1',
  Type2: 'tipo2',
  Type3: 'tipo3',
});

export type StationTypeValues = ValueOf<typeof StationType>;
export type StationTypeKeys = keyof typeof StationType;
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IStation } from 'common_library';
import { BehaviorSubject } from 'rxjs';
import { StationService } from 'src/app/services/entities/station.service';

@Component({
  selector: 'app-block-stations',
  templateUrl: './block-stations.component.html',
  styleUrls: ['./block-stations.component.scss']
})
export class BlockStationsComponent implements OnChanges {
  @Input() title: string = this.translate.instant("STATIONS.DETAIL.STATION");
  @Input() isRefreshActive: boolean = false;
  @Input() station: IStation;
  @Input() stationId: string;
  @Output() updateClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  stationDetail$ = new BehaviorSubject<IStation>(null)

  loading: boolean = false;

  constructor(private router: Router, private stationService: StationService, private translate: TranslateService) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.stationId?.currentValue) {
      await this.getItem();
    }
    if (changes.station?.currentValue) {
      this.stationDetail$.next(changes.station?.currentValue);
    }
  }

  async getItem() {
    this.loading = true;
    if (this.stationId) {
      let station = await this.stationService.getOneStationAdminBlock(this.stationId);
      this.stationDetail$.next(station);
    }
    this.loading = false;
  }

  async refreshItem() {
    await this.getItem();
  }

  goToStationDetail() {
    this.router.navigate([`authenticated/stations/${this.stationDetail$?.value.id}`]);
  }
}

<!-- numero della carta, scadenza, se è visa o mastercard -->
<app-block-header title="{{'USER.DETAIL.DEBIT_AUTHORIZATION'|translate}}"
                  [isRefreshActive]="isRefreshActive"
                  icon="credit-card"
                  (refreshRequest)="sendEvent()">
</app-block-header>

<ng-container *ngIf="paymentAuth else noData">
  <app-block-values [data]="paymentAuth" mainField="token" [clickableToShowAll]="false"
    [mainFields]="['expirationDate', 'authorizedAt', 'cdcType', 'amount']"></app-block-values>
</ng-container>

<ng-template #noData>
  <app-empty-data></app-empty-data>
</ng-template>

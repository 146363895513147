
import { OCPIDisplayText } from "./ocpi-interfaces";
import { ValueOf } from "./ocpi.interface";
import { IToken } from "./token.interface";

export const COMMANDS = {
  CANCEL_RESERVATION: 'CANCEL_RESERVATION',
  RESERVE_NOW: 'RESERVE_NOW',
  START_SESSION: 'START_SESSION',
  STOP_SESSION: 'STOP_SESSION',
  UNLOCK_CONNECTOR: 'UNLOCK_CONNECTOR'
} as const;

export type CommandKeys = keyof typeof COMMANDS;
export type CommandValues = ValueOf<typeof COMMANDS>;

export interface OCPIReserveNowParams {
  response_url: string;
  token: IToken;
  expiry_date: Date;
  reservation_id: string;
  location_id: string;
  evse_uid?: string;
  authorization_reference?: string;
}

export const COMMANDS_RESPONSE = {
  NOT_SUPPORTED: 'NOT_SUPPORTED',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
  UNKNOWN_SESION: 'UNKNOWN_SESSION',
} as const;

export type CommandsResponseKeys = keyof typeof COMMANDS_RESPONSE;
export type CommandsResponseValues = ValueOf<typeof COMMANDS_RESPONSE>;

export interface ICommandResponse {
  result: CommandsResponseValues;
  timeout: number;
  message: OCPIDisplayText
}

export const COMMANDS_RESULT = {
  ACCEPTED: 'ACCEPTED',
  CANCELED_RESERVATION: 'CANCELED_RESERVATION',
  EVSE_OCCUPIED: 'EVSE_OCCUPIED',
  EVSE_INOPERATIVE: 'EVSE_INOPERATIVE',
  FAILED: 'FAILED',
  NOT_SUPPORTED: 'NOT_SUPPORTED',
  REJECTED: 'REJECTED',
  TIMEOUT: 'TIMEOUT',
  UNKNOWN_RESERVATION: 'UNKNOWN_RESERVATION',
} as const;

export type CommandsResultKeys = keyof typeof COMMANDS_RESULT;
export type CommandsResultValues = ValueOf<typeof COMMANDS_RESULT>;

export const WHITE_LIST = {
  /**
   * Token always has to be whitelisted, realtime authorization is not possible/allowed.
   * CPO shall always allow any use of this Token.
   */
  ALWAYS: 'ALWAYS',
  
  /**
   * It is allowed to whitelist the token, realtime authorization is also allowed.
   * The CPO may choose which version of authorization to use.
   */
  ALLOWED: 'ALLOWED',

  /**
   * In normal situations realtime authorization shall be used.
   * But when the CPO cannot get a response from the eMSP (communication between CPO and eMSP is offline)
   * the CPO shall allow this Token to be used.
   */
  ALLOWD_OFFLINE: 'ALLOWED_OFFLINE',

  /**
   * Whitelisting is forbidden, only realtime authorization is allowed.
   * CPO shall always send a realtime authorization for any use of this Token to the eMSP
   */
  NEVER: 'NEVER'
} as const;
export type WhitelistKeys = keyof typeof WHITE_LIST;
export type WhitelistValues = ValueOf<typeof WHITE_LIST>;

export const PROFILE = {
  /**
   * Driver wants to use the cheapest charging profile possible.
   */
  CHEAP: 'CHEAP',

  /**
   * Driver wants his EV charged as quickly as possible and is willing to pay a premium for this if needed
   */
  FAST: 'FAST',

  /**
   * Driver wants his EV charged with as much regenerative (green) energy as possible.
   */
  GREEN: 'GREEN',

  /**
   * Driver does not have special preferences
   */
  REGULAR: 'REGULAR',
} as const;
export type ProfileKeys = keyof typeof PROFILE;
export type ProfileValues = ValueOf<typeof PROFILE>;

export interface IEnergyContract {
  /**
   * Name of the energy supplier for this token;
   */
  supplier_name: string;

  /**
   * Optional Contract ID at the energy supplier, that belongs to the owner of this token.
   */
  contract_id?: string;
}

export interface ICommandResult {
  result: CommandsResultValues;
  message: OCPIDisplayText;
}
interface ICommandsBody {
  /**
   * URL that the CommandResult POST should be send to. This URL might contain an unique ID to be able to distinguish between requests
   */
  response_url: string 
}

export interface ICancelReservationBody extends ICommandsBody {
  /**
   * Case insensitive Reservation id, unique for this reservation.
   */
  reservation_id: string;
}

export interface IReserveNowBody extends ICommandsBody {

  /**
   * Token object for how to reserve this ChargePoinrt (and specific EVSE)
   */
  token: IToken;

  /**
   * The Date/Time when this reservation ends, in UTC
   */
  expiry_date: Date,

  /**
   * Case insensitive Reservation id, unique for this reservation.
   * If the Receiver Point (typically CPO) already has a reservation that matches this reservationId
   * for that Location, it will replace the reservation
   */
  reservation_id: string;

  /**
   * Case insensitive Location.id of the Location (belonging to the CPO this request is send to) for which to reserve an EVSE.
   */
  location_id: string;

  /**
   * Case insensitive Optional EVSE.uid of the EVSE of this Location if a specific EVSE has to be reserved.
   * Not every chargepoint may support a reservation request with an empty EVSE.
   */
  evse_uid?: string;

  /**
   * Case insensitive Reference to the authorization given by the eMSP
   * When given, this reference will be provided in the relevant Session and/or CDR.
   */
  authorization_reference?: string;
}

export interface IStartSessionBody extends ICommandsBody {
  yflToken?: IToken;
  /**
   * Token Object the ChargePoint has to use to start a new session.
   * The Token provided in this request is authorized by the eMSP
   */
  token: IToken;

  /**
   * Case insensitive Locaiton.id of the Location (belonging to the CPO this request is send to)
   * on which a session is to be started.
   */
  location_id: string;

  /**
   * Case insensitive Optional EVSE.uid of the EVSE of this Location on which a session is to be started.
   * Not every ChargePoint may support a start_session request with an empty EVSE.
   */
  evse_uid?: string;

  /**
   * Case insensitive Optional Reference to the authorization given by the eMSP.
   * When given, this reference will be provided in the relevant Session and/or CDR.
   */
  authorization_reference?: string;
}

export interface IStopSessionBody{
  /**
   * Case insensitive Session.id of the Session that is requested to be stopped.
   */
  session_id: string;
  response_url?: string;
}

export interface IUnlockConnectorBody extends ICommandsBody {
  /**
   * Case insensitive Locaiton.id of the Location (belonging to the CPO this request is send to)
   * on which it is requested to unlock the connector.
   */
  location_id: string;

  /**
   * Case insensitive EVSE.uid of the EVSE of this Location
   * of which it is requested to unlock the connector
   */
  evse_uid: string;

  /**
   * Case insensitive Connector.id of the Connector of this Location of which it is requested to unlock.
   */
  connector_id: string;
}
import { Injectable } from "@angular/core";
import { IInfoSection, IInfoSectionVersion, RecordCounted } from "common_library";
import { HttpIoService } from "../communication/http-io.service";
import { CommonService } from "../common.service";

const CONTROLLER_ROOT = 'info-section-version';
@Injectable({ providedIn: 'root' })
export class InfoSectionVersionService {

  constructor(private HIO: HttpIoService, private commonService: CommonService) { }


  public async getInfoSectionVersions(sectionId: string): Promise<IInfoSectionVersion[]> {
    try {
      return await this.HIO.get(`${CONTROLLER_ROOT}/versions/${sectionId}`);
    } catch (error) {
      console.error('🐸 Error: ', error);
    }
  }

  public async createInfoSectionVersion(dto: IInfoSectionVersion): Promise<any> {
    try {
      return await this.HIO.post<IInfoSectionVersion, any>(`${CONTROLLER_ROOT}`, dto);
    } catch (error) {
      console.error('🐸 Error: ', error);
    }
  }

  public async updateInfoSectionVersion(dto: IInfoSectionVersion): Promise<IInfoSectionVersion> {
    return await this.HIO.patch(`${CONTROLLER_ROOT}/toggle-published/`, dto);
  }
}

<app-block-header title="{{'STATIONS.DETAIL.STATION'|translate}}" [isRefreshActive]="isRefreshActive" icon="api"
  (refreshRequest)="refreshItem()"></app-block-header>
<app-skeleton [loading]="loading">
  <ng-container *ngIf="stationDetail$|async as station; else noDati">
    <app-block-values [data]="station"
      [mainFields]="['name','cpo','state','innerState','online','power','firmwareVersion']"
      (goToItemDetail)="goToStationDetail()">
    </app-block-values>
  </ng-container>
</app-skeleton>
<ng-template #noDati><app-empty-data></app-empty-data></ng-template>
export type ValueOf<T> = T[keyof T];

export const OCPISessionStatus = Object.freeze({
  ACTIVE: "ACTIVE",
  COMPLETED: "COMPLETED",
  INVALID: "INVALID",
  PENDING: "PENDING",
  RESERVATION: "RESERVATION"
})

export type OCPISessionStatusKeys = keyof typeof OCPISessionStatus;
export type OCPISessionStatusValues = ValueOf<typeof OCPISessionStatus>;
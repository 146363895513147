import { Injectable } from '@angular/core';
import { ILog, RecordCounted } from 'common_library';
import { FilterLogDto } from 'src/app/components/blocks/block-log/block-log.component';
import { HttpIoService } from '../communication/http-io.service';
import { Observable } from 'rxjs';

const CONTROLLER_ROOT = 'log';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private HIO: HttpIoService) { }

  public getLogsFilteredAndPaginated(searchCriteria: FilterLogDto): Observable<RecordCounted<ILog>> {
    try {
      const searchCriteraCleaned: FilterLogDto = {...searchCriteria, entityType: searchCriteria.entityType !== 'ALL' ? searchCriteria.entityType : undefined }
      return this.HIO.post$<RecordCounted<ILog>, FilterLogDto>(`${CONTROLLER_ROOT}/logs-filtered-paginated`, searchCriteraCleaned);
    } catch (error) {
      console.error("🐸, error -> ", error);
    }
  }
}

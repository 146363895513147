import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IStation } from 'common_library';

@Component({
  selector: 'app-block-stations-mini-list',
  templateUrl: './block-stations-mini-list.component.html',
  styleUrls: ['./block-stations-mini-list.component.scss']
})
export class BlockStationsMiniListComponent implements OnInit {
  @Input() isRefreshActive: boolean = false;
  @Output() updateClicked: EventEmitter<boolean> = new EventEmitter();
  @Input() stations: IStation[] = [];
  @Input() title: string = this.translate.instant("GATEWAY.DETAIL.GATEWAY_STATIONS");
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }
  sendEvent(){
    this.updateClicked.emit()
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpIoService } from '../communication/http-io.service';
import { GroupTypeCategoryValues, ICreateGroupEntityDTO, IEntityType, IGroup, IGroupEntity, RecordCounted } from 'common_library';
import { CommonService } from '../common.service';

const CONTROLLER_ROOT = 'group';

@Injectable({ providedIn: 'root' })
export class GroupService {

  constructor(private HIO: HttpIoService, private commonService: CommonService) { }

  async get(): Promise<IGroup[]> {
    try {
      return await this.HIO.get(CONTROLLER_ROOT);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getGroup(id: number): Promise<IGroup> {
    try {
      return await this.HIO.get(`${CONTROLLER_ROOT}/${id}`)
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getGroupsByEntityId(entityId: string, entityType: string): Promise<IGroup[]> {
    try {
      return await this.HIO.get(`${CONTROLLER_ROOT}/by-entity/${entityId}/${entityType}`);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getGroupCountEntitiesById(id: string): Promise<IGroupEntity[]> {
    try {
      return await this.HIO.get(`${CONTROLLER_ROOT}/types-entities/${id}`)
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getGroupCountEntities(): Promise<IEntityType[]> {
    try {
      return await this.HIO.get(`${CONTROLLER_ROOT}/types-entities`)
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getGroupDetails(id: number): Promise<IGroup> {
    try {
      return await this.HIO.get(`${CONTROLLER_ROOT}/type-details/${id}`)
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async update(groupId: number, dto: any): Promise<IGroup> {
    try {
      return await this.HIO.patchItem<IGroup, any>(`${CONTROLLER_ROOT}`, groupId, dto);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async create(dto: any): Promise<IGroup> {
    try {
      return await this.HIO.post<IGroup, any>(`${CONTROLLER_ROOT}`, dto)
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async delete(groupId: string): Promise<boolean> {
    try {
      await this.HIO.deleteItem(CONTROLLER_ROOT, groupId);
      return true;
    } catch (error) {
      console.error('🐱️ : error', error);
      return false;
    }
  }

  async addEntityToGroup(groupId: number, dto: ICreateGroupEntityDTO): Promise<IGroupEntity> {
    try {
      return await this.HIO.post<IGroupEntity, any>(`group/${groupId}/entity`, dto);
    } catch (error) {
      console.error('🐱️ : error', error);
      return null;
    }
  }

  async deleteEntityOnGroup(groupId: number, entityId, entityType): Promise<boolean> {
    try {
      await this.HIO.deleteItemOnGroup(CONTROLLER_ROOT, groupId, entityId, entityType);
      return true;
    } catch (error) {
      console.error('🐱️ : error', error);
      return false;
    }
  }

  getAndCountFilteredGroups(pageNumber?: number, searchString?: string): Observable<RecordCounted<IGroup>> {
    return this.commonService.getAndCount$({
      controllerRoot: CONTROLLER_ROOT,
      controllerDir: 'filter-and-count',
      page: pageNumber,
      search: searchString
    });
  }

  async getGroupsByTypeAsOptions(entityType: GroupTypeCategoryValues): Promise<{label: string, value: number}[]> {
    const groups = await this.getGroupsByType(entityType);
    return this.buildGroupOptions(groups);
  }

  async getGroupsByType(entityType: GroupTypeCategoryValues): Promise<IGroup[]> {
    try {
      return this.HIO.get(`${CONTROLLER_ROOT}/groups-by-type/${entityType}`);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  buildGroupOptions(groups: IGroup[]): {label: string, value: number}[] {
    const groupsAsOptions: {label: string, value: number}[] = [];
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      groupsAsOptions.push({label: group.name, value: group.id});
    }
    return groupsAsOptions;
  }
}
<span 
*ngIf="!isSocial"
class="m-1" 
nz-icon 
[nzType]="iconType" 
[nzTheme]="'twotone'" 
[nzTwotoneColor]="manageIconState(isActive)" 
style="font-size: 14pt" 
nz-tooltip [nzTooltipTitle]="_nzToolTipTitle"></span>

<span 
*ngIf="isSocial"
class="m-1 social-icon" 
nz-icon 
[nzType]="iconType" 
[nzTheme]="'outline'" 
[nzTooltipColor]="'blue'"
nz-tooltip [nzTooltipTitle]="_nzToolTipTitle"></span>

import { Injectable } from '@angular/core';
import { HttpIoService } from '../communication/http-io.service';
import { IConnector } from 'common_library';


const CONTROLLER_ROOT = 'connector';

@Injectable({ providedIn: 'root' })
export class ConnectorService {

  constructor(private HIO: HttpIoService) { }

  async getOneAdmin(id: number): Promise<IConnector> {
    return await this.HIO.get<IConnector>(`${CONTROLLER_ROOT}/admin/one/${id}`);
  }

  async getOneAdminBlock(id: number): Promise<IConnector> {
    return await this.HIO.get<IConnector>(`${CONTROLLER_ROOT}/admin/block/one/${id}`);
  }

  async getConnectorsByStationId(id: string): Promise<IConnector[]> {
    return await this.HIO.get<IConnector[]>(`${CONTROLLER_ROOT}/by-station/${id}`);
  }

  public async dataTransfer(json: string): Promise<any> {
    try {
      return await this.HIO.post<string, any>(`${CONTROLLER_ROOT}/data-transfer`, json);
    } catch (error) {
      console.error('🐸 Error: ', error);
    }
  }

}

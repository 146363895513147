import { Component, Input, OnInit } from '@angular/core';
import { IPod } from 'common_library';
import { PodService } from 'src/app/services/entities/pod.service';

@Component({
  selector: 'app-block-pod-list',
  templateUrl: './block-pod-list.component.html',
  styleUrls: ['./block-pod-list.component.scss']
})
export class BlockPodListComponent implements OnInit {
  @Input() pods: IPod[]
  @Input() userId: string;
  
  constructor(private podsService: PodService) { }

  async ngOnInit(): Promise<void> {
    if (this.userId) this.pods = await this.podsService.getPodByOwnerId(this.userId);
  }

}

import { Injectable } from '@angular/core';
import { IMultimedia, IPhoto } from 'common_library';
import { AppService } from '../app.service';
import { HttpIoService } from '../communication/http-io.service';

const CONTROLLER_ROOT = 'multimedia'

@Injectable({
  providedIn: 'root',
})
export class MultimediaService {

  constructor(private app: AppService, private HIO: HttpIoService) {}

  async getAllMultimedia(search?: string): Promise<IMultimedia[]> {
    try {
      if (search) {
        return this.HIO.get(`${CONTROLLER_ROOT}/${search}`);
      } else {
        return this.HIO.get(`${CONTROLLER_ROOT}`);
      }
    } catch (error) {
      console.error("🐸, error -> ", error);
    }
  }

  async uploadMultimedia(file: Blob) {
    const formData = new FormData();
    formData.append('file', file);
    try {
      return this.HIO.post(`${CONTROLLER_ROOT}/upload-multimedia`, formData);
    } catch (error) {
      console.error("🐸, error -> ", error);
    }
  }

  async getMultimedia(id: string): Promise<any> {
    try {
      return this.HIO.get(`${CONTROLLER_ROOT}/one/${id}`);
    } catch (error) {
      console.error("🐸, error -> ", error);
    }
  }

  async getImage(name) {
    try {
      return this.HIO.get(`${CONTROLLER_ROOT}/image/${name}`)
    } catch (error) {
      console.error("🐸, error -> ", error);
    }
  }

  async deleteMultimedia(multimediaId: string): Promise<boolean> {
    try {
      await this.HIO.deleteItem(CONTROLLER_ROOT, multimediaId);
      return true;
    } catch (error) {
      console.log("🐸: error -> ", error);
      return false;
    }
  }

  getImageUrl(imageName: string): string {
    return this.HIO.endPoint(`${CONTROLLER_ROOT}/image/${imageName}`)
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IInvoice } from 'common_library';
import { InvoiceService } from 'src/app/services/entities/invoice.service';

@Component({
  selector: 'app-block-invoice',
  templateUrl: './block-invoice.component.html',
  styleUrls: ['./block-invoice.component.scss']
})
export class BlockInvoiceComponent implements OnInit {

  @Input() invoiceId: number;
  @Input() invoice: IInvoice;
  
  loading: boolean = false;
  
  constructor(private invoiceService: InvoiceService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    await this.getInvoice();
  }

  async getInvoice() {
    this.loading = true;
    if (!this.invoice) {
      this.invoice = await this.invoiceService.getOne(this.invoiceId);
    }
    this.invoice["invoiceState"] = this.invoice.state;
    this.loading = false
  }

  goToInvoiceDetail() {
    this.router.navigate([`authenticated/invoice/${this.invoiceId}`]);
  }

}

export type ValueOf<T> = T[keyof T];

export const OCPIChargingPreferencesResponse = Object.freeze({
  ACCEPTED: "ACCEPTED",
  DEPARTURE_REQUIRED: "DEPARTURE_REQUIRED",
  ENERGY_NEED_REQUIRED: "ENERGY_NEED_REQUIRED",
  NOT_POSSIBLE: "NOT_POSSIBLE",
  PROFILE_TYPE_NOT_SUPPORTED: "PROFILE_TYPE_NOT_SUPPORTED",
});

export type OCPIChargingPreferencesResponseKeys = keyof typeof OCPIChargingPreferencesResponse;
export type OCPIChargingPreferencesResponseValues = ValueOf<typeof OCPIChargingPreferencesResponse>;
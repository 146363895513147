export type ValueOf<T> = T[keyof T];

export const StatoCorrispettivoElettronico = Object.freeze({
  New: 0,
  Processing: 1,
  Done: 2,
  Failed: 3,
  Reprocessing: 4,
  Canceled: 5,
  Unnecessary: 6,
  Canceling: 7
});

export type StatoCorrispettivoElettronicoValues = ValueOf<typeof StatoCorrispettivoElettronico>;
export type StatoCorrispettivoElettronicoKeys = keyof typeof StatoCorrispettivoElettronico;
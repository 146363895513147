import { Injectable } from "@angular/core"
import { HttpIoService } from "./communication/http-io.service"
import { RecordCounted } from "common_library";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

export const UNKNOWN_ICON = "question-circle";

export interface Item { label?: string; value?: any, icon?: string; type?: string; um?: string; decimal?: number; key?: string; section?: string, sectionRank?: number, color?: string, fullLengthDate?: boolean }


/**
 * ```page``` -> il valore di "offset" usato per paginare i dati; 
 * ```search``` -> la stringa per filtrare i dati; 
 * ```controllerRoot``` -> la cartella root di dove si trova il controller; 
 * ```controllerDir``` -> il path del controller che risponde alla chiamata; 
 */
export type ParamsOfGetAndCount = {
  page?: number,
  search?: string,
  controllerRoot: string,
  controllerDir: string
}

@Injectable({ providedIn: 'root' })
export class CommonService {

  constructor(private HIO: HttpIoService, private translate: TranslateService) { }

  /**
   * Metodo che prende come parametro un oggetto e ritorna record di tipo ```<T>``` specificato, paginati e filtrati.
   * Nell'oggetto passato come parametro vengono specificati pagina, stringa di ricerca, cartella root del controller a cui si va a fare la chiamata
   * e il path a cui risponde il controller che recupera i record filtrati e paginati.
   * @param params: ```{page: number, search: string, controllerRoot: string, controllerDir: string}```
   * @returns un oggetto contenente data: un array di record di tipo ```<T>``` filtrati e paginati, count: il numero totale di record di tipo ```<T>``` in database
   */
  async getAndCount<T>(params: ParamsOfGetAndCount): Promise<RecordCounted<T>> {
    try {
      const res: RecordCounted<T> = await this.HIO.post<RecordCounted<T>, any>(`${params.controllerRoot}/${params.controllerDir}`, { pageNumber: params.page - 1, searchCriteria: params.search });
      return res;
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }
  getAndCount$<T>(params: ParamsOfGetAndCount): Observable<RecordCounted<T>> {
    try {
      const res = this.HIO.post$<RecordCounted<T>, any>(`${params.controllerRoot}/${params.controllerDir}`, { pageNumber: params.page - 1, searchCriteria: params.search });
      return res;
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }
  /**
   * Metodo che controlla se il valore passato come parametro è una data con a formato ISO-8601
   * Se il valore è una data con formato ISO-8601 viene ritornato "data" altrimenti null
   * @param value : any -> il valore da verificare
   * @returns "date" | null
   */
  public isTypeDate(value: any): "date" | null {
    const iso8601RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;
    if (iso8601RegExp.test(value)) {
      return "date"
    }
    return null
  }

  /**
   * Metodo che ritorna la chiave dell'oggetto (object) dato il valore (value)
   * 
   * @param object object o enum che contiene i valori
   * @param value il valore per il quale trovare la chiave
   * @returns la chiave che ha il valore "value"
   */
  findEntityKey(object: object, value: number) {
    return Object.keys(object).find(key => object[key] === value);
  }
}

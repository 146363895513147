<app-spinner [isActive]="spinner"></app-spinner>
<div class="page-cont scrollbar-color">
  <ng-container *ngIf="sessions$ | async as sessions">
    <div class="form-container yf-flex yf-flex-align-start">
      <div class="tipo1 yf-flex yf-flex-align-center yf-wrap">
        <form [formGroup]="form"
              class="form-session pl-5">
          <formly-form [model]="model"
                      [fields]="fields"
                      [form]="form">
          </formly-form>
        </form>
      </div>


      <div class="pr-20 buttons-container">
        <ng-container *ngIf="!AS.isMobile" [ngTemplateOutlet]="nzPaginationTemplate"></ng-container>
        <button (click)="refreshActiveSessions()" nzSpaceItem nz-button>
          <i nz-icon nzType="reload" nzTheme="outline"></i>
        </button>
      </div>
    </div>

    <nz-table *ngIf="!AS.isMobile"
              #basicTable
              [nzData]="sessions.data"
              [nzPageSize]="10"
              [nzFrontPagination]="false"
              [nzShowPagination]="false"
              [nzScroll]="{y: 'calc(100vh - 230px)'}"
              nzSize="small">
      <thead>
        <tr>
          <th [nzWidth]="'40px'"></th>
          <th nzWidth="25%">{{'SESSION.MAIN.USER'|translate}}</th>
          <th>{{'SESSION.MAIN.STATE'|translate}}</th>
          <th [nzWidth]="'260px'">{{'SESSION.MAIN.STATION'|translate}}</th>
          <th>{{'SESSION.MAIN.DATE'|translate}}</th>
          <th>{{'SESSION.MAIN.DURATION'|translate}}</th>
          <th>{{'SESSION.MAIN.ENERGY'|translate}}</th>
          <th>{{'SESSION.MAIN.POWER'|translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data" routerLink="/authenticated/sessions/{{data.id}}">
          <!-- <td>
            <app-entity-tag [isSmallContainer]="true" [entityType]="'online'" [entity]="!(!!data.endedAt)"></app-entity-tag>
          </td>   -->
          <td>
            <span class="m-1" nz-icon nzType="thunderbolt" [nzTheme]="'twotone'" [nzTwotoneColor]="data.endedAt? '#ccc' : 'blue'"  style="font-size: 14pt" nz-tooltip nzTooltipTitle="{{'NZTOOLTIPTITLE.ACTIVE_SESSION'|translate}}"></span>
          </td>
        <td class="user">
            <app-entity-tag [entity]="data.user" entityType="user"></app-entity-tag>
          </td>
          <td>
            <app-entity-tag [entity]="data.state" entityType="state"></app-entity-tag>
          </td>
          <td>
            <app-entity-tag [entity]="data.connector.station" entityType="station"></app-entity-tag>
          </td>
          <td>
            <span>
              {{ data.createdAt | localizedDate:"short" }}
            </span>
            <span *ngIf="data.endedAt">
              - {{ data.endedAt | localizedDate:"short" }}
            </span>
          </td>
          <td>
            <app-timespan [fromDate]="data.createdAt" [toDate]="data.endedAt"></app-timespan>
          </td>
          <td>
            {{data.energy | energy }} kWh
          </td>
          <td>
            {{data.power | power }} kW
          </td>

        </tr>
      </tbody>
    </nz-table>


    
    <div *ngIf="AS.isMobile" class="yf-scroll-y cards-container" nz-space nzDirection="vertical" nzAlign="center">
      <ng-container *ngIf="!sessions.data || sessions.data.length === 0" [ngTemplateOutlet]="noDati"></ng-container>
      <nz-card
      class="mobile-card"
      *ngFor="let session of sessions.data"
      routerLink="/authenticated/sessions/{{session.id}}"
      nzTitle="{{session.user.surname}} {{session.user.name}}  {{ session.connector.station.name }}"
      [nzExtra]="extraIconsTemplate"
      >
      <div>

        <!-- <app-block-values [data]="session" [mainFields]="['power']"></app-block-values> -->

        <app-block-value itemKey="user" label="{{'SESSION.MAIN.USER'|translate}}" [type]="'entity'" [value]="session.user"></app-block-value>
        <app-block-value itemKey="state" label="{{'SESSION.MAIN.STATE'|translate}}" [type]="'entity'" [value]="session.state"></app-block-value>
        <app-block-value itemKey="station" label="{{'SESSION.MAIN.STATION'|translate}}" [type]="'entity'" [value]="session.connector.station" ></app-block-value>
        <app-block-value itemKey="createdAt" label="{{'SESSION.MAIN.CREATED'|translate}}" [value]="session.createdAt"></app-block-value>
        <app-block-value *ngIf="session.endedAt" itemKey="endedAt" label="{{'SESSION.MAIN.ENDED'|translate}}" [value]="session.endedAt"></app-block-value>
        <app-block-value itemKey="{{'SESSION.MAIN.ENERGY'|translate}}" value="{{session.energy | energy }} kWh"></app-block-value>
        <app-block-value itemKey="{{'SESSION.MAIN.POWER'|translate}}" value="{{session.power | power }} kW"></app-block-value>
        <app-block-value type="timespan" label="{{'SESSION.MAIN.DURATION'|translate}}" [value]="{from: session.createdAt, to: session.endedAt}"></app-block-value>
      </div>
      <ng-template #extraIconsTemplate>
        <app-status-icon iconType="thunderbolt" [isActive]="session.endedAt ? false : true"></app-status-icon>
      </ng-template>
      </nz-card>
    </div>

    <div class="mobile-footer" *ngIf="AS.isMobile">
      <ng-container *ngTemplateOutlet="nzPaginationTemplate"></ng-container>
    </div>
    <ng-template #nzPaginationTemplate>
      <nz-pagination
      [nzTotal]="sessions.count"
      [nzSize]="'small'"
      [nzPageSize]="10"
      (nzPageIndexChange)="pageIndexChange($event)"
      [nzPageIndex]="(sessionSearchCriteria$ | async).pageIndex">
      ></nz-pagination>
    </ng-template>
  </ng-container>
</div>

<ng-template #noDati>
  <app-empty-data>
  </app-empty-data>
</ng-template>

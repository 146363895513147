export type ValueOf<T> = T[keyof T];

export const GroupTypeCategory = Object.freeze({
  Users: 'Users',
  Stations: 'Stations',
  Tariff: 'Tariff',
  Visibility: 'Visibility'
});

export type GroupTypeCategoryValues = ValueOf<typeof GroupTypeCategory>;
export type GroupTypeCategoryKeys = keyof typeof GroupTypeCategory;
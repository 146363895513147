import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IConnector, IStation } from 'common_library';
import { BehaviorSubject } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { ConnectorService } from 'src/app/services/entities/connector.service';
import { StationService } from 'src/app/services/entities/station.service';

@Component({
  selector: 'app-block-connectors',
  templateUrl: './block-connectors.component.html',
  styleUrls: ['./block-connectors.component.scss']
})

export class BlockConnectorsComponent implements OnInit, OnChanges {
  @Input() stationId: string;
  @Input() connectorId: number;

  @Output() selectedConnectorIdChanged = new EventEmitter<number>();

  @Input() isEditActive: boolean;
  @Input() showEdit: boolean;

  @Input() isRefreshActive: boolean;

  showSelect: boolean;

  loading: boolean = false;
  station: IStation = null;
  connector: IConnector = null;

  selectedConnectorId: number;

  public connectorForm: UntypedFormGroup;
  connectorOptions$ = new BehaviorSubject<any>(null);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private stationService: StationService,
    private connectorService: ConnectorService,
    public AS: AppService) { }

  ngOnInit(): void {
    this.connectorForm = this.formBuilder.group({
      selectedConnectorIdForm: new UntypedFormControl()
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    // se viene indicato l'id stazione, allora mostro anche la combo
    if (changes.stationId?.currentValue) {
      this.showSelect = true;
      await this.getStation();
    }

    // se viene indicato l'id connettore, non mostro la combo
    if (changes.connectorId?.currentValue) {
      this.showSelect = false;
      await this.getItem();
    }
  }

  async getItem() {
    this.loading = true;
    if (this.connectorId || this.selectedConnectorId) {
      let connectorToRefresh = this.connectorId || this.selectedConnectorId;
      this.connector = await this.connectorService.getOneAdminBlock(connectorToRefresh);
    }
    this.loading = false;
  }

  async refreshItem() {
    await this.getItem();
  }

  async getStation() {
    this.loading = true;
    this.connectorForm?.reset();
    if (this.stationId) {
      this.station = await this.stationService.getOneStationAdminBlockConnector(this.stationId);
      this.connectorOptions$.next(this.getConnectorOptions());
      const defaultConnector = this.station.connectors[0]?.id;
      this.connectorForm?.setValue({
        selectedConnectorIdForm: defaultConnector
      });
    }
    this.loading = false;
  }

  getConnectorOptions() {
    let option = []
    for (let item of this.station.connectors) {
      const dto = {
        label: item?.id?.toString() ? item?.id.toString() : item.id.toString(),
        value: item.id.toString()
      }
      option.push(dto)
    }
    return option
  }

  selectConnector(id: number) {
    this.selectedConnectorId = id;
    this.selectedConnectorIdChanged.emit(id);
    this.getItem();
  }

  edit() {
    this.showEdit = true;
  }

  updateConnector(id: number) {
    this.showEdit = false;
    this.selectConnector(id);
  }
}
export type ValueOf<T> = T[keyof T];

export const ENERGY_SOURCE_CATEGORY = Object.freeze({
  NUCLEAR: "NUCLEAR",
  GENERAL_FOSSIL: "GENERAL_FOSSIL",
  COAL: "COAL",
  GAS: "GAS",
  GENERAL_GREEN: "GENERAL_GREEN",
  SOLAR: "SOLAR",
  WIND: "WIND",
  WATER: "WATER"
})

export type ENERGY_SOURCE_CATEGORYValues = ValueOf<typeof ENERGY_SOURCE_CATEGORY>;
export type ENERGY_SOURCE_CATEGORYKeys = keyof typeof ENERGY_SOURCE_CATEGORY;

<form nz-form [formGroup]="podForm" (ngSubmit)="submit()" class="size container-form">
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzRequired>{{'POD.DETAIL.NAME_NO_PERSON'|translate}}</nz-form-label>
    <nz-form-control nzErrorTip="{{'POD.DETAIL.FORM.VALID_NAME'|translate}}">
      <input nz-input formControlName="name" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzRequired>{{'POD.DETAIL.SERIAL_NUMBER'|translate}}</nz-form-label>
    <nz-form-control nzErrorTip="{{'POD.DETAIL.FORM.VALID_SERIAL_NUMBER'|translate}}">
      <input nz-input formControlName="serial">
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzRequired>{{'POD.DETAIL.POWER_WATT'|translate}}</nz-form-label>
    <nz-form-control nzErrorTip="{{'POD.DETAIL.FORM.VALID_VALUE'|translate}}">
      <nz-input-number formControlName="power"></nz-input-number>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzRequired>{{'POD.DETAIL.MANAGEMENT'|translate}}</nz-form-label>
    <nz-form-control>
      <nz-select formControlName="energyMngt">
        <nz-option *ngFor="let item of energyManagement" [nzValue]="item.id" [nzLabel]="'POD.NEW.'+item.name.toUpperCase()|translate"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">{{'POD.DETAIL.OWNER'|translate}}</nz-form-label>
    <nz-form-control>
      <nz-select nzShowSearch nzServerSearch nzPlaceHolder="{{'POD.DETAIL.FORM.INSERT_ONE_CHAR_FOR_SEARCH'|translate}}"
        formControlName="ownerName" [nzShowArrow]="false" [nzAllowClear]="true"
        (nzOnSearch)="onInputAcOwnerStation($event)">
        <nz-option *ngFor="let option of this.optionUsers$ | async" [nzLabel]="option.label"
          [nzValue]="option.value"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6" [nzRequired]="podForm.value.energyMngt !== '1'">{{'POD.DETAIL.STATION_MASTER'|translate}}</nz-form-label>
    <nz-form-control nzErrorTip="{{'POD.DETAIL.FORM.SELECT_VALID_STATION'|translate}}">
      <nz-select nzShowSearch nzServerSearch nzPlaceHolder="{{'POD.DETAIL.FORM.INSERT_THREE_CHAR_FOR_SEARCH'|translate}}"
        formControlName="masterStationName" [nzShowArrow]="false" [nzAllowClear]="true"
        (nzOnSearch)="onInputAcMasterStation($event)">
        <nz-option *ngFor="let option of this.optionStations$ | async" [nzLabel]="option.label"
          [nzValue]="option.value"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">{{'POD.DETAIL.RATE'|translate}}</nz-form-label>
    <nz-form-control nzErrorTip="{{'POD.DETAIL.FORM.VALID_TARIFF'|translate}}">
      <nz-select nzShowSearch nzServerSearch nzPlaceHolder="{{'POD.DETAIL.FORM.INSERT_ONE_CHAR_FOR_SEARCH'|translate}}"
        formControlName="tariffName" [nzShowArrow]="false" [nzAllowClear]="true"
        (nzOnSearch)="onInputTariffStation($event)">
        <nz-option *ngFor="let tariff of tariffs$ | async" [nzLabel]="tariff.name" [nzValue]="tariff.id"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</form>
<div nz-row>
  <div nz-col nzXs="24" nzSm="24" nzMd="24">
    <div nz-row nzJustify="end" [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <div nz-col nzXs="8">
        <button class="btn" nz-button nzBlock [disabled]="!podForm.dirty" (click)="reset()">{{'BUTTONS.CANCEL'|translate}}</button>
      </div>
      <div nz-col nzXs="8">
        <button class="btn" nz-button type="submit" nzType="primary" nzBlock (click)="submit()"
          [disabled]="podForm.invalid">{{'BUTTONS.SAVE'|translate}}</button>
      </div>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import { IError, INewUser, IRfid, IUpdateUserDTO, IUser, RecordCounted } from 'common_library';
import { Observable, Subject } from 'rxjs';
import { HttpIoService } from '../communication/http-io.service';
import { CommonService } from '../common.service';

const CONTROLLER_ROOT = 'user/admin';


@Injectable({ providedIn: 'root' })
export class UserService {
  selectedRfid$ = new Subject<IRfid>()
  constructor(private HIO: HttpIoService, private commonService: CommonService) { }

  async getUsersByString(string: string): Promise<IUser[]> {
    return await this.HIO.get<IUser[]>(`${CONTROLLER_ROOT}/search/${string}`);
  }

  async getUserById(id: string): Promise<IUser> {
    return await this.HIO.get<IUser>(`${CONTROLLER_ROOT}/${id}`);
  }

  async getOneUserAdminBlock(id: string): Promise<IUser> {
    try {
      return this.HIO.get<IUser>(`${CONTROLLER_ROOT}/block/one/${id}`);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  /**
   *
   * @param pageNumber {number}: il numero della pagina da cui parte la ricerca
   * @param searchCriteria {string}: la stringa usata per filtrare la ricerca
   * @returns
   */
  getAndCountUsersByStringPaginated(pageNumber: number, searchCriteria?: string): Observable<RecordCounted<IUser>> {
    const res = this.commonService.getAndCount$<IUser>({
      page: pageNumber,
      search: searchCriteria,
      controllerRoot: CONTROLLER_ROOT,
      controllerDir: "filterpage"
    });
    return res;
  }

  async updateMe(dto: IUpdateUserDTO): Promise<IUser> {
    try {
      return this.HIO.patch(`${CONTROLLER_ROOT}/me`, dto);
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }

  async updateUser(userId: string, dto: IUpdateUserDTO): Promise<IUser> {
    try {
      return this.HIO.patch(`${CONTROLLER_ROOT}/${userId}`, dto);
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }

  async create(user: INewUser): Promise<IUser | IError> {
    try {
      return this.HIO.post(`${CONTROLLER_ROOT}/create`, user);
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }

  async resetPassword(userId: string, newPassword: string): Promise<boolean> {
    try {
      return this.HIO.post(`${CONTROLLER_ROOT}/reset-password`, { userId, newPassword });
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }

  async changePassword(newPassword: string, oldPassword: string): Promise<boolean> {
    try {
      return this.HIO.post(`${CONTROLLER_ROOT}/change-password`, { newPassword, oldPassword });
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }

  async setLanguage(userID: string, newLanguage: any) {
    try {
      return this.HIO.post(`${CONTROLLER_ROOT}/set-language`, { userID, newLanguage });
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }

  async getUserRolesByToken(): Promise<string[]> {
    try {
      return this.HIO.get(`${CONTROLLER_ROOT}/roles-by-token`);
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }

  async addUsersToGroupByDomain(domain: string) {
    try {
      return this.HIO.post(`${CONTROLLER_ROOT}/add-users-by-domain`, { domain });
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getUsersByDomain(domain: string) {
    try {
      return this.HIO.get<IUser[]>(`${CONTROLLER_ROOT}/users-by-domain?domain=${domain}`);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getAllUsers() {
    try {
      return this.HIO.get<IUser[]>(`${CONTROLLER_ROOT}/options-users`);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

}

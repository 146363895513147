import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  get<T>(key:string):T {
    const value = localStorage.getItem(key);
    if (!value) {
      return null;
    }
    try {
      return JSON.parse(value) as T
    } catch (e) {
      return value as unknown as T;
    }
  }

  set(key:string, value:any) {
    localStorage.setItem(key,JSON.stringify(value));
  }

  remove(key:string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}

export type ValueOf<T> = T[keyof T];

export const IEntityTypeLog = Object.freeze({
  STATION: 'station',
  INSTALLATION: 'installation',
  SESSION: 'session',
  GATEWAY: 'gateway'
});


export type IEntityTypeLogValues = ValueOf<typeof IEntityTypeLog>;
export type IEntityTypeLogKeys = keyof typeof IEntityTypeLog;

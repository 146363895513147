import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ICostRevenueSplit, IUser } from 'common_library';
import { CommonService } from 'src/app/services/common.service';
import { CostRevenueSplitService } from 'src/app/services/entities/cost-revenue-split.service';

@Component({
  selector: 'app-block-crs',
  templateUrl: './block-crs.component.html',
  styleUrls: ['./block-crs.component.scss']
})
export class BlockCrsComponent implements OnChanges {
  @Input() crsId: number;
  crs: ICostRevenueSplit;
  loading: boolean = false;
  
  _crs: ICostRevenueSplit;
  user: IUser;

  constructor(public crsService: CostRevenueSplitService, public commonService: CommonService) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.crsId?.currentValue) {
      await this.getCrs();
    }
  }

  async getCrs() {
    this.loading = true;
    if (this.crsId) {
      this.crs = await this.crsService.getOne(this.crsId);
    }
    this.loading = false;
  }
}

<div *ngIf="chapter" class="info-preview__container">
  <div class="preview-chapter">
    <app-block-header title="{{'CONTENTS.MANAGEMENT.CHAPTER_PREVIEW'|translate}}" icon="mobile" [isRefreshActive]="false"></app-block-header>
    <div class="info-preview" *ngIf="chapter">
      <div class="info-preview__header">
        <i nz-icon nzType="info-circle" class="info-circle"></i>
        <h3>APP INFO</h3>
        <i nz-icon nzType="close" class="info-close"></i>
      </div>
      <div class="chapter-item">
        <div class="chapter-title">
          <i nz-icon nzType="file" slot="start" class="item-icon"></i>
          <div class="chapter-texts">
            <p class="fw-600">{{ chapter.title }}</p>
            <p>{{ chapter.subtitle }}</p>
          </div>
        </div>
        <div class="chapter-item-icons">
          <i nz-icon *ngIf="chapter.icon" [nzType]="chapter.icon" class="item-icon" color="secondary"> </i>
          <i nz-icon nzType="right" class="item-icon"></i>
        </div>
      </div>
    </div>
    
  </div>

  <div class="preview-section">
    <app-block-header title="{{'CONTENTS.CHAPTERS.DETAIL.SECTIONS_PREVIEW'|translate}}" icon="mobile" [isRefreshActive]="false"></app-block-header>
    <div class="info-preview" *ngIf="chapter">
      <div *ngFor="let section of chapter.infoSections">
        <div class="mt-10 section-title" *ngIf="section.title">{{ section.title }}</div>
        <div class="mt-4 section-subtitle" *ngIf="section.subtitle">{{ section.subtitle }}</div>
        <div class="mt-10 section-img" *ngIf="section.multimedia">
          <img width="100%" height="auto" [src]="multimediaService.getImageUrl(section?.multimedia?.name + '.' + section?.multimedia?.fileExtension)"/>
        </div>
        <div class="mt-10 section-html" *ngIf="section.html" [innerHTML]="section.html"></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="chapters" class="info-preview__container">
  <div class="preview-chapter">
    <app-block-header title="{{'CONTENTS.MANAGEMENT.CHAPTER_PREVIEW'|translate}}" icon="mobile" [isRefreshActive]="false"></app-block-header>
    <div class="info-preview" *ngIf="chapters">
      <div class="info-preview__header">
        <i nz-icon nzType="info-circle" class="info-circle"></i>
        <h3>APP INFO</h3>
        <i nz-icon nzType="close" class="info-close"></i>
      </div>
      <div *ngFor="let chapter of chapters" class="chapter-item">
        <div class="chapter-title">
          <i nz-icon nzType="file" slot="start" class="item-icon"></i>
          <div class="chapter-texts">
            <p class="fw-600">{{ chapter.title }}</p>
            <p>{{ chapter.subtitle }}</p>
          </div>
        </div>
        <div class="chapter-item-icons">
          <i nz-icon *ngIf="chapter.icon" [nzType]="chapter.icon" class="item-icon" color="secondary"> </i>
          <i nz-icon nzType="right" class="item-icon"></i>
        </div>
      </div>
    </div>
    
  </div>
</div>

<ng-container *ngIf="!!station && !!connector">
  <app-block-header [title]="title" icon="bars" [isRefreshActive]="false"></app-block-header>
  <ng-container *ngIf="!session?.endedAt || !!session?.id">
    <div nz-row [nzGutter]="16" class="pb-16">
      <ng-container *ngIf="type==='user'">
        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzXl="6">
          <a nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM_RESERVATION'|translate}}" (nzOnConfirm)="reserveConnector()">
            <button class="stop-button" nz-button nzType="primary" [nzBlock]="true"
              [disabled]="(connector.state !== 1)">
              {{'BUTTONS.BOOK_CONNECTOR'|translate}}
            </button>
          </a>
        </div>
        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzXl="6">
          <a nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM_CHARGING_START'|translate}}" (nzOnConfirm)="startCharging()">
            <button class="stop-button" nz-button nzType="primary" [nzBlock]="true"
              [disabled]="connector.state === 0 || connector.state === 3 || connector.state === 4 ">
              {{'BUTTONS.START_CHARGING'|translate}}
            </button>
          </a>
        </div>
      </ng-container>
      <ng-container *ngIf="type==='session'">
        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzXl="6">
          <a nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CANCEL_RESERVATION'|translate}}" (nzOnConfirm)="cancelReservation()">
            <button class="stop-button" nz-button nzType="default" [nzBlock]="true"
              [disabled]="(connector.state !== 2)">
              {{'BUTTONS.CANCEL_RESERVATION'|translate}}
            </button>
          </a>
        </div>
        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzXl="6">
          <a nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM_CHARGING_INTERRUPTION'|translate}}" (nzOnConfirm)="stopCharging()">
            <button class="stop-button" nz-button nzType="default" [nzBlock]="true" [disabled]="connector.state !== 3">
              {{'BUTTONS.STOP_CHARGING'|translate}}
            </button>
          </a>
        </div>
        <div nz-col nzXs="24" nzSm="12" nzMd="12" nzXl="6">
          <a class="mr-2" nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM_SESSION_CLOSURE'|translate}}" (nzOnConfirm)="stopSession()">
            <button class="stop-button" nzDanger nzSpaceItem nz-button nzType="primary" [nzBlock]="true"
              [disabled]="session?.endedAt ? true : false">
              {{'BUTTONS.CLOSE_SESSION'|translate}}
            </button>
          </a>
        </div>
      </ng-container>
    </div>
    <div nz-row [nzGutter]="16" *ngIf="type==='session'" class="pb-16">
      <div nz-col nzXs="24" nzSm="12" nzMd="12" nzXl="6">
        <div>
          {{'BUTTONS.LIMIT_POWER'|translate}}
          <nz-slider nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM_CHANGE_MAXIMUM_POWER'|translate}}"
            (nzOnConfirm)="limitPower()" (nzOnCancel)="resetPower()" [nzMin]="0" [nzMax]="sessionPowerMaxValue"
            [(ngModel)]="sessionPowerLimit" [nzTipFormatter]="sliderformatter"></nz-slider>
        </div>
      </div>
      <div nz-col nzXs="24" nzSm="12" nzMd="12" nzXl="6">
        <a nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CLEAR_POWER_LIMIT'|translate}}" (nzOnConfirm)="clearLimitPower()">
          <button class="stop-button" nz-button nzType="default" [nzBlock]="true" [disabled]="connector.state !== 3">
            {{'BUTTONS.CLEAR_POWER_LIMIT'|translate}}
          </button>
        </a>
      </div>
    </div>
  </ng-container>
</ng-container>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'power'
})
export class PowerPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    if(value){
      return parseFloat(`${value / 1000}`).toFixed(2);
    }else{
      return '0.0';
    }
  }

}

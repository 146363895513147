export type ValueOf<T> = T[keyof T];

export const ILevelLog = Object.freeze({
  INFO: "INFO",
  WARNING: "WARNING",
  ERROR: "ERROR",
  DEBUG: "DEBUG"
});


export type ILevelLogValues = ValueOf<typeof ILevelLog>;
export type ILevelLogKeys = keyof typeof ILevelLog;
<div class="w-full">

  <form nz-form nzLayout='vertical' [formGroup]="form">
    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
  </form>

  <p class="text-gray-500 ml-3 mb-10">{{'REPORT.CONTEXT_PARAMETERS_CTX'|translate}}:<b> gestoreId</b> </p>

  <div class="flex flex-row gap-2">
    <button nz-button nzType="primary" (click)="this._testerOpen$.next(true)" class="text-icons"><span nz-icon
        nzType="sliders"></span>Tester</button>
    <!-- <button nz-button nzType="default" (click)="this._selectorTesterOpen$.next(true)" class="text-icons"><span nz-icon
        nzType="block"></span>Selector Tester</button> -->
  </div>

  <nz-list nzBordered nzSize="small" class="mt-10">
    <nz-list-header><span nz-icon nzType="info-circle" nzTheme="outline" class="align-middle"></span> <b
        class="text-blue-600 ml-2">{{'REPORT.QUERY_USERS'|translate}}</b> </nz-list-header>
    <nz-list-item *ngFor="let item of usedBy$ | async; last as isLast">
      <nz-list-item-meta-title>
        <span nz-icon [nzType]="item.icon" nzTheme="outline" class="align-middle mr-2"></span>
        <nz-tag>{{ item.category }}</nz-tag>
        <b>{{ item.name }}</b>
        <span class="text-gray-500 text-xs ml-5">{{ item.description }}</span>
      </nz-list-item-meta-title>
    </nz-list-item>
    @if ((usedBy$ | async)?.length === 0) {
    <nz-list-item>
      <i class="text-gray-500">{{'REPORT.NO_EXPORT'|translate}}</i>
    </nz-list-item>
    }

  </nz-list>

</div>

<nz-drawer [nzWidth]="this.drawerWidth()" [nzVisible]="testerOpen$ | async" nzPlacement="right" nzTitle="Query Tester"
  (nzOnClose)="this._testerOpen$.next(false)">
  <ng-container *nzDrawerContent>

    <textarea nz-input placeholder="SQL" [(ngModel)]="this.model.sql" class="mb-10 bg-gray-100 text-blue-600"></textarea>

    <div class="params-container">
      <nz-input-group *ngFor="let paramName of this.model.params" [nzAddOnBefore]="paramName" class="param-input">
        <input type="text" nz-input [(ngModel)]="params[paramName]" />
      </nz-input-group>
    </div>

    <div class="mb-10 flex flex-row">
      <button nz-button nzType="primary" (click)="execSql()" class="text-icons"><span nz-icon
          nzType="thunderbolt"></span>{{'REPORT.EXECUTE'|translate}}</button>
      <nz-tag *ngIf="this.err$ | async as err" nzColor="red" class="m-5"><span nz-icon nzType="exclamation-circle"
          nzTheme="outline" class="align-middle"></span> {{ err }}</nz-tag>
      <nz-tag *ngIf="this.items$ | async as items" nzColor="blue" class="m-5"><span nz-icon nzType="database"
          nzTheme="outline" class="align-middle"></span> {{ items.length }} rows</nz-tag>
      <nz-tag *ngIf="this.ms$ | async as ms" class="m-5" nzColor="green"><span nz-icon nzType="field-time"
          nzTheme="outline" class="align-middle"></span> {{ ms }} ms</nz-tag>
    </div>

    <simple-table [items]="this.items$ | async" [loading]="this.loading$ | async" class="mt-10" [height]="this.heightPx(40)" [itemsPerPage]="10"></simple-table>

  </ng-container>
</nz-drawer>

<nz-drawer [nzWidth]="this.drawerWidth()" [nzVisible]="selectorTesterOpen$ | async" nzPlacement="right"
  nzTitle="Selector Tester" (nzOnClose)="this._selectorTesterOpen$.next(false)">
  <ng-container *nzDrawerContent>

    <simple-table [items]="this.items$ | async" [loading]="this.loading$ | async" class="10" [height]="this.heightPx(40)" [itemsPerPage]="10"></simple-table>
  </ng-container>
</nz-drawer>

<div nz-row class="mt-10 yf-flex yf-flex-row yf-jc-end" >
  <div nz-col div class="mb-10">
    <div nz-row nzJustify="end" [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <div nz-col  div class="mb-10">
        <button class="btn" nz-button nzType="primary" nzBlock
          (click)="this.reset()">{{'BUTTONS.CANCEL'|translate}}</button>
      </div>
      <div nz-col div class="mb-10">
        <button class="btn" nz-button type="submit" nzType="primary" nzBlock (click)="submitForm()"
          [disabled]="form.invalid || form.pristine">{{'BUTTONS.SAVE'|translate}}</button>
      </div>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IToken } from 'common_library';
import { TokenService } from 'src/app/services/entities/tokens.service';

@Component({
  selector: 'app-block-token-list',
  templateUrl: './block-token-list.component.html',
  styleUrls: ['./block-token-list.component.scss']
})
export class BlockTokenListComponent implements OnInit {

  @Input() tokens: IToken[];
  @Input() userId: string;

  constructor(private tokenService: TokenService, private translate: TranslateService) { }

  getTooltipTitleValid(data: IToken): string {
    return data.valid
      ? this.translate.instant('BLOCK.TOKEN_LIST.VALID_TOKEN')
      : this.translate.instant('BLOCK.TOKEN_LIST.INVALID_TOKEN');
  }

  async ngOnInit(): Promise<void> {
    await this.getTokens();
  }

  async getTokens() {
    if (this.userId) {
      this.tokens = await this.tokenService.getTokensByUserId(this.userId);
    }
  }
}

export type ValueOf<T> = T[keyof T];

export const EnergyManagement = Object.freeze({
  None: 0,
  Balanced: 1,
  AlmostBalanced: 2,
  FirstStarted: 3
});


export type EnergyManagementValues = ValueOf<typeof EnergyManagement>;
export type EnergyManagementKeys = keyof typeof EnergyManagement;
import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'multiselect-formly-custom',
  template: `
    <nz-form-item>
      <nz-form-label>{{props.label}}</nz-form-label>
      <nz-select class="nz-select" [formControl]="formControl" [formlyAttributes]="field" nzMode="multiple">
        <nz-option *ngFor="let option of _options | async" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
      </nz-select>
    </nz-form-item>
  `,
  styleUrls: ['multiselect-formly-custom.component.scss']
})
export class MultiSelectFormlyCustom extends FieldType<FieldTypeConfig> implements OnInit {
  _options;
  ngOnInit(): void {
    this._options = this.props.options
  }
}

import { Inject, Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { v4 } from 'uuid';
import { _ } from '../consts';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NzNotificationService } from 'ng-zorro-antd/notification';


export const log: Array<{ vers: string; info: string; items: Array<{ desc: string; type: string; }> }> = [
  { vers: '1.78', info: '25/10/2023', items: [{ desc: 'Aggiunta mappa con visualizzazione rapida dei comandi di troubleshooting.', type: 'feature' }] },
  { vers: '1.37', info: '13/06/2023', items: [{ desc: 'Tariffa migliorate, nuovo sistema pagamenti.', type: 'feature' }] },
  { vers: '1.36', info: '12/06/2023', items: [{ desc: 'Sistemazione CSS modulo Tariffa, CDR, ecc..', type: 'feature' }] },
  { vers: '1.35', info: '07/06/2023', items: [{ desc: 'Simulatore e form tariffa', type: 'feature' }] },
  { vers: '1.34', info: '09/05/2023', items: [{ desc: 'Aggiunta contenuti personalizzabili', type: 'feature' }, { desc: 'Filtro sessioni migliorato', type: 'feature' }, { desc: 'OCPI con EnelX', type: 'feature' }, { desc: 'Migliorata completamente UI/UX', type: 'feature' }, { desc: 'Fix vari', type: 'fix' }] },
  { vers: '1.05', info: '05/12/2022', items: [{ desc: 'Gateway Software Version', type: 'feature' }] },
  { vers: '1.03', info: '02/12/2022', items: [{ desc: 'Layout rivisto', type: 'fix' }] },
  { vers: '1.02', info: '25/11/2022', items: [{ desc: 'Fix vari', type: 'fix' }] },
  { vers: '1.01', info: '11/11/2022', items: [{ desc: 'Aggiunta gestione CP online', type: 'feature' }, { desc: 'Fix vari', type: 'fix' }] },
  { vers: '1.00', info: '04/11/2022', items: [{ desc: 'Release versione prod su yourfill.it', type: 'feature' }, { desc: 'Fix vari', type: 'fix' }] },
  { vers: '0.38', info: '03/11/2022', items: [{ desc: 'Aggiunto modulo log', type: 'feature' }, { desc: 'Fix vari', type: 'fix' }] },
  { vers: '0.37', info: '26/10/2022', items: [{ desc: 'Aggiunto modulo pod', type: 'feature' }, { desc: 'Adeguamento dashboard a nuovi moduli', type: 'feature' }, { desc: 'Fix vari', type: 'fix' }] },
  { vers: '0.36', info: '19/10/2022', items: [{ desc: 'Aggiunta entità gateway', type: 'feature' }, { desc: 'Filtro sessioni migliorato', type: 'feature' }, { desc: 'Fix vari', type: 'fix' }] },
  { vers: '0.35', info: '12/10/2022', items: [{ desc: 'Autenticazione migliorata', type: 'feature' }, { desc: "Se si è offline non viene revocato l'accesso all'applicazione. Aggiunto avviso in caso si è offline.", type: 'feature' }, { desc: 'Fix vari', type: 'fix' }] },
  { vers: '0.34', info: '11/10/2022', items: [{ desc: 'Aggiunta entità installazione', type: 'feature' }, { desc: 'Aggiunti riferimenti transazione', type: 'feature' }] },
  { vers: '0.33', info: '07/10/2022', items: [{ desc: 'Nuova dashboard', type: 'feature' }] },
  { vers: '0.32', info: '27/09/2022', items: [{ desc: 'Editor Gruppi', type: 'feature' }, { desc: 'Creazione nuovi gruppi', type: 'feature' }, { desc: 'Fix vari', type: 'fix' }] },
  { vers: '0.31', info: '21/09/2022', items: [{ desc: 'Fix record duplicati su search bar', type: 'fix' }, { desc: 'Visualizzazione dettaglio gruppo e funzionalità CRUD', type: 'feature' }, { desc: 'App responsive', type: 'feature' }, { desc: "L'applicazione è stata ottimizzata per dispositivi smartphone e tablet.", type: 'feature' }] },
  { vers: '0.30', info: '20/09/2022', items: [{ desc: 'Tile Sessioni Attive', type: 'feature' }, { desc: 'Numero delle sessioni attive, con il totale dei kW. ', type: 'feature' }] },
  { vers: '0.29', info: '06/09/2022', items: [{ desc: 'Lista sessioni migliorata', type: 'feature' }, { desc: 'Ora è possibile cercare le sessioni in base allo stato attivo o non, cercare le sessioni in base alla data di inizio e fine.', type: 'feature' }] },
  { vers: '0.28', info: '06/09/2022', items: [{ desc: 'Aggiunti gruppi', type: 'feature' }, { desc: 'Aggiunti dettaglio gruppi', type: 'feature' }] },
]

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public apiUrl: string;
  isOffline: boolean;
  hasValueBeenCopied: boolean;

  constructor(
    @Inject('BACKEND_URL') private backendUrl: string,
    private store: StorageService,
    private DDS: DeviceDetectorService,
    private notification: NzNotificationService,
  ) {
    this.apiUrl = `${backendUrl}/api`;
    if (backendUrl.startsWith('$')) console.error('!!WRONG CONFIGURATION!!');
  }

  get clientId(): string {
    let id = this.store.get<string>(_.CLIENT_KEY);
    if (!id) {
      id = v4();
      this.store.set(_.CLIENT_KEY, id);
    }
    return id;
  }
  get isMobile(): boolean {
    return this.DDS.isMobile();
  }
  createNotification(type, title, message) {
    return this.notification.create(type, title, message);
  }

  get appOff() {
    return this.isOffline
  }
  set appOff(value) {
    this.isOffline = value;
  }
}

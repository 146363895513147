import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-yfl-field-file',
  template: '<input type="file" accept="image/*" [formControl]="_formControl" [formlyAttributes]="field">',
})
export class YflFieldFileComponent extends FieldType implements OnInit {

  constructor() { 
    super();
  }

  ngOnInit(): void {
  }

  get _formControl() {
    return this.formControl as UntypedFormControl;
  }
}

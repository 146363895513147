export type ValueOf<T> = T[keyof T];

export const SideMenuItems = Object.freeze({
  WELCOME: "welcome",
  USERS: "users",
  MAP: "map",
  INSTALLATIONS: "installations",
  STATIONS: "stations",
  PODS: "pods",
  SESSIONS: "sessions",
  CHARGE_DETAIL_RECORD: "charge-detail-record",
  PAYMENTS: "payments",
  COST_REVENUE_SPLIT: "cost-revenue-split",
  INVOICE: "invoice",
  TARIFFS: "tariffs",
  GROUPS: "groups",
  GATEWAYS: "gateways",
  GESTORE: "gestore"
});

export type SideMenuItemsValues = ValueOf<typeof SideMenuItems>;
export type SideMenuItemsKeys = keyof typeof SideMenuItems;
 
export const SideMenuContentItems = Object.freeze({
  INFO_CONTENT: "info-content",
  INFO_CHAPTER: "info-chapter",
  INFO_SECTION: "info-section",
  INFO_MULTIMEDIA: "info-multimedia"
});

export type SideMenuContentItemsValues = ValueOf<typeof SideMenuContentItems>;
export type SideMenuContentItemsKeys = keyof typeof SideMenuContentItems;
 
export const SideMenuAboutItems = Object.freeze({
  ABOUT_LEGENDA: "about-legenda",
  ABOUT_VERSIONS: "about-versions"
});

export type SideMenuAboutItemsValues = ValueOf<typeof SideMenuAboutItems>;
export type SideMenuAboutItemsKeys = keyof typeof SideMenuAboutItems;

export declare const SideMenuReportsItems: Readonly<{
  REPORTS_QUERY: "reports-query";
  REPORTS_EXPORT: "reports-export";
}>;

export type SideMenuReportsItemsValues = ValueOf<typeof SideMenuReportsItems>;
export type SideMenuReportsItemsKeys = keyof typeof SideMenuReportsItems;

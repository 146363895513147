export interface EntitySelectorOptions {
  name: string
  queryId: string,
  limit?: number,
  templateName?: string,
  template?: EntitySelectorOptionsTemplate[][],
  showSearchBtn?: boolean
}

export interface EntitySelectorOptionsTemplate {
  label?: string,
  icon?: string,
  type?: "tasktype" | "state",
  prop: string,
  showed?: boolean
}

export const DEFAULT_TEMPLATES: { name: string, template: EntitySelectorOptionsTemplate[][] }[] = [{
  name: "base",
  template: [
    [
      {
        label: '##INTERFACES._GENERIC.ID##',
        prop: "id",
        showed: true
      },
      {
        label: '##COMMON_WORDS.NAME##',
        prop: "name",
        showed: true
      }
    ]
  ]
}]

<ng-container *ngIf="!!tariff;">
  <div class="page">
    <div nz-col>
      <app-block-header [isRefreshActive]="isRefreshActive" title="{{'RATE.DETAIL.SIMULATOR'|translate}}"
        icon="sliders">
      </app-block-header>
    </div>
    <div nz-col>
      <nz-alert nzType="info" [nzMessage]="tariffDescription$|async" class="mb-10"></nz-alert>
    </div>

    <div nz-row nzJustify="space-between">
      <div nz-col nzXs="24" nzMd="11" nzXl="6" class="page-section">
        <nz-descriptions nzTitle="{{'RATE.DETAIL.SIMULATOR_DATA'|translate}}"> </nz-descriptions>
        <form [formGroup]="form">
          <formly-form class="form-size" [form]="form" [fields]="fields" [model]="model"></formly-form>
        </form>
      </div>

      <div nz-col nzXs="24" nzMd="11" nzXl="5" class="page-section">
        <nz-descriptions nzTitle="{{'RATE.DETAIL.USER_COST'|translate}}" [nzColumn]="1"
          *ngIf="session$ | async as session">
          <nz-descriptions-item nzTitle="{{'RATE.DETAIL.BOOKING_COST'|translate}}">{{session.reservationCost ?
            (session.reservationCost |
            number:
            '1.2-2') : '0,00'}}€</nz-descriptions-item>
          <nz-descriptions-item nzTitle="{{'RATE.DETAIL.ENERGY_COST'|translate}}">{{session.energyCost ?
            (session.energyCost | number: '1.2-2') :
            '0,00'}}€</nz-descriptions-item>
          <nz-descriptions-item nzTitle="{{'RATE.DETAIL.PARKING_COST'|translate}}">{{session.occupationCost ?
            (session.occupationCost | number:
            '1.2-2') : '0.00'}}€</nz-descriptions-item>
          <nz-descriptions-item nzTitle="{{'RATE.DETAIL.TOTAL'|translate}}"><b>{{session.totalCost ? (session.totalCost
              | number:
              '1.2-2') : '0.00'}}€</b></nz-descriptions-item>
        </nz-descriptions>
      </div>

      <div nz-col nzXs="24" nzXl="9" class="page-section">
        <nz-descriptions nzTitle="{{'RATE.DETAIL.INCOME_DISTRIBUTION'|translate}}" [nzColumn]="2"
          *ngIf="revenues$ | async as rev">
          <nz-descriptions-item nzTitle="{{'CRS.ACTORS.CPO'|translate}}">{{rev.cpo | number:
            '1.3-3'}}€</nz-descriptions-item>
          <nz-descriptions-item nzTitle="{{'CRS.ACTORS.EMSP'|translate}}">{{rev.emsp | number:
            '1.3-3'}}€</nz-descriptions-item>
          <nz-descriptions-item nzTitle="{{'CRS.ACTORS.BRAND_OWNER'|translate}}">{{rev.brandOwner | number:
            '1.3-3'}}€</nz-descriptions-item>
          <nz-descriptions-item nzTitle="{{'CRS.ACTORS.POD_OWNER'|translate}}">{{rev.podOwner | number: '1.3-3'
            }}€</nz-descriptions-item>
          <nz-descriptions-item nzTitle="{{'CRS.ACTORS.DEVICE_OWNER'|translate}}">{{rev.deviceOwner | number:
            '1.3-3'}}€</nz-descriptions-item>
          <nz-descriptions-item nzTitle="{{'CRS.ACTORS.INSTALLATION_OWNER'|translate}}">{{rev.installationOwner | number:
            '1.3-3'}}€</nz-descriptions-item>
        </nz-descriptions>
      </div>
    </div>
  </div>
</ng-container>
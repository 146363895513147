<app-block-header title="{{'STATIONS.DETAIL.RELATED_GROUP'|translate}}" icon="group" [isRefreshActive]="true"
  (refreshRequest)="refreshGroups()">
</app-block-header>

<ng-container *ngIf="groups && groups.length > 0; else noDati">
  <nz-table class="group__tbale" [nzData]="groups" #basicTable [nzFrontPagination]="false" nzSize="small">
    <thead>
      <tr>
        <th nzWidth="40px"></th>
        <th>{{'USER.DETAIL.CATEGORY'|translate}}</th>
        <th>{{'USER.DETAIL.NAME_NO_PERSON'|translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of basicTable.data | keyvalue" class="yf-pointer">
        <td [nzAlign]="'center'">
          <span nz-icon [nzType]="item.value['valid']? 'check-circle': 'close-circle'" nzTheme="twotone"
            style="font-size: 14pt;" [nzTwotoneColor]="item.value['valid']? 'blue': '#ccc'"
            [nzTooltipTitle]="getTooltipTitleValid(item)" nzTooltipPlacement="top" 
            nz-tooltip></span><!-- funzioni gimmickose che Pietro crede si possano rifattorizzare meglio (si chiamano tutte getTooltipTitleXyz) -->
        </td>
        <td>
          <!-- <span>{{item.value['group_type'].name}}</span> -->
          <span>{{'GROUP_NAMES.'+item.value['group_type'].name.toUpperCase()|translate}}</span>
        </td>
        <td>
          <app-entity-tag class="yf-pointer" entityType="group" [label]="item.value['name']"
            [entityId]="item.value['id']">
          </app-entity-tag>
        </td>
      </tr>
    </tbody>
  </nz-table>
</ng-container>
<ng-template #noDati>
  <app-empty-data></app-empty-data>
</ng-template>
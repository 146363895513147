import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IConnector, ISessionCommand, IStation, SessionCmd, State } from 'common_library';
import { AppService } from 'src/app/services/app.service';
import { ConnectorService } from 'src/app/services/entities/connector.service';
import { StationService } from 'src/app/services/entities/station.service';

@Component({
  selector: 'app-block-station-commands',
  templateUrl: './block-station-commands.component.html',
  styleUrls: ['./block-station-commands.component.scss']
})
export class BlockStationCommandsComponent implements OnInit, OnChanges {
  @Input() type: 'user' | 'session' = 'session';
  @Input() stationId: string;
  @Input() connectorId: number;
  @Output() emitCommandRequest = new EventEmitter<ISessionCommand>();
  @Input() session: {
    endedAt?: Date,
    powerLimit?: number,
    id?: string,
  }
  @Output() sessionStopped = new EventEmitter()

  title: string;
  public station: IStation;
  public connector: IConnector;
  public sessionPowerLimit: number;
  public sessionPowerMaxValue: number;

  constructor(private app: AppService,
    private stationService: StationService,
    private connectorService: ConnectorService,
    private translate: TranslateService
  ) { }


  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.stationId?.currentValue) {
      await this.getStationPowerInfo(changes.stationId?.currentValue);
    }
    if (changes.connectorId?.currentValue) {
      this.connector = await this.connectorService.getOneAdmin(changes.connectorId?.currentValue);
    }
  }

  private async getStationPowerInfo(stationId: string) {
    this.station = await this.stationService.getOneStationAdmin(stationId);
    const stationPower = this.station?.power ? (this.station?.power / 1000) : 400;
    this.sessionPowerMaxValue = stationPower;
    this.sessionPowerLimit = this.session?.powerLimit ? this.session.powerLimit / 1000 : stationPower;
  }

  ngOnInit(): void {
    this.title = !!this.session?.endedAt ? this.translate.instant("SESSION.DETAIL.COMMANDS_NOT_AVAILABLE") : this.translate.instant("SESSION.DETAIL.COMMANDS");
  }

  reserveConnector() {
    if (this.connector.state === State.AVAILABLE && !!this.station.online) {
      this.emitCommandRequest.emit({
        connectorId: this.connector.id,
        cmd: SessionCmd.Reserve
      });
    } else {
      this.app.createNotification('warning', this.translate.instant("PHRASE.ATTENTION"), this.translate.instant("PHRASE.NO_RESERVE_CONNECTOR"));
    }
  }

  cancelReservation() {
    if ((this.connector.state === State.RESERVED || this.connector.state === State.AVAILABLE) && !!this.station.online) {
      this.emitCommandRequest.emit({
        connectorId: this.connector.id,
        cmd: SessionCmd.CancelReservation,
        sessionId: this.connector.currentSessionId
      });
    } else {
      this.app.createNotification('warning', this.translate.instant("PHRASE.ATTENTION"), this.translate.instant("PHRASE.NO_CANCEL_CONNECTOR"));
    }
  }

  startCharging() {
    if ((this.connector.state === State.AVAILABLE || this.connector.state === State.PREPARING || this.connector.state === State.RESERVED) && !!this.station.online) {
      this.emitCommandRequest.emit({
        connectorId: this.connector.id,
        cmd: SessionCmd.StartCharge
      });
    } else {
      this.app.createNotification('warning', this.translate.instant("PHRASE.ATTENTION"), this.translate.instant("PHRASE.CHARGING_NO_STARTED"));
    }
  }

  stopCharging() {
    if (this.connector.state === State.CHARGING && !!this.station.online) {
      this.emitCommandRequest.emit({
        connectorId: this.connector.id,
        cmd: SessionCmd.StopCharge,
        sessionId: this.connector.currentSessionId
      });
    } else {
      this.app.createNotification('warning', this.translate.instant("PHRASE.ATTENTION"), this.translate.instant("PHRASE.CHARGING_NO_FINISHED"));
    }
  }

  limitPower() {
    if (this.connector.state === State.CHARGING && !!this.station.online) {
      this.emitCommandRequest.emit({
        connectorId: this.connector.id,
        cmd: SessionCmd.SetPowerLimit,
        powerLimit: this.sessionPowerLimit * 1000,
        sessionId: this.connector.currentSessionId
      });
    } else {
      this.app.createNotification('warning', this.translate.instant("PHRASE.ATTENTION"), this.translate.instant("PHRASE.CHARGING_NO_CHANGE_POWER"));
    }
  }

  async resetPower() {
    await this.getStationPowerInfo(this.stationId);
  }

  clearLimitPower() {
    if (this.connector.state === State.CHARGING && !!this.station.online) {
      this.emitCommandRequest.emit({
        connectorId: this.connector.id,
        cmd: SessionCmd.ClearPowerLimit,
        sessionId: this.connector.currentSessionId
      });
    } else {
      this.app.createNotification('warning', this.translate.instant("PHRASE.ATTENTION"), this.translate.instant("PHRASE.CHARGING_NO_CANCEL_SET_POWER"));
    }
  }

  stopSession() {
    this.sessionStopped.emit()
  }

  public sliderformatter(value: number): string {
    return `${value} kW`;
  }
}

<app-block-header title="{{'USER.DETAIL.RFID_TAG_DETAIL'|translate}}" [isRefreshActive]="true" (refreshRequest)="refresh()" icon="audit" ></app-block-header>

<ng-container *ngIf="rfid else notSelected">
  <app-block-values [data]="rfid" [mainFields]="['name', 'valid', 'carId', 'createdAt', 'stationId', 'updatedAt', 'deletedAt']"></app-block-values>
  <nz-button-group>
    <button
    nz-button 
    nz-popconfirm
    nzPopconfirmTitle="{{'USER.DETAIL.CONFIRM'|translate}}"
    (nzOnConfirm)="toggleValidity()">
    {{ rfid.valid ? ('USER.DETAIL.DEACTIVE' | translate) : ('USER.DETAIL.ACTIVE' | translate) }}
    <span *ngIf="!!rfid.valid" nz-icon nzType="close-square"></span>
      <span *ngIf="!!!rfid.valid" nz-icon nzType="check-square" nzTheme="twotone" nzTwotoneColor="blue"></span>
    </button>
  </nz-button-group>
  
</ng-container>
<ng-template #notSelected>
  <app-empty-data title="{{'USER.DETAIL.SELECT_TAG'|translate}}"></app-empty-data>
</ng-template>

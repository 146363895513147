export type ValueOf<T> = T[keyof T];

export const SessionCmd = Object.freeze({
  Reserve: "RESERVE",
  CancelReservation: "CANCEL_RESERVATION",
  StartCharge: "START_CHARGE",
  StopCharge: "STOP_CHARGE",
  EndSession: "END_SESSION",
  SetPowerLimit: "SET_POWER_LIMIT",
  ClearPowerLimit: "CLEAR_POWER_LIMIT"
});

export type SessionCmdValues = ValueOf<typeof SessionCmd>;
export type SessionCmdKeys = keyof typeof SessionCmd;
<app-block-header title="{{'SESSION.DETAIL.PAYMENTS'|translate}}" icon="credit-card" [isRefreshActive]="isRefreshActive"
  (refreshRequest)="refreshItem()">
</app-block-header>
<app-skeleton [loading]="loading">
  <div *ngIf="bankTransactions?.length>0; else noDati">
    <div class="mega-block read-only-details" id="table-status">
      <nz-table class="table-mvalues" nzTemplateMode nzSize="small" [nzData]="bankTransactions"
        [nzScroll]="{y: '260px', x: '250px'}" [nzShowPagination]="false">
        <thead>
          <tr>
            <th>{{'SESSION.DETAIL.CARRIED_OUT_ON'|translate}} </th>
            <th style="text-align: right;">{{'SESSION.DETAIL.AMOUNT'|translate}} </th>
            <th style="text-align: center;">{{'SESSION.DETAIL.TYPE'|translate}} </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of bankTransactions" class="yf-pointer" routerLink="/authenticated/payments/{{item.id}}">
            <td>{{ item.createdAt | localizedDate:"medium" }}</td>
            <td style="text-align: right;">{{ item.value | payment }}</td>
            <td style="text-align: center;">{{ commonService.findEntityKey(_BankTransactionType,item.type) }}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
  <ng-template #noDati>
    <app-empty-data>
    </app-empty-data>
  </ng-template>
</app-skeleton>
<nz-layout class="menu">
  <nz-sider class="{{AS.isMobile ? 'menu__sidebar menu__sidebar_mobile' : 'menu__sidebar' }}"
    [ngClass]="{'totally-hidden': AS.isMobile && isCollapsed}" nzWidth="200px" nzCollapsible [nzCollapsed]="isCollapsed"
    [nzTrigger]="null">
    <div class="sidebar-logo">
      <a routerLink="/authenticated/welcome">
        <img src="/assets/logos/logo_bianco_yourfill.svg" />
        <h1>YourFill <span>Backoffice</span></h1>
      </a>
    </div>
    <app-skeleton [loading]="loading$|async">
      <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
        <!--DASHBOARD -->

        <li *ngIf="AS.isMobile" class="menu-trigger">
          <i class="trigger" nz-icon nzType="menu-fold"
            (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null"></i>
        </li>

        <span *ngFor="let sideMenuItem of sideMenuItems$ | async">
          <li [id]="sideMenuItem.id" routerLinkActive="ant-menu-item-selected" nz-menu-item>
            <a [routerLink]="sideMenuItem.routerLink" (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
              <i nz-icon [nzType]="sideMenuItem.icon" nzTheme="outline"></i>
              <span [style.display]="isCollapsed ? 'none' : 'inline'">{{ sideMenuItem.name | translate }}</span>
            </a>
          </li>
        </span>



        <!--CONTENTS -->
        <li *ngIf="isAdmin$ | async" nz-submenu [nzTitle]="isCollapsed ? '' :('MENU.CONTENTS' | translate)"
          nzIcon="edit">
          <ul>
            <li *ngFor="let contentsItem of contentSubMenuItems" nz-menu-item [nzMatchRouterExact]="true"
              [routerLink]="contentsItem.routerLink">
              <i nz-icon [nzType]="contentsItem.icon" nzTheme="outline"></i>
              <span (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">{{contentsItem.name |
                translate}}</span>
            </li>
          </ul>
        </li>

        <!-- REPORTS -->
        <li *ngIf="isAdmin$ | async" nz-submenu [nzTitle]="isCollapsed ? '' : ('REPORT.REPORTS' | translate) " nzIcon="file-zip">
          <ul>
            <!-- Loop through report items -->
            @for(reportItem of reportsSubMenuItems; track reportItem.id){
            <!-- Check if the reportItem is not 'reports-export' -->
            <li *ngIf="!reportItem?.subMenu" nz-menu-item [nzMatchRouterExact]="true"
              [routerLink]="reportItem.routerLink">
              <i nz-icon [nzType]="reportItem.icon" nzTheme="outline"></i>
              <span (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">{{ reportItem.name | translate
                }}</span>
            </li>

            <!-- Show submenu only when the id is 'reports-export' -->
            @if(reportItem?.subMenu?.length > 0 ){
            <li nz-submenu nzTitle="{{('REPORT.EXPORT' | translate)}}" [nzIcon]="reportItem.icon">
              <ul>
                @for(sub of reportItem.subMenu; track sub.id){
                <li nz-icon nz-menu-item [routerLink]="sub.routerLink" [nzMatchRouterExact]="true" [nzType]="sub.icon">
                  {{sub.name}}</li>
                }
              </ul>
            </li>
            }
            }
          </ul>
        </li>

        <!-- EXPORT FOR GESTORI -->
        @if(!(isAdmin$ | async)){
        <li nz-submenu [nzTitle]="isCollapsed ? '' :('REPORT.EXPORT' | translate)" nzIcon="export">
          <ul>
            @for(reportItem of reportsSubMenuItems; track reportItem.id){
            @for(sub of reportItem.subMenu; track sub.id){
            @if(sub.id !== 'reports-export-config'){
            <li nz-menu-item [nzMatchRouterExact]="true" [routerLink]="sub.routerLink" nzIcon="sub.icon">
              <i nz-icon [nzType]="" nzTheme="outline"></i>
              <span (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">{{sub.name |
                translate}}</span>
            </li>
            } 
            }
            }
          </ul>
        </li>
      }



        <!--LOGS -->
        <!-- <li id="logs" *ngIf="isAdmin$ | async" routerLinkActive="ant-menu-item-selected" nz-menu-item [nzMatchRouter]="true">
          <a routerLink="/authenticated/logs" (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
            <i nz-icon nzType="file-text" nzTheme="outline"></i>
            <span [style.display]="isCollapsed ? 'none': 'inline'">Log</span>
          </a>
        </li> -->

        <!--ABOUT -->
        <li *ngIf="isAdmin$ | async" nz-submenu [nzTitle]="isCollapsed ? '' :( 'MENU.ABOUT' | translate )"
          nzIcon="info-circle">
          <ul>
            <li *ngFor="let aboutItem of aboutSubMenuItems" nz-menu-item [nzMatchRouterExact]="true"
              [routerLink]="aboutItem.routerLink">
              <i nz-icon [nzType]="aboutItem.icon" nzTheme="outline"></i>
              <span (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">{{aboutItem.name |
                translate}}</span>
            </li>
          </ul>
        </li>
      </ul>
    </app-skeleton>
  </nz-sider>

  <nz-layout>
    <nz-header>
      <div class="app-header">
        <div>
          <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
          </span>
        </div>


        <div class="version-container">
          <div class="pr-16">
            <nz-select [(ngModel)]="selectedGestore" (ngModelChange)="onGestoreChange($event)" nzBorderless
              *ngIf="gestori.length > 0 && !AS.isMobile && !(isAdmin$ | async)" class="custom-select-width">
              <nz-option *ngFor="let gestore of gestori" [nzValue]="gestore.id" [nzLabel]="gestore.businessName">
                {{ gestore.businessName }}
              </nz-option>
            </nz-select>

            <nz-select [(ngModel)]="selectedLanguage" (ngModelChange)="onLanguageChange($event)"
              [nzCustomTemplate]="langTemplate" nzBorderless
              [ngClass]="AS.isMobile ? 'custom-selectLang-mobile-width' : 'custom-select-width'">
              <nz-option *ngFor="let language of languages" [nzCustomContent]="true" [nzValue]="language.code"
                [nzLabel]="language.label | translate">
                <span class="pr-8 ">
                  <span class="fi" [class]="'fi-'+language.flag"></span>
                </span>
                <span *ngIf="!AS.isMobile">
                  {{ language.label | translate }}
                </span>
              </nz-option>
            </nz-select>

            <ng-template #langTemplate>
              <ng-container *ngIf="selectedLanguageItem$| async as selected">
                <div nz-row>
                  <span nz-col class="pr-8">
                    <span class="fi" [class]="'fi-'+selected.flag"></span>
                  </span>
                  <span nz-col *ngIf="!AS.isMobile">
                    {{ selected.label | translate }}
                  </span>
                </div>
              </ng-container>
            </ng-template>
          </div>

          <div class="version" class="pr-16" [ngClass]="{'totally-hidden': AS.isMobile && !isCollapsed}">
            <div>YourFill Backoffice</div>
            <div><b>{{'ABOUT.VERSIONS_LOG.VERSION'|translate}} {{ version }}</b></div>
          </div>

          <div class="yf-pointer" *ngIf="(auth.user$|async) as avatarUser">
            <nz-avatar [nzText]="avatarInitials$ | async" class="avatar" nz-popover nzPopoverTrigger="hover"
              [nzPopoverTitle]="titleTemplate" [nzPopoverContent]="contentTemplate"
              nzPopoverPlacement="bottomRight"></nz-avatar>
            <ng-template #titleTemplate>
              <div style="text-align: center;">
                {{avatarUser.email}}
              </div>
            </ng-template>
            <ng-template #contentTemplate>
              <div nz-row nzJustify="space-around">
                <div nz-col nzFlex="auto"></div>
                <div nz-col>
                  <nz-avatar nzText={{avatarInitials$|async}} class="avatar" nzSize="large"></nz-avatar>
                </div>
                <div nz-col nzFlex="auto"></div>
              </div>
              <div nz-row nzJustify="space-around">
                <div nz-col nzFlex="auto"></div>
                <div nz-col>
                  <h2>
                    {{'AVATAR.HI'|translate}} {{avatarUser.name}}!
                  </h2>
                </div>
                <div nz-col nzFlex="auto"></div>
              </div>

              <div nz-row nzJustify="space-around">
                <button nz-button nzType="primary" nzSize="default" nzShape="round" (click)="showModalImpersonificate()"
                  *ngIf="isAdmin$ | async">
                  <span nz-icon nzType="user"></span>
                  {{"LABEL.IMPERSONATE_USER"|translate}}
                </button>
              </div>



              <div nz-row nzJustify="center" nzGutter="10">
                <div nz-col nzSpan="10">
                  <nz-select [(ngModel)]="selectedGestore" (ngModelChange)="onGestoreChange($event)" nzBorderless
                    *ngIf="gestori.length > 0 && AS.isMobile" class="custom-select-mobile-width">
                    <nz-option *ngFor="let gestore of gestori" [nzValue]="gestore.id" [nzLabel]="gestore.businessName">
                      {{ gestore.businessName }}
                    </nz-option>
                  </nz-select>
                </div>
              </div>
              <div nz-row nzJustify="space-around" class="select-column">
                <button nz-button nzType="primary" nzSize="default" nzShape="round" routerLink="avatarDetail"
                  class="no-round-right mr-2">
                  <i nz-icon nzType="edit" nzTheme="outline"></i>
                  {{'AVATAR.MY_PROFILE'|translate}}
                </button>
                <button nz-button nzType="primary" nzSize="default" nzShape="round" class="no-round-left ml-2"
                  (click)=logout()>
                  <i nz-icon nzType="logout" nzTheme="outline"></i>
                  {{'AVATAR.LOGOUT'|translate}}
                </button>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </nz-header>
    <nz-content>
      <app-impersonificate-modal *ngIf="isImpersonificateModalVisible$ | async" (cancelEvent)="hideImpersonation()"
        (valueSelected)="onValueSelected($event)"></app-impersonificate-modal>
      <div class="inner-content" (click)="this.AS.isMobile && !isCollapsed ? isCollapsed = true : null">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { AuthenticatedRoutingModule } from './authenticated-routing.module';
import { AuthenticatedComponent } from './authenticated.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzCardModule } from 'ng-zorro-antd/card';
import { AuthInterceptor } from 'src/app/services/auth/auth.interceptor';
import { BACKEND_URL } from 'src/app/backend_url';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { AvatarDetailModule } from '../avatar-detail/avatar-detail.module';





let _backend_url = BACKEND_URL;
if (_backend_url.startsWith('$')) _backend_url = 'http://localhost:3000';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({ declarations: [AuthenticatedComponent],
    exports: [AuthenticatedComponent, TranslateModule], imports: [AuthenticatedRoutingModule,
        FormsModule,
        NzLayoutModule,
        NzFormModule,
        NzInputModule,
        ReactiveFormsModule,
        NzNotificationModule,
        NzButtonModule,
        NzIconModule,
        NzMenuModule,
        CommonModule,
        NzDescriptionsModule,
        NzCardModule,
        ComponentsModule,
        NzAvatarModule,
        NzPopoverModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NzSelectModule,
        NzModalModule,
        AvatarDetailModule], providers: [
        { provide: 'BACKEND_URL', useValue: _backend_url },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })

export class AuthenticatedModule { }

import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// import { IExcelReport, IExportsArchiveItem, IExportsArchiveMonth, IExportsArchiveYear, IRequestExcelReportDTO } from '@common/interfaces/excelReport';
// import { ExportsHelpers } from '@common/utils/exports.helpers';
// import { TimeSlotGeneratorSettings } from '@common/interfaces/scheduler';
// import { AuthService } from '@ep-om/project/auth/auth.service';
// import { DownloadService } from '@ep-om/project/service/download.service';
// import { ProjectQuery } from '@ep-om/project/state/project/project.query';
// import { Download } from '@ep-om/utils/download';
import { FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { IExcelReport, IExportsArchiveItem, IExportsArchiveMonth, IExportsArchiveYear, IRequestExcelReportDTO } from 'common_library';
import { format, isThisSecond } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, Observable, Subject, combineLatest, firstValueFrom } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ExportsService } from 'src/app/services/reports/exports.service';
import { Download } from 'src/app/utils/reports/download';
import { environment } from 'src/environments/environment';
import { ComponentsModule } from '../../components.module';
import { CommonModule } from '@angular/common';
import { AppService } from 'src/app/services/app.service';
// import { ExportsService } from '@ep-om/project/service/exports.service';
// import { AuthQuery } from '@ep-om/project/auth/auth.query';

const mockExcelReport1: IExcelReportWithForm = null

const mockExcelReport2: IExcelReportWithForm = null


interface IExcelReportWithForm extends IExcelReport {
  formGroup: UntypedFormGroup;
  formModel: any;
  download$?: BehaviorSubject<Download>;
}

enum TABS { NEW_EXPORT, ARCHIVE };

@Component({
  selector: 'app-new-export',
  templateUrl: './new-export.component.html',
  styleUrl: './new-export.component.scss'
})
export class NewExportComponent implements OnInit {

  activatedRoute = inject(ActivatedRoute);
  selectedYear: string;
  selectedMonth: string;
  currentYear: string;
  currentMonth: string;

  private readonly WIDTH_SMALL = 600;
  // private apiUrl = environment.apiUrl; OM
  private apiUrl;
  appService = inject(AppService);


  // viewReports$: Observable<{ category: string, reports: IExcelReportWithForm[] }[]> = this.viewTest.asObservable().pipe(
  //   map(test => test)
  // );

  reports = signal<{ category: string, reports: IExcelReportWithForm[] }[]>([])

  $reports = signal<IExcelReportWithForm[]>([]);


  loading$ = new BehaviorSubject<boolean>(false);
  download$: Observable<Download>;
  projectId: string;
  options: FormlyFormOptions = {};
  triggerArchive$ = new Subject();
  archiveItems$: { [year: string]: Observable<IExportsArchiveItem[]> } = {};
  yearDownload$: { [year: string]: Observable<Download> } = {};
  selectedMonthMap: { [year: string]: string } = {};
  archive$: Observable<IExportsArchiveYear[]>;

  archiveEmpty: boolean;

  constructor(
    private http: HttpClient,
    private notificationService: NzNotificationService,
    // private projectQuery: ProjectQuery,
    private exportsService: ExportsService,
    //private downloads: DownloadService,
    private authService: AuthService,
    private translateService: TranslateService,
    private _cdr: ChangeDetectorRef
  ) {
    this.apiUrl = this.appService.apiUrl;
    this.archive$ = this.triggerArchive$.pipe(
      switchMap(() => this.http.get<IExportsArchiveYear[]>(`${this.apiUrl}/excel-export/archive/years`)),
      map(items => items.sort((a, b) => +b.year - +a.year)),
      tap(years => {
        this.archiveEmpty = !years || years.length === 0;
      })
    );
  }


  needRefresh(yearObj: any): boolean {
    const rv = this.selectedMonthMap[yearObj.year] === this.currentMonth && yearObj.year === this.currentYear;
    //console.log(yearObj.year,this.selectedMonthMap[yearObj.year],this.currentMonth,this.currentYear);
    return rv;
  }

  selectedMonthChanged(year: string, month: string) {
    this.archiveItems$[year] = this.http.get<IExportsArchiveItem[]>(`${this.apiUrl}/excel-export/archive/${year}/${month}`);
  }

  getSelectLabelMonth(m: IExportsArchiveMonth): string {
    const key = `MONTHS.${+m.month - 1}`;
    const localizedMonthName = this.translateService.instant(key);
    return `${localizedMonthName} (${m.items})`;
  }

  tabSelectedChanged(evt: any) {
    const selectedTab = TABS[evt.index];

    console.log("tabSelectedChanged", selectedTab);
    if (selectedTab == TABS[TABS.ARCHIVE] && this.archiveEmpty == true) this.triggerArchive$.next('');
  }

  getFileTypeColor(fileType: string): string {
    switch (fileType) {
      case 'pdf': return 'magenta';
      case 'xlsx': return 'green';
      default: return null;
    }
  }

  getTabPosition(): 'left' | 'top' {
    return window.innerWidth > this.WIDTH_SMALL ? 'left' : 'top';
  }

  // FV 20/01/24 13.00: perchè ho dovuto fare sta mezza porcata ?
  // perchè sembra (spero risolvano) che ci sia un problema con nz-tabset e nz-tab e quindi all'interno del tab height:100% (che poi sta sotto h-full) non funziona perchè non riesce a rilevare l'altezza del contenitore
  // quindi 100vh con calc per togliere l'altezza dell'header e del tab-menu quando passa orizzontale in caso di device mobile
  // Attenzione: se non hai una soluzione testa e migliore o provata da un'altra parte di cui sei sicuro al 100% (codice specifico per ngZorro) è meglio evitare di provare a sistamere perchè io c'ho già perso troppo tempo
  getHeightHeaderMargin() {
    return window.innerWidth > this.WIDTH_SMALL ? '90' : '145';
  }

  isNormalWidth() {
    return window.innerWidth > this.WIDTH_SMALL
  }

  async ngOnInit(): Promise<void> {
    // Valori di default x archivio (visualizzo anno e mese corrente)
    await this.getReport();


    const now = new Date();

    this.currentYear = now.getFullYear().toString();
    this.currentMonth = now.toISOString().substring(5, 7);

    this.selectedYear = now.getFullYear().toString();
    this.selectedMonth = now.toISOString().substring(5, 7);

    this.selectedMonthMap[this.currentYear] = this.currentMonth;
    this.selectedMonthChanged(this.currentYear, this.currentMonth)

    console.log(this.currentYear, this.selectedMonth);

  }

  async getReport() {
    const user = this.authService.user$.value;
    const isAdmin = user?.isAdmin;
    const gestoreSelected = user.settings?.gestoreSelected;

    const endpoint: string = isAdmin ? `${this.apiUrl}/excelReport/get-all` : `${this.apiUrl}/excelReport/get-by-gestoreId/${gestoreSelected}`;

    // const params = await firstValueFrom(this.activatedRoute.paramMap);
    // this.reportId = params.get('reportId'); // In caso vogliamo fare lista detail, altrimenti non serve
    this.http.get<IExcelReport[]>(endpoint).subscribe({
      next: (excelReports: IExcelReport[]) => {
        console.log(excelReports);
        const mapCategoryReport = excelReports.reduce((acc: { [key: string]: IExcelReportWithForm[] }, curr) => {
          if (!acc[curr.category]) {
            acc[curr.category] = [];
          }
          acc[curr.category].push({ ...curr, formGroup: new FormGroup({}), formModel: {} });
          return acc
        }, {});
        const reports: { category: string, reports: IExcelReportWithForm[] }[] = Object.entries(mapCategoryReport).map(([category, reports]) => ({
          category,
          reports
        }));

        if (!user.isAdmin) {
          const formWithoutCtxConstant = reports.map(r => ({
            category: r.category,
            reports: r.reports.map(r => {
              const ff = r.formFields.length > 0 ? r.formFields.filter(f => !['ctx', 'constant'].includes(f.props?.type)) : null; // rimuovo quelle di contesto e costanti
              return { ...r, formFields: ff };
            })
          }));
          this.reports.set(formWithoutCtxConstant);
        }
        else {
          this.reports.set(reports);
        }
      },
      error: (err) => {
        console.error(err);
        // Notifica di errore!
        this.notificationService.error('Errore', 'Errore durante il recupero dei report');
      }
    })
  }

  private async generate(excelReport: IExcelReportWithForm): Promise<IExportsArchiveItem> {
    try {

      const context = {
        userId: this.authService.user$.value.id,
        gestoreId: this.authService.user$.value?.settings?.gestoreSelected,
        ts: new Date()
      }

      if (!excelReport.formGroup.valid) {
        this.notificationService.error('error', 'Compilare tutti i campi richiesti!');
        return;
      }

      const request: IRequestExcelReportDTO = {
        excelReportId: excelReport.id,
        model: { ...context, ...excelReport.formModel }
      }

      return await this.exportsService.generateExport(request);
    } catch (e) {
      this.notificationService.error('Errore', 'Errore durante la generazione del report');
    }
  }

  // async generateAndOpen(excelReport: IExcelReportWithForm) {
  //   const item = await this.generate(excelReport);
  //   console.log("generateAndOpen", item);
  //   if (!item || !!item.error)
  //     this.notificationService.error('Attenzione', `Estrazione dati fallita (${item.error?.message || '?'})`);
  //   else
  //     this.openFromArchive(item);
  // }

  async generateAndDownload(excelReport: IExcelReportWithForm) {
    const item = await this.generate(excelReport);
    excelReport.download$ = new BehaviorSubject<Download>({ progress: 0, state: 'PENDING', content: null });
    if (!item || !!item.error) {
      console.error('Attenzione', `Estrazione dati fallita (${item.error?.message || '?'})`);
      this.notificationService.error('Attenzione', `Estrazione dati fallita (${item.error?.message || '?'})`);
    } else {
      this.exportsService.downloadFromArchive(item).subscribe({
        next: (download: Download) => {
          console.log(download);
          excelReport.download$.next(download);
        },
        error: (err) => {
          console.error(err);
          excelReport.download$.complete();
          this.notificationService.error('Errore', 'Errore durante il download del file');
        },
        complete: () => {
          console.log("completone 😺");
          excelReport.download$.complete();
        }
      });
      this._cdr.detectChanges();
    }
  }
  async generateAndOpen(excelReport: IExcelReportWithForm) {
    try {
      excelReport.download$ = new BehaviorSubject<Download>({ progress: 0, state: 'PENDING', content: null });
      const item = await this.generate(excelReport);
      if (!item || !!item.error) {
        this.notificationService.error('Attenzione', `Estrazione dati fallita (${item.error?.message || '?'})`);
        excelReport.download$.complete();
      } else {
        this.exportsService.downloadFromArchive(item).subscribe({
          next: (download: Download) => {
            console.log(download);
            excelReport.download$.next(download);
            if (download.progress === 100) {
              this.openFromArchive(item);
            }
          },
          error: (err) => {
            console.error(err);
            this.notificationService.error('Errore', 'Errore durante il download del file');
            excelReport.download$.complete();
          },
          complete: () => {
            excelReport.download$.complete();
          }
        });
      }
    } catch (e) {
      this.notificationService.error('Errore', 'Errore durante la generazione del report');
      excelReport.download$.complete();
    }
    this._cdr.detectChanges();
  }

  downloadFromArchive(item: IExportsArchiveItem) {
    console.log("downloadFromArchive", item);
    const year = new Date(item.ctx.ts).getFullYear().toString();
    this.yearDownload$[year] = this.exportsService.downloadFromArchive(item);
  }

  async getOpenableUrl(item: IExportsArchiveItem): Promise<string> {
    return await this.exportsService.openableUrl(item);
  }

  openFromArchive(item: IExportsArchiveItem) {
    const rv = this.exportsService.openFileInNewTab(item);
    console.log("Rv openfromarchive 😺", rv);
    if (!rv) this.downloadFromArchive(item);
  }
}


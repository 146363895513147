import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IStopTransaction } from 'common_library';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-block-stop-transactions',
  templateUrl: './block-stop-transactions.component.html',
  styleUrls: ['./block-stop-transactions.component.scss']
})
export class BlockStopTransactionsComponent implements OnChanges {
  @Input() isRefreshActive: boolean = false;
  @Input() stopTransactions: IStopTransaction[] = [];;
  @Input() sessionStart;
  @Output() updateClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  stopTransactions$ = new BehaviorSubject<any[]>([]);
  private langChangeSubscription: Subscription;

  constructor(private translate: TranslateService) {
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.populateStopTransaction();
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.stopTransactions?.currentValue || changes.stopTransactions?.currentValue) {
      this.populateStopTransaction();
    }
  }

  populateStopTransaction() {
    let stopTr = [];
    for (let st of this.stopTransactions) {
      stopTr.push({ label: this.translate.instant('SESSION.DETAIL.CREATED'), icon: "calendar", value: st.createdAt, type: "date" });
      stopTr.push({ label: this.translate.instant('SESSION.DETAIL.METER_STOP'), icon: "thunderbolt", value: st.meterStop, type: "text" });
      stopTr.push({ label: this.translate.instant('SESSION.DETAIL.ARRESTED'), icon: "hystory", value: st.timestamp, type: "date" });
      stopTr.push({ label: this.translate.instant('SESSION.DETAIL.REASON'), icon: "info-circle", value: st.reason, type: "text" });
    }
    this.stopTransactions$.next(stopTr);
  }

  sendEvent() {
    this.updateClicked.emit();
  }

  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
}

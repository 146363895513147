import { NgModule } from "@angular/core";
import { AvatarDetailComponent } from "./avatar-detail.component";
import { NzListModule } from 'ng-zorro-antd/list';
import { CommonModule } from '@angular/common';

import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';


import { ComponentsModule, ngZorro } from 'src/app/components/components.module';

import { BrowserModule } from '@angular/platform-browser';
import { NzInputModule } from "ng-zorro-antd/input";

export function minLengthValidationMessages(error: any, field: FormlyFieldConfig) {
  return `Should have atleast ${field.props.minLength} characters`;
}

export function fieldMatchValidator(control: AbstractControl) {
  const { password, passwordConfirm } = control.value;

  // avoid displaying the message error when values are empty
  if (!passwordConfirm || !password) {
    return null;
  }

  if (passwordConfirm === password) {
    return null;
  }

  return { fieldMatch: { message: 'Password Not Matching' } };
}

@NgModule({
  imports: [
    CommonModule,
    NzListModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      validators: [{ name: 'fieldMatch', validation: fieldMatchValidator }],
      validationMessages: [
        { name: 'required', message: '' },
        { name: 'minLength', message: minLengthValidationMessages },
      ],
    }),
    ...ngZorro,
    ComponentsModule,
    NzInputModule,
    FormlyNgZorroAntdModule,
  ],
  declarations: [AvatarDetailComponent],
  exports: [AvatarDetailComponent],
})
export class AvatarDetailModule {}

<!-- <div class="mb-6">
  <nz-form-label [nzSm]="6" [nzXs]="24" [nzFor]="formControl.value" [nzRequired]="field.props?.required">{{ to.label }}</nz-form-label>
  <div class="container">
    <i class="icon-preview" nz-icon [nzType]="formControl.value" nzTheme="outline"></i>
    <nz-select class="w-full" [nzDisabled]="props.disabled" [formControl]="formControl" nzShowSearch nzAllowClear>
      @for(icon of props.options | async; track icon.value){

      }
      <nz-option *ngFor="let item of $options()" [nzValue]="item.value" [nzLabel]="item.label" ></nz-option>
    </nz-select>
  </div>.container {
  display: inline-flex;
}

.icon-preview {
  min-width: 32px;
  min-height: 32px;
  margin-right: 2px;
  @apply flex items-center justify-center bg-gray-100;
}
</div> -->



<div class="mb-6">
  <nz-form-label [nzSm]="6" [nzXs]="24" [nzFor]="formControl.value" [nzRequired]="this.field.props?.required">{{ to.label }}</nz-form-label>
  <div class="container">
    <i class="icon-preview  yf-flex yf-flex-center bg-gray-100" nz-icon [nzType]="formControl.value" nzTheme="outline"></i>
    <nz-select class="w-full " [nzDisabled]="this.props.disabled" [formControl]="formControl" nzShowSearch nzAllowClear>
      <nz-option *ngFor="let item of $options()" [nzValue]="item.value" [nzLabel]="item.label" ></nz-option>
    </nz-select>
  </div>
</div>

<app-block-header title="{{'STATIONS.DETAIL.CONNECTOR'|translate}}" icon="api" [isEditActive]="isEditActive" (editRequest)="edit()"
  [isRefreshActive]="isRefreshActive" (refreshRequest)="refreshItem()">
  <div *ngIf="showSelect" class="yf-default-form">
    <form nz-form [formGroup]="connectorForm">
      <nz-form-item>
        <nz-form-control nzFlex="auto">
          <nz-select formControlName="selectedConnectorIdForm" (ngModelChange)="selectConnector($event)">
            <nz-option *ngFor="let conn of connectorOptions$ | async" [nzLabel]="conn.label"
              [nzValue]="conn.value"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</app-block-header>
<app-skeleton [loading]="loading">
  <app-block-values *ngIf="connector; else noDati" [data]="connector" name="{{'BLOCK_VALUE.DETAIL'|translate}}"
    [mainFields]="['createdAt', 'stationId', 'innerState', 'cpConnectorId', 'powerLimit', 'power']"></app-block-values>
</app-skeleton>
<ng-template #noDati>
  <app-empty-data>
  </app-empty-data>
</ng-template>
<app-drawer [visible]="showEdit" (newCloseEvent)="showEdit = false" [width]="AS.isMobile ? 'full-screen': 'default'">
  <app-block-connectors-form [selectedConnector]="connector"
    (connectorUpdated)="updateConnector($event)"></app-block-connectors-form>
</app-drawer>
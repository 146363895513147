export type ValueOf<T> = T[keyof T];

export const ActionType = Object.freeze({
  Unknown: "Sconosciuta",
  ForgotPassword: "ForgotPassword",
  ChangedPassword: "ChangedPassword",
  RegisterNewUser: "RegisterNewUser",
  CdCAuthFailed: "CdCAuthFailed",
  CdCAuthSuccess: "CdCAuthSuccess"
});

export type ActionTypeValues = ValueOf<typeof ActionType>;
export type ActionTypeKeys = keyof typeof ActionType;
<app-block-header title="{{'INVOICE.DETAIL.INVOICE_DETAIL'|translate}}" icon="file-text" [isRefreshActive]="false"></app-block-header>
<div *ngIf="invoice; else noDati" class="block read-only-details">
  <app-skeleton [loading]="loading">
    <app-block-values 
    [data]="invoice"
    (goToItemDetail)="goToInvoiceDetail()"
    [mainFields]='["progressiveId", "fileName", "sendDate", "outcome", "description", "invoiceState", "cdr", "cdrId", "sessionId", "userId", "userpIvaUniCode"]'></app-block-values>
  </app-skeleton>
</div>
<ng-template #noDati>
  <app-empty-data>
  </app-empty-data>
</ng-template>

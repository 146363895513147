export type ValueOf<T> = T[keyof T];

export const ChapterVisualizationPhase = Object.freeze({
  APP_START: 1,
  LOGIN: 2,
  BEFORE_STARTING_SESSION: 3,
  ONLY_INFO_SECTION: 10
});

export type ChapterVisualizationPhaseValues = ValueOf<typeof ChapterVisualizationPhase>;
export type ChapterVisualizationPhaseKeys = keyof typeof ChapterVisualizationPhase;
import { Injectable } from "@angular/core";
import { IInfoSection, RecordCounted } from "common_library";
import { HttpIoService } from "../communication/http-io.service";
import { CommonService } from "../common.service";

const CONTROLLER_ROOT = 'info-section';
@Injectable({ providedIn: 'root' })
export class InfoSectionService {


  constructor(private HIO: HttpIoService, private commonService: CommonService) { }

  public async getAllInfoSection(search?: string): Promise<IInfoSection[]> {
    try {
      if (search) {
        return await this.HIO.get(`${CONTROLLER_ROOT}/all/${search}`);
      }
      return await this.HIO.get(`${CONTROLLER_ROOT}/all`);
    } catch (error) {
      console.error('🐸 Error: ', error);
    }
  }

  public async getInfoSectionOrphans(infoChapterId: string, search?: string): Promise<IInfoSection[]> {
    try {
      if (!search) {
        return await this.HIO.get(`${CONTROLLER_ROOT}/orphans/${infoChapterId}`);
      } else {
        return await this.HIO.get(`${CONTROLLER_ROOT}/orphans/${infoChapterId}/${search}`);
      }
    } catch (error) {
      console.error('🐸 Error: ', error);
    }
  }

  public async getOneInfoSection(id: string): Promise<IInfoSection> {
    try {
      return await this.HIO.get<IInfoSection>(`${CONTROLLER_ROOT}/one/${id}`);
    } catch (error) {
      console.error('🐸 Error: ', error);
    }
  }

  public async updateInfoSection(id: string, dto: any): Promise<IInfoSection> {
    try {
      return await this.HIO.patchItem<IInfoSection, any>(`${CONTROLLER_ROOT}`, id, dto);
    } catch (error) {
      console.error('🐸 Error: ', error);
    }
  }

  public async createInfoSection(dto: any): Promise<IInfoSection> {
    try {
      return await this.HIO.post<IInfoSection, any>(`${CONTROLLER_ROOT}`, dto);
    } catch (error) {
      console.error('🐸 Error: ', error);
    }
  }

  public async getInfoSectionFilteredPaginatedAndCounted(pageIndex?: number, search?: string): Promise<RecordCounted<IInfoSection>> {
    return this.commonService.getAndCount({
      controllerRoot: CONTROLLER_ROOT,
      controllerDir: 'filter-paginate',
      page: pageIndex,
      search: search
    })
  }

  public async deteleteSection(sectionId: string): Promise<boolean> {
    try {
      await await this.HIO.deleteItem(CONTROLLER_ROOT, sectionId);
      return true;
    } catch (error) {
      console.log('🐸 Error: ', error);
      return false
    }
  }
}

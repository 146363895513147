export type ValueOf<T> = T[keyof T];

export const ReasonType = Object.freeze({
  byGroup: 0,
  byInstallation: 1,
  byPod: 2,
  default: 3,
  unknownUser: 4
});

export type ReasonTypeValues = ValueOf<typeof ReasonType>;
export type ReasonTypeKeys = keyof typeof ReasonType;
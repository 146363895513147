<div class="page yf-scroll-y">
  <div nz-col>
    <app-detail-header label="Nuova sezione" [isRefreshActive]="false">
    </app-detail-header>
  </div>
  <div nz-row>
    <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="12">
      <app-block-header [title]="'Sezione'" [icon]="'align-left'" [isRefreshActive]="false"></app-block-header>
      <form [formGroup]="newInfoSectionForm" (ngSubmit)="submit()">
        <formly-form [form]="newInfoSectionForm" [fields]="newInfoSectionFields" class="form-size"></formly-form>
      </form>
      <button class="btn" nz-button (click)="undo()" *ngIf="showUndoButton">{{'BUTTONS.CANCEL'|translate}}</button>
      <button class="btn" nz-button type="submit" nzType="primary" (click)="submit()"
        [disabled]="!newInfoSectionForm.dirty">{{'BUTTONS.SAVE'|translate}}</button>
    </div>
  </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { INumberIdEntity, IStringIdEntity } from 'common_library';

@Component({
  selector: 'app-base-details',
  templateUrl: './base-details.component.html',
  styleUrls: ['./base-details.component.scss']
})
export class BaseDetailsComponent implements OnInit {
  @Input() model: IStringIdEntity | INumberIdEntity;
  constructor() { }

  ngOnInit(): void {
  }

}

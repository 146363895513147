import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent extends FieldType<FieldTypeConfig> implements OnInit {
  @Output() sendDate: EventEmitter<string> = new EventEmitter();
  @Input() date: Date[];

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.props.date) {
      this.date = this.props.date;
    }
  }

  onChange(dates: Date[]) {
    this.field.props.date = dates;
  }

}

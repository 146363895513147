export type ValueOf<T> = T[keyof T];

export const CPOs = Object.freeze({
  UNKNOWN: "???",
  YOURFILL: "YFL",
  ENELX: "ENX",
  FREETOX: "F2X",
});

export type CPOsValues = ValueOf<typeof CPOs>;
export type CPOsKeys = keyof typeof CPOs;
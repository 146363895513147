export type ValueOf<T> = T[keyof T];

export const MODULE_ID = Object.freeze({
  Cdrs: 'cdrs',
  ChargingProfiles: 'chargingprofiles',
  Commands: 'commands',
  CredentialsRegistration: 'credentials',
  //HubClientInfo = 'hubclientinfo',
  Locations: 'locations',
  Sessions: 'sessions',
  Tariffs: 'tariffs',
  Tokens: 'tokens'
});

export type MODULE_IDValues = ValueOf<typeof MODULE_ID>;
export type MODULE_IDKeys = keyof typeof MODULE_ID;
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ICostRevenueSplit, RevenueEntity } from 'common_library';
import { CommonService } from 'src/app/services/common.service';
import { BankTransactionService } from 'src/app/services/entities/bank-transaction.service';
import { CostRevenueSplitService } from 'src/app/services/entities/cost-revenue-split.service';

@Component({
  selector: 'app-block-proventi-list',
  templateUrl: './block-proventi-list.component.html',
  styleUrls: ['./block-proventi-list.component.scss']
})
export class BlockProventiListComponent implements OnChanges {

  @Input() sessionId: string;
  @Input() isRefreshActive: boolean = false;
  crs: ICostRevenueSplit[];
  loading: boolean;
  _entityObject = RevenueEntity;

  constructor(
    public commonService: CommonService,
    public bankTransactionService: BankTransactionService,
    public crsService: CostRevenueSplitService) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.sessionId?.currentValue) {
      await this.getItem();
    }
  }

  async getItem() {
    this.loading = true;
    if (this.sessionId) {
      this.crs = await this.crsService.getCrsAdminBlockBySessionId(this.sessionId);
    }
    this.loading = false;
  }

  async refreshItem() {
    await this.getItem();
  }
}
export type ValueOf<T> = T[keyof T];

export const TroubleshootingRequests = Object.freeze({
  Reset: 'Reset',
  GetDiagnostics: 'GetDiagnostics',
  TriggerMessage: 'TriggerMessage',
  UnlockConnector: 'UnlockConnector',
  UpdateFirmare: 'UpdateFirmare',
  SetChargingProfile: 'SetChargingProfile',
  StopSession: 'StopSession',
  GetConfiguration: 'GetConfiguration',
  ChangeConfiguration: 'ChangeConfiguration',
  DataTransfer: 'DataTransfer'
});

export type TroubleshootingRequestsValues = ValueOf<typeof TroubleshootingRequests>;
export type TroubleshootingRequestsKeys = keyof typeof TroubleshootingRequests;

export const TriggerMessage = Object.freeze({
  BootNotification: 'BootNotification',
  DiagnosticsStatusNotification: 'DiagnosticsStatusNotification',
  FirmwareStatusNotification: 'FirmwareStatusNotification',
  Heartbeat: 'Heartbeat',
  MeterValues: 'MeterValues',
  StatusNotification: 'StatusNotification',
});

export type TriggerMessageValues = ValueOf<typeof TriggerMessage>;
export type TriggerMessageKeys = keyof typeof TriggerMessage;

export const ResetType = Object.freeze({
  Hard: 'Hard',
  Soft: 'Soft',
});

export type ResetTypeValues = ValueOf<typeof ResetType>;
export type ResetTypeKeys = keyof typeof ResetType;


import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { AppService } from '../../services/app.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-copy-value',
  templateUrl: './copy-value.component.html',
  styleUrls: ['./copy-value.component.scss']
})
export class CopyValueComponent implements OnInit {
  @Input() id: string | number;
  @Input() text: string;
  constructor(private clipboard: Clipboard, private AP: AppService, private translate: TranslateService) { }

  ngOnInit(): void {
  }
  copyValue(){
    const value = ((this.text || "") + " " + (this.id || "").toString()).trim();
    try{
      this.clipboard.copy(value);
      this.AP.createNotification('success', this.translate.instant("PHRASE.MISSION_ACCOMPLISHED"), this.translate.instant("PHRASE.VALUE_COPIED_CLIPBOARD"));
      this.AP.hasValueBeenCopied = true;
    }catch(e){
      console.error(e);
      this.AP.createNotification('error', e.type, this.translate.instant("PHRASE.VALUE_NO_COPIED"));
    }

  }

  get shortId():string {
    if (!this.id) return '';
    if(this.id.toString().length > 8)
      return this.id.toString().substring(0,4) + "*" + this.id.toString().substring(this.id.toString().length-4);
    else {
      return this.id.toString();
    }
    
  }
}

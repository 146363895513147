import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { IRfid, IStation, IUpdateRfidDTO } from 'common_library';
import { Subscription } from 'rxjs';
import { HttpIoService } from 'src/app/services/communication/http-io.service';
import { UserService } from 'src/app/services/entities/user.service';

@Component({
  selector: 'app-block-rfid',
  templateUrl: './block-rfid.component.html',
  styleUrls: ['./block-rfid.component.scss']
})
export class BlockRfidComponent implements OnInit, OnDestroy {
  @Output() isValidChanged = new EventEmitter<any>();
  
  sub: Subscription;
  rfid: IRfid;

  constructor(private userService: UserService, private HIO: HttpIoService) {
    this.sub = this.userService.selectedRfid$.subscribe(async (r)=>{
      this.rfid = r;
    })
  }

  ngOnInit(): void {

  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async toggleValidity() {
    const rv = await this.HIO.post<IRfid, IUpdateRfidDTO>('rfid/edit', {...this.rfid, valid: !this.rfid.valid});
    this.userService.selectedRfid$.next(await this.HIO.get(`rfid/by-code/${this.rfid.code}`));
    this.isValidChanged.emit()
  }

  async refresh() {
    if (!this.rfid) return;
    this.userService.selectedRfid$.next(await this.HIO.get(`rfid/by-code/${this.rfid.code}`))
  }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';
import { _ } from '../consts';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  constructor(
    private translate: TranslateService,
    private store: StorageService
  ) {
    this.translate.setDefaultLang(this.getDefaultLanguage());

    this.translate.onLangChange.subscribe((params) => {
      this.translations.next(this.updateTranslations());
    });
  }

  translations: BehaviorSubject<any> = new BehaviorSubject<any>(this.updateTranslations());

  getDefaultLanguage() {
    if (!this.store.get(JSON.stringify(_.LANG_KEY))) {
      let browserLang = this.translate.getBrowserLang();
      if (!browserLang || (browserLang !== "it" && browserLang !== "en")) {
        browserLang = "it";
      }
      this.setLanguage(browserLang);
    }
    return this.getCurrentLang();
  }

  setLanguage(setLang: string) {
    this.translate.use(setLang);
    // this.translations.next(this.updateTranslations());
    this.store.set(_.LANG_KEY, setLang);
  }

  getCurrentLang(): string {
    return this.store.get(_.LANG_KEY);
  }


  updateTranslations() {
    return {
      // Database
      id: { type: "id", label: this.translate.instant("COMMON_SERVICE.ID"), icon: "qrcode", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ENTITY") },
      version: { type: "text", label: this.translate.instant("COMMON_SERVICE.VERSION"), icon: "save", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ENTITY") },
      createdAt: { type: "date", label: this.translate.instant("COMMON_SERVICE.CREATION"), icon: "calendar", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ENTITY") },
      createdBy: { type: "text", label: this.translate.instant("COMMON_SERVICE.CREATED_BY"), icon: "idcard", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ENTITY") },
      updatedBy: { type: "text", label: this.translate.instant("COMMON_SERVICE.UPDATED_BY"), icon: "idcard", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ENTITY") },
      updatedAt: { type: "date", label: this.translate.instant("COMMON_SERVICE.UPDATE"), icon: "calendar", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ENTITY") },
      deletedAt: { type: "date", label: this.translate.instant("COMMON_SERVICE.DELETED_AT"), icon: "calendar", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ENTITY") },
      authorizedAt: { type: "date", label: this.translate.instant("COMMON_SERVICE.AUTHORIZATION"), icon: "tag", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ENTITY") },

      // Entità
      station: { type: "entity", label: this.translate.instant("COMMON_SERVICE.DEVICE"), icon: "", section: this.translate.instant("COMMON_SERVICE.SECTIONS.RELATIONS") },
      connector: { type: "entity", label: this.translate.instant("COMMON_SERVICE.CONNECTOR"), icon: "api", section: this.translate.instant("COMMON_SERVICE.SECTIONS.RELATIONS") },
      masterStation: { type: "entity", label: this.translate.instant("COMMON_SERVICE.MASTER_STATION"), icon: "", key: 'station', section: this.translate.instant("COMMON_SERVICE.SECTIONS.RELATIONS") },
      gateway: { type: "entity", label: this.translate.instant("COMMON_SERVICE.GATEWAY"), icon: "", section: this.translate.instant("COMMON_SERVICE.SECTIONS.RELATIONS") },
      installation: { type: "entity", label: this.translate.instant("COMMON_SERVICE.INSTALLATION"), icon: "tag", key: "installation", section: this.translate.instant("COMMON_SERVICE.SECTIONS.RELATIONS") },
      user: { type: "entity", label: this.translate.instant("COMMON_SERVICE.USER"), icon: "idcard", section: this.translate.instant("COMMON_SERVICE.SECTIONS.RELATIONS") },
      session: { type: 'entity', label: this.translate.instant("COMMON_SERVICE.SESSION"), icon: 'group', section: this.translate.instant("COMMON_SERVICE.SECTIONS.RELATIONS") },
      gatewayId: { type: "entity", label: this.translate.instant("COMMON_SERVICE.GATEWAY"), icon: "", key: "gateway", section: this.translate.instant("COMMON_SERVICE.SECTIONS.RELATIONS") },
      pod: { type: "entity", label: this.translate.instant("COMMON_SERVICE.POD"), icon: "one-to-one", section: this.translate.instant("COMMON_SERVICE.SECTIONS.RELATIONS") },
      multimedia: { type: "entity", label: this.translate.instant("COMMON_SERVICE.MULTIMEDIA"), icon: "file-image", section: this.translate.instant("COMMON_SERVICE.SECTIONS.RELATIONS") },
      cdr: { type: "entity", label: this.translate.instant("COMMON_SERVICE.CDR"), icon: 'area-chart', section: this.translate.instant("COMMON_SERVICE.SECTIONS.RELATIONS") },
      tariff: { type: "entity", label: this.translate.instant("COMMON_SERVICE.RATE"), icon: "dollar", section: this.translate.instant("COMMON_SERVICE.SECTIONS.RELATIONS") },
      currentSession: { type: "entity", label: this.translate.instant("COMMON_SERVICE.CURRENT_SESSION"), icon: "file-image", section: this.translate.instant("COMMON_SERVICE.SECTIONS.RELATIONS") },

      // IDS
      currentSessionId: { type: "text", label: this.translate.instant("COMMON_SERVICE.SESSION_ID"), icon: "barcode", section: this.translate.instant("COMMON_SERVICE.SECTIONS.DATABASE_ID") },
      stationId: { type: "text", label: this.translate.instant("COMMON_SERVICE.DEVICE_ID"), icon: "barcode", section: this.translate.instant("COMMON_SERVICE.SECTIONS.DATABASE_ID") },
      userId: { type: "text", label: this.translate.instant("COMMON_SERVICE.USER_ID"), icon: "barcode", section: this.translate.instant("COMMON_SERVICE.SECTIONS.DATABASE_ID") },
      carId: { type: "text", label: this.translate.instant("COMMON_SERVICE.VEHICLE_ID"), icon: "barcode", section: this.translate.instant("COMMON_SERVICE.SECTIONS.DATABASE_ID") },
      connectorId: { type: "text", label: this.translate.instant("COMMON_SERVICE.CONNECTOR_ID"), icon: "barcode", section: this.translate.instant("COMMON_SERVICE.SECTIONS.DATABASE_ID") },
      tariffId: { type: "id", label: this.translate.instant("COMMON_SERVICE.RATE_ID"), icon: "barcode", section: this.translate.instant("COMMON_SERVICE.SECTIONS.DATABASE_ID") },
      installationId: { type: "id", label: this.translate.instant("COMMON_SERVICE.INSTALLATION_ID"), icon: "barcode", section: this.translate.instant("COMMON_SERVICE.SECTIONS.DATABASE_ID") },
      ownerId: { type: "id", label: this.translate.instant("COMMON_SERVICE.OWNER_ID"), icon: "barcode", section: this.translate.instant("COMMON_SERVICE.SECTIONS.DATABASE_ID") },
      podId: { type: "id", label: this.translate.instant("COMMON_SERVICE.POD_ID"), icon: "barcode", section: this.translate.instant("COMMON_SERVICE.SECTIONS.DATABASE_ID") },
      sessionId: { type: "id", label: this.translate.instant("COMMON_SERVICE.SESSION_ID"), icon: "barcode", section: this.translate.instant("COMMON_SERVICE.SECTIONS.DATABASE_ID") },
      cdrId: { type: "id", label: this.translate.instant("COMMON_SERVICE.CDR_ID"), icon: "barcode", section: this.translate.instant("COMMON_SERVICE.SECTIONS.DATABASE_ID") },

      // CRONOLOGIA
      reservedAt: { type: "date", label: this.translate.instant("COMMON_SERVICE.RESERVATION"), icon: "history", fullLengthDate: true, section: this.translate.instant("COMMON_SERVICE.SECTIONS.CRONOLOGY") },
      bookingEndAt: { type: "date", label: this.translate.instant("COMMON_SERVICE.RESERVATION_END"), icon: "history", fullLengthDate: true, section: this.translate.instant("COMMON_SERVICE.SECTIONS.CRONOLOGY") },
      startedAt: { type: "date", label: this.translate.instant("COMMON_SERVICE.START"), icon: "history", fullLengthDate: true, section: this.translate.instant("COMMON_SERVICE.SECTIONS.CRONOLOGY") },
      fullyChargedAt: { type: "date", label: this.translate.instant("COMMON_SERVICE.CHARGING_COMPLETED_AT"), icon: "like", section: this.translate.instant("COMMON_SERVICE.SECTIONS.CRONOLOGY") },
      cancelledAt: { type: "date", label: this.translate.instant("COMMON_SERVICE.CANCELING"), icon: "history", fullLengthDate: true, section: this.translate.instant("COMMON_SERVICE.SECTIONS.CRONOLOGY") },
      stoppedAt: { type: "date", label: this.translate.instant("COMMON_SERVICE.INTERRUPTION"), icon: "history", fullLengthDate: true, section: this.translate.instant("COMMON_SERVICE.SECTIONS.CRONOLOGY") },
      endedAt: { type: "date", label: this.translate.instant("COMMON_SERVICE.END"), icon: "history", fullLengthDate: true, section: this.translate.instant("COMMON_SERVICE.SECTIONS.CRONOLOGY") },
      expirationDate: { type: "string", label: this.translate.instant("COMMON_SERVICE.EXPIRATION_DATE"), icon: "calendar", section: this.translate.instant("COMMON_SERVICE.SECTIONS.CRONOLOGY") },
      startDateTime: { type: "date", label: this.translate.instant("COMMON_SERVICE.START"), icon: "history", fullLengthDate: true, section: this.translate.instant("COMMON_SERVICE.SECTIONS.CRONOLOGY") },
      endDateTime: { type: "date", label: this.translate.instant("COMMON_SERVICE.END"), icon: "history", fullLengthDate: true, section: this.translate.instant("COMMON_SERVICE.SECTIONS.CRONOLOGY") },

      // ECONOMICS
      totalCost: { type: "currency", label: this.translate.instant("COMMON_SERVICE.TOTAL"), icon: "dollar", um: '€', section: this.translate.instant("COMMON_SERVICE.SECTIONS.ECONOMICS") },
      energyCost: { type: "currency", label: this.translate.instant("COMMON_SERVICE.ENERGY"), icon: "dollar", um: '€', section: this.translate.instant("COMMON_SERVICE.SECTIONS.ECONOMICS") },
      reservationCost: { type: "currency", label: this.translate.instant("COMMON_SERVICE.RESERVATION_COST"), icon: "dollar", um: '€', section: this.translate.instant("COMMON_SERVICE.SECTIONS.ECONOMICS") },
      occupationCost: { type: "currency", label: this.translate.instant("COMMON_SERVICE.PARKING_COST"), icon: "dollar", um: '€', section: this.translate.instant("COMMON_SERVICE.SECTIONS.ECONOMICS") },
      deposit: { type: "currency", label: this.translate.instant("COMMON_SERVICE.DEPOSIT"), icon: "dollar", um: '€', section: this.translate.instant("COMMON_SERVICE.SECTIONS.ECONOMICS") },
      balance: { type: "currency", label: this.translate.instant("COMMON_SERVICE.BALANCE"), icon: "dollar", um: '€', section: this.translate.instant("COMMON_SERVICE.SECTIONS.ECONOMICS") },
      energyPrice: { type: "currency", label: this.translate.instant("COMMON_SERVICE.ENERGY_PRICE"), icon: "dollar", um: '€/kWh', section: this.translate.instant("COMMON_SERVICE.SECTIONS.ECONOMICS") },
      servicePriceSetup: { type: "currency", label: this.translate.instant("COMMON_SERVICE.SERVICE_COST"), icon: "dollar", um: '€', section: this.translate.instant("COMMON_SERVICE.SECTIONS.ECONOMICS") },
      reservationPrice: { type: "currency", label: this.translate.instant("COMMON_SERVICE.RESERVATION_PRICE"), icon: "dollar", um: '€', section: this.translate.instant("COMMON_SERVICE.SECTIONS.ECONOMICS") },
      occupationPrice: { type: "currency", label: this.translate.instant("COMMON_SERVICE.PARKING_PRICE"), icon: "dollar", um: '€', section: this.translate.instant("COMMON_SERVICE.SECTIONS.ECONOMICS") },
      currency: { type: "text", label: this.translate.instant("COMMON_SERVICE.CURRENCY"), icon: "dollar", um: '€', section: this.translate.instant("COMMON_SERVICE.SECTIONS.ECONOMICS") },
      amountWithVat: { type: "currency", label: this.translate.instant("COMMON_SERVICE.COST_WITH_VAT"), icon: "dollar", um: '€', section: this.translate.instant("COMMON_SERVICE.SECTIONS.ECONOMICS") },
      cdcType: { type: "text", label: this.translate.instant("COMMON_SERVICE.CIRCUIT"), icon: "control", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ECONOMICS") },
      amount: { type: "currency", label: this.translate.instant("COMMON_SERVICE.QUANTITY"), icon: "dollar", um: "€", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ECONOMICS") },
      bankTransactionType: { type: "text", label: this.translate.instant("COMMON_SERVICE.TRANSACTION_TYPE"), icon: "credit-card", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ECONOMICS") },

      // Stato
      online: { type: "boolean", label: this.translate.instant("COMMON_SERVICE.ONLINE"), icon: "check-circle", key: "online", color: 'blue', section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATE") },
      innerState: { type: "text", label: this.translate.instant("COMMON_SERVICE.INTERNAL_STATE"), icon: "sliders", section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATE") },
      state: { type: "text", label: this.translate.instant("COMMON_SERVICE.STATE"), icon: "control", section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATE") },
      valid: { type: "boolean", label: this.translate.instant("COMMON_SERVICE.VALID"), icon: "check-circle", key: "online", color: 'blue', section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATE") },
      cdrState: { type: "text", label: this.translate.instant("COMMON_SERVICE.CDR_STATE"), icon: "sliders", section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATE") },
      bankTransactionSate: { type: "text", label: this.translate.instant("COMMON_SERVICE.TRANSACTION_STATE"), section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATE") },

      // Energy
      energy: { type: "energy", label: this.translate.instant("COMMON_SERVICE.ENERGY"), icon: "experiment", um: "kWh", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ENERGY") },
      totalEnergy: { type: "energy", label: this.translate.instant("COMMON_SERVICE.ENERGY"), icon: "experiment", um: "kWh", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ENERGY") },
      power: { type: "power", label: this.translate.instant("COMMON_SERVICE.POWER"), icon: "thunderbolt", um: "kW", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ENERGY") },
      powerLimit: { type: "powerLimit", label: this.translate.instant("COMMON_SERVICE.POWER_LIMIT"), icon: "vertical-align-top", um: "kW", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ENERGY") },

      // Anagrafica
      name: { type: "text", label: this.translate.instant("COMMON_SERVICE.NAME"), icon: "tag", section: this.translate.instant("COMMON_SERVICE.SECTIONS.REGISTRY_DATA_SHEET") },
      surname: { type: "text", label: this.translate.instant("COMMON_SERVICE.SURNAME"), icon: "tag", section: this.translate.instant("COMMON_SERVICE.SECTIONS.REGISTRY_DATA_SHEET") },
      email: { type: "text", label: this.translate.instant("COMMON_SERVICE.EMAIL"), icon: "mail", section: this.translate.instant("COMMON_SERVICE.SECTIONS.REGISTRY_DATA_SHEET") },
      isAdmin: { type: "boolean", label: this.translate.instant("COMMON_SERVICE.ADMIN"), icon: "safety-certificate", section: this.translate.instant("COMMON_SERVICE.SECTIONS.REGISTRY_DATA_SHEET") },
      disabled: { type: "boolean", label: this.translate.instant("COMMON_SERVICE.DISABLED"), icon: "stop", color: 'red', section: this.translate.instant("COMMON_SERVICE.SECTIONS.REGISTRY_DATA_SHEET") },
      cpo: { type: "text", label: this.translate.instant("COMMON_SERVICE.CPO"), icon: "shop", section: this.translate.instant("COMMON_SERVICE.SECTIONS.REGISTRY_DATA_SHEET") },
      address: { type: "text", label: this.translate.instant("COMMON_SERVICE.ADDRESS"), icon: "global", section: this.translate.instant("COMMON_SERVICE.SECTIONS.REGISTRY_DATA_SHEET") },
      publish: { type: "boolean", label: this.translate.instant("COMMON_SERVICE.TO_BE_PUBLISHED"), icon: "eye", section: this.translate.instant("COMMON_SERVICE.SECTIONS.REGISTRY_DATA_SHEET") },

      // Tariffa
      PLACEHOLDER: { type: "tariff-placeholder", label: this.translate.instant("COMMON_SERVICE.VALUES"), icon: "code", section: this.translate.instant("COMMON_SERVICE.SECTIONS.RATE") },
      REVENUE: { type: "tariff-revenue", label: this.translate.instant("COMMON_SERVICE.PROCEEDS"), icon: "code", section: this.translate.instant("COMMON_SERVICE.SECTIONS.RATE") },
      elements: { type: "tariff-elements", label: this.translate.instant("COMMON_SERVICE.ELEMENTS"), icon: "code", section: this.translate.instant("COMMON_SERVICE.SECTIONS.RATE") },

      // Connettore
      connectors: { type: 'none' },
      tariffIds: { type: 'none' },
      cpConnectorId: { type: "text", label: this.translate.instant("COMMON_SERVICE.CP_CONNECTOR_ID"), icon: "info-circle", section: this.translate.instant("COMMON_SERVICE.SECTIONS.CONNECTOR_DATA") },
      info: { type: "text", label: this.translate.instant("COMMON_SERVICE.INFO"), icon: "info-circle", section: this.translate.instant("COMMON_SERVICE.SECTIONS.CONNECTOR_DATA") },
      plugTypes: { type: "text", label: this.translate.instant("COMMON_SERVICE.TYPES_OF_PLUG"), icon: "", section: this.translate.instant("COMMON_SERVICE.SECTIONS.CONNECTOR_DATA") },

      // Entità
      connectorsStates: { type: "text", label: this.translate.instant("COMMON_SERVICE.CONNECTORS_STATE"), icon: "tag", section: this.translate.instant("COMMON_SERVICE.SECTIONS.OTHER") },

      // Sessione
      transactionId: { type: "text", label: this.translate.instant("COMMON_SERVICE.TRANSACTION_ID"), icon: "", section: this.translate.instant("COMMON_SERVICE.SECTIONS.OTHER") },

      //Pod
      serial: { type: "text", label: this.translate.instant("COMMON_SERVICE.SERIAL_NUMBER"), icon: "tag", section: this.translate.instant("COMMON_SERVICE.SECTIONS.OTHER") },
      availablePower: { type: "text", label: this.translate.instant("COMMON_SERVICE.AVAILABLE_POWER"), icon: "thunderbolt", section: this.translate.instant("COMMON_SERVICE.SECTIONS.OTHER") },
      energyManagement: { type: "tag", label: this.translate.instant("COMMON_SERVICE.ENERGY_MANAGEMENT"), icon: "tag", section: this.translate.instant("COMMON_SERVICE.SECTIONS.OTHER") },
      reservation: { type: "text", label: this.translate.instant("COMMON_SERVICE.RESERVATION"), icon: "clock-circle", section: this.translate.instant("COMMON_SERVICE.SECTIONS.OTHER") },
      charge: { type: "text", label: this.translate.instant("COMMON_SERVICE.CHARGING"), icon: "car", section: this.translate.instant("COMMON_SERVICE.SECTIONS.OTHER") },
      occupation: { type: "text", label: this.translate.instant("COMMON_SERVICE.OCCUPATION"), icon: "hourglass", section: this.translate.instant("COMMON_SERVICE.SECTIONS.OTHER") },
      duration: { type: "text", label: this.translate.instant("COMMON_SERVICE.TOTAL_DURATION"), icon: "history", section: this.translate.instant("COMMON_SERVICE.SECTIONS.OTHER") },

      // Stazione
      chargePointVendor: { type: "text", label: this.translate.instant("COMMON_SERVICE.CHARGE_POINT_VENDOR"), icon: "info-circle", section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATION_DATA") },
      chargePointModel: { type: "text", label: this.translate.instant("COMMON_SERVICE.CHARGE_POINT_MODEL"), icon: "info-circle", section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATION_DATA") },
      chargePointSerialNumber: { type: "text", label: this.translate.instant("COMMON_SERVICE.CHARGE_POINT_SERIAL_NUMBER"), icon: "info-circle", section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATION_DATA") },
      chargeBoxSerialNumber: { type: "text", label: this.translate.instant("COMMON_SERVICE.CHARGE_BOX_SERIAL_NUMBER"), icon: "info-circle", section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATION_DATA") },
      firmwareVersion: { type: "text", label: this.translate.instant("COMMON_SERVICE.FIRMWARE"), icon: "file-protect", section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATION_DATA") },
      iccid: { type: "text", label: this.translate.instant("COMMON_SERVICE.ICCID"), icon: "info-circle", section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATION_DATA") },
      imsi: { type: "text", label: this.translate.instant("COMMON_SERVICE.IMSI"), icon: "info-circle", section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATION_DATA") },
      meterType: { type: "text", label: this.translate.instant("COMMON_SERVICE.METER_TYPE"), icon: "", section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATION_DATA") },
      meterSerialNumber: { type: "text", label: this.translate.instant("COMMON_SERVICE.METER_SERIAL_NUMBER"), icon: "", section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATION_DATA") },
      stationType: { type: "text", label: this.translate.instant("COMMON_SERVICE.STATION_TYPE"), icon: "usb", section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATION_DATA") },
      coord: { type: "text", label: this.translate.instant("COMMON_SERVICE.COORDS"), icon: "usb", section: this.translate.instant("COMMON_SERVICE.SECTIONS.STATION_DATA") },

      // errori
      error: { type: "text", label: this.translate.instant("COMMON_SERVICE.ERROR"), icon: "warning", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ERROR") },
      errorCode: { type: "text", label: this.translate.instant("COMMON_SERVICE.ERROR"), icon: "warning", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ERROR") },
      errorCounter: { type: "text", label: this.translate.instant("COMMON_SERVICE.ERRORCOUNTER"), icon: "warning", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ERROR") },
      vendorErrorCode: { type: "text", label: this.translate.instant("COMMON_SERVICE.VENDOR_ERROR_CODE"), icon: "warning", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ERROR") },
      errorStatoCorrispettivoElettronico: { type: "text", label: this.translate.instant("COMMON_SERVICE.RECEIPT_ERROR_CODE"), icon: "warning", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ERROR") },
      errorStatoCorrispettivoElettronicoCounter: { type: "text", label: this.translate.instant("COMMON_SERVICE.RECEIPT_ERROR_CODE_COUNTER"), icon: "warning", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ERROR") },
      errorRevenues: { type: "text", label: this.translate.instant("COMMON_SERVICE.REVENUES_ERROR_CODE"), icon: "warning", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ERROR") },
      errorRevenuesCounter: { type: "text", label: this.translate.instant("COMMON_SERVICE.REVENUES_ERROR_CODE_COUNTER"), icon: "warning", section: this.translate.instant("COMMON_SERVICE.SECTIONS.ERROR") },
      // metadati
      metadata: { type: "text", label: this.translate.instant("COMMON_SERVICE.METADATA"), icon: "warning", section: this.translate.instant("COMMON_SERVICE.SECTIONS.METADATA") },

      // visibilità
      visible: { type: "text", label: this.translate.instant("COMMON_SERVICE.VISIBILITY"), icon: "warning", section: this.translate.instant("COMMON_SERVICE.SECTIONS.VISIBILITY") },
      visibilityGroups: { type: "text", label: this.translate.instant("COMMON_SERVICE.VISIBILITY_GROUPS"), icon: "warning", section: this.translate.instant("COMMON_SERVICE.SECTIONS.VISIBILITY") },

      // Dati fiscali
      statoCorrispettivoElettronico: { type: "text", label: this.translate.instant("COMMON_SERVICE.RECEIPT_STATE"), icon: "warning", section: this.translate.instant("COMMON_SERVICE.SECTIONS.TAX") },
    }
  }

  getKnownItemsSection() {
    return this.translations;
  }

}

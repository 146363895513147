export type ValueOf<T> = T[keyof T];

export const GroupEntityType = Object.freeze({
  'U': 'user',
  'UG': 'usergroup',
  'S': 'station',
  'SG': 'stationgroup',
  'G': 'group',
  'P': 'pod',
  'I': 'installation',
  'T': 'tariff'
});

export type GroupEntityTypeValues = ValueOf<typeof GroupEntityType>;
export type GroupEntityTypeKeys = keyof typeof GroupEntityType;
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-list-versions',
  templateUrl: './list-versions.component.html',
  styleUrls: ['./list-versions.component.scss']
})

export class ListVersionsComponent implements OnInit {

    @Input() logs: Array<{vers:string; info: string; items:Array<{desc: string; type: string;}>}>;
    
    dictColor = {
      'fix': 'red',
      'feature': 'blue'
    }

    constructor() { }

    ngOnInit() {
    }
 }
import { Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IUpTime } from 'common_library';
import { TranslateConfigService } from 'src/app/services/translateConfig.service';


@Component({
  selector: 'app-block-up-time-list',
  templateUrl: './block-up-time-list.component.html',
  styleUrls: ['./block-up-time-list.component.scss']
})
export class BlockUpTimeListComponent implements OnInit, OnChanges {
  @Input() upTimes: IUpTime[] = [];

  list = [this.translateService.instant('LABEL.FROM'), this.translateService.instant('LABEL.TO'), this.translateService.instant('LABEL.DURATION')]

  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
    this._sortUpTimes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.upTimes) {
      this._sortUpTimes();
    }
  }

  private _sortUpTimes() {
    this.upTimes.sort((a, b) => new Date(b.fromTs).getTime() - new Date(a.fromTs).getTime());
  }

}

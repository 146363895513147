import { Injectable } from '@angular/core';
import { OCPPComunication as OCPP, RecordCounted } from 'common_library';
import { HttpIoService } from '../communication/http-io.service';
import { CommonService } from '../common.service';
import { Observable } from 'rxjs';

const CONTROLLER_ROOT = 'ocpp-gateway';

@Injectable({
  providedIn: 'root'
})
export class GatewayService {
  constructor(private HIO: HttpIoService, private commonService: CommonService) { }

  async getGateways(): Promise<OCPP.IOcppGateway[]> {
    return await this.HIO.get<OCPP.IOcppGateway[]>(`${CONTROLLER_ROOT}/all`)
  }

  async getGatewayDetails(id: number): Promise<OCPP.IOcppGateway> {
    return await this.HIO.get<OCPP.IOcppGateway>(`${CONTROLLER_ROOT}/details/${id}`)
  }

  async getGatewaysByName(name: string): Promise<OCPP.IOcppGateway[]> {
    return await this.HIO.get<OCPP.IOcppGateway[]>(`${CONTROLLER_ROOT}/search/${name}`)
  }

  getCountAndPaginateGatewaysByString(page?: number, search?: string): Observable<RecordCounted<OCPP.IOcppGateway>> {
    return this.commonService.getAndCount$({
      controllerRoot: CONTROLLER_ROOT,
      controllerDir: 'filter-and-count',
      page: page,
      search: search
    })
  }
}

export type ValueOf<T> = T[keyof T];

export const PlatformType = Object.freeze({
  Desktop: "desktop",
  iOSMobileWeb: "ios web",
  AndroidMobileWeb: "android web",
  iOSNative: "ios native",
  AndroidNative: "android native"
});

export type PlatformTypeValues = ValueOf<typeof PlatformType>;
export type PlatformTypeKeys = keyof typeof PlatformType;
<div nz-row nzJustify="space-between" *ngIf="isAdmin$ | async">
  <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="11" nzXxl="11" class="page-section">
    <app-block-header title="{{'MANAGER.ASSOCIATE_MANAGERS'|translate}}" icon="user" [isRefreshActive]="false">
    </app-block-header>
    <div>
      <nz-table class="gestori-table" #gestoriAssociatiTable [nzData]="gestoriAssociati$.value" [nzPageSize]="100"
        [nzFrontPagination]="false">

        <tbody>
          <tr *ngFor="let data of gestoriAssociatiTable.data">
            <td class="tbody__row">
              <app-entity-tag class="yf-pointer" entityType="gestore" [label]="data?.businessName" [entity]="data">
              </app-entity-tag>
              <a nz-popconfirm nzPopconfirmTitle="{{'MANAGER.REMOVE_MANAGER'|translate}}" (nzOnConfirm)="removeGestore(data)"><i
                class="row__icon" nz-icon nzType="delete" nzTheme="fill"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>

  <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="11" nzXxl="11" class="page-section">
    <app-block-header title="{{'MANAGER.ASSOCIATE_MANAGER'|translate}}" icon="edit" [isRefreshActive]="false">
    </app-block-header>
    <div>
      <nz-table *ngIf="listGestori$ | async" class="gestori-table" #gestoriTable [nzData]="listGestori$.value"
        [nzPageSize]="100" [nzFrontPagination]="false">

        <tbody>
          <tr *ngFor="let data of gestoriTable.data">
            <td class="tbody__row">
              <app-entity-tag entityType="gestore" [label]="data?.businessName" [entity]="data">
              </app-entity-tag>
            </td>
            <td>
              <a nz-popconfirm nzPopconfirmTitle="{{'MANAGER.ADD_MANAGER'|translate}}" (nzOnConfirm)="addGestore(data)"><i
                  class="add__icon large-icon" nz-icon nzType="plus-square" nzTheme="fill"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ContentChange, QuillConfig, QuillFormat, QuillModules, QuillToolbarConfig } from 'ngx-quill';

@Component({
  selector: 'app-quill',
  templateUrl: './quill.component.html',
  styleUrls: ['./quill.component.scss']
})
export class QuillComponent extends FieldType<FieldTypeConfig> implements OnInit {

  @Output() html: EventEmitter<string> = new EventEmitter();

  config: QuillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],

      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],

      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean']
    ],
  }

  constructor() { 
    super();
  }

  ngOnInit(): void {
  }

  onContentChanged(changes: ContentChange) {
    this.html.emit(changes.html);
  }
}

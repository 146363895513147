export type ValueOf<T> = T[keyof T];

export const ErrorCode = Object.freeze({
  NoError: 0,
  ConnectorNotAvailable: 1, 	// non disponibile o preso da altri
  ConnectorStateError: 2, 	// è della stessa sessione ma non è nello stato desiderato
  MissingParameter: 3,
  SessionNotAvailable: 4,
  SessionStateError: 5,
  UnknownCommand: 6,
  CdcNotValid: 7,
  OcpiTimeout: 8,
  AlreadyRegisteredUser: 9,
  GeneralError: 10,
  CmdRejected: 11,
  UnpaidSession: 12,
  OcpiSessionActive: 13

});

export type ErrorCodeValues = ValueOf<typeof ErrorCode>;
export type ErrorCodeKeys = keyof typeof ErrorCode;
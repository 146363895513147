import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IChargeDetailRecord, CdrState } from 'common_library';
import { Router } from '@angular/router';
import { ChargeDetailRecordService } from 'src/app/services/entities/charge-detail-record.service';
import { CommonService } from 'src/app/services/common.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-block-cdr',
  templateUrl: './block-cdr.component.html',
  styleUrls: ['./block-cdr.component.scss']
})
export class BlockCdrComponent implements OnChanges {

  @Input() cdrId: string;
  @Input() sessionId: string;
  @Output() updateClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  cdr$ = new BehaviorSubject<IChargeDetailRecord>(null);
  metadata$ = new BehaviorSubject<any[]>([]);
  loading: boolean = false;
  private langChangeSubscription: Subscription;

  constructor(private router: Router,
    private cdrService: ChargeDetailRecordService,
    private commonService: CommonService,
    public translate: TranslateService) { 
      this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
        this.populateMetadata();
      });
    }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.sessionId?.currentValue || changes.cdrId?.currentValue) {
      await this.getCdr();
    }
  }

  async getCdr() {
    this.loading = true;
    let currentCdr;
    if (this.sessionId) {
      currentCdr = await this.cdrService.getOneBySessionAdminBlock(this.sessionId);
    } else if (this.cdrId) {
      currentCdr = await this.cdrService.getOneAdminBlock(this.cdrId);
    }
    if (currentCdr) {
      currentCdr["cdrState"] = this.commonService.findEntityKey(CdrState, currentCdr.state);
    }
    this.cdr$.next(currentCdr);
    this.populateMetadata();
    this.loading = false;
  }

  goToCdrDetail() {
    this.router.navigate([`authenticated/charge-detail-record/${this.cdr$.getValue().id}`]);
  }

  populateMetadata() {
    let metadata = [];
    if (this.cdr$.getValue()) {
      metadata.push({ [`${this.translate.instant("SESSION.DETAIL.TOTAL_COST")}`]: this.cdr$.getValue().totalCost });
      metadata.push({ [`${this.translate.instant("SESSION.DETAIL.BOOK_COST")}`]: this.cdr$.getValue().totalReservationCost });
      metadata.push({ [`${this.translate.instant("SESSION.DETAIL.ENERGY_COST")}`]: this.cdr$.getValue().totalEnergyCost });
      metadata.push({ [`${this.translate.instant("SESSION.DETAIL.PARKING_COST")}`]: this.cdr$.getValue().totalParkingCost });
      metadata.push({ [`${this.translate.instant("SESSION.DETAIL.RECEIPT_RESPONSE")}`]: this.cdr$.getValue().corrispettivoElettronicoMetadata });
    }
    this.metadata$.next(metadata);
  }

  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
}

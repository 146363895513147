import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.scss']
})

export class DetailHeaderComponent implements OnInit {

  @Input() label: string;
  @Input() subtitle: string;
  @Input() value: string;
  @Input() state?: string | number;
  @Input() stateGroup?: boolean;
  @Input() stateGroupValue?: boolean;
  @Input() root?: string;
  @Input() id?: string;
  @Input() isRefreshActive:boolean = true;
  @Input() data?: any;
  @Input() date?: any;
  @Output() refreshRequest = new EventEmitter();

  dictState = {
    0: 'UNAVAILABLE',
    1: 'AVAILABLE',
    2: 'RESERVED',
    3: 'CHARGING',
    4: 'OCCUPIED',
  };

  dictStateColor = {
    0: 'grey',
    1: 'green',
    2: 'blue',
    3: 'orange',
    4: 'red',
  }

  constructor() { }

  ngOnInit() {
  }

  refresh() {
    this.refreshRequest.emit();
  }

}

export type ValueOf<T> = T[keyof T];

export const OCPIReservationRestriction = Object.freeze({
  RESERVATION: "RESERVATION",
  /**
   *  Used in TariffElements to describe costs for a reservation that expires (i.e. driver does not start a
   * charging session before expiry_date of the reservation).
   */
  RESERVATION_EXPIRES: "RESERVATION_EXPIRES"
})

export type OCPIReservationRestrictionValues = ValueOf<typeof OCPIReservationRestriction>
export type OCPIReservationRestrictionKeys = keyof typeof OCPIReservationRestriction;
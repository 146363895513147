import { Injectable } from "@angular/core";
import { HttpIoService } from "../communication/http-io.service";
import { ISession, IToken, RecordCounted } from "common_library";
import { CommonService } from "../common.service";
import { SessionService } from "./session.service";

const CONTROLLER_ROOT = 'token';

@Injectable({ providedIn: 'root'})
export class TokenService {
  constructor(private HIO: HttpIoService, private commonService: CommonService, private sessionService: SessionService) {}

  async getTokensCountedFilteredAndPaginated(page?: number, search?: string): Promise<RecordCounted<IToken>> {
    return this.commonService.getAndCount({
      controllerRoot: CONTROLLER_ROOT,
      controllerDir: 'admin/filter-and-paginate',
      page: page,
      search: search
    })
  }

  async getOneToken(id: string): Promise<IToken> {
    try {
      return this.HIO.get<IToken>(`${CONTROLLER_ROOT}/one/${id}`); 
    } catch (error) {
      console.log('🐸: error -> ', error);
    }
  }

  async getSessionLinked(userId: string): Promise<ISession[]> {
    return this.sessionService.getSessionByUserId(userId);
  }

  async getTokensByUserId(userId: string): Promise<IToken[]> {
    return this.HIO.get(`${CONTROLLER_ROOT}/by-user-id/${userId}`)
  }
}

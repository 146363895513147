import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-block-header',
  templateUrl: './block-header.component.html',
  styleUrls: ['./block-header.component.scss']
})
export class BlockHeaderComponent implements OnInit {
  @Input() title: string = 'fail';
  @Output() refreshRequest = new EventEmitter();
  @Output() addNewRequest = new EventEmitter();
  @Output() editRequest = new EventEmitter();
  @Input() isRefreshActive: boolean = true;
  @Input() isAddNewActive: boolean = false;
  @Input() isEditActive: boolean = false;
  @Input() icon: string;
  @Input() refreshIcon: string;
  @Input() addNewIcon: string;
  @Input() editIcon: string;
  @Input() tooltip: string;
  @Input() addNewTooltip: string;
  @Input() editTooltip: string;
  @Input() confirmation: boolean;
  @Input() popConfirm: string;

  constructor() { }

  ngOnInit(): void {
  }

  refresh() {
    this.refreshRequest.emit();
  }

  addNew() {
    this.addNewRequest.emit();
  }

  edit() {
    this.editRequest.emit();
  }
}

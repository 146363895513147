<nz-modal nzTitle="{{'LABEL.IMPERSONATE_USER'|translate}}" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()" [nzVisible]="true">
  <ng-container *nzModalContent>
    <div nz-row nzJustify="center" nzGutter="10">
      <p>{{"LABEL.SELECT_USER"|translate}}</p>
      <nz-select nzShowSearch nzAllowClear nzPlaceHolder="{{'LABEL.SELECT_USER_PLACEHOLDER'|translate}}" [(ngModel)]="selectedValue">
        <nz-option *ngFor="let option of usersOptions" [nzValue]="option.id" [nzLabel]="option.surname+' '+option.name+' - '+option.email">
        </nz-option>
      </nz-select>
    </div>

    <div nz-row nzJustify="center" nzGutter="10">
      <nz-alert nzType="info" nzDescription="{{'LABEL.IMPERSONATION'|translate}}"></nz-alert>
    </div>
  </ng-container>

  <ng-template [nzModalFooter]>
    <button nz-button nzType="default" (click)="handleCancel()">{{"BUTTONS.CANCEL"|translate}}</button>
    <button nz-button nzType="primary" (click)="handleOk()">{{"BUTTONS.CONFIRM"|translate}}</button>
  </ng-template>
</nz-modal>
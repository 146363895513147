<div [ngClass]="{'page-cont': true, 'small': !isFull && logs.length > 0}">
  <ng-container *ngIf="logs$ | async as logs">
    <div class="form-container search-container" *ngIf="logsSearchCriteria$ | async as logsSearchCriteria">
      <div [ngClass]="{'log-search-expanded': app.isMobile}" class="log-search">
        <app-search-bar [debounceTime]=400 placeholder="{{'INSTALLATIONS.DETAIL.TEXT_SEARCH'|translate}}" (textChange)="onSearchChanged($event)" [text]="logsSearchCriteria.search" [padding]="false">
        </app-search-bar>
      </div>
      <div>
        <nz-select [ngModel]="logsSearchCriteria.level" (ngModelChange)="onLevelChange($event)">
          <nz-option *ngFor="let level of levels" [nzValue]="level.value" [nzLabel]="level.label"></nz-option>
        </nz-select>
      </div>
      <ng-container *ngIf="isFull">
        <div>
          <nz-date-picker [nzFormat]="'dd/MM/yyyy'" [nzAllowClear]="false" [(ngModel)]="logsSearchCriteria.date"
            (ngModelChange)="onDateChanged($event)">
          </nz-date-picker>
        </div>
        <div>
          <input nz-input placeholder="{{'PLACEHOLDER.ENTITY_ID'|translate}}" [(ngModel)]="logsSearchCriteria.entityId"
            (ngModelChange)="onEntityIdChange($event)" />
        </div>
        <div>
          <nz-select [ngModel]="logsSearchCriteria.entityType" (ngModelChange)="onEntityTypeChange($event)">
            <nz-option *ngFor="let type of types" [nzValue]="type.value" [nzLabel]="type.label"></nz-option>
          </nz-select>
        </div>
      </ng-container>
      <div class="search-buttons-container">
        <ng-container [ngTemplateOutlet]="nzPaginationTemplate"></ng-container>
        <div class="refresh-button">
          <button (click)="onRefresData()" nzSpaceItem nz-button>
            <i nz-icon nzType="reload" nzTheme="outline">
            </i>
          </button>
        </div>
      </div>
    </div>

    <nz-table #table [nzData]="logs.data" [nzScroll]="{y: isFull ? 'calc(100vh - 225px)' : '600px', x: '700px'}" [nzPageSize]="50"
      [nzFrontPagination]="false" [nzShowPagination]="false" nzSize="small" class="table-log">
      <thead>
        <tr>
          <th nzWidth="110px"></th>
          <th>{{'INSTALLATIONS.DETAIL.MESSAGE'|translate}}</th>
          <th *ngIf="isFull">Entity</th>
          <th nzWidth="44px"></th>
        </tr>
      </thead>
      <tbody class="yf-pointer">
        <ng-template nz-virtual-scroll let-data let-index="index">
          <tr>
            <td class="log-dates">
              <span nz-icon [nzType]="this.icons.level[data?.level]" nzTheme="outline"></span>
              <span> {{data?.timestamp | localizedDate:"medium"}} </span>
            </td>
            <td>
              {{data?.message}}
            </td>
            <td *ngIf="isFull">
              <span nz-icon [nzType]="this.icons.type[data?.entity?.type]" nzTheme="outline" [nzTooltipTitle]="icons.tooltip[data?.entity?.type]" nzTooltipPlacement="top" nz-tooltip></span>
              {{data?.entity?.id}} <span nz-icon nzType="copy" *ngIf="data?.entity?.id" nzTheme="outline" class="yf-pointer" (click)="copyValue(data?.entity?.id)"></span>
            </td>
            <td>
              <button nz-button nzType="default" nzShape="circle" nz-popover nzPopoverTrigger="click"
                nzPopoverTitle="{{'INSTALLATIONS.DETAIL.LOG_DETAIL'|translate}}" [nzPopoverContent]="getLogDetail(data)">
                <span nz-icon nzType="more"></span>
              </button>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </nz-table>
    <ng-template #nzPaginationTemplate>
      <nz-pagination
        (nzPageIndexChange)="pageIndexChange($event)"
        [nzTotal]="logs.count"
        [nzSize]="'small'"
        [nzPageIndex]="(logsSearchCriteria$ | async).page"
        [nzPageSize]="50"
        ></nz-pagination>
    </ng-template>
  </ng-container>
</div>

import { Injectable } from '@angular/core';
import { ICreateGestoreUserDTO, IGestore, IGestoreEntity, GestoreUserRoleValues, IUser, RecordCounted, GestoreEntityTypeValues, IGestoreUser, GestoreEntityType  } from 'common_library';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonService } from '../common.service';
import { HttpIoService } from '../communication/http-io.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GestoreService {

  private selectedGestoreSubject = new BehaviorSubject<string>(null);
  selectedGestore$ = this.selectedGestoreSubject.asObservable();

  private readonly CONTROLLER_ROOT = 'gestore';
  private readonly GESTORE_ENTITY_ROOT = 'gestore-entity';
  private readonly GESTORE_USER_ROOT = 'gestore-user'

  constructor(private commonService: CommonService, private HIO: HttpIoService, private auth: AuthService) { }

  /**
   * 
   * @param pageNumber {number}: il numero della pagina da cui parte la ricerca
   * @param searchCriteria {string}: la stringa usata per filtrare la ricerca
   * @returns 
  */
  getAndCountManagersByStringPaginated(pageNumber: number, searchCriteria?: string): Observable<RecordCounted<IGestore>> {
    const res = this.commonService.getAndCount$<IGestore>({
      page: pageNumber,
      search: searchCriteria,
      controllerRoot: this.CONTROLLER_ROOT,
      controllerDir: "admin/filter-and-paginate"
    });
    return res;
  }
  
  async getOneManager(managerId: string): Promise<IGestore> {
    try {
      return this.HIO.get(`${this.CONTROLLER_ROOT}/admin/one/${managerId}`);
    } catch (error) {
      console.error('Error: 🐸 -> ', error);
    }
  }

  async updateManager(managerId: string, dto: Partial<IGestore>): Promise<boolean> {
    try {
      return this.HIO.patch(`${this.CONTROLLER_ROOT}/admin/${managerId}`, dto);
    } catch (error) {
      console.error('Error: 🐸 -> ', error);
      return false;
    }
  }

  async getUsersOrphansFromGestore(managerId: string, searchString: string, pageIndex: number): Promise<RecordCounted<IUser>> {
    try {
      return this.HIO.post<RecordCounted<IUser>, any>(`${this.CONTROLLER_ROOT}/admin/orphans-from-manager`, {managerId, searchString, pageIndex: pageIndex - 1});  
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }

  async getGestoriRelatedToUser(user: IUser, managerId: string): Promise<IGestore[]> {
    try {
      return this.HIO.get<IGestore[]>(`${this.CONTROLLER_ROOT}/admin/gestori-options/${user.id}/${managerId}`)
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }

  async addUserToManager(userId: string, managerId: string): Promise<boolean> {
    try {
      return this.HIO.post<boolean, ICreateGestoreUserDTO>(`${this.GESTORE_USER_ROOT}/add`, {gestoreId: managerId, userId});
    } catch (error) {
      console.error('🐸 : error', error);
      return false;
    }
  }

  async removeUserFromManager(userId: string, managerId: string): Promise<boolean> {
    try {
      return this.HIO.deleteItem(`${this.GESTORE_USER_ROOT}/delete/${userId}`, managerId);
    } catch (error) {
      console.error('🐸 : error', error);
      return false;
    }
  }

  async createOne(dto: Partial<IGestore>): Promise<IGestore> {
    try {
      return this.HIO.post<IGestore, any>(`${this.CONTROLLER_ROOT}/admin/create`, dto);
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }

  async getGestoriOrphansFromEntity(entityId: string | number): Promise<IGestore[]> {
    try {
      return this.HIO.get(`${this.CONTROLLER_ROOT}/admin/gestori-orphans-from-entity/${entityId}`)    
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }

  async getGestoriConnectedToEntity(entityId: string | number, entityType: GestoreEntityTypeValues): Promise<IGestore[]> {
    try {
      return this.HIO.get(`${this.CONTROLLER_ROOT}/admin/connected-to-entity/${entityId}/${entityType}`)
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }

  async handleSetupGestore(id: string | number, gestoreSelected: string, entityType: GestoreEntityTypeValues) {
    if (gestoreSelected) {
      await this.addGestoreToEntity(id, gestoreSelected, entityType)
    }
    else return;
  }
  
  async addGestoreToEntity(entityId: string | number, gestoreId: string, entityType: GestoreEntityTypeValues): Promise<boolean> {
    try {
      const dto = {entityId: entityId, entityType: entityType, gestoreId: gestoreId};
      const rv = await this.HIO.post<boolean, Partial<IGestoreEntity>>(`${this.GESTORE_ENTITY_ROOT}/create`, dto)
      return rv;
    } catch (error) {
      console.error('🐸 : error', error);
    }  
  }
  async removeGestoreToEntity(entityId: string | number, gestoreId: string): Promise<boolean> {
    try {
      const dto = {entityId: entityId, gestoreId: gestoreId};
      const rv = await this.HIO.post<boolean, Partial<IGestoreEntity>>(`${this.GESTORE_ENTITY_ROOT}/delete`, dto)
      return rv;
    } catch (error) {
      console.error('🐸 : error', error);
    }  
  }


  async changeGestoreUserRole(gestoreId: string, userId: string | number, gestoreUserRole: GestoreUserRoleValues): Promise<boolean> {
    try {
      const rv = await this.HIO.post(`${this.GESTORE_USER_ROOT}/update-user-role/${gestoreId}/${userId}`, {gestoreUserRole});
      return true;
    } catch (error) {
      console.error('🐸 : error', error);
      return false;
    }
  }

  async getRelatedEntities(managerId: string): Promise<any[]> {
    try {
      return this.HIO.get(`${this.GESTORE_ENTITY_ROOT}/admin/by-managerId/${managerId}`);
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }

  async getIdGestoriPadreAsOptions(managerId?: string) {
    const gestoriUserConnected = await this.getGestoriRelatedToUser(this.auth.user$.getValue(), managerId);
    const idGestoriOptions: { label: string, value: string }[] = [{label: '', value: null}];
    for (let i = 0; i < gestoriUserConnected.length; i++) {
      const gestoreConnected = gestoriUserConnected[i];
      idGestoriOptions.push({ label: gestoreConnected.businessName, value: gestoreConnected.id });
    }
    return idGestoriOptions;
  }

  async getGestoreByUserId(userId: string): Promise<IGestore[]> {
    try {
      return this.HIO.get(`${this.CONTROLLER_ROOT}/gestore-by-user/${userId}`);
    } catch (error) {
      console.error('🐸 : error', error);
    }
  }

  async setSelectedGestore(gestoreId: string) {
    this.selectedGestoreSubject.next(gestoreId);
  }
  
}

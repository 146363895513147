import { Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import { BankTransactionState, BankTransactionType, IBankTransaction } from 'common_library';
import { CommonService } from 'src/app/services/common.service';
import { BankTransactionService } from 'src/app/services/entities/bank-transaction.service';

@Component({
  selector: 'app-block-payment',
  templateUrl: './block-payment.component.html',
  styleUrls: ['./block-payment.component.scss']
})
export class BlockPaymentComponent implements OnInit, OnChanges {

  @Input() cdrId: string;
  @Input() paymentId: number;
  loading: boolean;
  payment: IBankTransaction;
  
  constructor(private commonService: CommonService, private router: Router, private paymentService: BankTransactionService) { }

  async ngOnInit(): Promise<void> {
    await this.setPaymentFields();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.cdrId?.currentValue || changes.paymentId?.currentValue) {
      await this.getPayment()
      this.setPaymentFields()
    }
  }

  async getPayment() {
    this.loading = true;
    if (this.cdrId) {
      this.payment = await this.paymentService.getBankTransactionAdminBlockByCdrId(this.cdrId);
    } else if (this.paymentId) {
      this.payment = await this.paymentService.getOneBankTransactionAdminBlock(this.paymentId);
    }
    this.loading = false;
  }

  async setPaymentFields() {
    if (this.payment) {  
      this.payment["bankTransactionSate"] = this.commonService.findEntityKey(BankTransactionState, this.payment.state);
      this.payment["bankTransactionType"] = this.commonService.findEntityKey(BankTransactionType, this.payment.type);
      this.payment["amount"] = this.payment.value;
    }
  }

  goToPaymentDetail() {
    this.router.navigate([`authenticated/payments/${this.payment.id}`])
  }
}

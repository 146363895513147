import { Component, Input, OnInit, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Item, UNKNOWN_ICON } from 'src/app/services/common.service';
import * as clone from 'clone';
import { AppService } from 'src/app/services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/services/translateConfig.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-block-values',
  templateUrl: './block-values.component.html',
  styleUrls: ['./block-values.component.scss']
})
export class BlockValuesComponent implements OnInit, OnChanges {

  @Input() name: string = this.translate.instant("BLOCK_VALUE.DETAIL");
  @Input() data: { [key: string]: any };
  @Input() mainFields: string[] = []
  @Input() mainField: string = 'id';
  @Input() clickableToShowAll: boolean = true;
  @Output() goToItemDetail: EventEmitter<any> = new EventEmitter();

  drawerVisible = false;
  mainItems: Item[] = [];
  mainItem: Item;
  allItems: { [section: string]: Item[] } = {};
  sections: string[] = [];

  goingToItem: boolean = false;
  private langChangeSubscription: Subscription;
  translations: Observable<any> = this.translateConfigService.translations.asObservable();
  knowItems = {};

  constructor(
    public AS: AppService,
    public translate: TranslateService,
    private translateConfigService: TranslateConfigService
  ) {
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.recalc(this.data);
    });
  }


  ngOnInit(): void {
    this.translations.subscribe((translations) => {
      this.knowItems = translations;
      this.recalc(this.data);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data?.currentValue) {
      this.recalc(changes.data?.currentValue);
    }
  }

  showAll() {
    if (!this.clickableToShowAll || this.goingToItem) return;
    this.drawerVisible = true;
  }

  recalc(data: { [key: string]: any }) {
    if (data) {
      this.allItems = {};
      this.mainItems = [];
      for (const mk of this.mainFields) {
        const item = this.getItem(data, mk);
        this.mainItems.push(item);
      }
      for (const k of Object.keys(data)) {
        const item: Item = this.getItem(data, k);
        if (!this.allItems[item.section]) {
          this.allItems[item.section] = [];
        }
        this.allItems[item.section].push(item);
        if (k === this.mainField) {
          this.mainItem = item;
        }
      }
      this.allItems[this.translate.instant('COMMON_SERVICE.SECTIONS.MAIN_SECTION')] = this.mainItems;
      this.sections = Object.keys(this.allItems);
      if (!this.mainItem) {
        this.mainItem = this.allItems[this.translate.instant('COMMON_SERVICE.SECTIONS.ENTITY')].find(i => i.label === "ID")
      };

    }
  }

  getItem(data: any, key: string): Item {
    const item: Item = clone(this.knowItems[key]) || { label: key };
    item.key = item.key || key;
    item.value = data[key];
    item.section = item.section || this.translate.instant('COMMON_SERVICE.SECTIONS.OTHER');
    item.icon = item.icon || UNKNOWN_ICON;
   
    return item;
  }

closeDrawer() {
  this.drawerVisible = false;
}

goToItem() {
  this.goingToItem = true;
  this.goToItemDetail.emit();
}

ngOnDestroy(): void {
  if(this.langChangeSubscription) {
  this.langChangeSubscription.unsubscribe();
}
  }
}

<app-block-header title="{{'SESSION.DETAIL.METER_VALUE'|translate}}" icon="barcode" [isRefreshActive]="isRefreshActive"
  (refreshRequest)="refreshMeterValue()">
  <form [formGroup]="form" *ngIf="meterValues?.length>0" class="yf-default-form">
    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
  </form>
</app-block-header>
<div *ngIf="meterValues?.length>0; else noDati">
  <div class="mega-block read-only-details" id="table-status">
    <nz-table class="table-mvalues" nzTemplateMode nzSize="small" [nzData]="filteredMeterValues"
      [nzScroll]="{y: '260px', x: '250px'}">
      <thead>
        <tr>
          <th></th>
          <th>Phase </th>
          <th>Value </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of filteredMeterValues">
          <td>{{item.createdAt | localizedDate:"mediumTime"}}</td>
          <td>{{item.phase}}</td>
          <td><b>{{item.value | number: '1.1-1'}}</b> {{item.unit}}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
<ng-template #noDati>
  <nz-empty nzNotFoundImage="simple"></nz-empty>
</ng-template>
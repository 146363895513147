<app-block-header [title]="'GATEWAY.DETAIL.GATEWAY_STATIONS' | translate"
                  [isRefreshActive]="isRefreshActive"
                  icon="usb"
                  (refreshRequest)="sendEvent()">
</app-block-header>
<div *ngIf="stations.length>0; else noDati">
  <div class="big-table read-only-details" >
    <nz-table nzTemplateMode
              nzSize="small"
              id="table-status">
      <thead>
        <tr>
          <th>{{'GATEWAY.DETAIL.NAME_NO_PERSON'|translate}}</th>
          <th>{{'GATEWAY.DETAIL.STATE'|translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of stations"
            routerLink="/authenticated/stations/{{data.id}}" class="yf-pointer">
          <td>
            <app-entity-tag [entity]="data"
                            entityType="station">
            </app-entity-tag>
          </td>
          <td>
            <app-entity-tag [entity]="data.state"
                            entityType="state">
            </app-entity-tag>
          </td>
        </tr>
    </nz-table>
  </div>
</div>
<ng-template #noDati>
  <app-empty-data>
  </app-empty-data>
</ng-template>

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CU, ISessionUserDTO, RecordCounted } from 'common_library';
import { BehaviorSubject } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { SessionService } from 'src/app/services/entities/session.service';
export interface ISessionBlockConfig {
  entityType: string;
  connector: boolean;
  routing: boolean;
}
@Component({
  selector: 'app-block-sessions-list',
  templateUrl: './block-sessions-list.component.html',
  styleUrls: ['./block-sessions-list.component.scss']
})
export class BlockSessionsListComponent implements OnChanges, OnInit {
  _utilityFuncs = CU

  @Input() userId: string;
  @Input() stationId: string;
  @Input() isRefreshActive: boolean = false;
  @Input() config: ISessionBlockConfig;

  sessions: ISessionUserDTO[] = [];
  loading: boolean;
  pageIndex$ = new BehaviorSubject<number>(1);
  sessionRecords: RecordCounted<ISessionUserDTO>;

  constructor(public AS: AppService, private sessionService: SessionService) { }

  ngOnInit(): void {
    this.pageIndex$.subscribe(async page => {
      this.loading = true;
      await this.getItem(page);
      this.loading = false;
    })
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.stationId?.currentValue || changes.userId?.currentValue) {
      this.pageIndex$.next(1);
    }
  }

  async getItem(pageIndex?: number) {
    if (this.userId) {
      this.sessionRecords = await this.sessionService.getSessionAdminByUserIdPaginated(this.userId, pageIndex);
    } else if (this.stationId) {
      this.sessionRecords = await this.sessionService.getSessionAdminByStationIdPaginated(this.stationId, pageIndex);
    }
  }

  async refreshItem() {
    this.pageIndex$.next(1);
  }
}

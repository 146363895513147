<app-block-header title="{{'STATIONS.DETAIL.POD_DETAIL'|translate}}" icon="one-to-one" [isRefreshActive]="isRefreshActive"
  (refreshRequest)="refreshItem()">
</app-block-header>
<app-skeleton [loading]="loading">
  <ng-container *ngIf="pod; else noDati">
    <app-block-values [data]="pod" (goToItemDetail)="goToPodDetail()"
      [mainFields]="['serial', 'availablePower', 'energyManagement', 'masterStation']"></app-block-values>
  </ng-container>
  <ng-template #noDati>
    <app-empty-data></app-empty-data>
  </ng-template>
</app-skeleton>
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IRfid } from 'common_library';
import { Subscription } from 'rxjs';
import { HttpIoService } from 'src/app/services/communication/http-io.service';
import { UserService } from 'src/app/services/entities/user.service';

@Component({
  selector: 'app-block-rfid-list',
  templateUrl: './block-rfid-list.component.html',
  styleUrls: ['./block-rfid-list.component.scss']
})
export class BlockRfidListComponent implements OnInit, OnDestroy {
  @Input() rfids: IRfid[] = [];
  @Input() userId: string;
  @Input() isRefreshActive: boolean = false;
  @Output() refreshButtonClicked = new EventEmitter<any>()

  sub: Subscription;
  loading: boolean;

  constructor(private userService: UserService, private HIO: HttpIoService, private translate: TranslateService) {
/*     this.sub = this.userService.selectedRfid$.subscribe(async (r) => {
      await this.refreshRfids();
    }) */
  }

  getTooltipTitleValid(data: IRfid) : string {
    return data.valid
    ? this.translate.instant('BLOCK.RFID.RFID_ENABLED')
    : this.translate.instant('BLOCK.RFID.RFID_DISABLED')
  }

  getTooltipTitleDeleted(data: IRfid) : string {
    return data.valid
    ? this.translate.instant('BLOCK.RFID.DELETED')
    : this.translate.instant('BLOCK.RFID.NOT_DELETED')
  }

  async ngOnInit(): Promise<void> {
    await this.getRfids();
  }

  ngOnDestroy(): void {
   // this.sub.unsubscribe();
  }

  async refreshRfids() {
    await this.getRfids();
  }

  selectRfid(rfid: IRfid) {
    this.userService.selectedRfid$.next(rfid)
  }

  async getRfids() {
    this.loading = true;
    if (this.userId) {
      this.rfids = await this.HIO.get<IRfid[]>(`rfid/admin/user-rfids/${this.userId}`);
    } else {
      this.refreshButtonClicked.emit();
    }
    this.loading = false;
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ocpi-metadata',
  templateUrl: './ocpi-metadata.component.html',
  styleUrls: ['./ocpi-metadata.component.scss']
})
export class OcpiMetadataComponent {

  @Input() metadata;
  @Input() maxHeight = '350px';

  constructor() { }

  _typeOf(value) {
    if (Array.isArray(value)) return 'array';
    if (!value) return null;
    return typeof value;
  }
}

import { IconsProviderModule } from 'src/app/icons-provider.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { TableDataComponent } from './table-data/table-data.component';
import { AuthInterceptor } from '../services/auth/auth.interceptor';
import { RouterModule } from '@angular/router';
import { DescriptionComponent } from './description/description.component';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import { NzTransButtonModule } from 'ng-zorro-antd/core/trans-button';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzI18nModule } from 'ng-zorro-antd/i18n';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMentionModule } from 'ng-zorro-antd/mention';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzTreeViewModule } from 'ng-zorro-antd/tree-view';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzResizableModule } from 'ng-zorro-antd/resizable';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { FormlyModule } from '@ngx-formly/core';
import { BaseDetailsComponent } from './base-details/base-details.component';
import { DetailHeaderComponent } from './detail-header/detail-header.component';
import { BlockValueComponent } from './block-value/block-value.component';
import { BlockHeaderComponent } from './blocks/components/block-header/block-header.component';
import { ListEntityComponent } from './list-entity/list-entity.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ListVersionsComponent } from './list-versions/list-versions.component';
import { EntityTagComponent } from './entity-tag/entity-tag.component';
import { CopyValueComponent } from './copy-value/copy-value.component';
import { ListFeaturesComponent } from './list-features/list-features.component';
import { BlockConnectorsComponent } from './blocks/block-connectors/block-connectors.component';
import { GroupConfigDetailsComponent } from './group-config-details/group-config-details.component';
import { BlockMeterValuesComponent } from './blocks/block-meter-values/block-meter-values.component';
import { BlockSessionsComponent } from './blocks/block-sessions/block-sessions.component';
import { EmptyDataComponent } from './blocks/components/empty-data/empty-data.component';
import { BlockUsersComponent } from './blocks/block-users/block-users.component';
import { BlockStationsComponent } from './blocks/block-stations/block-stations.component';
import { BlockStopTransactionsComponent } from './blocks/block-stop-transactions/block-stop-transactions.component';
import { BlockStatusNotificationsComponent } from './blocks/block-status-notifications/block-status-notifications.component';
import { BlockTroubleshootinComponent } from './blocks/block-troubleshooting/block-troubleshooting.component';
import { BlockPodComponent } from './blocks/block-pod/block-pod.component';
import { BlockGroupsRelatedComponent } from './blocks/block-groups-related/block-groups-related.component';
import { BlockSessionsListComponent } from './blocks/block-sessions-list/block-sessions-list.component';
import { FieldAutocomplete } from './custom-field-formly/field-autocomplete/field-autocomplete.component';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { BlockGatewayComponent } from './blocks/block-gateway/block-gateway.component';
import { BlockStationsMiniListComponent } from './blocks/block-stations-mini-list/block-stations-mini-list.component';
import { BlockMenuCdcComponent } from './blocks/block-menu-cdc/block-menu-cdc.component';
import { BlockLogComponent } from './blocks/block-log/block-log.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { BlockPodListComponent } from './blocks/block-pod-list/block-pod-list.component';
import { BlockFormPodComponent } from './blocks/block-form-pod/block-form-pod.component';
import { BlockUpTimeListComponent } from './blocks/block-up-time-list/block-up-time-list.component';
import { BlockFormStationComponent } from './blocks/block-form-station/block-form-station.component';
import { TimeSpanComponent } from './timespan/timespan.component';
import { BlockClientListComponent } from './blocks/block-client-list/block-client-list.component';
import { BlockRfidComponent } from './blocks/block-rfid/block-rfid.component';
import { BlockRfidListComponent } from './blocks/block-rfid-list/block-rfid-list.component';
import { BlockNotificationListComponent } from './blocks/block-notification-list/block-notification-list.component';
import { StatusIconComponent } from './status-icon/status-icon.component';
import { OcpiMetadataComponent } from './ocpi-metadata/ocpi-metadata.component';
import { InfoPreviewComponent } from './info-preview/info-preview.component';
import { QuillComponent } from './quill/quill.component';
import { QuillModule } from 'ngx-quill';
import { YflFieldFileComponent } from './yfl-field-file/yfl-field-file.component';
import { FileValueAccessor } from './yfl-field-file/file-value-accessor';
import { BlockTokenListComponent } from './blocks/block-token-list/block-token-list.component';
import { TariffSimulatorComponent } from './blocks/tariff-simulator/tariff-simulator.component';
import { BlockTariffComponent } from './blocks/block-tariff/block-tariff.component';
import { TariffAutoComplete } from './custom-field-formly/tariff-autocomplete.component';
import { BlockCdrComponent } from './blocks/block-cdr/block-cdr.component';
import { BlockObjViewerComponent } from './blocks/block-obj-viewer/block-obj-viewer.component';
import { BackofficePipesModule } from '../pipes/backoffice-pipes.module';
import { BlockCrsComponent } from './blocks/block-crs/block-crs.component';
import { DrawerComponent } from './drawer/drawer.component';
import { InfoNewSectionComponent } from './info-new-section/info-new-section.component';
import { BlockValuesComponent } from './block-values/block-values.component';
import { BlockConnectorsFormComponent } from './blocks/block-connectors-form/block-connectors-form.component';
import { BlockTariffRevenueComponent } from './blocks/block-tariff-revenue/block-tariff-revenue.component';
import { PipesModule } from 'yf-lib';
import { ItemValueComponent } from './item-value.component';
import { BlockPaymentComponent } from './blocks/block-payment/block-payment.component';
import { BlockStationCommandsComponent } from './blocks/block-station-commands/block-station-commands.component';
import { BlockBankTransactionListComponent } from './blocks/block-bank-transaction-list/block-bank-transaction-list.component';
import { MapComponent } from './map/map.component';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { BlockInstallationReadonlyComponent } from './blocks/block-installation-readonly/block-installation-readonly.component';
import { StationConnectorsComponent } from './station-connectors/station-connectors.component';
import { MapInstallationDetailComponent } from './map-installation-detail/map-installation-detail.component';
import { InstallationClustersComponent } from './installation-clusters/installation-clusters.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { BlockGestoriComponent } from './blocks/block-gestori/block-gestori.component';
import { GestoreEntityLinkComponent } from './gestore-entity-link/gestore-entity-link.component';
import { MultiSelectFormlyCustom } from './multiselect-formly-custom/multiselect-formly-custom.component';
import { BlockInvoiceComponent } from './blocks/block-invoice/block-invoice.component';
import { BlockProventiListComponent } from './blocks/block-proventi-list/block-proventi-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { ImpersonificateComponent } from './impersonificate/impersonificate.component';
import { ExportComponent } from './reports/export/export.component';
import { ExportFormComponent } from './reports/export-form/export-form.component';
import { AgentsMonitorComponent } from './reports/monitor/agents-monitor.component';
import { QueryFormComponent } from './reports/query-form/query-form.component';
import { EntitySelectComponent } from './reports/entity-selector/entity-select/entity-select.component';
import { EntitySelectionComponent } from './reports/entity-selector/entity-selection/entity-selection.component';
import { RepeatSectionTypeComponent } from './custom-field-formly/repeat-section/repeat-section.type';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { SimpleTableComponent } from './simple-table/simple-table.component';
import { FormlySwitchComponent } from './custom-field-formly/switch/formly-switch.component';
import { RepeatComponent } from './custom-field-formly/repeat/repeat.component';
import { FormlyAntIconSelectComponent } from './custom-field-formly/ant-icon-select/formly-ant-icon-select.component';

import { NewExportComponent } from './reports/new-export/new-export.component';
import { ExportArchiveComponent } from './reports/export-archive/export-archive.component';

const blocks = [
  BlockMenuCdcComponent,
  BlockFormPodComponent,
  BlockValueComponent,
  BlockValuesComponent,
  BlockHeaderComponent,
  BlockConnectorsComponent,
  BlockMeterValuesComponent,
  BlockSessionsComponent,
  BlockUsersComponent,
  BlockStationsComponent,
  BlockStopTransactionsComponent,
  BlockStatusNotificationsComponent,
  BlockTroubleshootinComponent,
  BlockPodComponent,
  BlockClientListComponent,
  BlockSessionsListComponent,
  BlockGatewayComponent,
  BlockStationsMiniListComponent,
  BlockLogComponent,
  BlockPodListComponent,
  BlockGroupsRelatedComponent,
  BlockUpTimeListComponent,
  BlockFormStationComponent,
  BlockRfidComponent,
  BlockRfidListComponent,
  BlockNotificationListComponent,
  BlockTokenListComponent,
  BlockCdrComponent,
  BlockObjViewerComponent,
  BlockTariffComponent,
  BlockTariffRevenueComponent,
  BlockCrsComponent,
  BlockConnectorsFormComponent,
  BlockPaymentComponent,
  BlockStationCommandsComponent,
  BlockBankTransactionListComponent,
  BlockProventiListComponent,
  BlockGestoriComponent,
  BlockInvoiceComponent,
  ImpersonificateComponent,
]

const components = [
  ItemValueComponent,
  TableDataComponent,
  DescriptionComponent,
  SearchBarComponent,
  BaseDetailsComponent,
  DetailHeaderComponent,
  ListEntityComponent,
  DatePickerComponent,
  ListVersionsComponent,
  EntityTagComponent,
  CopyValueComponent,
  ListFeaturesComponent,
  GroupConfigDetailsComponent,
  EmptyDataComponent,
  SpinnerComponent,
  TimeSpanComponent,
  StatusIconComponent,
  OcpiMetadataComponent,
  InfoPreviewComponent,
  QuillComponent,
  YflFieldFileComponent,
  FileValueAccessor,
  TariffSimulatorComponent,
  TariffAutoComplete,
  TariffAutoComplete,
  DrawerComponent,
  MapComponent,
  BlockInstallationReadonlyComponent,
  StationConnectorsComponent,
  MapInstallationDetailComponent,
  InfoNewSectionComponent,
  InstallationClustersComponent,
  SkeletonComponent,
  GestoreEntityLinkComponent,
  MultiSelectFormlyCustom,
  ExportComponent,
  ExportFormComponent,
  AgentsMonitorComponent,
  QueryFormComponent,
  EntitySelectComponent,
  EntitySelectionComponent,
  RepeatSectionTypeComponent,
  SimpleTableComponent,
  ExportFormComponent,
  FormlySwitchComponent,
  RepeatComponent,
  NewExportComponent,
  ExportArchiveComponent
];

export const ngZorro = [
  NzLayoutModule,
  NzCardModule,
  NzIconModule,
  NzDescriptionsModule,
  NzPaginationModule,
  NzFormModule,
  NzInputModule,
  NzNotificationModule,
  NzTableModule,
  NzDividerModule,
  NzCardModule,
  NzAffixModule,
  NzAlertModule,
  NzAnchorModule,
  NzAutocompleteModule,
  NzAvatarModule,
  NzBackTopModule,
  NzBadgeModule,
  NzButtonModule,
  NzBreadCrumbModule,
  NzCalendarModule,
  NzCardModule,
  NzCarouselModule,
  NzCascaderModule,
  NzCheckboxModule,
  NzCollapseModule,
  NzCommentModule,
  NzDatePickerModule,
  NzDescriptionsModule,
  NzDividerModule,
  NzDrawerModule,
  NzDropDownModule,
  NzEmptyModule,
  NzFormModule,
  NzGridModule,
  NzI18nModule,
  NzIconModule,
  NzImageModule,
  NzInputModule,
  NzInputNumberModule,
  NzLayoutModule,
  NzListModule,
  NzMentionModule,
  NzMenuModule,
  NzMessageModule,
  NzModalModule,
  NzNoAnimationModule,
  NzNotificationModule,
  NzPageHeaderModule,
  NzPaginationModule,
  NzPopconfirmModule,
  NzPopoverModule,
  NzProgressModule,
  NzRadioModule,
  NzRateModule,
  NzResultModule,
  NzSegmentedModule,
  NzSelectModule,
  NzSkeletonModule,
  NzSliderModule,
  NzSpaceModule,
  NzSpinModule,
  NzStatisticModule,
  NzStepsModule,
  NzSwitchModule,
  NzTableModule,
  NzTabsModule,
  NzTagModule,
  NzTimePickerModule,
  NzTimelineModule,
  NzToolTipModule,
  NzTransButtonModule,
  NzTransferModule,
  NzTreeModule,
  NzTreeViewModule,
  NzTreeSelectModule,
  NzTypographyModule,
  NzUploadModule,
  NzWaveModule,
  NzResizableModule,
  NzPipesModule,
  FormlyNgZorroAntdModule,
  FormlySelectModule,
];

const customField = [
  FieldAutocomplete,
  FormlyAntIconSelectComponent
]
@NgModule({ declarations: [components, blocks, customField],
    exports: [
        ngZorro,
        ...components,
        ...blocks,
        ...customField,
        TranslateModule
    ], imports: [FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        FormlyModule.forRoot({
            types: [
                { name: 'nz-autocomplete-tariff', component: TariffAutoComplete },
                { name: 'repeat-section', component: RepeatSectionTypeComponent },
                { name: 'switch', component: FormlySwitchComponent },
                { name: 'repeat', component: RepeatComponent },
                { name: 'formly-ant-icon-select', component: FormlyAntIconSelectComponent },
            ],
        }),
        ...ngZorro,
        IconsProviderModule,
        PipesModule,
        BackofficePipesModule,
        QuillModule,
        NgxMapLibreGLModule,
        TranslateModule], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        KeyValuePipe,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class ComponentsModule { }

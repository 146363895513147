import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedComponent } from './authenticated.component';

const routes: Routes = [
  {
    path: '',
    component: AuthenticatedComponent,
    children: [
      {
        path: 'welcome',
        loadChildren: () =>
          import('../welcome/welcome.module').then((m) => m.WelcomeModule),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('../users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'users/new-user', loadChildren: () =>
          import('../users/user-new/new-user.module').then((m) => m.NewUserModule),
      },
      {
        path: 'users/:userId', loadChildren: () =>
          import('../users/user-detail/user-detail.module').then((m) => m.UserDetailModule),
      },
      {
        path: 'installations',
        loadChildren: () =>
          import('../installations/installations.module').then((m) => m.InstallationsModule),
      },
      {
        path: 'installations/:id',
        loadChildren: () =>
          import('../installations/installation-detail/installation-detail.module').then((m) => m.InstallationDetailModule),
      },
      {
        path: 'stations',
        loadChildren: () =>
          import('../stations/stations.module').then((m) => m.StationsModule),
      },
      {
        path: 'stations/:stationId',
        loadChildren: () =>
          import('../stations/station-detail/station-detail.module').then((m) => m.StationDetailModule),
      },
      {
        path: 'pods',
        loadChildren: () =>
          import('../pods/pods.module').then((m) => m.PodsModule),
      },
      {
        path: 'pods/:id',
        loadChildren: () =>
          import('../pods/pod-detail/pod-detail.module').then((m) => m.PodDetailModule),
      },
      {
        path: 'sessions',
        loadChildren: () =>
          import('../sessions/sessions.module').then((m) => m.SessionsModule),
      },
      {
        path: 'groups',
        loadChildren: () =>
          import('../groups/groups.module').then((m) => m.GroupsModule),
      },
      {
        path: 'groups/editor/:groupId',
        loadChildren: () =>
          import('../groups/group-detail/group-detail.module').then((m) => m.GroupDetailModule),
      },
      {
        path: 'sessions/:sessionId',
        loadChildren: () =>
          import('../sessions/session-detail/session-detail.module').then((m) => m.SessionDetailModule),
      },
      {
        path: 'about/legenda',
        loadChildren: () => import('../about/legenda/legenda.module').then((m) => m.LegendaModule)
      },
      {
        path: 'about/versions',
        loadChildren: () => import('../about/versions/versions.module').then((m) => m.VersionsModule)
      },
      {
        path: 'gateway',
        loadChildren: () => import('../gateway/gateway.module').then((m) => m.GatewayModule)
      },
      {
        path: 'gateway/:gatewayId',
        loadChildren: () => import('../gateway/gateway-detail/gateway-detail.module').then((m) => m.GatewayDetailModule)
      },
      {
        path: 'tariffs',
        loadChildren: () =>
          import('../tariffs/tariffs.module').then((m) => m.TariffsModule),
      },
      {
        path: 'tariffs/editor/:id',
        loadChildren: () =>
          import('../tariffs/tariff-detail/tariff-detail.module').then((m) => m.TariffDetailModule),
      },
      {
        path: 'logs',
        loadChildren: () => import('../log/log.module').then((m) => m.LogModule)
      },
      {
        path: 'info-section',
        loadChildren: () => import('../info/info-section/info-section.module').then((m) => m.InfoSectionModule)
      },
      {
        path: 'info-section/:infoSectionId',
        loadChildren: () => import('../info/info-section/info-section-detail/info-section-detail.module').then((m) => m.InfoSectionDetailModule)
      },
      {
        path: 'info-chapter',
        loadChildren: () => import('../info/info-chapter/info-chapter.module').then((m) => m.InfoChapterModule)
      },
      {
        path: 'info-chapter/:infoChapterId',
        loadChildren: () => import('../info/info-chapter/info-chapter-detail/info-chapter-detail.module').then((m) => m.InfoChapterDetailModule)
      },
      {
        path: 'info-content',
        loadChildren: () => import('../info/info-content/info-content.module').then((m) => m.InfoContentModule)
      },
      {
        path: 'info-multimedia',
        loadChildren: () => import('../info/info-multimedia/info-multimedia.module').then((m) => m.InfoMultimediaModule)
      },
      {
        path: 'info-multimedia/:infoMultimediaId',
        loadChildren: () => import('../info/info-multimedia/info-multimedia-detail/info-multimedia-detail.module').then((m) => m.InfoMultimediaDetailModule)
      },
      {
        path: 'reports-query',
        loadChildren: () => import('../reports/query/query.module').then((m) => m.QueryModule)
      },
      {
        path: 'reports-query/:queryId',
        loadChildren: () => import('../reports/query/query-detail/query-detail.module').then((m) => m.QueryDetailModule)
      },
      {
        path: 'reports-query/editor/new-query',
        loadChildren: () => import('../reports/query/new-query/query-editor-routing.module').then((m) => m.QueryEditorRoutingModule)
      },
      {
        path: 'reports-export',
        loadChildren: () => import('../reports/export-pages/export.module').then((m) => m.ExportPageModule)
      },
      // {
      //   path: 'token',
      //   loadChildren: () => import('../tokens/tokens.module').then((m) => m.TokensModule)
      // },
      // {
      //   path: 'token/:tokenId',
      //   loadChildren: () => import('../tokens/token-detail/token-detail.module').then((m) => m.TokenDetailModule)
      // },
      {
        path: 'charge-detail-record',
        loadChildren: () => import('../charge-detail-record/charge-detail-record.module').then((m) => m.ChargeDetailRecordModule)
      },
      {
        path: 'charge-detail-record/:chargeDetailId',
        loadChildren: () => import('../charge-detail-record/charge-detail-record-detail/charge-detail-record-detail.module').then((m) => m.ChargeDetailRecordDetailModule)
      },
      {
        path: 'cost-revenue-split',
        loadChildren: () => import('../cost-revenue-split/cost-revenue-split.module').then((m) => m.CostRevenueSplitModule)
      },
      {
        path: 'cost-revenue-split/:crsDetailId',
        loadChildren: () => import('../cost-revenue-split/cost-revenue-split-detail/cost-revenue-split-detail.module').then((m) => m.CostRevenueSplitDetailModule)
      },
      {
        path: 'payments',
        loadChildren: () => import('../payments/payments.module').then((m) => m.PaymentsModule)
      },
      {
        path: 'payments/:paymentId',
        loadChildren: () => import('../payments/payment-detail/payment-detail.module').then((m) => m.PaymentDetailModule)
      },
      {
        path: 'map',
        loadChildren: () => import('../app-map/app-map.module').then((m) => m.AppMapModule)
      },
      {
        path: 'gestore',
        loadChildren: () => import('../gestore/gestore.module').then((m) => m.GestoreModule)
      },
      {
        path: 'gestore/:gestoreId',
        loadChildren: () => import('../gestore/gestore-detail/gestore-detail.module').then((m) => m.GestoreDetailModule)
      },
      {
        path: 'invoice',
        loadChildren: () => import('../invoice/invoice.module').then((m) => m.InvoiceModule)
      },
      {
        path: 'invoice/:invoiceId',
        loadChildren: () => import('../invoice/invoice-detail/invoice-detail.module').then((m) => m.InvoiceDetailModule)
      },
      {
        path: 'unaccessible-resource',
        loadChildren: () => import('../unaccessible-resource/unaccessible-resource.module').then((m) => m.UnaccessibleResourceModule)
      },
      {
        path: 'notfound-resource',
        loadChildren: () => import('../notfound-resource/notfound-resource.module').then((m) => m.NotfoundResourceModule)
      },
      {
        path: 'avatarDetail',
        loadChildren: () => import('../avatar-detail/avatar-detail-routing.module').then((m) => m.AvatarDetailRoutingModule)
      },
      {
        path: '**', pathMatch: 'full', redirectTo: '/authenticated/welcome'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticatedRoutingModule { }

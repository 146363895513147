export type ValueOf<T> = T[keyof T];

export const RevenueDimension = Object.freeze({
  DEFAULT: 'DEFAULT',
  RESERVATION: 'RESERVATION',
  ENERGY: 'ENERGY',
  PARKING: 'PARKING'
});

export type RevenueDimensionValues = ValueOf<typeof RevenueDimension>;
export type RevenueDimensionKeys = keyof typeof RevenueDimension;
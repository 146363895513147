import { OnChanges } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { INotification, RecordCounted } from 'common_library';
import { BehaviorSubject } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { NotificationService } from 'src/app/services/entities/notification.service';

@Component({
  selector: 'app-block-notification-list',
  templateUrl: './block-notification-list.component.html',
  styleUrls: ['./block-notification-list.component.scss']
})
export class BlockNotificationListComponent implements OnChanges, OnInit {
  
  @Input() userId: string;
  @Input() isRefreshActive: boolean = false;

  notificationRecord: RecordCounted<INotification>;
  loading: boolean;
  pageIndex$ = new BehaviorSubject<number>(1)

  constructor(private notificationService: NotificationService, public app: AppService, private translate: TranslateService) { }

  ngOnInit(): void {
      this.pageIndex$.subscribe(async page => {
        this.loading = true;
        await this.getItem(page);
        this.loading = false;
      })
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.userId?.currentValue) {
      this.pageIndex$.next(1);
    }
  }

  async getItem(pageIndex?: number) {
    if (this.userId) {
      this.notificationRecord = await this.notificationService.getNotificationByUserPaginated(this.userId, pageIndex);
    }
  }

  async refreshItem() {
    this.pageIndex$.next(1);
  }

  getTooltipTitleReadedAt(data): string {
    return data.readedAt
      ? this.translate.instant('NZTOOLTIPTITLE.NOTIFICATION_READ')
      : this.translate.instant('NZTOOLTIPTITLE.NOTIFICATION_UNREAD');
  }
}

export type ValueOf<T> = T[keyof T];

export const OCPITariffType = Object.freeze({
  AD_HOC_PAYMENT: 'AD_HOC_PAYMENT',
  PROFILE_CHEAP: 'PROFILE_CHEAP',
  PROFILE_FAST: 'PROFILE_FAST',
  PROFILE_GREEN: 'PROFILE_GREEN',
  REGULAR: 'REGULAR'
})

export type OCPITariffTypeValues = ValueOf<typeof OCPITariffType>;
export type OCPITariffTypeKeys = keyof typeof OCPITariffType;

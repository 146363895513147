<div class="container__tipology"> 
  <div class="container-detail-value" *ngFor="let item of this.config | keyvalue">
    <span>
      <nz-avatar 
      nzIcon="{{this.dictIcon[item.key.toString()]}}"></nz-avatar>
    </span>
      <div>
        <div class="title__cardinality">
          {{ item.key.toString() | titlecase  }}
        </div>
        
        <div>
          <span class="subtitle">
            Min <b class="value">{{ item.value['min'] }} </b>
            Max <b class="value"> {{ item.value['max'] || '--' }} </b>
          </span>
        </div>
      </div>
    </div>
</div>

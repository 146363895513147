import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatHourPipe } from './format-hour.pipe';
import { PricePipe } from './price.pipe';
import { PowerPipe } from './power.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';

@NgModule({
  declarations: [FormatHourPipe, PricePipe, PowerPipe, LocalizedDatePipe],
  imports: [
    CommonModule
  ],
  exports: [FormatHourPipe, PricePipe, PowerPipe, LocalizedDatePipe]
})
export class BackofficePipesModule { }

import { NgModule } from '@angular/core';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';

import {
  MenuFoldOutline,
  MenuUnfoldOutline,
  FormOutline,
  DashboardOutline
} from '@ant-design/icons-angular/icons';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleCheck, faCircleXmark, faPlugCircleBolt, faSquare } from '@fortawesome/free-solid-svg-icons';

const faIcons = [faSquare, faPlugCircleBolt, faCircleCheck, faCircleXmark, faCircleCheck, faCircleXmark]
const icons = [MenuFoldOutline, MenuUnfoldOutline, DashboardOutline, FormOutline];

@NgModule({
  imports: [NzIconModule, FontAwesomeModule ],
  exports: [NzIconModule, FontAwesomeModule],
  providers: [
    { provide: NZ_ICONS, useValue: icons }
  ]
})
export class IconsProviderModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      ...faIcons
    );
  }
}

<div class="metadata_container" [style.max-height]="maxHeight">
  <div *ngFor="let data of metadata | keyvalue">
    <span *ngIf="_typeOf(data.value) !== 'object' && _typeOf(data.value) !== 'array' ">
      <app-block-value label="{{data.key}}" [value]="data.value"></app-block-value>
    </span>

    <span *ngIf="_typeOf(data.value) === 'object'">
      <ng-container [ngTemplateOutlet]="objectPrinterTemplate" [ngTemplateOutletContext]="{$implicit: data}"></ng-container>
    </span>

    <span *ngIf="_typeOf(data.value) === 'array'">
      <ng-container [ngTemplateOutlet]="arrayOfObjectPrinterTemplate" [ngTemplateOutletContext]="{$implicit: data}"></ng-container>
    </span>
  </div>
</div>

<ng-template #objectPrinterTemplate let-data>
  <small>{{data.key}} : </small>
  <ul>
    <li *ngFor="let objectToPrint of data.value | keyvalue">
      <app-block-value *ngIf="_typeOf(objectToPrint.value) !== 'array' && _typeOf(objectToPrint.value) !== 'object'" label="{{objectToPrint.key}}" [value]="objectToPrint.value"></app-block-value>
      <ng-container *ngIf="_typeOf(objectToPrint.value) === 'array' && _typeOf(objectToPrint.value[0]) === 'object'" [ngTemplateOutlet]="arrayOfObjectPrinterTemplate" [ngTemplateOutletContext]="{$implicit: objectToPrint}"></ng-container>
      <ng-container *ngIf="_typeOf(objectToPrint.value) === 'array' && _typeOf(objectToPrint.value[0]) !== 'object'" [ngTemplateOutlet]="arrayPrinterTemplate" [ngTemplateOutletContext]="{$implicit: objectToPrint}"></ng-container>
      <ng-container *ngIf="_typeOf(objectToPrint.value) === 'object'" [ngTemplateOutlet]="objectPrinterTemplate" [ngTemplateOutletContext]="{$implicit: objectToPrint}"></ng-container>
    </li>
  </ul>
</ng-template>

<ng-template #arrayPrinterTemplate let-data>
  <small>{{data.key}} : </small>
  
  <ul>
    <li *ngFor="let arrayElement of data.value | keyvalue" >
      <app-block-value [value]="arrayElement.value"></app-block-value>
    </li>
  </ul>
</ng-template>

<ng-template #arrayOfObjectPrinterTemplate let-data>
  <small>{{data.key}} :</small>
  <ul>
    <li class="object-of-array" *ngFor="let print of data.value | keyvalue">
      <span *ngIf="_typeOf(print.value) === 'object'">
        <ng-container [ngTemplateOutlet]="objectPrinterTemplate" [ngTemplateOutletContext]="{$implicit: print}"></ng-container>
      </span>
    </li>
  </ul>
</ng-template>

<nz-layout class="layout">
  <nz-header class="header header-flex">
    <div class="sidebar-logo">
      <img src="/assets/logos/logo_bianco_yourfill.svg" class="sidebar-logo-img" />
    </div>
    <div class="header-title">{{brandName}}</div>
  </nz-header>
  <nz-content>
    <nz-page-header class="site-page-header" [nzTitle]="docTitle"></nz-page-header>
    <div *ngIf="chapterToShow else noData" class="inner-content">
      <div *ngFor="let section of chapterToShow.infoSections">
        <div class="mt-10 section-title" *ngIf="section.title">{{ section.title }}</div>
        <div class="mt-4 section-subtitle" *ngIf="section.subtitle">{{ section.subtitle }}</div>
        <div class="mt-10 section-img" *ngIf="section.multimedia">
          <img width="auto" height="auto"
            [src]="multimediaService.getImageUrl(section?.multimedia?.name + '.' + section?.multimedia?.fileExtension)" />
        </div>
        <div class="mt-10" *ngIf="section.html" [innerHTML]="section.html"></div>
      </div>
    </div>
    <ng-template #noData>
      <div class="inner-content">
        Nessun documento trovato
      </div>
    </ng-template>
  </nz-content>
  <nz-footer class="header">
    <div><span class="mr-10">{{brandName}}</span><span class="mr-10">{{brandVAT}}</span><span>{{brandEmail}}</span>
    </div>
  </nz-footer>
</nz-layout>
import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';
import { ICostRevenueSplit, RecordCounted, RevenueEntity } from 'common_library';
import { HttpIoService } from '../communication/http-io.service';
import { Observable } from 'rxjs';

const CONTROLLER_ROOT = "cost-revenue-split";


@Injectable({
  providedIn: 'root'
})
export class CostRevenueSplitService {

  constructor(private commonService: CommonService, private HIO: HttpIoService) { }

  getCDRFilteredAndPaginated(page?: number, search?: string): Observable<RecordCounted<ICostRevenueSplit>> {
    return this.commonService.getAndCount$({
      controllerRoot: CONTROLLER_ROOT,
      controllerDir: 'admin/filter-and-paginate',
      page: page,
      search: search      
    })
  }

  async getOne(id: number): Promise<ICostRevenueSplit> {
    try {
      return this.HIO.get(`${CONTROLLER_ROOT}/one/${id}`);
    } catch (error) {
      console.error('🐸: error -> ', error);
    }
  }

  async getCrsAdminBlockBySessionId(sessionId: string): Promise<ICostRevenueSplit[]> {
    try {
      return this.HIO.get(`${CONTROLLER_ROOT}/admin/block/by-session/${sessionId}`);
    } catch (error) {
      console.error("🐸, error: ", error);
    }
  }
}

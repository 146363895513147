<app-block-header title="{{'SESSION.DETAIL.CDR_DETAIL'|translate}}" icon="area-chart" [isRefreshActive]="false"></app-block-header>
<div *ngIf="(cdr$|async) as cdr; else noDati" class="block read-only-details">
  <app-skeleton [loading]="loading">
    <app-block-values [data]="cdr" (goToItemDetail)="goToCdrDetail()"
      [mainFields]="['startDateTime', 'endDateTime', 'totalEnergy', 'session', 'connectorId', 'cdrState']"></app-block-values>
  </app-skeleton>
  <ng-container *ngFor="let item of metadata$|async">
    <app-ocpi-metadata [metadata]="item"></app-ocpi-metadata>
  </ng-container>
</div>
<ng-template #noDati>
  <app-empty-data>
  </app-empty-data>
</ng-template>
<app-block-header [isRefreshActive]="false" title="{{'SESSION.DETAIL.RATE'|translate}}" icon="euro-circle"
  class="tariff__header"></app-block-header>

<app-block-values *ngIf="tariff; else noDati" [data]="tariff" (goToItemDetail)="goToTariffDetail()"
  [mainFields]="['name','currency','version']" [clickableToShowAll]="false">
</app-block-values>

<div *ngIf="tariff; else noDati" class="block read-only-details">
  <div *ngIf="tariff.tariffAltText">
    <app-block-value type="text" label="Tariff Alt text" icon="arrow-down"></app-block-value>
    <ul>
      <app-ocpi-metadata maxHeight="unset" [metadata]="tariff.tariffAltText"></app-ocpi-metadata>
    </ul>
  </div>
</div>
<ng-template #noDati><app-empty-data></app-empty-data></ng-template>
<nz-list nzItemLayout="vertical">
  <ng-container *ngFor="let item of connectors">
    <div class="list-container">
      <div class="state-bar" [ngStyle]="getColorByState(item.state)">
        <!-- BARRA -->
      </div>
      <nz-list-item class="list-item">
        <nz-list-item-meta [nzAvatar]="avatar" [nzTitle]="title" [nzDescription]="description"> </nz-list-item-meta>
        <ng-container *ngIf="!!item?.session">
          <nz-divider></nz-divider>
          <div class="session">
            <div class="details">
              <div class="name">
                <span>{{item.session.user.name + ' ' + item.session.user.surname}}</span>
              </div>
              <div class="meters">
                <span class="value">{{item.session.energy | energy}} kWh</span>
                <span class="spacchino">/</span>
                <span class="value">{{item.session.totalCost | payment}}</span>
                <span class="spacchino">/</span>
                <span class="value">
                  <app-timespan [fromDate]="item.session.createdAt" [toDate]="now()"></app-timespan>
                </span>
              </div>
            </div>
            <div class="button">
              <span class="icon" nz-icon nzType="close-circle" nzTheme="outline" nzTooltipTitle="{{'NZTOOLTIPTITLE.CLOSES_SESSION'|translate}}"
                nzTooltipPlacement="top" nz-tooltip nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.SESSION_WILL_BE_CLOSED'|translate}}"
                (nzOnConfirm)="stopSession(item)" nzPopconfirmPlacement="top" nzOkText="{{'PHRASE.CONFIRM'|translate}}"></span>
            </div>
          </div>
        </ng-container>

        <nz-list-item-extra>
          <!-- <a (click)="unlockConnector(item)"><div nz-icon nzType="unlock" nzTheme="outline" class="icon"></div></a> -->
        </nz-list-item-extra>
        <ng-template #avatar>
          <!-- <app-entity-tag [entityType]="'stateonly'" [entity]="item.state"></app-entity-tag> -->
          <img [src]="item.avatar" alt="Immagine del connettore">
        </ng-template>
        <ng-template #title>
          <h4 [routerLink]="item.href" class="yf-pointer">{{ getTitle(item) }}</h4>
        </ng-template>
        <ng-template #description>
          <div class="description-container yf-flex yf-flex-space-between pr-16">
            <div class="description-innerstate">
              {{ item.innerState }}
            </div>
            <div class="description-button">
              <span class="icon" nz-icon nzType="unlock" nzTheme="outline" nzTooltipTitle="{{'BUTTONS.UNLOCK_CONNECTOR'|translate}}"
                nzTooltipPlacement="top" nz-tooltip nz-popconfirm
                nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM_CONNECTOR_UNLOCK'|translate}}" (nzOnConfirm)="unlockConnector(item)"
                nzPopconfirmPlacement="top" nzOkText="{{'PHRASE.CONFIRM'|translate}}"></span>
            </div>
          </div>
        </ng-template>
      </nz-list-item>
    </div>
  </ng-container>
</nz-list>
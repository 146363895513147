import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { TranslateConfigService } from 'src/app/services/translateConfig.service';

export interface Item {
  label?: string;
  icon?: string;
  type?: ItemType;
  um?: string;
  key?: string;
}

export type ItemType = "date" | "number" | "text" | "entity" | "none" | "currency" | "energy" | "power" | "content" | "powerLimit" | "tag" | "online" | "installation" | "timespan" | "list-entity" | "id-clipboard";

@Component({
  selector: 'app-block-value',
  templateUrl: './block-value.component.html',
  styleUrls: ['./block-value.component.scss']
})
export class BlockValueComponent implements OnInit, OnChanges {

  @Input() itemKey;
  @Input() label: string;
  @Input() value: any;

  @Input() icon?: string;
  @Input() type?: ItemType;
  @Input() um?: string;
  @Input() state?;
  @Input() mainDate?;
  @Input() isSmall = false;

  _item: Item;
  _type: ItemType;
  _label: string;
  _icon: string;
  _um: string;
  _itemKey: string;

  regexIDv4 = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

  knowItems = {};

  private langChangeSubscription: Subscription;

  constructor(
    private commonService: CommonService,
    public translate: TranslateService,
    private translateConfigService: TranslateConfigService
  ) {
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.updateComponent();
    });
  }

  ngOnInit() {
    this.knowItems = this.translateConfigService.getKnownItemsSection();
    this.updateComponent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemKey || changes.label || changes.value || changes.icon || changes.type || changes.um || changes.state || changes.mainDate || changes.isSmall) {
      this.updateComponent();
    }
  }

  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  private updateComponent() {
    this._item = this.knowItems[this.itemKey];
    this._type = this.type || this._item?.type || this.commonService.isTypeDate(this.value) || "text";
    this._label = this.label || this._item?.label || this.itemKey;
    this._icon = this.icon || this._item?.icon || "tag";
    this._um = this.um || this._item?.um;
    this._itemKey = this._item?.key || this.itemKey;
    if (this._type === 'text' && this.regexIDv4.test(this.value)) {
      this._type = 'id-clipboard';
    }
  }
}

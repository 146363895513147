export type ValueOf<T> = T[keyof T];

export const DAY_OF_WEEk = Object.freeze({
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THURSDAY: "THURSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
  SUNDAY: "SUNDAY"
})

export type DayOfWeekValues = ValueOf<typeof DAY_OF_WEEk>;
export type DayOFWeekKeys = keyof typeof DAY_OF_WEEk;
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IGroup } from 'common_library';
import { GroupService } from 'src/app/services/entities/group.service';

@Component({
  selector: 'app-block-groups-related',
  templateUrl: './block-groups-related.component.html',
  styleUrls: ['./block-groups-related.component.scss']
})
export class BlockGroupsRelatedComponent implements OnInit {
  @Input() entityId: string;
  @Input() entityType: string;

  groups: IGroup[] = []
  constructor(private groupService: GroupService, private translate: TranslateService) {
  }

  getTooltipTitleValid (item) : string {
    return item.valid 
    ? this.translate.instant('BLOCK.GROUPS_RELATED.VALID_GROUP')
    : this.translate.instant('BLOCK.GROUPS_RELATED.INVALID_GROUP')
  }
  

  async ngOnInit(): Promise<void> {
    await this.getGroups();
  }

  async getGroups() {
    if (!this.entityId && !this.entityType) return;
    const groups = await this.groupService.getGroupsByEntityId(this.entityId, this.entityType);
    this.groups = groups.sort((a, b) => a.group_type?.name?.localeCompare(b.group_type?.name));    
  }

  async refreshGroups() {
    await this.getGroups();
  }
}

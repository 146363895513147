import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GatewayService } from 'src/app/services/entities/gateway.service';

@Component({
  selector: 'app-block-gateway',
  templateUrl: './block-gateway.component.html',
  styleUrls: ['./block-gateway.component.scss']
})
export class BlockGatewayComponent implements OnChanges {
  @Input() isRefreshActive: boolean = false;
  @Output() updateClicked: EventEmitter<any> = new EventEmitter();
  @Input() gatewayId: number;
  gateway: any;
  loading: boolean;

  constructor(private gatewayService: GatewayService) { }
  
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.gatewayId?.currentValue) {
      await this.getCdr();
    }
  }

  async getCdr() {
    this.loading = true;
    if (this.gatewayId) {
      this.gateway = await this.gatewayService.getGatewayDetails(this.gatewayId);
    }
    this.loading = false;
  }

  sendEvent(){
    this.updateClicked.emit()
  }
}

export type ValueOf<T> = T[keyof T];

export const AuthorizationMethod = Object.freeze({
  GROUP: 'GROUP',
  RFID: 'RFID',
  APP: 'APP',
  OCPI: 'OCPI',
});

export type AuthorizationMethodValues = ValueOf<typeof AuthorizationMethod>;
export type AuthorizationMethodKeys = keyof typeof AuthorizationMethod;
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, signal } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// import { IExcelReport, IExportsArchiveItem, IExportsArchiveMonth, IExportsArchiveYear, IRequestExcelReportDTO } from '@common/interfaces/excelReport';
// import { ExportsHelpers } from '@common/utils/exports.helpers';
// import { TimeSlotGeneratorSettings } from '@common/interfaces/scheduler';
// import { AuthService } from '@ep-om/project/auth/auth.service';
// import { DownloadService } from '@ep-om/project/service/download.service';
// import { ProjectQuery } from '@ep-om/project/state/project/project.query';
// import { Download } from '@ep-om/utils/download';
import { FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { IExcelReport, IExportsArchiveItem, IExportsArchiveMonth, IExportsArchiveYear, IRequestExcelReportDTO } from 'common_library';
import { format } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ExportsService } from 'src/app/services/reports/exports.service';
import { Download } from 'src/app/utils/reports/download';
import { environment } from 'src/environments/environment';
// import { ExportsService } from '@ep-om/project/service/exports.service';
// import { AuthQuery } from '@ep-om/project/auth/auth.query';
import { of } from 'rxjs';
import { AppService } from 'src/app/services/app.service';


interface IExcelReportWithForm extends IExcelReport {
  formGroup: UntypedFormGroup;
  formModel: any;
}

enum TABS { NEW_EXPORT, ARCHIVE };
@Component({
  selector: 'app-export-archive',
  templateUrl: './export-archive.component.html',
  styleUrl: './export-archive.component.scss'
})
export class ExportArchiveComponent {


  selectedYear: string;
  selectedMonth: string;
  currentYear: string;
  currentMonth: string;

  private readonly WIDTH_SMALL = 600;
  private apiUrl;
  appService = inject(AppService);

  viewReports$: Observable<{ category: string, reports: IExcelReportWithForm[] }[]>;
  loading$ = new BehaviorSubject<boolean>(false);
  download$: Observable<Download>;
  projectId: string;
  options: FormlyFormOptions = {};
  triggerArchive$ = new Subject();
  archiveItems$: { [year: string]: Observable<IExportsArchiveItem[]> } = {};
  yearDownload$: { [year: string]: Observable<Download> } = {};
  selectedMonthMap: { [year: string]: string } = {};
  archive$: Observable<IExportsArchiveYear[]>;
  // this.triggerArchive$.pipe(
  //   switchMap(() => this.http.get<IExportsArchiveYear[]>(`${this.apiUrl}/excel-export/archive/years`)),
  //   map(items=>items.sort((a, b) =>  +b.year - +a.year )),
  //   tap(years=>{
  //     console.log("years nel tap",years);
  //     this.archiveEmpty.set(years.length===0);
  //   },)
  // );

  archiveEmpty = signal<boolean>(false);
  archive = signal<IExportsArchiveYear[]>([]);
  constructor(
    private http: HttpClient,
    private notificationService: NzNotificationService,
    private exportsService: ExportsService,
    private translateService: TranslateService,
    private _cdr: ChangeDetectorRef
  ) {
    this.apiUrl = this.appService.apiUrl;
    this.archive$ = this.triggerArchive$.pipe(
      switchMap(() => this.http.get<IExportsArchiveYear[]>(`${this.apiUrl}/excel-export/archive/years`)),
      map(items => items.sort((a, b) => +b.year - +a.year)),
      tap(years => {
        this.archiveEmpty.set(years.length === 0);
      })
    );

    this.archive$.subscribe(data => {
      this.archive.set(data)
    });


  }

  needRefresh(yearObj: any): boolean {
    const rv = this.selectedMonthMap[yearObj.year] === this.currentMonth && yearObj.year === this.currentYear;
    //console.log(yearObj.year,this.selectedMonthMap[yearObj.year],this.currentMonth,this.currentYear);
    return rv;
  }

  selectedMonthChanged(year: string, month: string) {
    this.archiveItems$[year] = this.http.get<IExportsArchiveItem[]>(`
      ${this.apiUrl}/excel-export/archive/${year}/${month}
      `);
  }

  getSelectLabelMonth(m: IExportsArchiveMonth): string {
    const key = `MONTHS.${+m.month}`;
    const localizedMonthName = this.translateService.instant(key);
    return `${localizedMonthName}`;
  }

  getFileTypeColor(fileType: string): string {
    switch (fileType) {
      case 'pdf': return 'magenta';
      case 'xlsx': return 'green';
      default: return null;
    }
  }

  getTabPosition(): 'left' | 'top' {
    return window.innerWidth > this.WIDTH_SMALL ? 'left' : 'top';
  }

  // FV 20/01/24 13.00: perchè ho dovuto fare sta mezza porcata ?
  // perchè sembra (spero risolvano) che ci sia un problema con nz-tabset e nz-tab e quindi all'interno del tab height:100% (che poi sta sotto h-full) non funziona perchè non riesce a rilevare l'altezza del contenitore
  // quindi 100vh con calc per togliere l'altezza dell'header e del tab-menu quando passa orizzontale in caso di device mobile
  // Attenzione: se non hai una soluzione testa e migliore o provata da un'altra parte di cui sei sicuro al 100% (codice specifico per ngZorro) è meglio evitare di provare a sistamere perchè io c'ho già perso troppo tempo
  getHeightHeaderMargin() {
    return window.innerWidth > this.WIDTH_SMALL ? '90' : '145';
  }

  isNormalWidth() {
    return window.innerWidth > this.WIDTH_SMALL
  }

  ngOnInit(): void {
    // Valori di default x archivio (visualizzo anno e mese corrente)
    const now = new Date();

    this.currentYear = now.getFullYear().toString();
    this.currentMonth = now.toISOString().substring(5, 7);

    this.selectedYear = now.getFullYear().toString();
    this.selectedMonth = now.toISOString().substring(5, 7);

    this.selectedMonthMap[this.currentYear] = this.currentMonth;
    this.selectedMonthChanged(this.currentYear, this.currentMonth)

    console.log(this.currentYear, this.selectedMonth);
    this.archiveItems$[this.currentYear].subscribe(data => {
      console.log("archiveItems", data);
    });
    this.triggerArchive$.next('');
  }

  private async generate(excelReport: IExcelReportWithForm): Promise<IExportsArchiveItem> {

    const context = {
      // projectId: this.projectQuery.getActiveId() // Nostro contesto
    }

    if (!excelReport.formGroup.valid) {
      this.notificationService.error('error', 'Compilare tutti i campi richiesti!');
      return;
    }

    const request: IRequestExcelReportDTO = {
      excelReportId: excelReport.id,
      model: { ...context, ...excelReport.formModel }
    }

    return await this.exportsService.generateExport(request);
  }

  async generateAndOpen(excelReport: IExcelReportWithForm) {
    const item = await this.generate(excelReport);
    if (!item || !!item.error)
      this.notificationService.error('Attenzione', `Estrazione dati fallita (${item.error?.message || '?'})`);
    else
      this.openFromArchive(item);
  }

  async generateAndDownload(excelReport: IExcelReportWithForm) {
    const item = await this.generate(excelReport);
    if (!item || !!item.error)
      this.notificationService.error('Attenzione', `Estrazione dati fallita (${item.error?.message || '?'})`);
    else {
      excelReport['download$'] = this.exportsService.downloadFromArchive(item);
      this._cdr.detectChanges();
    }
  }

  downloadFromArchive(item: IExportsArchiveItem) {
    console.log("downloadFromArchive", item);
    const year = new Date(item.ctx.ts).getFullYear().toString();
    this.yearDownload$[year] = this.exportsService.downloadFromArchive(item);
  }

  // getOpenableUrl(item:IExportsArchiveItem):string {
  //   return this.exportsService.openableUrl(item);
  // }

  openFromArchive(item: IExportsArchiveItem) {
    console.log("openFromArchive", item);
    if (item.definition.file === 'pdf') {
      const rv = this.exportsService.openFileInNewTab(item);
      if (!rv) this.downloadFromArchive(item);
    }
  }
}

import { Injectable } from '@angular/core';
import { IInvoice, RecordCounted, ISearchCriteriaWithState } from 'common_library';
import { Observable } from 'rxjs';
import { HttpIoService } from '../communication/http-io.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  private readonly CONTROLLER_ROOT = "invoice/admin";

  constructor(private HIO: HttpIoService) { }

  public getInvoicesWithState$(dto: ISearchCriteriaWithState): Observable<RecordCounted<IInvoice>> {
   try {    
    return this.HIO.post$<RecordCounted<IInvoice>, any>(`${this.CONTROLLER_ROOT}/filter-paginate-by-state`, {...dto, pageIndex: dto.pageIndex - 1});
   } catch (error) {
    console.error("🐸, error -> ", error);
   }
  }

  public async getOne(invoiceId: number): Promise<IInvoice> {
    try {
      return this.HIO.get(`${this.CONTROLLER_ROOT}/one/${invoiceId}`);
    } catch (error) {
      console.error("🐸, error -> ", error);
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth/auth-guard.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/authenticated/welcome'},
  {
    path: 'authenticated',
    loadChildren: () =>
    import('./pages/authenticated/authenticated.module').then((m) => m.AuthenticatedModule),
    canActivate: [AuthGuardService],
    canLoad: [AuthGuardService],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'docs',
    loadChildren: () =>
      import('./pages/docs/docs.module').then((m) => m.DocsModule),
  },
  { path: '**', pathMatch: 'full', redirectTo: '/authenticated/welcome'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<app-block-header [isRefreshActive]="isRefreshActive"
                  title="Gateway"
                  (refreshRequest)="sendEvent()"
                  icon="apartment">
</app-block-header>
<app-skeleton [loading]="loading">
  <div class="block read-only-details" *ngIf="gateway; else noDati">
    <app-block-values [data]="gateway" [mainFields]="['name', 'updatedAt', 'version', 'online', 'createdBy', 'updatedBy']"></app-block-values>
  </div>
</app-skeleton>
<ng-template #noDati>
  <app-empty-data>
  </app-empty-data>
</ng-template>

export type ValueOf<T> = T[keyof T];

export const QuickFilter = Object.freeze({
  Disponibile: 'Disponibile',
  YourFill: 'YourFill',
  H24: 'H24',
  Pubblico: 'Pubblico',
  Privato: 'Privato',
  Ospiti: 'Ospiti',
});

export type QuickFilterValues = ValueOf<typeof QuickFilter>;
export type QuickFilterKeys = keyof typeof QuickFilter;
import { Platform } from "@angular/cdk/platform";
import { EventEmitter, Injectable, Type } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { UserService } from "./entities/user.service";
import { TranslateConfigService } from "./translateConfig.service";
import { ID, IUser } from "common_library";
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ModalService {

  fireOpenModal: EventEmitter<void> = new EventEmitter<void>();
  fireCloseModal: EventEmitter<void> = new EventEmitter<void>();

  commonFields = {
    nzClosable: false,
    nzFooter: null,
    nzAfterOpen: this.fireOpenModal,
    nzAfterClose: this.fireCloseModal
  }

  openModalId: string;


  constructor(
    private _nzModalService: NzModalService,
    public _userService: UserService,
    // private _projectQuery: ProjectQuery,
    // private uiQuery: UiQuery,
    // private _issueStateWorkflowQuery: IssueStateWorkflowQuery,
    // private _issueService: IssueService,
    // private _milestoneService: MilestoneService,
    private platform: Platform,
    private translate: TranslateService
  ) { }

  openEditUserModal() {
    // this.openModalId = this.openEditUserModal.name;
    // this._nzModalService.create({
    //   nzContent: UserEditModalComponent,
    //   nzWidth: 700,
    //   nzComponentParams: {
    //     $userObservable: this._userService.getLoggedUser$(),
    //   },
    //   ...this.commonFields
    // });
  }

  openCreateIssueModal() {
    // this.openModalId = this.openCreateIssueModal.name;
    // //const project = this._projectQuery.getActive();
    // const project = this._projectQuery.getEntity(this.uiQuery.getValue().prjId as string);
    // const isw = this._issueStateWorkflowQuery.getAll().filter(isw => isw.workflowId === project.workflowId).sort((isw1, isw2) => isw1.order - isw2.order)[0]
    // if (this.platform.ANDROID || this.platform.IOS) {
    //   this._nzModalService.create({
    //     nzContent: AddIssueModalComponent,
    //     nzWidth: '100%',
    //     nzClassName: 'issue-modal',
    //     nzComponentParams: { projectId: project.id, workflowId: project.workflowId, stateId: isw.issueStateId },
    //     ...this.commonFields
    //   });
    // } else {

    //   this._nzModalService.create({
    //     nzContent: AddIssueModalComponent,
    //     nzWidth: '100%',
    //     nzClassName: 'issue-modal',
    //     nzComponentParams: { projectId: project.id, workflowId: project.workflowId, stateId: isw.issueStateId },
    //     ...this.commonFields
    //   });
    // }
    // this._issueService.newIssueModalIsOpened$.next(true);
  }

  openIssueModal(issueId: ID): { ref: NzModalRef<any>, onClose: EventEmitter<any> } {
    // this._issueService.setActive(issueId);
    this.openModalId = this.openIssueModal.name;
    const onClose = new EventEmitter();
    const ref = this._nzModalService.create({
      // nzContent: IssueModalComponent,
      nzWidth: '100%',
      nzClassName: 'issue-modal',
      ...this.commonFields,
      nzAfterClose: onClose,
    });
    return { ref, onClose };
  }

  openMilestoneModal(milestoneId: ID): { ref: NzModalRef<any>, onClose: EventEmitter<any> } {
    // this._milestoneService.setActive(milestoneId);
    this.openModalId = this.openIssueModal.name;
    const onClose = new EventEmitter();
    const ref = this._nzModalService.create({
      // nzContent: MilestoneModalComponent,
      nzWidth: '100%',
      nzClassName: 'issue-modal',
      ...this.commonFields,
      nzAfterClose: onClose,
    });
    return { ref, onClose };
  }

  openExportExcelModal(onExportExcel: EventEmitter<any>) {
    this._nzModalService.create({
      // nzContent: DangerActionModalComponent,
      nzStyle: {
        top: '140px'
      },
      nzClosable: false,
      nzFooter: null,
      // nzComponentParams: {
      //   info: 'Dopo la riconnessione, i dati verranno aggiornati',
      //   actionName: 'Esportare',
      //   question: 'Sei offline, i dati potrebbero non essere aggiornati',
      //   onConfirm: onExportExcel
      // }, TODO: Capire come si fanno i modali, per bene con zorro
    });
  }


  openArchiveIssueModal(issueId: ID, onArchive: EventEmitter<any>) {
    this._nzModalService.create({
      // nzContent: DangerActionModalComponent,
      nzStyle: {
        top: '140px'
      },
      // nzComponentParams: {
      //   actionName: 'Sì, archivia.',
      //   question: "Confermi l'archiviazione di questo elemento ?",
      //   issueId: issueId,
      //   onConfirm: onArchive
      // },
      nzClosable: false,
      nzFooter: null
    });
  }

  openDeleteIssueModal(issueId: ID, onDelete: EventEmitter<any>) {
    this._nzModalService.create({
      // nzContent: DangerActionModalComponent,
      nzStyle: {
        top: '140px'
      },
      nzClosable: false,
      nzFooter: null,
      // nzComponentParams: {
      //   actionName: this.translate.instant('ISSUE.DELETE_MODAL.MODAL_ACTION'),
      //   question: this.translate.instant('ISSUE.DELETE_MODAL.MODAL_QUESTION'),
      //   info: this.translate.instant('ISSUE.DELETE_MODAL.MODAL_INFO'),
      //   issueId: issueId,
      //   onConfirm: onDelete
      // },

    });
  }

  openDeleteModal(issueId: ID, onDelete: EventEmitter<any>) {
    this.openDeleteIssueModal(issueId, onDelete);
  }



  openRestoreIssueModal(issueId: ID, onRestore: EventEmitter<any>) {
    this.openModalId = this.openRestoreIssueModal.name;
    this._nzModalService.create({
      // nzContent: DangerActionModalComponent,
      nzStyle: {
        top: '140px'
      },
      // nzComponentParams: {
      //   actionName: 'Sì, ripristina.',
      //   question: "Confermi il ripristino di questo elemento ?",
      //   info: 'Questo elemento tornerà ad essere un elemento attivo di questo progetto',
      //   issueId: issueId,
      //   onConfirm: onRestore
      // },
      nzClosable: false,
      nzFooter: null
    });
  }

  openArchiveIssueModalAsync(issue: any): { ref: NzModalRef<any>, onClose: EventEmitter<any> } {
    this.openModalId = this.openArchiveIssueModalAsync.name;
    const onClose = new EventEmitter();
    const ref = this._nzModalService.create({
      // nzContent: IssueViewerComponent,
      nzWidth: 1040,
      // nzComponentParams: {
      //   archivedIssue: issue,
      // },
      ...this.commonFields,
      nzAfterClose: onClose,
    });
    return { ref, onClose };
  }

  openUserProfile(user: IUser) {
    this.openModalId = this.openUserProfile.name;
    this._nzModalService.create({
      // nzContent: UserEditModalComponent,
      nzWidth: 700,
      // nzComponentParams: { user: user, isUploadDisabled: true },
      ...this.commonFields
    });
  }

  openAppointmentRescheduleModal(
    appointment: Partial<any>,
    users$: BehaviorSubject<Partial<any>[]>,
    rescheduleForm: UntypedFormGroup,
    onClose: EventEmitter<boolean>
  ): void {
    this._nzModalService.create({
      nzTitle: 'Sposta appuntamento',
      // nzContent: RescheduleModalComponent,
      nzMaskClosable: false,
      nzClosable: false,
      // nzComponentParams: { appointment, users$, rescheduleForm, onClose },
      nzOnOk: () => null,
      nzOnCancel: () => null
    });
  }

  unavailabilityModal(
    users$: BehaviorSubject<Partial<IUser>[]>,
    disponibilitaForm: UntypedFormGroup,
    onClose: EventEmitter<any>,
    nzTitle: string,
    blockUserSelect: boolean,
    showRemoveBtn?: boolean
  ): void {
    this._nzModalService.create({
      nzTitle,
      // nzContent: UnavailabilityModalComponent,
      nzMaskClosable: true,
      nzClosable: true,
      // nzComponentParams: { users$, unavailabilityForm: disponibilitaForm, onClose, blockUserSelect, showRemoveBtn },
      nzOnOk: () => null,
      nzOnCancel: () => null
    });
  }

  /**
  * Modale di conferma generica (è un semplice wrap del confirm di NgZorro)
  * @param title il titolo della modale di conferma
  * @param okBtn label del bottone di conferma
  * @param okRed se si vuole il bottone rosso
  * @param descriptionContent è il contenuto della modale (html)
  * @param cancelBtn label del bottone di annullamento operazione
  * @returns boolean
  */
  showGenericConfirm(title: string, okBtn = "Ok", okRed = false, descriptionContent: string = null, cancelBtn = "Annulla"): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._nzModalService.confirm({
        nzTitle: title,
        nzContent: descriptionContent,
        nzOkText: okBtn,
        nzOkType: 'primary',
        nzOkDanger: okRed,
        nzCancelText: cancelBtn,
        nzOnOk: () => resolve(true),
        nzOnCancel: () => resolve(false)
      });
    });
  }

  createAlert<T>(nzContent: Type<T>, nzComponentParams?: Partial<T>): Promise<{ ok: boolean, data: any }> {
    return new Promise(async (resolve, reject) => {
      const modalRef = this._nzModalService.create({
        nzContent,
        // nzCentered: true,
        nzWidth: '100%',
        nzClassName: 'alert-modal',
        nzClosable: false,
        nzFooter: null,
        // nzComponentParams,
        // nzOnOk: () => resolve(true),
        // nzOnCancel: () => resolve(false)
      });
      const res = await modalRef.afterClose.toPromise();
      resolve(res);
    });
  }

}

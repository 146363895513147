import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IUser } from 'common_library';
import { UserService } from 'src/app/services/entities/user.service';

@Component({
  selector: 'app-impersonificate-modal',
  templateUrl: './impersonificate.component.html',
  styleUrls: ['./impersonificate.component.scss']
})
export class ImpersonificateComponent implements OnInit {

  @Output() cancelEvent: EventEmitter<void> = new EventEmitter<void>();  
  @Output() valueSelected: EventEmitter<string> = new EventEmitter<string>();


  selectedValue = null;

  usersOptions: IUser[] = [];

  constructor(
    private userService: UserService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.usersOptions = await this.allUser();
  }

  handleOk(): void {
    if (this.selectedValue) {
      this.valueSelected.emit(this.selectedValue);
      this.cancelEvent.emit();
    }
  }

  handleCancel(): void {
    this.cancelEvent.emit();
  }

  async allUser(){
    let allUsers = await this.userService.getAllUsers();
    return allUsers;
  }
  
}
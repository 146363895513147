<div class="page">
  <div nz-col>
    <app-detail-header label="{{'REPORT.EXPORTS'|translate}}" [isRefreshActive]="false">
    </app-detail-header>
  </div>
  <div>
    <div nz-row [nzGutter]="16" nzJustify="space-between">
      <div nz-col nzXs="48" nzSm="48" nzMd="12" nzLg="12" nzXl="16" nzXxl="16" class="page-section">
        <nz-skeleton [nzLoading]="loading$ | async">
          @if(!reports()){
          <nz-alert class="m-2" nzType="info" nzMessage="{{'NZMESSAGE.ATTENTION'|translate}}" nzDescription="{{'REPORT.NO_ELEMENT_AVAILABLE'|translate}}"
            nzShowIcon></nz-alert>
          }
          @if(reports(); as viewReports){

          <nz-list *ngIf="viewReports?.length > 0; else noReports">
            @for(viewReport of viewReports; track viewReport.category){
              <nz-list-item class="w-full no-border">
                <div class="flex flex-col w-full">
                  <h5 nz-typography><span nz-icon nzType="appstore" class="mr-10" nzTheme="outline"></span> <span class="text-gray-500">{{ viewReport.category }}</span></h5>
                  @for(report of viewReport.reports; track report.id){
                    <nz-collapse class="mb-2">
                      <nz-collapse-panel [nzHeader]="report.name" [nzExtra]="extraTemplate" style="width: 100%;">

                        <ng-template #extraTemplate>
                          <nz-tag [nzColor]="getFileTypeColor(report.file)">{{ report.file }}</nz-tag>
                          <i nz-icon [nzType]="report.icon || 'file-text'"></i>
                        </ng-template>
                        <div class="yf-flex yf-flex-col yf-gap-2">
                          <span nz-typography class="text-textDarkest" nzType="secondary">{{report.description}}</span>
                          <div *ngIf="report.formFields?.length>0">
                            <form #form class="p-4" style="max-width:320px;" nz-form nzLayout="vertical"
                              [formGroup]="report.formGroup">
                              <formly-form [form]="report.formGroup" [fields]="report.formFields" [model]="report.formModel"
                                [options]="options"></formly-form>
                            </form>
                          </div>
                          <div class="yf-flex yf-gap-2">
                            <div class="w-full">
                              @if((report.download$ | async); as download){
                                <nz-progress  [nzPercent]="download.progress"></nz-progress>
                              }
                            </div>

                            <button nz-button class="btn" type="submit" (click)="generateAndOpen(report)" nzType="primary" [disabled]="report.file !== 'pdf'">
                              <span nz-icon nzType="eye" nzTheme="outline"></span>
                              {{'REPORT.OPEN'|translate}}
                            </button>

                            <button nz-button class="btn" nzType="default" type="submit"
                              (click)="generateAndDownload(report)">
                              <span nz-icon class="align-top" nzType="download" nzTheme="outline"></span>
                            </button>

                          </div>
                        </div>
                      </nz-collapse-panel>
                    </nz-collapse>
                  }
                </div>
              </nz-list-item>
            }
          </nz-list>
          }
          <ng-template #noReports>

            <nz-alert class="m-2" nzType="warning" nzMessage="{{'NZMESSAGE.ATTENTION'|translate}}" nzDescription="{{'REPORT.NO_ELEMENT_AVAILABLE'|translate}}"
              nzShowIcon></nz-alert>

          </ng-template>
        </nz-skeleton>
      </div>
    </div>
  </div>
</div>

import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { IEntityTypeLog, IEntityTypeLogValues, ILevelLog, ILevelLogValues, ILog, RecordCounted } from "common_library";
import { NzTableComponent } from "ng-zorro-antd/table";
import { AppService } from "src/app/services/app.service";
import { HttpIoService } from "src/app/services/communication/http-io.service";
import { Clipboard } from '@angular/cdk/clipboard';
import { StorageService } from "src/app/services/storage.service";
import { _ } from "src/app/consts";
import { BehaviorSubject, Observable, switchMap } from "rxjs";
import { LogService } from "src/app/services/entities/log.service";
import { TranslateService } from "@ngx-translate/core";

export interface FilterLogDto {
  level?: ILevelLogValues;
  search?: string;
  entityId?: string;
  entityType?: IEntityTypeLogValues | string;
  page?:number;
  date?: string;
}

@Component({
  selector: 'app-block-log',
  templateUrl: './block-log.component.html',
  styleUrls: ['./block-log.component.scss']
})
export class BlockLogComponent implements OnInit {

  @ViewChild('table', { static: false })
  nzTableComponent: NzTableComponent<ILog>;
  logs: ILog[] = [];
  logsCount: number;
  levels = [
    { label: this.translate.instant("LABEL.ALL"), value: 'ALL' },
    ...Object.entries(ILevelLog).map((e) => { return { label: e[0], value: e[1].toString() } })
  ];
  types = [
    { label: this.translate.instant("LABEL.ALL"), value: 'ALL' },
    ...Object.entries(IEntityTypeLog).map((e) => { return { label: e[0], value: e[1].toString() } })
  ];
  icons = {
    level: {
      INFO: 'info-circle',
      WARNING: 'warning',
      ERROR: 'close-circle',
      DEBUG: 'bug',
    },
    type: {
      station: 'usb',
      installation: 'cluster',
      session: 'group',
      gateway: 'apartment'
    },
    tooltip: {
      station: 'Stazione',
      installation: 'Installazione',
      session: 'Sessione',
      gateway: 'Gateway',
    }
  };

  @Input() isFull: boolean = true;
  @Input() search: string;
  @Input() date = new Date();
  @Input() level: string = 'ALL';
  @Input() entityId: string;
  @Input() entityType: string = 'ALL';
  @Input() page: number = 1;

  logsSearchCriteria$ = new BehaviorSubject<FilterLogDto>(null);
  logs$: Observable<RecordCounted<ILog>>;

  constructor(private clipboard: Clipboard, public app: AppService, private storage: StorageService, private logService: LogService, private translate: TranslateService) { }

  async ngOnInit() {
    let logsFilterObject: FilterLogDto = this.createLogsFilterObject();
    this.logsSearchCriteria$.next(logsFilterObject);
    this.logs$ = this.logsSearchCriteria$.pipe(switchMap((searchCriteria) => {
      if (this.isFull) {
        this.storage.set(_.LOG_SEARCH_CRITERIA, searchCriteria);
      }
      return this.logService.getLogsFilteredAndPaginated(searchCriteria);
    }));
  }

  async onSearchChanged(event: string) {
    this.logsSearchCriteria$.next({
      ...this.logsSearchCriteria$.getValue(),
      search: event
    })
  }

  async onDateChanged(event: Date) {
    this.logsSearchCriteria$.next({
      ...this.logsSearchCriteria$.getValue(),
      date: event.toISOString().split('T')[0]
    })
  }

  async onEntityIdChange(entityId: string) {
    this.logsSearchCriteria$.next({
      ...this.logsSearchCriteria$.getValue(),
      entityId: entityId
    })
  }

  async onEntityTypeChange(entityType: IEntityTypeLogValues) {
    this.logsSearchCriteria$.next({
      ...this.logsSearchCriteria$.getValue(),
      entityType
    })
  }

  async onRefresData() {
    this.logsSearchCriteria$.next({...this.logsSearchCriteria$.getValue()});
  }

  // async loadLogs() {
  //   const searchDto: FilterLogDto = this.logsSearchCriteria$.getValue();
  //   const resp = await this.HIO.post<RecordCounted<ILog>, {}>(`${this.CONTROLLER_ROOT}/logs-filtered-paginated`, searchDto);
  //   if (resp) {
  //     this.storage.setLocalStorage(_.LOG_SEARCH_CRITERIA, searchDto);
  //     this.logsCount = resp.count;
  //     this.logs = resp.data;
  //   } else {
  //     this.app.createNotification('error', 'Error', 'QUalcosa è andato storto, riprova più tardi');
  //   }    
  //   this.types = [
  //     { label: 'TUTTI', value: 'ALL' },
  //     ...Array.from(new Set(this.logs.filter((l) => l.entity?.type).map((l) => l.entity.type))).map((v) => { return { label: v.toUpperCase(), value: v } })
  //   ];
  //   console.log("logs > ", this.logs);
  //   console.log("types > ", this.types);
  // }

  getLogDetail(log: ILog): string {
    return JSON.stringify(log, null, 2);
  }

  copyValue(value){
    try{
      this.clipboard.copy(value.toString());
      this.app.createNotification('success', this.translate.instant("PHRASE.SUCCEEDED"),this.translate.instant("PHRASE.ITEM_COPIED"));
    }catch(e){
      console.error(e);
      this.app.createNotification('error', e.type, this.translate.instant("PHRASE.NO_ITEM_COPIED"));
    }
  }

  pageIndexChange(pageIndex: number) {
    this.logsSearchCriteria$.next({
      ...this.logsSearchCriteria$.getValue(),
      page: pageIndex
    })
  }

  onLevelChange(level: ILevelLogValues) {
    this.logsSearchCriteria$.next({
      ...this.logsSearchCriteria$.getValue(),
      level
    })
  }

  createLogsFilterObject(): FilterLogDto {
    if (!this.isFull) {
      return {
        date: this.date ? this.date.toISOString().split('T')[0] : undefined,
        search: this.search && this.search.length > 2 ? this.search : undefined,
        level: this.level && this.level !== 'ALL' ? ILevelLog[this.level] : 'ALL',
        entityId: this.entityId && this.entityId.length > 2 ? this.entityId : undefined,
        entityType: this.entityType && this.entityType !== 'ALL' ? this.entityType as IEntityTypeLogValues : 'ALL',
        page: 1
      }
    } else if (this.isFull && this.storage.get(_.LOG_SEARCH_CRITERIA)) {
      return this.storage.get(_.LOG_SEARCH_CRITERIA);
    } else if (this.isFull && !this.storage.get(_.LOG_SEARCH_CRITERIA)) {
      const filterObject = {
        date: new Date().toISOString().split('T')[0],
        entityId: undefined,
        entityType: 'ALL' as IEntityTypeLogValues,
        level: 'ALL' as ILevelLogValues,
        page: 1,
        search: undefined
      }
      this.storage.set(_.LOG_SEARCH_CRITERIA, filterObject)
      return filterObject;
    }
  }
}

export type ValueOf<T> = T[keyof T];

export const EVSE_STATUS = {
  AVAILABLE: 'AVAILABLE',
  BLOCKED: 'BLOCKED',
  CHARGING: 'CHARGING',
  UNOPERATIVE: 'UNOPERATIVE',
  INOPERATIVE: 'INOPERATIVE',
  OUTOFORDER: 'OUTOFORDER',
  PLANNED: 'PLANNED',
  REMOVED: 'REMOVED',
  RESERVED: 'RESERVED',
  UNKNOWN: 'UNKNOWN'
} as const

export type EVSE_STATUS_Keys = keyof typeof EVSE_STATUS;
export type EVSE_STATUS_Values = ValueOf<typeof EVSE_STATUS>
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CU, ISession } from 'common_library';
import { SessionService } from 'src/app/services/entities/session.service';

@Component({
  selector: 'app-block-sessions',
  templateUrl: './block-sessions.component.html',
  styleUrls: ['./block-sessions.component.scss']
})
export class BlockSessionsComponent {
  _utilityFuncs = CU
  @Input() sessionId?: string; // nel caso venga passao l'id
  @Input() isRefreshActive: boolean = false;

  loading: boolean = false;
  session: ISession;

  constructor(
    private router: Router,
    private sessionService: SessionService) {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.sessionId.currentValue) {
      await this.getItem();
    }
  }

  async getItem() {
    this.loading = true;
    if (this.sessionId) {
      this.session = await this.sessionService.getOneSessionAdminBlock(this.sessionId);
    }
    this.loading = false;
  }

  async refreshItem() {
    await this.getItem();
  }

  calcDate(fromDate: Date, toDate: Date) {
    if (fromDate == null) {
      //SO CHE NON HO QUEL CAMPO
      return '--:--';
    }
    const date_end = toDate ? toDate : new Date();
    const time = this._utilityFuncs.timeSpan(fromDate, date_end, true);

    return time;
  }

  goToSessionDetail() {
    this.router.navigate([`authenticated/sessions/${this.session.id}`])
  }
}

export type ValueOf<T> = T[keyof T];

export const ChapterType = Object.freeze({
  PRIVACY: 'PRIVACY',
  TERMS_AND_CONDITIONS: 'TERMS AND CONDITIONS',
  CONTACTS: 'CONTACTS',
  PAYMENT_INSTRUCTIONS: 'PAYMENT INSTRUCTIONS',
  ACCOUNT_DELETION: 'ACCOUNT DELETION'
});

export type ChapterTypeValues = ValueOf<typeof ChapterType>;
export type ChapterTypeKeys = keyof typeof ChapterType;
export enum OcpiGatewayMessage {
  Installations = 'INSTALLATIONS',
  GetLocations = 'GET_LOCATIONS',
  UpdateSession = 'UPDATE_SESSION',
  GetSession = 'GET_SESSION',
  GtwHandshake = 'GTW_HANDSHAKE',
  GetMunicipalities = 'GET_MUNICIPALITIES',
  SendMeters = 'SEND_METERS',
  PostCdr = 'POST_CDR',
  GetCdr = 'GET_CDR',
  TokenAuth = 'TOKEN_AUTH',
  GetTariff = 'GET_TARIFF',
  PutTariff = 'PUT_TARIFF',
  DeleteTariff = 'DELETE_TARIFF',
  PaginatedTokens = 'PAGINATED_TOKENS',
  SendStatusNotification = 'SEND_STATUS_NOTIFICATION',
  GetConnector = 'GET_CONNECTOR',
}

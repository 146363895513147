import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
// import { Download, download } from '@ep-om/utils/download';
// import { SAVER, Saver } from '@ep-om/utils/saver.provider';
import { Observable, Subject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { download, Download } from 'src/app/utils/reports/download';
import { SAVER, Saver } from 'src/app/utils/reports/saver.provider';

export interface IDownloadError { error:any, item:any };
@Injectable({providedIn: 'root'})
export class DownloadService {

  private _errors$ = new Subject<IDownloadError>();
  errors$ = this._errors$.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(SAVER) private save: Saver
  ) {
  }

  download<T>(url: string, item: T, filename?: string): Observable<Download> {
    return this.http.post(url, item, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    }).pipe(
      download(blob => this.save(blob, filename)),
      catchError((error) => {
        this._errors$.next({error,item});
        return of(null);
      })
      );
  }


  blob(url: string, filename?: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    })
  }
}

export type ValueOf<T> = T[keyof T];

export const State = Object.freeze({
  UNAVAILABLE: 0, // non disponibile
  AVAILABLE: 1, // disponibile
  RESERVED: 2, // prenotato
  CHARGING: 3, // in carica
  OCCUPIED: 4, // occupato
  PREPARING: 5, // occupato
});

export function getStateLabelByNumber(state: number): string {
  const keys = Object.keys(State).filter(k => typeof State[k as any] === "number");
  return keys.find((key) => State[key] === state)
}

export type StateValues = ValueOf<typeof State>;
export type StateKeys = keyof typeof State;
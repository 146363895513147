<nz-table #nzTable nzSize="small" [nzData]="items" nzShowPagination="true" [nzPageSize]="itemsPerPage" nzShowSizeChanger [nzPageSizeOptions]="[10,25,50,100,250,500]" [nzLoading]="loading" [nzScroll]="{ y:this.height, x:this.width }">

    <thead>
        <tr>
            <th *ngFor="let field of fields; let i = index" class="field-header" [nzLeft]="mainFields.includes(field)" [nzWidth]="widthField(field)">{{ field }}</th>
        </tr>
    </thead>

    <tbody>
        <ng-container *ngFor="let data of nzTable.data">
          <tr>
            <td *ngFor="let field of fields" [nzAlign]="align(data[field])" class="field-value" [nzLeft]="mainFields.includes(field)">
            <div class="cursor-pointer" (click)="log(data[field])">{{ format(data[field],field) }}</div>
            </td>
          </tr>
        </ng-container>
    </tbody>

</nz-table>

export * from './client'

export type TypedEnumUnion<T extends string> = `${T}`;

export enum WsNamespaces {
	Client = 'client',
	Ocpp = 'OCPP',
  Ocpi = 'OCPI',
}

export function getNamespacesArray() {
	const namespaces: WsNamespaces[] = [];
	for (const index in WsNamespaces) {
		namespaces.push(WsNamespaces[index]);
	}
	return namespaces;
}

<app-block-header title="{{'STATIONS.DETAIL.CONNECTOR'|translate}}" icon="api" [isRefreshActive]="true" (refreshRequest)="refresh()">
</app-block-header>

<div nz-row nzJustify="space-around">
  <div nz-col nzSpan="20">
    <form nz-form [formGroup]="connectorForm" (ngSubmit)="submit()">
      <nz-form-item>
        <nz-form-label nzFlex="150px">{{'STATIONS.DETAIL.NAME_NO_PERSON'|translate}}</nz-form-label>
        <nz-form-control nzFlex="auto">
          <input nz-input formControlName="name" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFlex="150px">{{'STATIONS.DETAIL.INFORMATION'|translate}}</nz-form-label>
        <nz-form-control nzFlex="auto">
          <input nz-input formControlName="info">
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFlex="150px">{{'STATIONS.DETAIL.POWER_WATT'|translate}}</nz-form-label>
        <nz-form-control nzFlex="auto">
          <nz-input-number formControlName="power"></nz-input-number>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFlex="150px">{{'STATIONS.DETAIL.POWER_LIMIT_WATT'|translate}}</nz-form-label>
        <nz-form-control nzFlex="auto">
          <nz-input-number formControlName="powerLimit"></nz-input-number>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFlex="150px">{{'STATIONS.DETAIL.RATE'|translate}}</nz-form-label>
        <nz-form-control nzFlex="auto">
          <nz-select nzShowSearch nzServerSearch nzPlaceHolder="{{'PLACEHOLDER.ENTER_ONE_CHAR'|translate}}"
            formControlName="tariffId" [nzShowArrow]="false" [nzAllowClear]="true"
            (nzOnSearch)="onInputTariffStation($event)">
            <nz-option *ngFor="let tariff of tariffs$ | async" [nzLabel]="tariff.name"
              [nzValue]="tariff.id"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>

<div nz-row nzJustify="end" [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
  <div nz-col nzXs="8">
    <button class="btn" nz-button type="submit" nzType="primary" nzBlock (click)="submit()"
      [disabled]="!connectorForm.dirty">{{'BUTTONS.SAVE'|translate}}</button>
  </div>
</div>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IInfoSection, IInfoSectionVersion, IMultimedia } from 'common_library';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AppService } from 'src/app/services/app.service';
import { Router } from '@angular/router';
import { InfoSectionService } from 'src/app/services/entities/info-section.service';
import { InfoSectionVersionService } from 'src/app/services/entities/info-section-version.service';
import { MultimediaService } from 'src/app/services/entities/multimedia.service';
import { QuillComponent } from '../quill/quill.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info-new-section',
  templateUrl: './info-new-section.component.html',
  styleUrls: ['./info-new-section.component.scss']
})
export class InfoNewSectionComponent implements OnInit {
  @Input() showUndoButton: boolean = true;
  @Output() newSectionSavedEvent = new EventEmitter<string>();
  @Output() newSectionUndoEvent = new EventEmitter();

  multimediaSelectOptions$ = new BehaviorSubject<IMultimedia[]>([]);
  newInfoSectionForm = new UntypedFormGroup({});
  newInfoSectionFields: FormlyFieldConfig[] = this.getUpdatedFields();

  quillHtml: string;

  private langChangeSubscription: Subscription;


  constructor(
    private appService: AppService,
    private router: Router,
    private infoSectionService: InfoSectionService,
    private infoSectionVersionService: InfoSectionVersionService,
    private multimediaService: MultimediaService,
    private translate: TranslateService
  )  {
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.newInfoSectionFields = this.getUpdatedFields();
    });
  }

  async ngOnInit(): Promise<void> {
    this.multimediaSelectOptions$.next(await this.getMultimediaAsOptions());
  }

  getUpdatedFields(): FormlyFieldConfig[] {
    return[
      {
        key: 'title',
        type: 'input',
        props: {
          label: this.translate.instant("LABEL.TITLE"),
        },
      },
      {
        key: 'subTitle',
        type: 'input',
        props: {
          label: this.translate.instant("LABEL.SUBTITLE"),
        }
      },
      {
        key: 'image',
        type: 'select',
        props: {
          label: this.translate.instant("LABEL.PICTURE"),
          options: this.multimediaSelectOptions$
        }
      },
      {
        key: 'html',
        type: QuillComponent,
        wrappers: ['form-field'],
        className: 'quill-container',
        props: {
          label: this.translate.instant("LABEL.HTML"),
        },
        hooks: {
          onInit: (field) => {
            field.formControl.valueChanges.subscribe((value) => {
              this.quillHtml = value;
            });
          },
        }
      },
    ]
  }

  ngOnDestroy() {
    this.langChangeSubscription.unsubscribe();
  }


  async submit() {
    if (!this.newInfoSectionForm.dirty) return;

    const dto: Partial<IInfoSection> = {
      title: this.newInfoSectionForm.get("title").value?.trim(),
      subtitle: this.newInfoSectionForm.get("subTitle").value?.trim(),
      multimedia: this.newInfoSectionForm.get("image").value,
      html: this.quillHtml ? this.quillHtml : null,
    }
    try {
      const res = await this.infoSectionService.createInfoSection(dto);
      if (!!res) {
        // Salvataggio della versione
        let versionDto: IInfoSectionVersion = {
          id: null,
          isPublished: true,
          sectionVersion: 0,
          sectionId: res.id,
          description: this.quillHtml ? this.quillHtml : null
        };
        const resVersion = await this.infoSectionVersionService.createInfoSectionVersion(versionDto);
        if (resVersion) {
          this.appService.createNotification(
            'success',
            this.translate.instant("PHRASE.SAVED"),
            this.translate.instant("PHRASE.VERSION_CREATED_SUCCESSFULLY"),
          )
        }
        else {
          this.appService.createNotification('error', this.translate.instant("PHRASE.ERROR"), this.translate.instant("PHRASE.ERROR_OCCURRED_WHILE_SAVING"))
        }
        this.appService.createNotification(
          'success',
          this.translate.instant("PHRASE.SAVED"),
          this.translate.instant("PHRASE.SECTION_CREATED_SUCCESSFULLY"),
        )
        this.newSectionSavedEvent.emit(res.id);
      } else {
        this.appService.createNotification('error', this.translate.instant("PHRASE.ERROR"), this.translate.instant("PHRASE.SOMETHING_WRONG_TRY"))
      }
    } catch (error) {
      this.appService.createNotification('error', this.translate.instant("PHRASE.ERROR"), this.translate.instant("PHRASE.SOMETHING_WRONG_try"))
    }
    //this.goBack();
  }

  undo() {
    this.newSectionUndoEvent.emit();
  }

  async getMultimediaAsOptions(): Promise<any[]> {
    const selectOptions = [{ label: this.translate.instant("LABEL.NONE_F"), value: null }];
    const multimedias: IMultimedia[] = await this.multimediaService.getAllMultimedia();
    for (let i = 0; i < multimedias.length; i++) {
      const currentOption: { label: string, value: string } = { label: multimedias[i].name, value: multimedias[i].id };
      selectOptions.push(currentOption)
    }
    return selectOptions;
  }
}

export type ValueOf<T> = T[keyof T];

export const RevenueState = Object.freeze({
  New: 0,
  Processing: 1,
  Done: 2,
  Failed: 3,
  Reprocessing: 4
});

export type RevenueStateValues = ValueOf<typeof RevenueState>;
export type RevenueStateKeys = keyof typeof RevenueState;
<app-block-header title="{{'USER.DETAIL.SESSIONS'|translate}}" [isRefreshActive]="isRefreshActive" icon="fund"
  (refreshRequest)="refreshItem()"></app-block-header>
<ng-container *ngIf="!AS.isMobile" [ngTemplateOutlet]="nzPaginationTemplate"></ng-container>
<app-skeleton [loading]="loading">
  <ng-container *ngIf="sessionRecords?.data.length>0; else noDati">
    <nz-table #smallTable nzSize="small" [nzData]="sessionRecords.data" [nzPageSize]="10" [nzFrontPagination]="false"
      [nzShowPagination]="false" style="max-height: 300px; overflow: auto">
      <thead>
        <tr>
          <th>{{'USER.DETAIL.DATE'|translate}}</th>
          <th>{{'USER.DETAIL.ENERGY'|translate}}</th>
          <th [nzWidth]="config.entityType === 'user' ? '150px' : '180px'">
            {{ config.entityType === 'user' ? ('USER.DETAIL.USER_DETAIL' | translate) : ('USER.DETAIL.STATION' |
            translate) }}
          </th>
          <th>{{'USER.DETAIL.DURATION'|translate}}</th>
          <th>{{'USER.DETAIL.CPO'|translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of smallTable.data" routerLink="/authenticated/sessions/{{data.id}}" class="yf-pointer"
          [ngClass]="data.endedAt? '': 'green'">
          <td>{{ data.endedAt ? (data.endedAt | localizedDate): 'Sessione attiva' }}</td>
          <td>{{ data.energy | energy }} kWh</td>
          <td>
            <app-entity-tag [entity]="config.entityType === 'user' ? data.user : data.station"
              [entityType]="config.entityType" [isSmallContainer]="true">
            </app-entity-tag>
          </td>
          <td>
            <app-timespan [fromDate]="data.createdAt" [toDate]="data.endedAt"></app-timespan>
          </td>
          <td>{{ data.cpo }}</td>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>
  <ng-template #nzPaginationTemplate>
    <nz-pagination [nzTotal]="sessionRecords?.count" [nzSize]="'small'" [nzPageSize]="10"
      (nzPageIndexChange)="pageIndex$.next($event)" [nzPageIndex]="pageIndex$ | async"></nz-pagination>
  </ng-template>
  <ng-template #noDati>
    <app-empty-data></app-empty-data>
  </ng-template>
</app-skeleton>
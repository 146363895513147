import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IClient } from 'common_library';
import { ClientService } from 'src/app/services/entities/client.service';

const CONTROLLER_ROOT = 'client'

@Component({
  selector: 'app-block-client-list',
  templateUrl: './block-client-list.component.html',
  styleUrls: ['./block-client-list.component.scss']
})
export class BlockClientListComponent implements OnChanges {  
  @Input() userId: string;
  @Input() isRefreshActive: boolean = false;

  clients: IClient[];
  loading: boolean;

  constructor(private clientService: ClientService, private translate: TranslateService) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.userId?.currentValue) {
      await this.getItem()
    }
  }

  async getItem() {
    this.loading = true;
    if (this.userId) {
      this.clients = await this.clientService.getUserClientsAdminBlock(this.userId)
    }
    this.loading = false;
  }

  async refreshItem() {
    await this.getItem();
  }
}
export type ValueOf<T> = T[keyof T];

export const InvoiceState = Object.freeze({
  New: 0,
  Processing: 1,
  Reprocessing: 2,
  Completed: 3,
  Failed: 4,
  Rejected: 5,
  Unnecessary: 6
});

export type InvoiceStateValues = ValueOf<typeof InvoiceState>;
export type InvoiceStateKeys = keyof typeof InvoiceState;
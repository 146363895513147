import { Injectable } from '@angular/core';
import { HttpIoService } from '../communication/http-io.service';
import { ChapterType, ChapterVisualizationPhase, IGroup, IInfoChapter, RecordCounted } from 'common_library';
import { CommonService } from '../common.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

const CONTROLLER_ROOT = 'info-chapter';

@Injectable({
  providedIn: 'root'
})
export class InfoChapterService {

  constructor(private HIO: HttpIoService, private commonService: CommonService, private translate: TranslateService) { }

  public async getAllInfoChapter(search?: string): Promise<IInfoChapter[]> {
    try {
      if (!search) {
        return await this.HIO.get(`${CONTROLLER_ROOT}/all`);
      }
      return await this.HIO.get(`${CONTROLLER_ROOT}/all/${search}`);
    } catch (error) {
      console.error('🐸 Error: ', error);
    }
  }

  public async getInfoChaptersByBrand(brand?: string): Promise<IInfoChapter[]> {
    try {
      if (brand) {
        return await this.HIO.get<IInfoChapter[]>(`${CONTROLLER_ROOT}/chapters-by-brand/${brand}`);
      } else {
        return await this.HIO.get<IInfoChapter[]>(`${CONTROLLER_ROOT}/chapters-by-brand`);
      }
    } catch (error) {
      console.error('🐸 Error: ', error);
    }
  }

  public async getOneInfoChapter(id: string): Promise<IInfoChapter> {
    try {
      return await this.HIO.get<IInfoChapter>(`${CONTROLLER_ROOT}/one/${id}`);
    } catch (error) {
      console.error('🐸 Error: ', error);
    }
  }

  public async updateInfoChapter(id: string, dto: any): Promise<IInfoChapter> {
    try {
      return await this.HIO.patchItem<IInfoChapter, any>(`${CONTROLLER_ROOT}`, id, dto);
    } catch (error) {
      console.error('🐸 Error: ', error);
    }
  }

  public async addSectionToChapter(infoSectionId: string, infoChapterId: string): Promise<IInfoChapter> {
    try {
      return await this.HIO.post<IInfoChapter, any>(`${CONTROLLER_ROOT}/addSection`, { infoSectionId: infoSectionId, infoChapterId: infoChapterId });
    } catch (error) {
      console.error('🐸 Error: ', error);
    }
  }

  public async removeSectionToChapter(infoSectionId: string, infoChapterId: string): Promise<IInfoChapter> {
    try {
      return await this.HIO.patchItem<IInfoChapter, any>(`${CONTROLLER_ROOT}/removeSection`, infoChapterId, { sectionId: infoSectionId });
    } catch (error) {
      console.error('🐸 Error: ', error);
    }
  }

  public async createInfoChapter(dto: any): Promise<IInfoChapter> {
    try {
      return await this.HIO.post<IInfoChapter, any>(`${CONTROLLER_ROOT}`, dto);
    } catch (error) {
      console.error('🐸 Error: ', error);
      return null;
    }
  }

  public getAllInfoChapterCountedFilteredAndPaginated(pageIndex?: number, search?: string): Observable<RecordCounted<IInfoChapter>> {
    return this.commonService.getAndCount$({
      controllerRoot: CONTROLLER_ROOT,
      controllerDir: 'filter-paginate',
      page: pageIndex,
      search: search
    })
  }

  public async deleteChapter(chapterId: string): Promise<boolean> {
    try {
      await await this.HIO.deleteItem(CONTROLLER_ROOT, chapterId);
      return true;
    } catch (error) {
      console.error('🐸 Error: ', error);
      return false;
    }
  }

  ChapterTypeToOptions(): any[] {
    return Object.keys(ChapterType).map(key => ({
      value: ChapterType[key],
      label: key
    }));
  }

  ChapterVisualizationPhaseOptions(): any[] {
    let phases: any = [];
    phases.push({
      value: ChapterVisualizationPhase.APP_START,
      label: this.translate.instant("LABEL.APPLICATION_START_UP"),
    });
    phases.push({
      value: ChapterVisualizationPhase.LOGIN,
      label: this.translate.instant("LABEL.LOGIN"),
    });
    phases.push({
      value: ChapterVisualizationPhase.BEFORE_STARTING_SESSION,
      label: this.translate.instant("LABEL.BEFORE_CHARGING_SESSION"),
    });
    phases.push({
      value: ChapterVisualizationPhase.ONLY_INFO_SECTION,
      label: this.translate.instant("LABEL.ONLY_IN_INFO_SECTION"),
    });
    return phases;
  }

  async getGroupsByInfoChapterAsOptions(infoChapterId: string): Promise<number[]> {
    const groups = await this.getGroupsForInfoChapter(infoChapterId);
    return groups?.map(group => group.id);
  }

  async getGroupsForInfoChapter(infoChapterId: string): Promise<IGroup[]> {
    try {
      return this.HIO.get<IGroup[]>(`${CONTROLLER_ROOT}/groups-for-info-chapter/${infoChapterId}`);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { IPod, IStation } from 'common_library';
import { PodService } from 'src/app/services/entities/pod.service';
import { StationService } from 'src/app/services/entities/station.service';

@Component({
  selector: 'app-block-pod',
  templateUrl: './block-pod.component.html',
  styleUrls: ['./block-pod.component.scss']
})
export class BlockPodComponent implements OnInit, OnChanges {
  
  @Input() podId: number;
  @Input() stationId: string;
  @Input() isRefreshActive: boolean = false;
  @Output() updateClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  pod: IPod;
  loading: boolean;

  constructor(private stationService: StationService, private router: Router, private podService: PodService) { }

  ngOnInit() {
    this.checkIfKeyNeeded('masterStation')
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.podId?.currentValue || changes.stationId?.currentValue) {
      await this.getItem()
    }
  }

  async getItem() {
    this.loading = true;
    if (this.podId) {
      this.pod = await this.podService.getPodByIdAdminBlock(this.podId)
    } else if (this.stationId) {
      this.pod = await this.podService.getPodByStationIdAdminBlock(this.stationId)
    }
    this.loading = false;
  }

  async refreshItem() {
    await this.getItem();
  }

  async checkIfKeyNeeded(key: string) {
    if(this.pod?.masterStationId && !this.pod.masterStation) {
      this.pod.masterStation = await this.getMasterStation(this.pod.masterStationId);
    }
  }

  async getMasterStation(id: string): Promise<IStation> {
    return await this.stationService.getOneStation(id);
  }

  goToPodDetail() {
    this.router.navigate([`authenticated/pods/${this.pod.id}`]);
  }
}


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormControl, Validators } from '@angular/forms';
import { EntitySelectorOptions, EntitySelectorOptionsTemplate } from '../entitySelectorOptions';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'entity-selection',
  templateUrl: './entity-selection.component.html',
  styleUrls: ['./entity-selection.component.scss']
})
@UntilDestroy()
export class EntitySelectionComponent implements OnInit {
  @Input() control: FormControl;
  @Input() selector: EntitySelectorOptions;
  @Input() template: EntitySelectorOptionsTemplate[][];

  @Input() label: string;
  @Input() hint: string;
  @Input() mode: 'multiple' | 'default' = 'default';
  @Input() placeholder: string = '';
  @Input() clearable: boolean = true;
  @Input() icon: string;

  @Output() onEntityChanged = new EventEmitter<any | any[]>();

  isRequired$ = new BehaviorSubject<boolean>(false);

  entityChange(entity: any) {
    const value = !!entity ? this.mode === 'default' ? entity.id : entity.map(u => u.id) : entity;
    this.control.patchValue(value);
    this.control.markAsDirty();
    this.onEntityChanged.emit(entity);
  }

  ngOnInit(): void {
    this.control = this.control ?? new FormControl(this.mode === 'default' ? '' : []);
    this.isRequired$.next(this.control.hasValidator(Validators.required))
  }
}

<ng-container *ngIf="(template$ | async) as template">
  <nz-input-group *ngIf="selector.showSearchBtn; else liveSearch" [nzSuffix]="suffixIconSearch" class="search-padding">
    <input type="text" nz-input [placeholder]="(placeholder | translate)" [(ngModel)]="valueWithButton"
      (ngModelChange)="updateSearchWithButtonString($event)" />
    <ng-template #suffixIconSearch>
      <button nz-button nzType="primary" nzSearch [disabled]="!(searchButtonEnabled$|async)"
        (click)="filterEntities(valueWithButton)"><span nz-icon nzType="search"></span>
      </button>
    </ng-template>
  </nz-input-group>

  <ng-template #liveSearch>
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input type="text" nz-input [placeholder]="(placeholder | translate)" [(ngModel)]="value"
        (ngModelChange)="filterEntities($event)" />
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search"></span>
      </ng-template>
    </nz-input-group>
  </ng-template>

  <ng-container *ngIf="(items$ | async)?.items as items">
    <nz-alert *ngIf="(showLimitAlert$|async)" class="mt-4" nzType="info"
      nzMessage="{{ 'ENTITY_SELECTOR.LIMIT_REACHED_MESSAGE' | translate :  {nItem: getSelectorLimit()} }}"
      nzShowIcon></nz-alert>
    <nz-card *ngIf="items.length>0 ; else noResults" class="mt-4 p-2 issue-container">
      <nz-list [nzLoading]="(isLoading$ | async)">
        <div (click)="toggleSelectedItem(item.id)" *ngFor="let item of items; let i = index"
          [class]="isItemSelected(item.id)?'selected-item':''" class="m-2 p-2">
          <div nz-row [nzGutter]="16" *ngFor="let templateRow of template" class="pointer">
            <div nz-col [nzSpan]="24">
              <ng-container *ngFor="let templateRowItem of templateRow">
                <ng-container *ngIf="templateRowItem.type; else defaultItem">
                  <container-element [ngSwitch]="templateRowItem.type">
                    <ng-container *ngSwitchCase="'tasktype'">
                      <span style="vertical-align: middle;" *ngIf="item.taskTypeIcon !== 'none'" nz-icon>
                        <!-- <svg-icon size="20" [name]="item.taskTypeIcon"></svg-icon> -->
                      </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'state'">
                      <nz-tag class="ml-2" style="vertical-align: middle;" [nzColor]="item.backgroundColor"
                        [style.color]="computeTextColor(item.backgroundColor)">{{
                        item.state }}</nz-tag>
                    </ng-container>
                  </container-element>
                </ng-container>
                <ng-template #defaultItem>
                  <span *ngIf="item[templateRowItem.prop]" class="pr-2" style="vertical-align: middle;"><b
                      *ngIf="templateRowItem.label"
                      class="pr-1">{{templateRowItem.label}}:</b>{{item[templateRowItem.prop]}}</span>
                </ng-template>
              </ng-container>
            </div>
          </div>
          <nz-divider *ngIf="i<items.length-1"
            style="margin-top: 0px!important;; margin-bottom: 0px!important;"></nz-divider>
        </div>
      </nz-list>
    </nz-card>
    <ng-template #noResults>
      <ng-container *ngIf="(showNoResultsAlert$|async)">
        <nz-alert class="mt-4" nzType="warning" nzMessage="{{ 'ENTITY_SELECTOR.NO_RESULTS_MESSAGE' | translate }}"
          nzShowIcon>
        </nz-alert>
      </ng-container>
    </ng-template>
  </ng-container>
</ng-container>

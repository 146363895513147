import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    private app: AppService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      eMail: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  async submitForm() {
    if (this.validateForm.valid) {
      try {
        const user = await this.auth.login(
          this.validateForm.value.eMail,
          this.validateForm.value.password
        );
          this.app.createNotification(
            'success',
            this.translate.instant("PHRASE.LOGGED"),
            `${this.translate.instant("PHRASE.USER_LOGIN")} ${user.email} ${this.translate.instant("PHRASE.SUCCESSFUL")}`
          );
          this.router.navigate(['/authenticated/welcome']);

      } catch (err) {
        console.error("Err", err)
        this.app.createNotification('error', this.translate.instant("PHRASE.ERROR"), this.translate.instant("PHRASE.SOMETHING_WRONG_TRY"));
      }
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}

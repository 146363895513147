export type ValueOf<T> = T[keyof T];

export const OCPIProfileType = Object.freeze({
  CHEAP: "CHEAP",
  FAST: "FAST",
  GREEN: "GREEN",
  REGULAR: "REGULAR",
});

export type OCPIProfileTypeKeys = keyof typeof OCPIProfileType;
export type OCPIProfileTypeValues = ValueOf<typeof OCPIProfileType>;
<app-block-header title="Tag Rfid" [isRefreshActive]="isRefreshActive" icon="audit"
  (refreshRequest)="refreshRfids()"></app-block-header>
<app-skeleton [loading]="loading">
  <ng-container *ngIf="rfids && rfids.length > 0; else noDati">
    <nz-table #smallTable nzSize="small" [nzData]="rfids" [nzPageSize]="100" [nzFrontPagination]="false"
      [nzShowPagination]="false" style="max-height: 300px; overflow: auto">
      <thead>
        <tr>
          <th></th>
          <th>{{'USER.DETAIL.REGISTRATION_DATE'|translate}}</th>
          <th>{{'USER.DETAIL.NAME'|translate}}</th>
          <th>{{'USER.DETAIL.CAR'|translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of smallTable.data" class="yf-pointer" [ngClass]="data.deletedAt? '': 'green'"
          (click)="selectRfid(data)">
          <td class="fs-16">
            <span nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="data.valid? 'blue': '#ccc'"
              style="font-size: 14pt" [nzTooltipTitle]="getTooltipTitleValid(data)"
              nzTooltipPlacement="top" nz-tooltip>
            </span>
            <span class="ml-4" nz-icon nzType="delete" [nzTheme]="'twotone'"
              [nzTwotoneColor]="data.deletedAt ? 'blue' : '#ccc'" style="font-size: 14pt"
              [nzTooltipTitle]="getTooltipTitleDeleted(data)" nzTheme="outline"
              nzTooltipPlacement="top" nz-tooltip>
            </span>
          </td>
          <td>{{ data.createdAt | localizedDate:"medium" }}</td>
          <td>{{ data.name }}</td>
          <td>
            {{ data.car.model.brand.name + ' ' + data.car.model.name}}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>
  <ng-template #noDati>
    <app-empty-data></app-empty-data>
  </ng-template>
</app-skeleton>
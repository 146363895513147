import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IStatusNotification } from 'common_library';

@Component({
  selector: 'app-block-status-notifications',
  templateUrl: './block-status-notifications.component.html',
  styleUrls: ['./block-status-notifications.component.scss']
})
export class BlockStatusNotificationsComponent implements OnInit {
  @Input() isRefreshActive: boolean = false;
  @Input() statusNotifications: IStatusNotification[] = [];
  @Input() sessionStart: Date;
  @Output() updateClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }
  sendEvent(){
    this.updateClicked.emit();
  }
}

export type ValueOf<T> = T[keyof T];

export const OCPIFacilities = Object.freeze({
  HOTEL: 'HOTEL',
  RESTAURANT: 'RESTAURANT',
  CAFE: 'CAFE',
  MALL: 'MALL',
  SUPERMARKET: 'SUPERMARKET',
  SPORT: 'SPORT',
  RECREATION_AREA: 'RECREATION_AREA',
  NATURE: 'NATURE',
  MUSEUM: 'MUSEUM',
  BUS_STOP: 'BUS_STOP',
  TAXI_STAND: 'TAXI_STAND',
  TRAIN_STATION: 'TRAIN_STATION',
  AIRPORT: 'AIRPORT',
  CARPOOL_PARKING: 'CARPOOL_PARKING',
  FUEL_STATION: 'FUEL_STATION',
  WIFI: 'WIFI'
})

export type OCPIFacilitiesValues = ValueOf<typeof OCPIFacilities>;
export type OCPIFacilitiesKeys = keyof typeof OCPIFacilities;
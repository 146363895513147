import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IConnector, ITariff } from 'common_library';
import { BehaviorSubject, Observable, Subject, debounceTime, switchMap } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { HttpIoService } from 'src/app/services/communication/http-io.service';
import { ConnectorService } from 'src/app/services/entities/connector.service';
import { TariffService } from 'src/app/services/entities/tariff.service';

@Component({
  selector: 'app-block-connectors-form',
  templateUrl: './block-connectors-form.component.html',
  styleUrls: ['./block-connectors-form.component.scss']
})
export class BlockConnectorsFormComponent implements OnInit, OnChanges {
  @Input() selectedConnector: IConnector;
  @Output() connectorUpdated = new EventEmitter<number>();
  connectorsAsOptions$ = new BehaviorSubject<{ label: string, value: string }[]>(null);

  tariffs$: Observable<ITariff[]>;
  tariffSearch$ = new Subject<string>();

  connectorForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private HIO: HttpIoService,
    private connectorService: ConnectorService,
    private tariffService: TariffService,
    private app: AppService,
    private translate: TranslateService
  ) { }

  async ngOnInit(): Promise<void> {
    // this.tariffs$.next([...await this.HIO.get<ITariff[]>('tariff/search'), { name: '', id: '' } as ITariff])

    this.connectorForm = this.formBuilder.group({
      name: new UntypedFormControl(),
      power: new UntypedFormControl(),
      powerLimit: new UntypedFormControl(),
      info: new UntypedFormControl(),
      tariffId: new UntypedFormControl()
    });

    this.tariffs$ = this.tariffSearch$.pipe(
      debounceTime(500),
      // filter((searchText) => !!searchText),
      switchMap(searchText => {
        if (searchText) { //evito di fare la chiamata che chieda tutti gli elementi: restituisco array vuoto
          return this.getTariffByString(searchText);
        }
        else {
          return [];
        }
      })
    )
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.selectedConnector?.currentValue) {
      await this.getItem();
    }
  }

  async submit() {
    try {
      if (!this.connectorForm.dirty) return;
      const dto = {
        name: this.connectorForm.get('name').value?.trim(),
        info: this.connectorForm.get('info').value?.trim(),
        power: this.connectorForm.get('power').value ? this.connectorForm.get('power').value : null,
        powerLimit: this.connectorForm.get('powerLimit').value ? this.connectorForm.get('powerLimit').value : null,
        tariffId: this.connectorForm.get('tariffId').value
      }
      let rv = await this.HIO.patch(`connector/${this.selectedConnector.id}`, dto);
      if (!!rv) {
        this.app.createNotification(
          'success',
          this.translate.instant("PHRASE.UPDATE_DONE"),
          this.translate.instant("PHRASE.CONNECTOR_UPDATE_SUCCESSFULLY"),
        )
        this.connectorUpdated.emit(this.selectedConnector.id);
      } else {
        this.app.createNotification('error', this.translate.instant("PHRASE.ERROR"), this.translate.instant("PHRASE.SOMETHING_WRONG_TRY"))
      }
    } catch (err) {
      this.app.createNotification('error', this.translate.instant("PHRASE.ERROR"), this.translate.instant("PHRASE.SOMETHING_WRONG_TRY"))
    }
  }

  async refresh() {
    await this.getItem();
  }

  async getItem() {
    this.connectorForm.reset();
    if (this.selectedConnector?.id) {
      const connector = await this.connectorService.getOneAdminBlock(this.selectedConnector.id);
      if (connector.tariffId) {
        let tariff = await this.tariffService.getTariffById(connector.tariffId);
        if (tariff) {
          this.onInputTariffStation(tariff.name);
        }
      }

      this.connectorForm.setValue({
        name: connector.name,
        power: connector.power,
        powerLimit: connector.powerLimit,
        info: connector.info,
        tariffId: connector.tariffId
      });
    }
  }

  async onInputTariffStation(event) {
    this.tariffSearch$.next(event);
  }

  async getTariffByString(stringToFind: string): Promise<ITariff[]> {
    return await this.tariffService.getTariffsByString(stringToFind);
  }
}

import { Injectable } from '@angular/core';
import { HttpIoService } from '../communication/http-io.service';
import { INotification, RecordCounted } from 'common_library';

const CONTROLLER_ROOT = 'notification';

@Injectable({ providedIn: 'root' })
export class NotificationService {

  constructor(private HIO: HttpIoService) {}

  async getAllNotification(): Promise<INotification[]> {
    try {
      const rv = await this.HIO.get<INotification[]>(`${CONTROLLER_ROOT}/all-for-dashboard`)
      return rv
    } catch (err) {
      console.error(err)
    }
  }

  //Per utente o tipologia notifica
  async getNotificationsByString(text: string): Promise<INotification[]> {
    try {
      const rv = await this.HIO.get<INotification[]>(`${CONTROLLER_ROOT}/search/${text}`)
      return rv
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getNotificationById(id: number): Promise<INotification> {
    try {
      const rv = await this.HIO.get<INotification>(`${CONTROLLER_ROOT}/${id}`);
      return rv;
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getNotificationByUserPaginated(userId: string, page: number): Promise<RecordCounted<INotification>> {
    try {
      const rv = await this.HIO.post<RecordCounted<INotification>, any>(`${CONTROLLER_ROOT}/by-user-paginated/${userId}`, {pageIndex: page - 1});
      return rv;
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IInfoChapter, ChapterType } from 'common_library';
import { Subscription } from 'rxjs';
import { InfoChapterService } from 'src/app/services/entities/info-chapter.service';
import { MultimediaService } from 'src/app/services/entities/multimedia.service';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class DocsComponent implements OnInit {
  type: string = "";
  brand: string = "";
  sub: Subscription;
  chapterToShow: IInfoChapter = null;
  docTitle: string = "";
  brandName: string = "";
  brandVAT: string = "";
  brandEmail: string = "";

  constructor(
    private route: ActivatedRoute,
    private chapterService: InfoChapterService,
    public multimediaService: MultimediaService
  ) { }


  ngOnInit(): void {
    this.sub = this.route.paramMap.subscribe(async (params) => {
      this.type = params.get('type');
      this.brand = params.get('brand');


      //if (this.brand) {
      // leggo da db le info del brand
      this.brandName = "Y&F S.r.l";
      this.brandVAT = "03712100548";
      this.brandEmail = "support@yourfill.it";
      //}

      let chapters: IInfoChapter[] = await this.chapterService.getInfoChaptersByBrand(this.brand);      

      switch (this.type.toLowerCase()) {
        case "privacy":
          this.chapterToShow = chapters.find(c => c.type === ChapterType.PRIVACY);
          this.docTitle = "Privacy policy";
          break;
        case "terms-and-conditions":
          this.chapterToShow = chapters.find(c => c.type === ChapterType.TERMS_AND_CONDITIONS);
          this.docTitle = "Termini e condizioni";
          break;
        case "account-deletion":
          this.chapterToShow = chapters.find(c => c.type === ChapterType.ACCOUNT_DELETION);
          this.docTitle = "Eliminazione account";
          break;
      }

      if (this.chapterToShow && this.chapterToShow.infoSections && this.chapterToShow.infoSections.length > 0) {
        this.chapterToShow.infoSections.forEach(element => {
          element.html = element.infoSectionVersion[0]?.description;
        });
      }
    });
  }
}

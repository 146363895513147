<app-block-header title="Tokens" [isRefreshActive]="false" icon="fund"></app-block-header>
<ng-container *ngIf="tokens && tokens.length > 0; else noDati">
  <nz-table #smallTable nzSize="small" [nzData]="tokens" [nzPageSize]="100" [nzFrontPagination]="false"
    [nzShowPagination]="false" [nzScroll]="{ y: '240px' }" style="overflow: auto">
    <thead>
      <tr>
        <th>{{'USER.DETAIL.VALID'|translate}}</th>
        <th>{{'USER.DETAIL.CONTRACT_ID'|translate}}</th>
        <th>{{'USER.DETAIL.CREATED'|translate}}</th>
        <th>{{'USER.DETAIL.TYPE'|translate}}</th>
        <th>{{'USER.DETAIL.WHITELIST'|translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of smallTable.data" class="yf-pointer">
        <td>
          <span nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="data.valid? 'blue': '#ccc'"
            style="font-size: 14pt" [nzTooltipTitle]="getTooltipTitleValid(data)" nzTooltipPlacement="top"
            nz-tooltip></span>
        </td>
        <td>{{ data.contractId }}</td>
        <td>{{ data.createdAt | localizedDate:'medium'}}</td>
        <td>{{ data.type }}</td>
        <td>{{ data.whitelist }}</td>
      </tr>
    </tbody>
  </nz-table>
</ng-container>
<ng-template #noDati>
  <app-empty-data></app-empty-data>
</ng-template>
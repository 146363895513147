<nz-list nzItemLayout="vertical">
  <nz-list-item *ngFor="let version of logs | keyvalue">
    <nz-list-item-meta>
      <nz-list-item-meta-title>
        <span class="flip" nz-icon nzType="tag" nzTheme="twotone"></span>
        {{'ABOUT.VERSIONS_LOG.VERSION'|translate}}: {{ version.value['vers']}}
        <ng-container [ngSwitch]="version.value['info']">
          <ng-container *ngSwitchCase="'Prossima'">
            <nz-tag nzColor="green"> {{ version.value['info']}} </nz-tag>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <nz-tag nzColor="blue"> {{ version.value['info']}} </nz-tag>
          </ng-container>
        </ng-container>

      </nz-list-item-meta-title>

      <nz-list-item-meta-description>
        <div class="item">
          <nz-timeline>
            <ng-container *ngFor="let feature of version.value['items'] | keyvalue">
              <nz-timeline-item [nzColor]="dictColor[feature.value['type']]"> {{ feature.value['desc'] }}
              </nz-timeline-item>
            </ng-container>
          </nz-timeline>
        </div>
      </nz-list-item-meta-description>
    </nz-list-item-meta>
  </nz-list-item>
</nz-list>

<div class="container__text" *ngIf="_type !== 'none'">

  <div *ngIf="_label" class="label">{{ _label }}</div>
  <div *ngIf="_icon && _icon !== 'none'" class="icon"><i nz-icon [nzType]="_icon" nzTheme="outline"></i></div>

    <ng-container [ngSwitch]="_type">
      <div *ngSwitchCase="'text'" class="text" [ngClass]="{'truncate': isSmall}">{{ value }}</div>
      <div *ngSwitchCase="'date'" class="date">{{ value | localizedDate:"medium" }}</div>
      <div *ngSwitchCase="'number'" class="number">{{ value ? (value | number:'1.1-2') : '--'}}</div>
      <div *ngSwitchCase="'power'" class="number">{{ value | power}}</div>
      <div *ngSwitchCase="'powerLimit'" class="number">{{ value | power}}</div>
      <div *ngSwitchCase="'energy'" class="number">{{ value | energy}}</div>
      <div *ngSwitchCase="'currency'" class="number">{{ value | payment}}</div>
      <app-entity-tag *ngSwitchCase="'entity'" [entityType]="_itemKey" [entity]="value"
        [entityId]="value?.id"></app-entity-tag>
      <div *ngSwitchCase="'content'"><ng-content></ng-content></div>
      <app-entity-tag *ngSwitchCase="'tag'" [entityType]="_itemKey" [label]="value"></app-entity-tag>
      <app-timespan *ngSwitchCase="'timespan'" [fromDate]="value.from" [toDate]="value.to"></app-timespan>
      <app-list-entity *ngSwitchCase="'list-entity'" [entity]="value.category"></app-list-entity>
      <app-copy-value *ngSwitchCase="'id-clipboard'" [id]="value"></app-copy-value>
      <div *ngSwitchDefault>
        {{ value }}
      </div>
  </ng-container>
</div>
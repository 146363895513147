import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter, Subject, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  @Input() placeholder = ''
  @Input() debounceTime = 300;
  @Input() padding: boolean = true;
  @Input() disabled: boolean = false;

  @Input() text: string;
  @Output() textChange = new EventEmitter<string>();

  inputValue = new Subject<string>();

  constructor() { }

  ngOnInit() {
    this.inputValue.pipe(
      untilDestroyed(this),
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
      tap((value) => this.textChange.emit(value))
    ).subscribe();
  }

  ngOnDestroy() { }

  onInput(e: any) {
    this.inputValue.next(e.target.value);
  }

}

export type ValueOf<T> = T[keyof T];

export const PARKING_TYPES = Object.freeze({
  ALONG_MOTORWAY: "ALONG_MOTORWAY",
  PARKING_GARAGE: "PARKING_GARAGE",
  PARKING_LOT: "PARKING_LOT",
  ON_DRIVEWAY: "ON_DRIVEWAY",
})

export type PARKING_TYPESValues = ValueOf<typeof PARKING_TYPES>;
export type PARKING_TYPESKeys = keyof typeof PARKING_TYPES;
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ITariff } from 'common_library';
import { TariffService } from 'src/app/services/entities/tariff.service';

@Component({
  selector: 'app-block-tariff',
  templateUrl: './block-tariff.component.html',
  styleUrls: ['./block-tariff.component.scss']
})
export class BlockTariffComponent implements OnChanges {

  @Input() tariffId?: string;
  @Input() tariff: ITariff;

  constructor(private router: Router, private tariffService: TariffService) { }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.tariffId?.currentValue) {
      await this.getItem();
    }
  }

  async getItem() {
    if (!this.tariffId) return;
    this.tariff = await this.tariffService.getOneTariffAdminBlock(this.tariffId);
  }

  goToTariffDetail() {
    this.router.navigate([`authenticated/tariffs/editor/${this.tariff.id}`])
  }
}

import { Injectable } from '@angular/core';
import { HttpIoService } from '../communication/http-io.service';
import { IConnector, IMeterValue, ISession, IStation, IStatusNotification, IStopTransaction, RecordCounted, IUser, ITariff, CU, TariffUtil, OCPITariffDimension, RevenueDimension, OCPICdrDimensionType, ISessionUserDTO } from 'common_library';
import { SELECT_DATE_RANGE, SELECT_SESSIONS } from 'src/app/pages/sessions/sessions.component';
import { Observable } from 'rxjs';

const CONTROLLER_ROOT = 'session';

@Injectable({ providedIn: 'root' })
export class SessionService {

  constructor(private HIO: HttpIoService) { }

  dateCalcToArrayOfDates(time: SELECT_DATE_RANGE): Date[] {
    const today = new Date();
    today.setHours(0, 0, 0);
    switch (time) {
      case SELECT_DATE_RANGE.TODAY:
        return [today, today];// nel backend l'orario delle date viene formattato così: la prima alle 00:00:00 e la seconda alle 23:59:59
      case SELECT_DATE_RANGE.YESTERDAY:
        const yesterday = new Date(today.setDate(today.getDate() - 1));
        return [yesterday, yesterday]; // nel backend l'orario delle date viene formattato così: la prima a mezzanotte e la seconda alle 23:59:59
      case SELECT_DATE_RANGE.THIS_WEEK:
        const thisDayW = new Date()
        const thisWeek = new Date(today.setDate(today.getDate() - (today.getDay() - 1)));
        return [thisWeek, thisDayW];
      case SELECT_DATE_RANGE.LAST_WEEK:
        const thisDay = new Date()
        thisDay.setHours(0, 0, 0)
        const lastWeek = new Date(today.setDate(today.getDate() - (today.getDay() + 6)));
        const lastDay = new Date(thisDay.setDate(thisDay.getDate() - (thisDay.getDay() - 1)));
        return [lastWeek, lastDay];
    }
  }

  async getSessionByUserId(userId: string): Promise<ISession[]> {
    const session = await this.HIO.get<ISession[]>(`session/all-user/${userId}`);
    return session;
  }

  /**
   *
   *
   * @param param oggetto che contiene tutte le variabili per gestire i vari casi
   * @returns un oggetto contenente data un array di ISession[] e count conto  totale dei record recuperati
   */
  /*
  async getSessionsByDate(param: { dateFilter?: Date[], pageNumber?: number, sessionActive?: boolean }): Promise<RecordCounted<ISession>> {
    return await this.HIO.post<RecordCounted<ISession>, any>(`${CONTROLLER_ROOT}/paginated`, { dates: param.dateFilter, pageNumber: param.pageNumber - 1, sessionActive: param.sessionActive });
  }
  */

  getSessionsByDate$(param: { dateRange?: Date[], pageIndex?: number, sessionState: SELECT_SESSIONS }): Observable<RecordCounted<ISession>> {
    return this.HIO.post$<RecordCounted<ISession>, any>(`${CONTROLLER_ROOT}/paginated`, {
      dates: param.dateRange,
      pageNumber: param.pageIndex ? param.pageIndex - 1 : 0,
      sessionActive: param.sessionState === SELECT_SESSIONS.ACTIVE ? true : false
    });
  }

  async getOneSession(id: string): Promise<ISession> {
    return await this.HIO.get<ISession>(`${CONTROLLER_ROOT}/one/${id}`)
  }

  async getOneSessionAdmin(id: string): Promise<ISession> {
    return await this.HIO.get<ISession>(`${CONTROLLER_ROOT}/admin/one/${id}`)
  }

  async getOneSessionAdminBlock(id: string): Promise<ISession> {
    return await this.HIO.get<ISession>(`${CONTROLLER_ROOT}/admin/block/one/${id}`)
  }

  async getSessionAdminByStationIdPaginated(stationId: string, pageIndex: number): Promise<RecordCounted<ISessionUserDTO>> {
    const session = await this.HIO.post<RecordCounted<ISession>, {pageIndex: number}>(`${CONTROLLER_ROOT}/admin/block/by-station-paginated/${stationId}`, {pageIndex: pageIndex - 1});
    return session;
  }

  async getSessionAdminByUserIdPaginated(userId: string, pageIndex: number): Promise<RecordCounted<ISessionUserDTO>> {
    return await this.HIO.post(`${CONTROLLER_ROOT}/admin/block/by-user-paginated/${userId}`, {pageIndex: pageIndex - 1});
  }

  async getMeterValues(id: string): Promise<IMeterValue[]> {
    return await this.HIO.get<IMeterValue[]>(`metervalue/session/${id}`);
  }

  async getStatusNotifications(id: string): Promise<IStatusNotification[]> {
    return await this.HIO.get<IStatusNotification[]>(`statusnotification/session/${id}`);
  }

  async getStopTransactions(id: string): Promise<IStopTransaction[]> {
    return await this.HIO.get<IStopTransaction[]>(`stoptransaction/session/${id}`);
  }

  async getDistinctMetervalues(sessionId: string) {
    const dMV = await this.HIO.get<any>(`metervalue/keys/${sessionId}`)
    dMV.map((s, i) => { return { id: i, name: s } })
    let arr = dMV.map((s, i) => { return { id: s, name: s } })
    return arr;
  }

  calculateCost(session: ISession, withVat?: boolean): ISession {
    const rv = TariffUtil.calculateSessionCosts(session);

    const energy = rv.find((e) => e.type === OCPICdrDimensionType.ENERGY);
    const reservation = rv.find((e) => e.type === "RESERVATION_TIME");
    const parkingTime = rv.find((e) => e.type === OCPICdrDimensionType.PARKING_TIME);
    const totalCost = {
      exclVat: CU.roundNumber(energy?.price.exclVat + reservation?.price.exclVat + parkingTime?.price.exclVat),
      inclVat: CU.roundNumber(energy?.price.inclVat + reservation?.price.inclVat + parkingTime?.price.inclVat)
    }
    return {
      ...session,
      energyCost: energy?.price.inclVat,
      reservationCost: reservation?.price.inclVat,
      occupationCost: parkingTime?.price.inclVat,
      totalCost: totalCost?.inclVat
    }
  }

  async stopSession(sessionId: string) {
    try {
      await this.HIO.get(`${CONTROLLER_ROOT}/quit/${sessionId}`)
    } catch (err) {
      console.error(err);
    }
  }
}

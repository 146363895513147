import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-empty-data',
  template: `
     <nz-empty
        nzNotFoundImage="simple"
        [nzNotFoundContent]="contentTpl"
        >
        <ng-template #contentTpl>
          <span>{{this.translate.instant("USER.DETAIL.NO_DATA")}}</span>
        </ng-template>
    </nz-empty>
  `,
  styles: [
  ]
})
export class EmptyDataComponent implements OnInit {
  @Input() title: string = this.translate.instant("USER.DETAIL.NO_DATA");
  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

}

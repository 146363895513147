import { IMapBounds } from "src/map.interface";

export module IClientSocket {

  export module Dto {

    export class HandshakeData {
      clientId: string;
    }

    export type MapBounds = IMapBounds;
  }

  export type ValueOf<T> = T[keyof T];

  export const ClientEvents = Object.freeze({
    Handshake: 'handshake',
    GetInstallationById: 'GetInstallationById',
    CurrentBounds: "current_bounds",
    GetClusterByIds: 'GetClusterByIds'
  });

  export type ClientEventsValues = ValueOf<typeof ClientEvents>;
  export type ClientEventsKeys = keyof typeof ClientEvents;
  

  export const ServerEvents = Object.freeze({
    SessionUpdates: "session_updates",
    InstallationsUpdates: "installations_updates",
    RfidUpdates: "rfid_updates",
  });

  export type ServerEventsValues = ValueOf<typeof ServerEvents>;
  export type ServerEventsKeys = keyof typeof ServerEvents;
}

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { throwIfEmpty } from 'rxjs/operators';


@Component({
  selector: 'simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss']
})
export class SimpleTableComponent implements OnInit {

  public readonly mainFields = ['name','title'];
  public readonly serviceFields = ['id','created_at','created_by','updated_at','last_updated_by','deleted_at','version'];

  @Input() loading: boolean = false;
  @Input() items: any[];

  @Input() width = '1000px';
  @Input() height = '640px';
  @Input() itemsPerPage = 25;

  constructor() { }

  ngOnInit(): void {

  }

  get fields():string[] {
    if(this.items && this.items[0]) {
      const nf:string[] = [];
      const sf:string[] = [];
      const rv:string[] = [];
      for(const f of Object.keys(this.items[0])) {
        if(this.serviceFields.includes(f))
          sf.push(f);
        else if(this.mainFields.includes(f)) {
          rv.push(f)
        } else {
          nf.push(f);
        }
      }
      return rv.concat(nf,sf);
    }
    else
      return [];
  }


  align(value:any): 'left' | 'right' | 'center' {
    if(typeof value === "number") return 'right';
    return 'left';
  }

  widthField(field:string) {
    if(field==="email") return "140px";
    return "80px";
  }

  format(value:any,field:string) {
    if(!value) return null;
    if(typeof(value)==="object") return Object.keys(value).length===0 ? "{}" : "{JSON}";
    if(field==="id") return value.toString().substring(0,8);
    if(value.toString().length>50) return "...";
    if(field.endsWith("_at")) return new Date(value).toLocaleString();
    return value;
  }
  log(value:any) {
    console.log(value);
  }

  // transform(value:string, fieldName:string, obj:any = null): string {
  //   // if(!value) return value;
  //   // if(fieldName.startsWith("Data")) {
  //   //   if(value.indexOf("T")>0) {
  //   //     const rv = CU.dateTime('D/M/Y H:mm.S',new Date(value));
  //   //     if(rv.endsWith(" 00:00.00")) return rv.substring(0,10);
  //   //     return rv;
  //   //   } else if(value.length===8 && value.startsWith("2") && /^[0-9]*$/.test(value)) {
  //   //     return value.substring(6,8) + "/" + value.substring(4,6) + "/" + value.substring(0,4);
  //   //   }
  //   // }
  //   // if(fieldName.toUpperCase()==="QTA") {
  //   //   const f = Number.parseFloat(value);
  //   //   let rv = f.toLocaleString("IT",{minimumFractionDigits:2});
  //   //   if(!!obj && obj["UMisura"]) rv += " " + obj["UMisura"];
  //   //   return rv;
  //   // }
  //   // return value;
  // }


}

<app-block-header title="{{'SESSION.DETAIL.CRS'|translate}}" icon="euro-circle" [isRefreshActive]="isRefreshActive"
  (refreshRequest)="refreshItem()">
</app-block-header>
<app-skeleton [loading]="loading">
  <div *ngIf="crs?.length>0; else noDati">
    <div class="mega-block read-only-details" id="table-status">
      <nz-table class="table-mvalues" nzTemplateMode nzSize="small" [nzData]="crs" [nzScroll]="{y: '260px', x: '250px'}"
        [nzShowPagination]="false">
        <thead>
          <tr>
            <th>{{'SESSION.DETAIL.ENTITY'|translate}} </th>
            <th>{{'SESSION.DETAIL.USER'|translate}} </th>
            <th style="text-align: center;">{{'SESSION.DETAIL.MOVEMENT'|translate}} </th>
            <th style="text-align: center;">{{'SESSION.DETAIL.PHASE'|translate}}</th>
            <th style="text-align: right;">{{'SESSION.DETAIL.AMOUNT'|translate}} </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of crs" class="yf-pointer" routerLink="/authenticated/cost-revenue-split/{{item.id}}">
            <td>{{ 'CRS.ACTORS.' + commonService.findEntityKey(_entityObject, item.entity).toUpperCase() | translate }}
            <td *ngIf="item.user; else noUser">
              <app-entity-tag [entity]="item.user" [isSmallContainer]="!!item.user.deletedAt"
                entityType="user"></app-entity-tag>
            </td>
            <ng-template #noUser>
              <td>
                {{ item.userId }}
              </td>
            </ng-template>

            <td style="text-align: center;">{{ (item.isInCredit ? 'CRS.DETAIL.CREDIT' : 'CRS.DETAIL.DEBIT') |translate}}
            </td>
            <td style="text-align: center;">{{ item.type }}</td>
            <td style="text-align: right;">{{ item.amountWithVat | payment }}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
  <ng-template #noDati>
    <app-empty-data>
    </app-empty-data>
  </ng-template>
</app-skeleton>
export type ValueOf<T> = T[keyof T];

export const SocialProvider = Object.freeze({
  // YOURFILL = 'YOURFILL',
  GOOGLE: 'GOOGLE',
  FACEBOOOK: 'FACEBOOOK',
  AMAZON: 'AMAZON',
  MICROSOFT: 'MICROSOFT',
  APPLE: 'APPLE'
});

export type SocialProviderValues = ValueOf<typeof SocialProvider>;
export type SocialProviderKeys = keyof typeof SocialProvider;
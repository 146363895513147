import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocsComponent } from './docs.component';
import { DocsRoutingModule } from './docs-routing.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzLayoutModule } from 'ng-zorro-antd/layout';

@NgModule({ declarations: [DocsComponent],
    exports: [DocsComponent], imports: [CommonModule,
        DocsRoutingModule,
        NzPageHeaderModule,
        NzLayoutModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class DocsModule { }

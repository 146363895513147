import { Injectable } from '@angular/core';
import { IClient } from 'common_library';
import { HttpIoService } from '../communication/http-io.service';

const CONTROLLER_ROOT = 'client';

@Injectable({ providedIn: 'root' })
export class ClientService {
  constructor(private HIO: HttpIoService) { }

  async getUserClientsAdminBlock(id: string): Promise<IClient[]> {
    try {
      return this.HIO.get<IClient[]>(`${CONTROLLER_ROOT}/admin/block/by-user/${id}`);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }
}
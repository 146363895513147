export type ValueOf<T> = T[keyof T];

export const IMAGE_CATEGORY = Object.freeze({
  CHARGER: "CHARGER",
  ENTRANCE: "ENTRANCE",
  LOCATION: "LOCATION",
  NETWORK: "NETWORK",
  OPERATOR: "OPERATOR",
  OTHER: "OTHER",
  OWNER: "OWNER"
})

export type IMAGE_CATEGORYValues = ValueOf<typeof IMAGE_CATEGORY>;
export type IMAGE_CATEGORYKeys = keyof typeof IMAGE_CATEGORY;
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPaymentAuth, CU } from 'common_library';
@Component({
  selector: 'app-block-menu-cdc',
  templateUrl: './block-menu-cdc.component.html',
  styleUrls: ['./block-menu-cdc.component.scss']
})
export class BlockMenuCdcComponent implements OnInit {
  @Input() paymentAuth: Partial<IPaymentAuth>;
  @Input() isRefreshActive: boolean = false;
  @Output() updateClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if(!!this.paymentAuth){
      this.paymentAuth['expirationDate'] = this.paymentAuth.cdcExpiryMonth + '/' + this.paymentAuth.cdcExpiryYear;
    }
  }

  sendEvent() {
    this.updateClicked.emit();
  }

}

export type ValueOf<T> = T[keyof T];

export const GestoreEntityType = Object.freeze({
  INSTALLATION: 'Installation',
  STATION: 'Station',
  CONNECTOR: 'Connector',
  POD: 'Pod',
  TARIFF: 'Tariff',
  GROUP: 'Group'
});

export type GestoreEntityTypeValues = ValueOf<typeof GestoreEntityType>;
export type GestoreEntityTypeKeys = keyof typeof GestoreEntityType;

<app-block-header title="{{'STATIONS.DETAIL.DETAILS_ACTIVITY_TIME'|translate}}" [isRefreshActive]="false" icon="fund"></app-block-header>
<nz-table #smallTable
  nzSize="small"
  [nzData]="upTimes"
  [nzPageSize]="100"
  [nzFrontPagination]="false"
  [nzShowPagination]="false"
  [nzScroll]="{ y: '240px' }"
  style="max-height: 300px; overflow: auto"
  [lang]="">

  <thead>
    <tr>
       <ng-container *ngFor="let item of list">
        <th>{{ item | translate }}</th>
       </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of smallTable.data">
      <td>{{ data.fromTs | localizedDate:'medium' }}</td>
      <td>{{ data.toTs | localizedDate:'medium' }}</td>
      <td><app-timespan [fromDate]="data.fromTs"  [toDate]="data.toTs"></app-timespan></td>
    </tr>
  </tbody>
</nz-table>

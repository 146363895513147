<app-block-header [isRefreshActive]="false"
                  title="{{'CDR.DETAIL.PAYMENT'|translate}}"
                  icon="credit-card">
</app-block-header>
<app-block-values 
  *ngIf="payment; else noDati" 
  [data]="payment"
  (goToItemDetail)="goToPaymentDetail()"
  [mainFields]="['createdAt','version','sessionId','amount', 'bankTransactionSate', 'bankTransactionType', 'cdr']">
</app-block-values>
<ng-template #noDati><app-empty-data></app-empty-data></ng-template>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'StationName'
})
export class StationName implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    const regex = /^([^*]*\*){3}[^*]*$/
    if (value && regex.test(value)) {
      const valueSplitted: string[] = value.split("*");
      return `${valueSplitted[1]}*${valueSplitted[2].slice(-4)}`;
    } else {
      return value;
    }
  }

}

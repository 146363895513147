import { RolesValues } from './enums/roles.enum';
import { ENERGY_SOURCE_CATEGORYValues } from './enums/energySource.enum';
import { EVSE_CAPABILITYValues } from './enums/evseCapability.enum';
import { EVSE_STATUS_Values } from './enums/evseStatus.enum';
import { VOLTAGEValues } from './enums/voltage.enum';
import { OCPIPowerTypeValues } from './enums/ocpiPowerType.enum';
import { OCPIConnectorTypeValues } from './enums/ocpiConnectorType.enum';
import { OCPIConnectorFormatValues } from './enums/ocpiConnectorFormat.enum';
import { IMAGE_CATEGORYValues } from './enums/imageCategory.enum';
import { PARKING_RESTRICTIONSValues } from './enums/parkingRestriction.enum';
import { PARKING_TYPESValues } from './enums/parkingType.enum';
import { OCPIFacilitiesValues } from './enums/ocpiFacility.enum';
import { TokenValues } from './enums/toknType.enum';

export const OCPI_SUCCESS_STATUS = Object.freeze({
  'GENERIC': 1000,
});

export const VERSION_URL = '/ocpi/emsp/versions';

export interface OCPICredential {
  url: string;
  token: string;
  roles: OCPIRoles[];
  // party_id: string;
  // country_code: string;
  business_details?: OCPIBusinessDetails;
}

export interface OCPIBusinessDetails {
  name: string;
  logo?: OCPIBusinessLogo;
  website?: string;
}

export interface OCPIRoles {
  role: RolesValues;
  party_id: string;
  country_code: string;
  business_details?: OCPIBusinessDetails;
}

export interface OCPIBusinessLogo {
  url: string;
  thumbnail: string;
  category: string;
  type: string;
  width: number;
  height: number;
}

export interface ILocation {
  country_code: string;
  party_id: string;
  id: string;
  publish: boolean;
  publish_allowed_to?: OCPIPublishTokenType;
  name?: string;
  address: string;
  city: string;
  postal_code?: string;
  state?: string;
  country: string;
  coordinates: OCPIGeoLocation;
  related_locations?: OCPIAdditionalGeoLocation; // enum additional geolocation
  parking_type?: PARKING_TYPESValues; // enum parking type
  evses: OCPIEvse[];
  directions?: OCPIDisplayText;
  operator?: OCPIBusinessDetails;
  suboperator?: OCPIBusinessDetails;
  facilities?: OCPIFacilitiesValues;
  time_zone: string;
  opening_times?: IOpeningTimes;
  charging_when_closed?: boolean;
  images?: OCPIImage; // image
  energy_mix?: OCPIEnergyMix // energy source
  last_updated: Date
}

export interface OCPIPeriod {
  period_begin: Date;
  period_end: Date;
}

export interface OCPIDayPeriod {
  weekday: number;
  period_begin: string;
  period_end: string;
}

export interface OCPIEnergyMix {
  is_green_energy: boolean;
  energy_sources: IEnergySource[];
  environ_impact: IEnergySource;
  supplier_name: string;
  energy_product_name: string;
}

export interface IEnergySource {
  source: ENERGY_SOURCE_CATEGORYValues;
  percentage: number;
}

export interface OCPIEnvironmentalImpact {
  category: ENERGY_SOURCE_CATEGORYValues;
  amount: number;
}

export interface OCPIPublishTokenType {
  uid?: string;
  type?: TokenValues;
  visual_number?: string;
  issuer?: string;
  group_id?: string;
}

export interface OCPIEvse {
  uid: string;
  evse_id?: string;
  status: EVSE_STATUS_Values;
  status_schedule?: STATUS_SCHEDULE;
  capabilities?: EVSE_CAPABILITYValues;
  connectors: OCPIConnector[];
  floor_level?: string;
  coordinates?: OCPIGeoLocation;
  physical_reference?: string;
  direction?: OCPIDisplayText;
  parking_restrictions?: PARKING_RESTRICTIONSValues;
  images?: OCPIImage;
  last_updated: Date;
}

export interface STATUS_SCHEDULE {
  period_begin: Date;
  period_end: Date;
  status: EVSE_STATUS_Values;
}

export interface OCPIConnector {
  id: string;
  metadata: any;
  standard: OCPIConnectorTypeValues;
  format: OCPIConnectorFormatValues;
  power_type: OCPIPowerTypeValues;
  max_voltage: number;
  max_amperage: number;
  max_electric_power: number;
  tariff_ids?: string;
  terms_and_conditions?: string;
  voltage: VOLTAGEValues;
  amperage: number;
  last_updated: Date;
}

export interface OCPIDisplayText {
  language: string;
  text: string;
}

export interface OCPIImage {
  url: string;
  thumbnail: string;
  category: IMAGE_CATEGORYValues;
  type: string;
  width: number;
}

export interface OCPIGeoLocation {
  latitude: string,
  longitude: string,
}
export interface OCPIAdditionalGeoLocation extends OCPIGeoLocation {
  name: string;
}

export interface IOpeningTimes {
  regular_hours?: OCPIDayPeriod[];
  twentyfourseven?: boolean;
  exceptional_openings?: OCPIPeriod[];
  exceptional_closings?: OCPIPeriod[];
}

export interface OCPIEntityBase {
  countryCode?: string;
  partyId?: string;
  id: string;
}
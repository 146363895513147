import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { GestoreService } from '../entities/gestore.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public AS: AuthService, private router: Router, private gestoreService: GestoreService) { }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.error.statusCode === 403) {
      this.router.navigate(['authenticated/unaccessible-resource']);
    }
    if (err.error.statusCode === 404) {
      this.router.navigate(['authenticated/notfound-resource']);
    }
    throw new HttpErrorResponse(err);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.AS.state && this.AS.state.accessToken) {
      req = req.clone({
        setHeaders: { 
          Authorization: `Bearer ${this.AS.state.accessToken}`,
         },
      });
    }
    return next.handle(req).pipe(catchError(x=> this.handleAuthError(x)));
  }
}

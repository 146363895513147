import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
// import { ID } from '@datorama/akita';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IExecutionQueryResult, IQuery, RecordCounted } from 'common_library';
import { HttpIoService } from '../communication/http-io.service';
import { CommonService } from '../common.service';
import { AppService } from '../app.service';


const CONTROLLER_ROOT = 'query';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  apiUrl;

  appService = inject(AppService);
  constructor(
    private _http: HttpClient,
    private HIO: HttpIoService,
    private commonService: CommonService
  ) {
    this.apiUrl = this.appService.apiUrl;
  }


  getQuery(id: string): Promise<IQuery> {
    try {
    return this.HIO.get<IQuery>(`${CONTROLLER_ROOT}/${id}`);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  selectQuery(id: any): Observable<IQuery> {
    return this._http.get<IQuery>(`${this.apiUrl}/api/query/${id}`);
  }

  execQuery(query: any, params: { [name: string]: number | string }, limit: number): Observable<IExecutionQueryResult> {
    const body = { query, params, limit };
    return this._http.post<IExecutionQueryResult>(`${this.apiUrl}/api/query/selector`, body);
  }

  getAndCountQueryPaginated(pageNumber?: number, searchString?: string): Observable<RecordCounted<IQuery>> {
    return this.commonService.getAndCount$({
      controllerDir: 'filter-and-count',
      controllerRoot: CONTROLLER_ROOT,
      page: pageNumber,
      search: searchString
    })
  };

  async createOrUpdateQueryByForm(dto: any): Promise<IQuery> {
    try {
      return await this.HIO.post<IQuery, any>(`${CONTROLLER_ROOT}`, dto)
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async delete(queryId: string): Promise<boolean> {
    try {
      await this.HIO.deleteItem(CONTROLLER_ROOT, queryId)
      return true;
    } catch (error) {
      console.error('🐱️ : error', error);
      return false;
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, interval, map, Subscription, takeWhile } from 'rxjs';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-spinner',
  template: `
  <div class="spinner-container" *ngIf="isShow">
    <div class="spinnner-header">
      <ng-template #indicatorTemplate><span class="spin" nz-icon nzType="loading"></span></ng-template>
      <nz-spin nzSimple nzSize="large" [nzIndicator]="indicatorTemplate"></nz-spin>
    </div>
  </div>
  `,
  styles: [
    `.spinner-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      position: fixed;
      align-items: center;
      background-color: black;
      z-index: 100000;
      inset: 0;
      opacity: 0.6;
    }
    .spin {
      font-size: 30px;
    }`
  ]
})
export class SpinnerComponent implements OnInit {
  @Input() isActive: BehaviorSubject<boolean>;
  activatedAt: number;
  isShow: boolean;

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    if (!this.isActive) {
      this.isActive = new BehaviorSubject(false);
    }
    this._progressionCore();
  }
  private _progressionCore() {
    this.isActive.subscribe((v) => {
      // if (v) {
      //   this.activatedAt = new Date().getTime();
      //   this.isShow = true;
      // } else {
      //   const duration = new Date().getTime() - this.activatedAt;
      //   if (duration > 1000) {
      //     this.isShow = false;
      //   } else {
      //     setTimeout(() => this.isShow = false, 10);
      //   }
      // };
    });
  }
}

import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, Subject, Subscription, debounceTime } from 'rxjs';
import { CPOs, ITariff, ISession, State, ITariffRevenues, TariffUtil, OCPICdrDimensionType, RevenueDimension } from 'common_library';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/services/translateConfig.service';

@Component({
  selector: 'app-tariff-simulator',
  templateUrl: './tariff-simulator.component.html',
  styleUrls: ['./tariff-simulator.component.scss']
})
export class TariffSimulatorComponent implements OnInit, OnChanges {
  isRefreshActive: boolean = false;
  form = new UntypedFormGroup({});
  session$ = new BehaviorSubject<ISession>(null);
  revenues$ = new Subject<ITariffRevenues>();
  tariffDescription$ = new BehaviorSubject("");

  @Input() tariff: ITariff;
  newTariff: ITariff;

  model = {};
  fields: FormlyFieldConfig[] = this.getUpdatedFields();

  private langChangeSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private translateConfig: TranslateConfigService
  ) {
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.fields = this.getUpdatedFields();
      this.getTariffDescription();
    });

    this.form.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (this.form.dirty) {
        this.onFormValueChanges(value);
      }
    });
  }

  ngOnInit(): void { }

  getUpdatedFields(): FormlyFieldConfig[] {
    return [
      {
        key: 'time',
        type: 'input',
        className: 'i-simulator ',
        props: {
          label: this.translate.instant("RATE.DETAIL.RESERVATION"),
        },
        validators: {
          'number': {
            expression: (c: AbstractControl) => !isNaN(Number(c.value)),
            message: (error: any, field: FormlyFieldConfig) => this.translate.instant("PHRASE.INSERT_NUMBER")
          }
        }
      },
      {
        key: 'energy',
        type: 'input',
        className: 'i-simulator',
        props: {
          label: this.translate.instant("RATE.DETAIL.ENERGY"),
        },
        validators: {
          'number': {
            expression: (c: AbstractControl) => !isNaN(Number(c.value)),
            message: (error: any, field: FormlyFieldConfig) => this.translate.instant("PHRASE.INSERT_NUMBER")
          }
        }
      },
      {
        key: 'parking',
        type: 'input',
        className: 'i-simulator ',
        props: {
          label: this.translate.instant("RATE.DETAIL.PARKING"),
        },
        validators: {
          'number': {
            expression: (c: AbstractControl) => !isNaN(Number(c.value)),
            message: (error: any, field: FormlyFieldConfig) => this.translate.instant("PHRASE.INSERT_NUMBER")
          }
        }
      },
      {
        key: 'power',
        type: 'input',
        className: 'i-simulator ',
        props: {
          label: this.translate.instant("RATE.DETAIL.CONNECTOR"),
        },
        validators: {
          'number': {
            expression: (c: AbstractControl) => !isNaN(Number(c.value)),
            message: (error: any, field: FormlyFieldConfig) => this.translate.instant("PHRASE.INSERT_NUMBER")
          }
        }
      },
    ];
  }

  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  onFormValueChanges(value: any) {
    const t: ITariff = this.tariff;
    const startDate = new Date();
    const s: ISession = {
      id: "1",
      reservedAt: value.time ? startDate : null,
      bookingEndAt: value.time ? new Date(startDate.getTime() + value.time * 60000) : null,
      startedAt: new Date(),
      cancelledAt: null,
      stoppedAt: value.parking ? startDate : null,
      endedAt: value.parking ? new Date(startDate.getTime() + value.parking * 60000) : null,
      power: 2.961,
      powerLimit: 22,
      energy: value.energy ? value.energy : 0,
      transactionId: 1,
      userId: '1',
      carId: 1,
      connectorId: 1,
      createdAt: new Date(),
      state: State.AVAILABLE,
      energyPrice: 0,
      reservationPrice: null,
      occupationPrice: null,
      innerState: null,
      metadata: null,
      cpo: CPOs.YOURFILL
    }

    try {
      const reservationTimeInSeconds = this.form.get("time")?.value * 60;
      const energyInWatt = this.form.get("energy")?.value * 1000;
      const parkingTimeInSeconds = this.form.get("parking")?.value * 60;
      const connectorPower = this.form.get("power")?.value * 1;
      const costs = TariffUtil.calculateCosts(reservationTimeInSeconds, energyInWatt, parkingTimeInSeconds, this.tariff, connectorPower, true);

      const costsValues = {
        reservationCost: costs.find(c => c.type === OCPICdrDimensionType.RESERVATION_TIME),
        energyCost: costs.find(c => c.type === OCPICdrDimensionType.ENERGY),
        parkingCost: costs.find(c => c.type === OCPICdrDimensionType.PARKING_TIME)
      }

      const updatedSession = {
        ...this.session$.getValue(),
        reservationCost: costsValues.reservationCost?.price.inclVat,
        energyCost: costsValues.energyCost?.price.inclVat,
        occupationCost: costsValues.parkingCost?.price.inclVat,
        totalCost: costsValues.reservationCost?.price.inclVat + costsValues.energyCost?.price.inclVat + costsValues.parkingCost?.price.inclVat
      };
      this.session$.next(updatedSession);

      let sesRevs: ITariffRevenues = {
        type: RevenueDimension.DEFAULT,
        brandOwner: 0,
        cpo: 0,
        deviceOwner: 0,
        emsp: 0,
        installationOwner: 0,
        podOwner: 0
      }
      let calculatedSesRevs = TariffUtil.calculateRevenues(reservationTimeInSeconds, energyInWatt, parkingTimeInSeconds, t, connectorPower);

      let mergedRevs = {
        ...sesRevs,
        brandOwner: calculatedSesRevs.brandOwner,
        cpo: calculatedSesRevs.cpo,
        deviceOwner: calculatedSesRevs.deviceOwner,
        emsp: calculatedSesRevs.emsp,
        installationOwner: calculatedSesRevs.installationOwner,
        podOwner: calculatedSesRevs.podOwner
      };

      this.revenues$.next(mergedRevs)
      this.cdr.detectChanges();
    } catch (e) {
      console.error(e)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getTariffDescription();
    this.onFormValueChanges(this.form.value)
  }

  getTariffDescription() {
    let descriptions = TariffUtil.createTariffAltText(this.tariff);
    let currentLanguage = this.translateConfig.getCurrentLang();
    this.tariffDescription$.next(descriptions.find(c => c.language === currentLanguage)?.text);
  }
}
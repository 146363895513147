<app-block-header title="Pods" [isRefreshActive]="false" icon="one-to-one"></app-block-header>
<ng-container *ngIf="pods && pods.length > 0; else noDati">
  <nz-table #smallTable nzSize="small" [nzData]="pods" [nzPageSize]="100" [nzFrontPagination]="false"
    [nzShowPagination]="false" style="max-height: 300px; overflow: auto">
    <thead>
      <tr>
        <th>{{'USER.DETAIL.NAME'|translate}}</th>
        <th>{{'USER.DETAIL.POWER'|translate}}</th>
        <th>{{'USER.DETAIL.AVAILABLE_POWER'|translate}}</th>
        <th>{{'USER.DETAIL.CURRENT_POWER'|translate}}</th>
        <th>{{'USER.DETAIL.ENERGY_MANAGEMENT'|translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of smallTable.data" routerLink="/authenticated/pods/{{data.id}}" class="yf-pointer">
        <td>{{data.name | titlecase}}</td>
        <td>{{data.power | power}}</td>
        <td>{{data.availablePower | power }}</td>
        <td>{{ data.meterValue | power }}<br>
          <span>{{data.meterValueTs | localizedDate:"medium"}}</span>
        </td>

        <td>
          <app-entity-tag entityType="energyManagement" label="{{data.energyManagement}}"></app-entity-tag>
        </td>
      </tr>
    </tbody>
  </nz-table>
</ng-container>
<ng-template #noDati>
  <app-empty-data></app-empty-data>
</ng-template>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-group-config-details',
  templateUrl: './group-config-details.component.html',
  styleUrls: ['./group-config-details.component.scss']
})
export class GroupConfigDetailsComponent implements OnInit {

  @Input() config: any;

  dictIcon = {
    'user': 'user',
    'station': 'usb',
    'group': 'ungroup',
    'pod': 'one-to-one',
    'tariff': 'euro-circle',
    'usergroup': 'group',
    'stationgroup': 'group',
  };

  constructor() { }

  ngOnInit(): void {
  }

}

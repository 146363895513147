export type ValueOf<T> = T[keyof T];

export const CdrState = Object.freeze({
  New: 0,
  Processing: 5,
  Done: 6,
  Failed: 7,
  NotExpected: 8,
  Review: 9,
  Reprocessing: 10,
});

export type CdrStateValues = ValueOf<typeof CdrState>;
export type CdrStateKeys = keyof typeof CdrState;
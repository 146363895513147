<app-block-header title="{{'SESSION.DETAIL.STOP_TRANS'|translate}}" [isRefreshActive]="isRefreshActive" icon="stop"
  (refreshRequest)="sendEvent()">
</app-block-header>
<div *ngIf="stopTransactions?.length>0; else noDati">
  <div class="block read-only-details" *ngFor="let item of stopTransactions$|async">
    <app-block-value [label]="item.label" [icon]="item.icon" [value]="item.value" [type]="item.type">
    </app-block-value>
  </div>
</div>
<ng-template #noDati>
  <app-empty-data></app-empty-data>
</ng-template>
import { Component, Input, OnInit } from '@angular/core';
import { ISession, IUser } from 'common_library';

type UserProperties = IUser['clients'] | IUser['paymentAuth'] | IUser['isAdmin'] | IUser['sessions']
type SessionProperies = ISession['endedAt']

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent implements OnInit {

  /**
   * the actual name of the nz-icon
   */
  @Input() iconType: string;
  /**
   * the factor that define if the icon must be active or not
   */
  @Input() isActive: UserProperties | SessionProperies;
  /**
   * the message presented when overing the icon
   */
  @Input() _nzToolTipTitle?: string;
  /**
   * defines if the icon to render is a social-login icon or not
   */
  @Input() isSocial: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public manageIconState(value): string {
    if(typeof value === "string") {
      return value !== '0' ? 'blue' : '#ccc';
    } else {
      return value ? 'blue' : '#ccc';
    }
  }

}

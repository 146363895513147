import { Component, Input } from "@angular/core";
import { Item } from "../services/common.service";

@Component({
  selector: 'app-item-value',
  template: `
  <div  [ngSwitch]="item.type">
      <div *ngSwitchCase="'text'" class="text">{{ item.value }}</div>
      <div *ngSwitchCase="'boolean'" class="text">
        <span *ngIf="!!item.value" nz-icon nzType="check-square" nzTheme="twotone" [nzTwotoneColor]="item.color || '#52c41a'"></span>
      </div>    
      <div *ngSwitchCase="'date'" class="date">
        <span *ngIf="item.fullLengthDate">{{ item.value | localizedDate:"medium" }}</span>
        <span *ngIf="!item.fullLengthDate">{{ item.value | localizedDate}}</span>
      </div>
      <div *ngSwitchCase="'number'" class="number">{{ item.value ? (item.value | number:'1.1-1') : '--'}}</div>
      <div *ngSwitchCase="'power'" class="number"><b>{{ item.value | power }}</b> {{ item.um }}</div>
      <div *ngSwitchCase="'powerLimit'" class="number">{{ item.value | power }} {{ item.um }}</div>
      <div *ngSwitchCase="'energy'" class="number"><b>{{ item.value | energy }}</b> {{ item.um }}</div>
      <div *ngSwitchCase="'currency'" class="number">
        <div *ngIf="item.value && item.value !==0; else nullMoney"><b>{{ item.value | number:'1.2-2' }}</b> {{ item.um }}</div>
        <ng-template #nullMoney>--</ng-template>
      </div>
      <div *ngSwitchCase="'metadata'" class="metadata">
        <pre>{{ item.value | json }}</pre>
        <!-- <ul>
          <app-ocpi-metadata maxHeight="unset" [metadata]="item.value"></app-ocpi-metadata>
        </ul> -->
      </div>

      <!-- TARIFF -->
      <div *ngSwitchCase="'tariff-text'">
        <nz-tag [nzColor]="'blue'">{{ item.value.languange?.toUpperCase() }}</nz-tag>
        <small>{{ item.value.text }}</small>
      </div>
      <div *ngSwitchCase="'tariff-revenue'">
        <nz-input-group *ngFor="let key of keys(item.value)" nzSize="small" [nzAddOnBefore]="key" nzAddOnAfter="%"><input type="number" nz-input [value]="item.value[key]*100" readonly /></nz-input-group>
      </div>
      <div *ngSwitchCase="'tariff-placeholder'">
        <div *ngFor="let key of keys(item.value)"><input type="number" nz-input [value]="item.value[key]*100" readonly />
      </div>
      </div>
      <!-- TARIFF:END -->

      <app-entity-tag *ngSwitchCase="'entity'" [entityType]="item.key" [entity]="item.value" [entityId]="item.value?.id"></app-entity-tag>
      <div *ngSwitchCase="'content'"><ng-content></ng-content></div>
      <div *ngSwitchCase="'none'" class="none"></div>
      <app-entity-tag *ngSwitchCase="'tag'" [entityType]="item.key" [label]="item.value"></app-entity-tag>
      <app-timespan *ngSwitchCase="'timespan'" [fromDate]="item.value.from" [toDate]="item.value.to"></app-timespan>
      <app-list-entity *ngSwitchCase="'list-entity'" [entity]="item.value"></app-list-entity>
      <app-copy-value *ngSwitchCase="'id'" [id]="item.value"></app-copy-value>
      <div *ngSwitchDefault>
        {{ item.value }} {{ item.um }}
      </div>

</div>
  `
})
export class ItemValueComponent {
  @Input() item: Item;

  keys(value: object): string[] {
    return Object.keys(value);
  }

}

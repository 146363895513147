export type ValueOf<T> = T[keyof T];

export const TOKEN_TYPE  = Object.freeze({
  /**
   * One time use Token ID generated by a server (or App)
   * The eMSP uses this to bind a Sessiont o a customer, probably an app user.
   */
  AD_HOC_USER: 'AD_HOC_USER',

  /**
   * Token ID generated by a server (or App) to identify a user of an App.
   * The same user uses the same Token for every Session
   */
  APP_USER: 'APP_USER',
  OTHER: 'OTHER',
  RFID: 'RFID'
})

export type TokenValues = ValueOf<typeof TOKEN_TYPE>;
export type TokenKeys = keyof typeof TOKEN_TYPE;
<app-block-header title="{{'USER.DETAIL.CLIENTS'|translate}}" icon="one-to-one" [isRefreshActive]="isRefreshActive"
  (refreshRequest)="refreshItem()">
</app-block-header>
<app-skeleton [loading]="loading">
  <ng-container *ngIf="clients && clients.length > 0; else noDati">
    <nz-table #smallTable nzSize="small" [nzData]="clients" [nzPageSize]="100" [nzFrontPagination]="false"
      [nzShowPagination]="false" [nzScroll]="{ y: '240px' }" style="max-height: 300px; overflow: auto">
      <thead>
        <tr>
          <th nzWidth="60px"></th>
          <th>User Agent</th>
          <th nzWidth="80px"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of smallTable.data">
          <td>
            <span nz-icon nzType="mobile" [nzTheme]="'twotone'" [nzTwotoneColor]="data.socketId ? 'blue' : '#ccc'"
              style="font-size: 14pt" nz-tooltip nzTooltipTitle="{{'NZTOOLTIPTITLE.CONNECTED'|translate}}"></span>
            <span nz-icon nzType="message" [nzTheme]="'twotone'" [nzTwotoneColor]="data.wpEndpoint ? 'blue' : '#ccc'"
              style="font-size: 14pt" nz-tooltip nzTooltipTitle="{{'NZTOOLTIPTITLE.WEB_NOTIFICATIONS_ALLOWED'|translate}}"></span>
          </td>
          <td><small>{{ data.userAgent }}</small></td>
          <td class="updatedAt">{{ data.updatedAt | localizedDate:"medium" }}</td>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>
  <ng-template #noDati>
    <app-empty-data></app-empty-data>
  </ng-template>
</app-skeleton>
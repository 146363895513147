<div class="container">
  <div class="container__title">
    <h4 class="yf-bold mr-4 title_flex-2" style="flex: 2; width: 100%;">
      <span nz-icon nzType="{{icon}}" nzTheme="outline">
      </span>
      {{title}}
    </h4>
    <ng-content>
    </ng-content>
  </div>
  <button *ngIf="isRefreshActive && !confirmation" class="yf-refresh-button-right" (click)="refresh()" nzSpaceItem nz-button>
    <i nz-icon [nzType]="refreshIcon ? refreshIcon : 'reload'" nzTheme="outline" [nz-tooltip]="tooltip ? tooltip : ''"></i>
  </button>
  <button *ngIf="isEditActive" class="yf-addnew-button-right" (click)="edit()" nzSpaceItem nz-button>
    <i nz-icon [nzType]="editIcon ? editIcon : 'edit'" nzTheme="outline" [nz-tooltip]="editTooltip ? editTooltip : ''"></i>
  </button>
  <button *ngIf="isAddNewActive" class="yf-addnew-button-right" (click)="addNew()" nzSpaceItem nz-button nzType="primary">
    <i nz-icon [nzType]="addNewIcon ? addNewIcon : 'plus'" nzTheme="outline" [nz-tooltip]="addNewTooltip ? addNewTooltip : ''"></i>
  </button>
  <button *ngIf="isRefreshActive && confirmation" 
    class="yf-refresh-button-right" 
    nz-popconfirm 
    [nzPopconfirmTitle]="popConfirm ? popConfirm : ('NZPOPCONFIRMTITLE.CONFIRM_QUESTION' | translate)"
    (nzOnConfirm)="refresh()" 
    nzSpaceItem 
    nz-button>
    <i nz-icon [nzType]="refreshIcon ? refreshIcon : 'reload'" nzTheme="outline" [nz-tooltip]="tooltip ? tooltip : ''"></i>
  </button>
</div>

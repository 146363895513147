import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { SessionService } from 'src/app/services/entities/session.service';
import { CPOs, IMeterValue, ISession } from 'common_library';
import { TranslateService } from '@ngx-translate/core';
const DEFAULT_VALUE_MEASURAND = 'Power.Active.Import';
const DEFAULT_VALUE_MEASURAND_OCPI = 'ENERGY'; // Perchè i nomi che abbiamo noi non sono chiari e quelli ocpi sì
@Component({
  selector: 'app-block-meter-values',
  templateUrl: './block-meter-values.component.html',
  styleUrls: ['./block-meter-values.component.scss']
})
export class BlockMeterValuesComponent implements OnChanges {

  measurandOptions$ = new BehaviorSubject<any>(null);
  filteredMeterValues: IMeterValue[];
  @Input() meterValues: IMeterValue[] = [];
  @Input() session: ISession;
  @Output() updateClicked: EventEmitter<IMeterValue> = new EventEmitter();
  @Input() isRefreshActive: boolean = false;
  SELECT_VALUE: string;
  sessionStart;
  formSub: Subscription;
  form = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};

  private langChangeSubscription: Subscription;

  fields: FormlyFieldConfig[] = this.getUpdatedFields();


  constructor(private sessionService: SessionService, private translate: TranslateService) {
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.fields = this.getUpdatedFields();
    });
  }


  getUpdatedFields(): FormlyFieldConfig[] {
    return [
      {
        key: 'measurand',
        type: 'select',
        defaultValue: this.session?.cpo !== CPOs.YOURFILL ? DEFAULT_VALUE_MEASURAND_OCPI : DEFAULT_VALUE_MEASURAND,
        className: 'form-measurand',
        props: {
          label: '',
          options: this.measurandOptions$,
          valueProp: 'id',
          labelProp: 'name',
          change: (field: FormlyFieldConfig) => {
            this.SELECT_VALUE = field.formControl.value;
            this.filterMeterValues();
          },
        },
        hooks: {
          onInit: (field: FormlyFieldConfig) => {
            this.SELECT_VALUE = field.formControl.value;
            this.filteredMeterValues = this.meterValues?.filter(v => v.measurand === field.formControl.value);
            this.filterMeterValues();
          }
        }
      }
    ];
  }

  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.session?.currentValue) {
      await this.getOptions();
    }
    else if (changes.meterValues?.currentValue) {
      this.filterMeterValues();
    }
  }

  refreshMeterValue() {
    this.updateClicked.emit();
  }

  formatDate(date: Date, sessionDate: Date) {
    let data1 = new Date(date);
    let data2 = new Date(sessionDate);
    let startingDate = data1.getFullYear() + '-' + data1.getMonth() + '-' + data1.getDate();
    let endingDate = data2.getFullYear() + '-' + data2.getMonth() + '-' + data2.getDate();
    if (startingDate == endingDate) {
      return 'HH:mm:ss';
    } else {
      return 'dd/MM/YYYY HH:mm:ss';
    }
  }

  private async getOptions() {
    const options = await this.sessionService.getDistinctMetervalues(this.session.id);
    this.measurandOptions$.next(options)
    this.sessionStart = (this.session.startedAt || this.session.createdAt || this.session.reservedAt);
  }

  private filterMeterValues() {
    this.filteredMeterValues = this.meterValues?.filter(v => v.measurand === this.SELECT_VALUE);
    this.filteredMeterValues?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());;
  }
}

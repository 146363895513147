import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BankTransactionType, IBankTransaction } from 'common_library';
import { CommonService } from 'src/app/services/common.service';
import { BankTransactionService } from 'src/app/services/entities/bank-transaction.service';

@Component({
  selector: 'app-block-bank-transaction-list',
  templateUrl: './block-bank-transaction-list.component.html',
  styleUrls: ['./block-bank-transaction-list.component.scss']
})
export class BlockBankTransactionListComponent implements OnChanges {

  @Input() sessionId: string;
  @Input() isRefreshActive: boolean = false;
  bankTransactions: IBankTransaction[];
  _BankTransactionType = BankTransactionType;
  loading: boolean;

  constructor(
    public commonService: CommonService,
    public bankTransactionService: BankTransactionService) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.sessionId?.currentValue) {
      await this.getItem();
    }
  }

  async getItem() {
    this.loading = true;
    if (this.sessionId) {
      this.bankTransactions = await this.bankTransactionService.getBankTransactionsAdminBlockBySessionId(this.sessionId);
    }
    this.loading = false;
  }

  async refreshItem() {
    await this.getItem();
  }
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit {
  @Input() title: string = null;
  @Output() newCloseEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  get visible(): boolean { return this._visible; }
  set visible(visible: boolean) {
    this._visible = visible;
  }
  private _visible = false;

  @Input()
  get width(): string {
    switch (this._width) {
      case "full-screen": 
        return "100%"
      case "large":
        return "70%";
      case "default":
        return "50%";
      default:
        return "50%";
    }
  }
  set width(width: string) {
    this._width = width;
  }
  private _width: string;

  childrenVisible = false;

  close(): void {
    this.newCloseEvent.emit();
  }

  openChildren(): void {
    this.childrenVisible = true;
  }

  closeChildren(): void {
    this.childrenVisible = false;
  }
}

export type ValueOf<T> = T[keyof T];

export const GestoreUserRole = Object.freeze({
  ADMINISTRATOR: 0,
  REPORT: 1,
  FINANCE: 2
});

export type GestoreUserRoleValues = ValueOf<typeof GestoreUserRole>;
export type GestoreUserRoleKeys = keyof typeof GestoreUserRole;

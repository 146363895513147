import { Component, OnInit } from '@angular/core';
import { CU, IConnector, IInstallation, ISession, StateValues, ResetType, TroubleshootingRequests  } from 'common_library';
import { _ } from 'src/app/consts';
import { InstallationService } from 'src/app/services/entities/installation.service';
import { NgStyleInterface } from 'ng-zorro-antd/core/types';
import { CoreService } from 'src/app/services/core.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SessionService } from 'src/app/services/entities/session.service';
import { AppService } from 'src/app/services/app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-map-installation-detail',
  templateUrl: './map-installation-detail.component.html',
  styleUrls: ['./map-installation-detail.component.scss']
})
export class MapInstallationDetailComponent implements OnInit {
  installation$ = this.installationService.mapSelectedInstallation$;
  installations$ = this.installationService.mapInstallations$;
  
  constructor(private installationService: InstallationService, 
    private coreService: CoreService, 
    private notificationService: NzNotificationService, 
    private sessionService: SessionService,
    public app: AppService,
    private translate: TranslateService) {
  }

  async ngOnInit(): Promise<void> {
    await this.installationService.checkLocalStorage();
  }

  getColorByState(state: StateValues): NgStyleInterface {
    return CU.getColorByState(state);
  }
  async stationSoftReset(id: string) {
    const result = await this.coreService.stationReset(
      id,
      ResetType.Soft
    );
    this.notificationService.create(
      'success',
      'Risultato richiesta',
      `${result}`
    );
  }
  async stationHardReset(id: string) {
    const result = await this.coreService.stationReset(
      id,
      ResetType.Hard
    );
    this.notificationService.create(
      'success',
      'Risultato richiesta',
      `${result}`
    );
  }

  async selectInstallation(installation: IInstallation) {
    await this.installationService.selectMapInstallation(installation.id);
  }

  async unlockConnector(c: IConnector) {
    const rv = await this.coreService.troubleshooting(TroubleshootingRequests.UnlockConnector, c.stationId, null, c.id, c?.evseId);
    this.notificationService.create('success', 'Risultato richiesta', rv);
  }

  async stopSession(s: ISession) {
    try {
      await this.sessionService.stopSession(s.id);

      this.notificationService.create('success', 'Sessione chiusa', 'La sessione è stata terminata.')
      await this.installationService.selectMapInstallation(s.connector.station.installation.id);
    } catch (err) {
      console.error(err);
      this.notificationService.create('error', 'Errore sessione', 'Non è possibile terminare la sessione.')
    }
  }

}

<div class="login" style="width: 100vw; height: 100vh">
  <img
    class="logo-login"
    src="assets/logos/logo_bianco_yourfill.svg"
    alt="YourFill logo"
  />
  <div class="container">
    <form
      nz-form
      [formGroup]="validateForm"
      class="login-form"
      (ngSubmit)="submitForm()"
    >
      <nz-form-item>
        <nz-form-control nzErrorTip="Inserisci la tua e-mail.">
          <nz-input-group nzPrefixIcon="user">
            <input
              type="text"
              nz-input
              formControlName="eMail"
              placeholder="{{'PLACEHOLDER.EMAIL'|translate}}"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Inserisci la tua password.">
          <nz-input-group nzPrefixIcon="lock">
            <input
              type="password"
              nz-input
              formControlName="password"
              placeholder="{{'PLACEHOLDER.PASSWORD'|translate}}"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <div nz-row class="login-form-margin">
        <!-- <div nz-col>
          <a class="login-form-forgot">Forgot password</a>
        </div> -->
      </div>
      <button nz-button class="login-form-button login-form-margin"  nzType="primary">
        <i nz-icon nzType="login" nzTheme="outline"></i>
        {{'BUTTONS.LOG_IN'|translate}}
      </button>
    </form>
  </div>
</div>

<ng-container [ngSwitch]="entityType" *ngIf="!loading">
  <ng-container *ngSwitchCase="'station'">
    <div [ngClass]="{'small-container': isSmallContainer}" (click)="navigate($event)" class="yf-fit-content">
      <nz-tag [ngClass]="{'truncate': isSmallContainer}" [nzColor]="'geekblue'" class="tag yf-pointer">
        <div class="entity-tag-content">
          <div class="tag-icon"><span nz-icon nzType="usb" nzTheme="outline"></span></div>
          <div class="tag-label">
            <div>{{ entity?.installation?.name?.substring(0,30).trim() }}</div>
            <div><b>{{ (entity?.name ? entity.name : label) | StationName }}</b></div>
          </div>
        </div>
      </nz-tag>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'connector'">
    <div [ngClass]="{'small-container': isSmallContainer}" (click)="navigate($event)" class="yf-fit-content">
      <nz-tag [ngClass]="{'truncate': isSmallContainer}" [nzColor]="'geekblue'" class="tag yf-pointer">
        <div class="entity-tag-content">
          <div class="tag-icon"><span nz-icon nzType="api" nzTheme="outline"></span></div>
          <div class="tag-label">
            <div>{{ entity?.name ? entity.name : label ? label : entity?.id }}</div>
          </div>
        </div>
      </nz-tag>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'user'">
    <div [ngClass]="{'small-container': isSmallContainer}" (click)="navigate($event)" class="yf-fit-content">
      <nz-tag [ngClass]="{'truncate': isSmallContainer}" [nzColor]="'purple'" class="tag yf-pointer">
        <div class="entity-tag-content">
          <div class="tag-icon"><span nz-icon nzType="user" nzTheme="outline"></span></div>
          <div class="tag-label">
            <div>{{ _label?.toUpperCase() }}</div>
          </div>
        </div>
      </nz-tag>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'online'">
    <span nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="entity ? 'blue': '#ccc'"
      style="font-size: 14pt" [nzTooltipTitle]="getTooltipTitleConnection(entity)" nzTooltipPlacement="top" nz-tooltip>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'visible'">
    <span nz-icon nzType="eye" nzTheme="twotone" [nzTwotoneColor]="entity ? 'blue': '#ccc'" style="font-size: 14pt"
      [nzTooltipTitle]="getTooltipTitleVisible(entity)" nzTooltipPlacement="top" nz-tooltip>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'reservationEnable'">
    <span nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="entity ? 'blue': '#ccc' "
      style="font-size: 14pt" [nzTooltipTitle]="getTooltipTitleReservation(entity)" nzTooltipPlacement="top" nz-tooltip>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'state'">
    <div (click)="navigate($event)" class="yf-fit-content">
      <nz-tag [nzColor]="dictStateColor[entity]" class="tag yf-pointer">
        <img class="state-image mr-3" src="{{baseURL}}/assets//stations//not_yfl_{{ entity }}.png">
        {{ getStateLabel(entity)}}
      </nz-tag>
    </div>
    <div>
      <ng-content select="div.description"></ng-content>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'stateonly'">
    <div (click)="navigate($event)" class="yf-fit-content">
      <img class="state-image-only mr-3 tag yf-pointer" src="{{baseURL}}/assets//stations//not_yfl_{{ entity }}.png">
    </div>
    <div>
      <ng-content select="div.description"></ng-content>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'group'">
    <div (click)="navigate($event)">
      <nz-tag [nzColor]="'cyan'" class="tag yf-pointer">
        <span nz-icon nzType="group" nzTheme="outline"></span> {{ label}}
      </nz-tag>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'usergroup'">
    <div (click)="navigate($event)">
      <nz-tag [nzColor]="'cyan'" class="tag yf-pointer">
        <span nz-icon nzType="group" nzTheme="outline"></span> {{ label}}
      </nz-tag>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'stationgroup'">
    <div (click)="navigate($event)">
      <nz-tag [nzColor]="'cyan'" class="tag yf-pointer">
        <span nz-icon nzType="group" nzTheme="outline"></span> {{ label}}
      </nz-tag>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'installation'">
    <div (click)="navigate($event)">
      <nz-tag [nzColor]="'lime'" class="tag yf-pointer">
        <span nz-icon nzType="cluster" nzTheme="outline"></span> {{ entity?.name ? entity.name : label}}
      </nz-tag>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'group_validation'">
    <div>
      <nz-tag nzColor="{{stateGroupValue? 'green' : 'red'}}" class="tag yf-pointer">
        <span nz-icon nz-icon [nzType]="stateGroupValue? 'check-circle': 'close-circle'" nzTheme="twotone"
          [nzTwotoneColor]="stateGroupValue? '#52c41a': '#eb2f96'"></span>
        <span>{{stateGroupValue? 'Valido' : 'Non valido'}}</span>
      </nz-tag>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'gateway'">
    <div (click)="navigate($event)" class="yf-fit-content">
      <nz-tag [nzColor]="'cyan'" class="tag yf-pointer" *ngIf="entity?.name; else noEntity">
        <div class="entity-tag-content">
          <div class="tag-icon"><span nz-icon nzType="apartment" nzTheme="outline"></span></div>
          <div class="tag-label">
            <div>{{ entity?.name ? entity.name : label }}</div>
          </div>
        </div>
      </nz-tag>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'energyManagement'">
    <div>
      <nz-tag class="tag yf-pointer">
        {{ dictEnergyManagement[label]}}
      </nz-tag>
    </div>
    <div>
      <ng-content select="div.description"></ng-content>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'pod'">
    <div *ngIf="entity?.name; else noEntity">
      <div (click)="navigate($event)" class="yf-fit-content">
        <nz-tag class="tag yf-pointer" nzColor="volcano">
          <div class="entity-tag-content">
            <div class="tag-icon"><span nz-icon nzType="one-to-one" nzTheme="outline"></span></div>
            <div class="tag-label">
              <div>{{entity?.name | titlecase}}</div>
            </div>
          </div>
        </nz-tag>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'section'">
    <div [ngClass]="{'small-container': isSmallContainer}" (click)="navigate($event)" class="yf-fit-content">
      <nz-tag [ngClass]="{'truncate': isSmallContainer}" [nzColor]="'geekblue'" class="tag yf-pointer"><span nz-icon
          nzType="align-left" nzTheme="outline"></span> {{ entity?.name ? entity.name : label }}
      </nz-tag>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'chapter'">
    <div [ngClass]="{'small-container': isSmallContainer}" (click)="navigate($event)" class="yf-fit-content">
      <nz-tag [ngClass]="{'truncate': isSmallContainer}" [nzColor]="'geekblue'" class="tag yf-pointer"><span nz-icon
          nzType="align-left" nzTheme="outline"></span> {{ entity?.name ? entity.name : label }}
      </nz-tag>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'multimedia'">
    <div [ngClass]="{'small-container': isSmallContainer}" (click)="navigate($event)" class="yf-fit-content">
      <nz-tag [ngClass]="{'truncate': isSmallContainer}" [nzColor]="'geekblue'" class="tag yf-pointer"><span nz-icon
          nzType="align-left" nzTheme="outline"></span> {{ entity?.name ? entity.name : label }}
      </nz-tag>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'session'">
    <div [ngClass]="{'small-container': isSmallContainer}" (click)="navigate($event)" class="yf-fit-content">
      <nz-tag [ngClass]="{'truncate': true}" [nzColor]="'geekblue'" class="tag yf-pointer">
        <div class="entity-tag-content">
          <div class="tag-icon"><span nz-icon nzType="align-left" nzTheme="outline"></span></div>
          <div class="tag-label">
            <div>{{ entity?.id ? entity.id : label }}</div>
          </div>
        </div>
      </nz-tag>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'cdr'">
    <div [ngClass]="{'small-container': isSmallContainer}" (click)="navigate($event)" class="yf-fit-content">
      <nz-tag [ngClass]="{'truncate': true}" [nzColor]="'geekblue'" class="tag yf-pointer">
        <div class="entity-tag-content">
          <div class="tag-icon"><span nz-icon nzType="align-left" nzTheme="outline"></span></div>
          <div class="tag-label">
            <div>{{ entity?.id ? entity.id : label }}</div>
          </div>
        </div>
      </nz-tag>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'tariff'">
    <div [ngClass]="{'small-container': isSmallContainer}" (click)="navigate($event)" class="yf-fit-content">
      <nz-tag [ngClass]="{'truncate': isSmallContainer}" nzColor="magenta" class="tag yf-pointer">
        <div class="entity-tag-content">
          <div class="tag-icon"><span nz-icon nzType="align-left" nzTheme="outline"></span></div>
          <div class="tag-label">
            <div>{{ entity?.name ? entity?.name.toUpperCase() : label }}</div>
          </div>
        </div>
      </nz-tag>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'bankTransaction'">
    <div [ngClass]="{'small-container': isSmallContainer}" (click)="navigate($event)" class="yf-fit-content">
      <nz-tag [ngClass]="{'truncate': isSmallContainer}" nzColor="magenta" class="tag yf-pointer">
        <div class="entity-tag-content">
          <div class="tag-icon"><span nz-icon nzType="align-left" nzTheme="outline"></span></div>
          <div class="tag-label">
            <div>{{ entity?.id ? entity?.id : label }}</div>
          </div>
        </div>
      </nz-tag>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <span>{{entityType}}</span>
  </ng-container>

  <ng-container *ngSwitchCase="'gestore'">
    <div [ngClass]="{'small-container': isSmallContainer}" (click)="navigate($event)" class="yf-fit-content">
      <nz-tag [ngClass]="{'truncate': isSmallContainer}" [nzColor]="'geekblue'" class="tag yf-pointer">
        <div class="entity-tag-content">
          <div class="tag-icon"><span nz-icon nzType="user" nzTheme="outline"></span></div>
          <div class="tag-label">
            <div><b>{{ !label ? entity?.id : label}}</b></div>
          </div>
        </div>
      </nz-tag>
    </div>
  </ng-container>
</ng-container>
<ng-template #noEntity>
  <span>--</span>
</ng-template>
<app-block-header [isRefreshActive]="false" title="{{'STATIONS.DETAIL.INTERACTION_WITH_STATION'|translate}}"
  icon="exclamation">
</app-block-header>

<div nz-row [nzGutter]="8" nzJustify="space-around">
  <div nz-col nzXs="24" nzSm="24" nzMd="12">
    <nz-divider nzText="{{'STATIONS.DETAIL.INTERACTION_WITH_STATION'|translate}}"></nz-divider>

    <div nz-row [nzGutter]="8" nzJustify="space-between" class="mt-10">
      <div nz-col nzXs="12" nzSm="12">
        <a nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM'|translate}}" (nzOnConfirm)="stopSession()">
          <button class="stop-button" nz-button nzDanger nzType="primary" [nzBlock]="true"
            [disabled]="buttonDisabled || !sessionId">
            {{'BUTTONS.CLOSE_SESSION'|translate}}
          </button>
        </a>
      </div>

      <div nz-col nzXs="12" nzSm="12">
        <a nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM'|translate}}"
          (nzOnConfirm)="unlockConnector()">
          <button class="stop-button" nzDanger nzSpaceItem nz-button nzType="primary" [nzBlock]="true"
            [disabled]="cpoId !== cpos.YOURFILL">
            {{'BUTTONS.UNLOCK_CONNECTOR'|translate}}
          </button>
        </a>
      </div>
    </div>

    <ng-container *ngIf="cpoId === cpos.YOURFILL">
      <nz-divider nzText="Reset"></nz-divider>
      <div nz-row [nzGutter]="8" nzJustify="space-between" class="mt-10">
        <div nz-col nzXs="12">
          <nz-select [(ngModel)]="resetVal" (ngModelChange)="setResetValue($event)">
            <ng-container *ngFor="let m of resets | keyvalue">
              <nz-option [nzValue]="m.value" [nzLabel]="m.value"></nz-option>
            </ng-container>
          </nz-select>
        </div>

        <div nz-col nzXs="12">
          <a class="mr-2" nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM'|translate}}"
            (nzOnConfirm)="reset()">
            <button class="stop-button" nzDanger nzSpaceItem nz-button nzType="primary" [nzBlock]="true">
              {{'BUTTONS.SEND_RESET'|translate}}
            </button>
          </a>
        </div>
      </div>

      <nz-divider nzText="{{'STATIONS.DETAIL.PROFILE_LOADING'|translate}}"></nz-divider>
      <div nz-row [nzGutter]="8" nzJustify="space-between" class="mt-10">
        <div nz-col nzXs="12">
          <nz-select [(ngModel)]="chargingProfileValue.name" (ngModelChange)="setChargingProfile($event)"
            class="padding-t-b">
            <ng-container *ngFor="let m of chargingProfileVals">
              <nz-option [nzValue]="m.name" [nzLabel]="m.name"></nz-option>
            </ng-container>
          </nz-select>
        </div>

        <div nz-col nzXs="12">
          <a class="mr-2" nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM'|translate}}"
            (nzOnConfirm)="sendChargingProfile()">
            <button class="stop-button" nzDanger nzSpaceItem nz-button nzType="primary" [nzBlock]="true">
              {{'BUTTONS.EDIT_LOADING_PROFILE'|translate}}
            </button>
          </a>
        </div>
      </div>

      <nz-divider nzText="Trigger message"></nz-divider>
      <div nz-row [nzGutter]="8" nzJustify="space-between" class="mt-10">
        <div nz-col nzXs="12">
          <nz-select [(ngModel)]="triggerMessageVal" (ngModelChange)="setTriggerValue($event)" class="padding-t-b">
            <ng-container *ngFor="let m of triggers | keyvalue">
              <nz-option [nzValue]="m.value" [nzLabel]="m.value"></nz-option>
            </ng-container>
          </nz-select>
        </div>

        <div nz-col nzXs="12">
          <a class="mr-2" nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM'|translate}}"
            (nzOnConfirm)="triggerMessage()">
            <button class="stop-button" nzSpaceItem nz-button nzType="primary" [nzBlock]="true">
              {{'BUTTONS.SEND_REQUEST'|translate}}
            </button>
          </a>
        </div>
      </div>

      <nz-divider nzText="Data transfer"></nz-divider>
      <div nz-row [nzGutter]="8" nzJustify="space-between" class="mt-10 dataTransferDiv">
        <div nz-col nzXs="24" nzSm="24">
          <form [formGroup]="formDataTransfer">
            <formly-form [model]="modelDataTransfer" [fields]="fieldsDataTransfer" [options]="optionsDataTransfer"
              [form]="formDataTransfer"></formly-form>
          </form>
        </div>
        <div nz-col nzXs="12" nzOffset="6">
          <a class="mr-2" nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM'|translate}}"
            (nzOnConfirm)="dataTransferJson()">
            <button class="stop-button" nzDanger nzSpaceItem nz-button nzType="primary" [nzBlock]="true"
              [disabled]="!formDataTransfer.valid">
              {{'BUTTONS.SEND'|translate}}
            </button>
          </a>
        </div>
      </div>

        <nz-divider nzText="{{'STATIONS.DETAIL.COMMUNICATE_LOG'|translate}}"></nz-divider>
        <div nz-row [nzGutter]="8" nzJustify="space-between" class="mt-10">
          <div nz-col nzXs="24" nzSm="14">
            <form [formGroup]="formLog" class="padding-t-b">
              <formly-form [form]="formLog" [model]="modelLog" [options]="optionsLog"
                [fields]="fieldsLog"></formly-form>
            </form>
          </div>

          <div nz-col nzXs="24" nzSm="10">
            <a class="mr-2" nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM'|translate}}"
              (nzOnConfirm)="sendGetDiagnostics()">
              <button class="stop-button" nzDanger nzSpaceItem nz-button nzType="primary" [nzBlock]="true"
                [disabled]="!formLog.valid">
                {{'BUTTONS.SEND_DIAGNOSTICS'|translate}}
              </button>
            </a>
          </div>
        </div>

      <nz-divider nzText="{{'STATIONS.DETAIL.FIRMWARE_UPDATE'|translate}}"></nz-divider>
      <div nz-row [nzGutter]="8" nzJustify="space-between" class="mt-10">
        <div nz-col nzXs="24" nzSm="14">
          <form [formGroup]="form" class="padding-t-b">
            <formly-form [form]="form" [model]="model" [options]="options" [fields]="fields"></formly-form>
          </form>
        </div>

        <div nz-col nzXs="24" nzSm="10">
          <a class="mr-2" nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM'|translate}}"
            (nzOnConfirm)="updateFirmware()">
            <button class="stop-button" nzSpaceItem nz-button nzType="primary" [disabled]="!form.valid"
              [nzBlock]="true">
              {{'BUTTONS.UPDATE_FIRMWARE'|translate}}
            </button>
          </a>
        </div>
      </div>
      <nz-divider nzText="{{'STATIONS.DETAIL.RFID_UPDATE'|translate}}"></nz-divider>
      <div nz-row [nzGutter]="8" nzJustify="space-between" class="mt-10">
        <div nz-col nzXs="24" nzSm="14">
          <form [formGroup]="formRfid" class="padding-t-b">
            <formly-form [form]="formRfid" [model]="modelRfid" [options]="optionsRfid"
              [fields]="fieldsRfid"></formly-form>
          </form>
        </div>

        <div nz-col nzXs="24" nzSm="10">
          <a class="mr-2" nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM'|translate}}"
            (nzOnConfirm)="registerRfid()">
            <button class="stop-button" nzSpaceItem nz-button nzType="primary" [disabled]="!formRfid.valid"
              [nzBlock]="true">
              {{'BUTTONS.REGISTER_RFID'|translate}}
            </button>
          </a>
        </div>
      </div>


      <nz-divider nzText="Aggiornamento singola configurazione"></nz-divider>
      <div nz-row [nzGutter]="8" nzJustify="space-between" class="mt-10">
        <div nz-col nzXs="24" nzSm="14">
          <form [formGroup]="formUpdateConfiguration" class="padding-t-b">
            <formly-form [form]="formUpdateConfiguration" [model]="modelUpdateConfiguration" [options]="optionsUpdateConfiguration"
              [fields]="fieldsUpdateConfiguration"></formly-form>
          </form>
        </div>

        <div nz-col nzXs="24" nzSm="10">
          <a class="mr-2" nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM'|translate}}"
          (nzOnConfirm)="updateConfiguration()">
            <button class="stop-button" nzSpaceItem nz-button nzType="primary" [disabled]="!formUpdateConfiguration.valid"
              [nzBlock]="true">
              {{'BUTTONS.SEND'|translate}}
            </button>
          </a>
        </div>
      </div>



    </ng-container>
  </div>
  <div nz-col nzXs="24" nzSm="24" nzMd="12">
    <nz-divider nzText="{{'STATIONS.DETAIL.REQUEST_RESULT'|translate}}"></nz-divider>
    <nz-card [nzBordered]="false">
      <div>
        {{ textResult$ | async}}
      </div>
    </nz-card>
  </div>
</div>


<nz-divider nzText="{{'STATIONS.DETAIL.CONFIGURATION'|translate}}"></nz-divider>

<div nz-row [nzGutter]="8" nzJustify="space-around" class="mt-10">
  <div nz-col nzXs="24" nzSm="12" nzMd="6">
    <button class="stop-button" nzSpaceItem nz-button nzType="primary" [nzBlock]="true"
      [nzLoading]="!!(gettingConfiguration | async)" (click)="sendGetConfigRequest()">
      {{'STATIONS.DETAIL.CURRENT_CONFIGURATION'|translate}}
    </button>
  </div>
</div>
<div nz-row [nzGutter]="8" class="mt-5">
  <div nz-col nzXs="24" *ngIf="!!(gotConfiguration|async)">
    <nz-card>
      <div class="maxHeight">
        {{configurationResult$ | async}}
      </div>
    </nz-card>
  </div>
</div>

<div class="mt-10" *ngIf="!!(gotConfiguration|async)">
  <div nz-row [nzGutter]="8" nzJustify="space-around" *ngFor="let item of stationConfiguration">
    <div nz-col nzXs="24" nzSm="8">
      <nz-descriptions *ngIf="!!item.readonly">
        <nz-descriptions-item [nzTitle]="item.key">{{item.value}}</nz-descriptions-item>
      </nz-descriptions>
      <form nz-form [formGroup]="configurationForm" *ngIf="!!configurationForm && !item.readonly">
        <nz-form-item>
          <nz-form-label nzRequired [nzFor]="item.key">{{item.key}}</nz-form-label>
          <nz-form-control nzErrorTip="Valore errato">
            <input nz-input [formControlName]="item.key" [value]="item.value" />
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
    <div nz-col nzXs="24" nzSm="4">
      <a class="mr-2" *ngIf="!item.readonly" nz-popconfirm nzPopconfirmTitle="{{'NZPOPCONFIRMTITLE.CONFIRM'|translate}}"
        (nzOnConfirm)="sendChangeConfiguration(item.key)">
        <button class="stop-button" nzSpaceItem nz-button nzDanger nzType="primary" [nzBlock]="true"
          [nzLoading]="(mapConfigurationButtonCommands$ | async).get(item.key)?.sendingCommand">
          {{'BUTTONS.SEND'|translate}}
        </button>
      </a>
    </div>
    <div nz-col nzXs="24" nzSm="12">
      <nz-alert nzCloseable nzType="info" *ngIf="(mapConfigurationButtonCommands$ | async).get(item.key)?.result"
        [nzMessage]="(mapConfigurationButtonCommands$ | async).get(item.key)?.result"></nz-alert>
    </div>
  </div>

</div>
<div>
  @if(!!this.archiveEmpty()){
    <nz-alert class="m-2" nzType="info" nzMessage="Attenzione" nzDescription="{{'REPORT.NO_ELEMENT_IN_ARCHIVE'|translate}}" nzShowIcon></nz-alert>
  }
  @for(Y of archive(); track Y.months){
    <nz-collapse class="mb-10"> <!-- CICLIAMO ARCHIVIO PER ANNO -->
      <nz-collapse-panel [nzHeader]="Y.year" [nzExtra]="extraTemplate" [nzActive]="Y.year==selectedYear">

        <ng-template #extraTemplate>
          <b>{{ Y.items }}</b>
          <i nz-icon [nzType]="'copy'"></i>
        </ng-template>

        <div class="yf-flex yf-flex-space-between yf-flex-row">
          <div>
            <nz-select [ngModel]="selectedMonthMap[Y.year]" class="w-month-select" (ngModelChange)="selectedMonthChanged(Y.year,$event)"> <!-- Select per decidere quale anno far vedere! -->
              <nz-option *ngFor="let M of Y.months" [nzValue]="M.month" [nzLabel]="getSelectLabelMonth(M)"></nz-option>
            </nz-select>
          </div>
          <div>
            <button nz-button *ngIf="needRefresh(Y)" nzType="primary" (click)="selectedMonthChanged(selectedYear,selectedMonth)"> <!-- Se c'è bisogno di refresh mostro il pulsante -->
              <i nz-icon nzType="reload"></i>
              <span *ngIf="isNormalWidth()"> {{'REPORT.RELOAD'|translate}} </span>
            </button>
          </div>
        </div>

        <nz-table #smallTable nzSize="small" [nzData]="archiveItems$[Y.year] | async" class="mt-20" [nzPageSize]="isNormalWidth() ? 10 : 4">
          <tbody>
            <tr *ngFor="let item of smallTable.data" (click)="openFromArchive(item)" class="cursor-pointer">
              <ng-container *ngIf="isNormalWidth()">
                <td class="w-6 align-top">
                  <span nz-icon class="align-baseline" [nzType]="item.definition.icon || 'file-text'" class="text-2xl text-gray-500"></span>
                </td>
                <td>
                  <div class="yf-flex yf-flex-col">
                    <div class="font-semibold">{{ item.definition.name }}</div>
                    <div class="text-xs text-gray-400">{{ item.definition.description }}</div>
                  </div>
                </td>
                <td class="w-64 text-xs text-orange-600 text-right align-top">
                  <nz-tag class="mb-2"><b>{{ item.definition.category }}</b></nz-tag>
                  <nz-tag class="mb-2" nzColor="red">{{ item.ctx.ts | date:'medium' }}</nz-tag>
                </td>
                <td class="w-32 text-right align-bottom">
                  <nz-tag [nzColor]="getFileTypeColor(item.definition.file)">{{ item.definition.file }}</nz-tag>

                  <button nz-button nzType="primary" class="btn-primary mr-1" type="submit" (click)="openFromArchive(item)" [disabled]="item.definition.file !== 'pdf'">
                    <span nz-icon class="align-top" nzType="eye" nzTheme="outline"></span>
                  </button>

                  <button nz-button type="submit" (click)="$event.stopPropagation(); downloadFromArchive(item)">
                    <span nz-icon class="align-top" nzType="download" nzTheme="outline"></span>
                  </button>

                </td>
              </ng-container>
            </tr>
          </tbody>
        </nz-table>

        <div class="w-full">
          <nz-progress  *ngIf="yearDownload$[Y.year] | async as download" [nzPercent]="download.progress"></nz-progress>
        </div>

      </nz-collapse-panel>
    </nz-collapse>
  }
</div>
